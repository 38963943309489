// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse-designer-item-profile-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 10px 0;
}

.browse-designer-item-image img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 33px;
    margin: 0 0 0 0;
}

.browse-designer-item-profile-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 0 0 20px;
}

.browse-designer-item-profile-info .browse-designer-item-info-name h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 0 0;
    padding: 0 0 5px 0;
    text-align: left;
}

.browse-designer-item-profile-info .browse-designer-item-info-location p {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 0 0;
    padding: 0 0 5px 0;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/features/designer/components/DesignerProfileHM.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".browse-designer-item-profile-wrapper {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    margin: 0 0 10px 0;\n}\n\n.browse-designer-item-image img {\n    width: 60px;\n    height: 60px;\n    object-fit: cover;\n    border-radius: 33px;\n    margin: 0 0 0 0;\n}\n\n.browse-designer-item-profile-info {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    padding: 0 0 0 20px;\n}\n\n.browse-designer-item-profile-info .browse-designer-item-info-name h3 {\n    font-size: 16px;\n    font-weight: 700;\n    margin: 0 0 0 0;\n    padding: 0 0 5px 0;\n    text-align: left;\n}\n\n.browse-designer-item-profile-info .browse-designer-item-info-location p {\n    font-size: 14px;\n    font-weight: 400;\n    margin: 0 0 0 0;\n    padding: 0 0 5px 0;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
