// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.similar-products {
    width: 100%;
}

.similar-products-title {
    font-size: 18px;
    font-weight: 500;
    margin: 30px 0 20px 0;
    padding: 0;
    color: black;
}

.similar-products-list {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/features/studio/components/SimilarProducts.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,cAAc;AAClB","sourcesContent":[".similar-products {\n    width: 100%;\n}\n\n.similar-products-title {\n    font-size: 18px;\n    font-weight: 500;\n    margin: 30px 0 20px 0;\n    padding: 0;\n    color: black;\n}\n\n.similar-products-list {\n    display: grid;\n    grid-template-columns: repeat(8, 1fr);\n    grid-gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
