import React, { useRef } from 'react';
import { XCircle } from 'react-feather';
import { useTranslation, Trans } from 'react-i18next';

function ImageUploader({ onImageUpload, loadedImg, removeImg }) {
    const fileInputRef = useRef(null);

    const { t } = useTranslation();
    const onDragOver = (e) => {
        e.preventDefault();
    };

    const onDrop = (e) => {
        e.preventDefault();
        onImageUpload(e.dataTransfer.files);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className='uploader-input'>
            <h4 className="" style={{ fontWeight: "bold", fontSize: "16px" }}><Trans>{t('uploadImageStudio')}</Trans></h4>
            {/* <p className="text-md lg:text-xl mb-5">
                Upload your desire space image to redesign using Hancut Studio in a glance.
            </p> */}
            {loadedImg ?
                <div
                    className="uploader-input-image not-selectable br"
                    style={{
                        cursor: 'pointer',
                        height: '70px',
                        width: '100%',
                        justifyContent: 'left',
                        alignItems: 'center',
                        backgroundColor: '#F5F5F5',
                    }}
                    onClick={handleClick}
                >
                    <div className="uploader-input-image wrap" style={{
                        width: 'auto',
                        aspectRatio: '1/1',
                        margin: '5px',
                    }}>
                        <img src={loadedImg} alt="Upload Image" className="thumbnail-image not-selectable br"
                            onDragOver={onDragOver}
                            onDrop={onDrop}
                            onClick={handleClick}
                            style={{
                                width: '60px',
                                height: '60px',
                                objectFit: 'cover',
                            }} />
                        <XCircle color="white" className='upload-image-remove' onClick={() => removeImg()}
                            style={{
                                top: '-6px',
                                right: '-6px',
                            }} />
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={e => onImageUpload(e.target.files)}
                        accept="image/jpeg, image/jpg, image/png"
                        style={{ display: 'none' }}
                    />
                </div> :
                <div
                    className="uploader-input-box br"
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    onClick={handleClick}
                    style={{
                        cursor: 'pointer',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div style={{
                        height: '26px',
                    }}><img width="26" height="26" src="https://img.icons8.com/sf-regular/48/add-image.png" alt="add-image" /></div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={e => onImageUpload(e.target.files)}
                        accept="image/jpeg, image/png"
                        style={{ display: 'none' }}
                    />
                </div>}
        </div>
    );
}

export default ImageUploader;
