import living_room_1 from './jakomo_1.jpeg';
import living_room_2 from './jakomo_7.png';
import living_room_3 from './jakomo_3.jpg';
import living_room_4 from './jakomo_4.jpeg';
import living_room_5 from './jakomo_5.jpeg';
import living_room_6 from './jakomo_6.jpeg';
import living_room_7 from './jakomo_test_01.png';
import user_test_1 from './living_room_6.jpg';
import user_test_2 from './bedroom_1.jpg';
import user_test_3 from './bedroom_2.jpg';
import user_test_4 from './kitchen_1.jpg';

export {
    living_room_1, living_room_2, living_room_3, living_room_4, living_room_5, living_room_6, living_room_7,
    user_test_1, user_test_2, user_test_3, user_test_4
}