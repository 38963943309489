import React, { useState, useEffect } from "react";
import './StoreBannerItem.css';

import { GetStartedButton } from "../../../components/buttons";

const StoreBannerItem = (props) => {
    return (
        <div className="store-banner-item-wrapper">
            <div className="store-banner-img">
                <img src={props.item?.image} alt="" />
            </div>
            <div className="store-banner-item-overlay"></div>
            <div className="store-banner-item-labels">
                <h2>{props.item.name}</h2>
                <p>{props.item.description}</p>
                <GetStartedButton text={"Shop Now"} showArrow={true} />
            </div>
        </div>
    );
}

export default StoreBannerItem;