import { useState, useEffect } from "react";

export const useWindowResize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= 768 && window.innerWidth < 1280
  );
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1280);

  const listener = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);

    console.log("resize" + window.innerWidth);
    if (window.innerWidth >= 768) {
      setIsTablet(true);
      setIsDesktop(false);
    } else if (window.innerWidth >= 1280) {
      setIsTablet(false);
      setIsDesktop(true);
    } else {
      setIsTablet(false);
      setIsDesktop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return {
    width,
    height,
    isTablet,
    isDesktop,
  };
};
