// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse-designer-item-portfolio {
    width: 18%;
    height: auto;
    margin: 0 0 10px 0;
    cursor: pointer;
    flex-grow: 1;
}

.browse-designer-item-portfolio img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1/1;
}`, "",{"version":3,"sources":["webpack://./src/features/designer/components/BrowseDesignerItemPortfolio.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".browse-designer-item-portfolio {\n    width: 18%;\n    height: auto;\n    margin: 0 0 10px 0;\n    cursor: pointer;\n    flex-grow: 1;\n}\n\n.browse-designer-item-portfolio img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    aspect-ratio: 1/1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
