import React, { useEffect } from "react";
import { BrowseDesignerItem, BrowseDesignerItemPortfolio, useBrowseDesigners } from "../../designer";
import './BrowseDesigners.css';

const BrowseDesigners = () => {
    const { browseDesigners, handleBrowseDesigners, error } = useBrowseDesigners();

    useEffect(() => {
        handleBrowseDesigners();
    }, []);

    return (
        <div className="browse-designers-wrapper">
            <div className="browse-designers">
                <div className="browse-designers-title">
                    <h2>Browse Designers</h2>
                    <p>Find the perfect designer for your project.</p>
                </div>

                <div className="browse-designers-list">
                    {
                        browseDesigners.map((item, index) => {
                            return (
                                <BrowseDesignerItem key={index} item={item} />
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default BrowseDesigners;