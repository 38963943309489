import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../assets/styles/Studio.css';
import logoImg from '../assets/images/my04-new.png';
import logoLabel from '../assets/images/hancut_logo_small.png';
import useToken from './auth/useToken';
import Header from './Header';
import Footer from './Footer';
import '../assets/styles/Header.css'
import 'font-awesome/css/font-awesome.min.css';
import toast, { Toaster } from 'react-hot-toast';
import DropdownMenu from './DropdownMenu';
import { useTranslation, Trans } from 'react-i18next';

export default function AboutApp() {
    const { t } = useTranslation();
    const [userName, setUserName] = useState();
    const [userType, setUserType] = useState();
    const [userProfileImage, setUserProfileImage] = useState(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const { token, setToken } = useToken();

    return (
        <div className="AboutApp">
            <Header userType={userType} userName={userName} userImg={userProfileImage} token={undefined} toggleDropdown={toggleDropdown} />
            <div className="content mobile-padding not-selectable">
                <img src={logoImg} style={{
                    height: "130px",
                }} />
                <section>
                    <h2><Trans>MY04</Trans></h2>
                    {/* <p style={{
                        fontSize: "1.5em",
                    }}><Trans>My Tiny Space, MY04</Trans></p> */}
                </section>

                {/* <section className='center'>
                    <p>
                        <Trans>
                            My04 dreams of a world where more creators can create indoor spaces that contain their creativity more easily and quickly. And My04 hopes that this creative act itself will be a joyful and fun play.
                        </Trans>
                    </p>
                </section>

                <section className='center'>
                    <p>
                        <Trans>
                            My04, Inc. (“My04”) is a creator platform that provides contents generation services to connect indoor spaces with human creativity.
                        </Trans>
                    </p>
                </section> */}

                {/* <img src={logoLabel} style={{
                    marginTop: "77px",
                    marginBottom: "33px",
                    height: "22px",
                }} /> */}
                <section>
                    {/* <h2>Hancut Studio</h2> */}
                    {/* <p style={{
                        fontSize: "1.5em",
                    }}>My Tiny Space, MY04</p> */}
                </section>
                <section className='center'>
                    <p style={{
                        color: "black",
                        fontWeight: 500,
                        fontSize: "16px",
                        wordBreak: "keep-all"
                    }}><Trans>

                            마이공사는 디자인과 기술을 결합하여 새로운 디자인 패러다임을 제시하고, 고객들에게 혁신적인 경험과 더 나은 삶의 가치를 제공합니다.
                            <br />
                            저희는 고객들에게 편리하고 고품질의 공간 디자인 경험을 제공하기 위해 노력하고 단순히 기술을 개발하는 것을 넘어, 사회적 문제를 해결하고 새로운 가능성을 모색하는 열정으로 시작하였습니다.
                            <br />
                            한컷스튜디오는 마이공사의 첫 번째 공간스타일링 서비스이며 스타일링과 인테리어에 관심 있는 모든 분들을 위한 서비스입니다.
                            <br />
                            마이공사 한컷 스튜디오는 한국형 공간스타일링 플랫폼으로 세계로 나아가겠다는
                            꿈을 안고 첫발을 내딛었습니다.
                            <br />
                            저희의 비젼은 솔루션을 넘어 사회에 새로운 기술과 혁신으로 미래를 개척하고
                            인테리어 디자인업계에 긍정적인 변화를 가져오는 것입니다.
                            <br />
                            마이공사와 함께 가치있는 여정을 함께해 주실 것을 기대합니다.
                            <br /><br />
                            감사합니다.
                            <br />
                            대표 양수영
                        </Trans></p>
                </section>

                {/* <div style={{
                    width: "100%",
                    height: "0.5px",
                    marginTop: "44px",
                    borderBottom: "0.5px solid #00000040"
                }} /> */}

                {/* <section>
                    <h2 style={{
                        marginTop: "44px"
                    }}><Trans>TEAM</Trans></h2>
                    <p style={{
                        fontSize: "1.5em",
                        margin: "3px"
                    }}><Trans>Awesome teammates at MY04</Trans></p>
                </section>

                <section>
                    <p style={{
                        fontSize: "1.5em",
                        margin: "22px 0px 0px 0px",
                        color: "#000"
                    }}><Trans>CEO</Trans></p>
                    <p style={{
                        fontWeight: "bold",
                        margin: "3px",
                        color: "#000"
                    }}><Trans>Aglaia</Trans>
                    </p>
                    <p style={{
                        margin: "3px",
                        color: "#A0A0A0"
                    }}><Trans>We will shine every moment in your life.</Trans>
                    </p>
                </section>

                <section>
                    <p style={{
                        fontSize: "1.5em",
                        margin: "22px 0px 0px 0px",
                        color: "#000"
                    }}><Trans>Research & Development</Trans></p>
                    <p style={{
                        fontWeight: "bold",
                        margin: "3px",
                        color: "#000"
                    }}><Trans>Khami</Trans>
                    </p>
                    <p style={{
                        margin: "3px",
                        color: "#A0A0A0"
                    }}><Trans>I dream of a sweet candy-like space.</Trans>
                    </p>
                    <p style={{
                        fontWeight: "bold",
                        margin: "3px",
                        color: "#000"
                    }}><Trans>Andy</Trans>
                    </p>
                    <p style={{
                        margin: "3px",
                        color: "#A0A0A0"
                    }}><Trans>Life is a binary, you either do it or die hard.</Trans>
                    </p>
                </section>

                <section>
                    <p style={{
                        fontSize: "1.5em",
                        margin: "22px 0px 0px 0px",
                        color: "#000"
                    }}><Trans>Prompt E & D</Trans></p>
                    <p style={{
                        fontWeight: "bold",
                        margin: "3px",
                        color: "#000"
                    }}><Trans>April</Trans>
                    </p>
                    <p style={{
                        margin: "3px",
                        color: "#A0A0A0"
                    }}><Trans>My imagination becomes reality.</Trans>
                    </p>
                    <p style={{
                        fontWeight: "bold",
                        margin: "3px",
                        color: "#000"
                    }}><Trans>May</Trans>
                    </p>
                    <p style={{
                        margin: "3px",
                        color: "#A0A0A0"
                    }}><Trans>prompt, make it work, accio, aparecium</Trans>
                    </p>
                </section>

                <section>
                    <p style={{
                        fontSize: "1.5em",
                        margin: "22px 0px 0px 0px",
                        color: "#000"
                    }}><Trans>Business Support Manager</Trans></p>
                    <p style={{
                        fontWeight: "bold",
                        margin: "3px",
                        color: "#000"
                    }}><Trans>Felix</Trans>
                    </p>
                    <p style={{
                        margin: "3px",
                        color: "#A0A0A0"
                    }}><Trans>Everyday Wisdom, Business Innovation</Trans>
                    </p>
                </section> */}

            </div>
            <Footer />
            <DropdownMenu
                isDropdownOpen={isDropdownOpen} />
            <Toaster />
        </div>
    )
} 
