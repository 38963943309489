// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.store-category-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px;
}

.store-category-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.store-category-label h2 {
    color: black;
    font-size: 22px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

/* To move to general components for see-more */
.store-category-see-more {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/features/store/components/StoreCategoryHeader.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,UAAU;AACd;;AAEA,+CAA+C;AAC/C;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,SAAS;IACT,UAAU;IACV,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["\n.store-category-header {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    padding: 0px 10px;\n}\n\n.store-category-label {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: center;\n    width: 100%;\n}\n\n.store-category-label h2 {\n    color: black;\n    font-size: 22px;\n    font-weight: 500;\n    margin: 0;\n    padding: 0;\n}\n\n/* To move to general components for see-more */\n.store-category-see-more {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-end;\n    width: 100%;\n    margin: 0;\n    padding: 0;\n    cursor: pointer;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
