import React from 'react';
import './FeaturedDesignerItem.css';

const FeaturedDesignerItem = ({ designer }) => {
    const { name, location, image } = designer;

    return (
        <div className="featured-designer-item-wrapper">
            <div className="featured-designer-item">
                <div className="featured-designer-item-image">
                    <img src={image} alt="" />
                </div>
                <div className="featured-designer-item-overlay" />
                <div className="featured-designer-item-info">
                    <div className="featured-designer-item-info-name">
                        {name}
                    </div>
                    <div className="featured-designer-item-info-location">
                        {location}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeaturedDesignerItem;