import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../assets/styles/Studio.css';
import useToken from './auth/useToken';
import Header from './Header';
import Footer from './Footer';
import '../assets/styles/Header.css'
import 'font-awesome/css/font-awesome.min.css';
import toast, { Toaster } from 'react-hot-toast';
import DropdownMenu from './DropdownMenu';

export default function Home() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const { token, setToken } = useToken();

    return (
        <div className="DefaultApp">
            <Header toggleDropdown={toggleDropdown} />
            <div className="content mobile-padding not-selectable">
                <section>
                    <h2>우리의 서비스 약관</h2>
                    <p>아래의 약관을 읽고 Hancut Studio 사용자로서의 권리와 책임에 대해 자세히 알아보세요.</p>
                </section>

                <section className='paragraph'>
                    <p className='updated-info'>Updated Oct 16, 2023</p>
                    <p>MY04 플랫폼(“서비스”)을 이용해 주셔서 감사합니다. 이 서비스 약관(“계약”)은 서비스를 통해 생성할 수 있는 이미지 및 기타 자산(“자산”)이나 서비스에 입력할 수 있는 프롬프트에 대한 귀하의 권리, 서비스 이용 및 중재와 같은 중요한 주제들을 설명합니다. 신중히 읽어주시기 바랍니다. 우리의 개인정보 보호정책은 여기에서 귀하의 데이터를 어떻게 처리하는지 설명합니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        이 계약은 MY04 Inc.와 이 조항에 동의하는 개체 또는 사람(‘고객’, ‘당신’ 또는 ‘귀하’)이 체결하며, 고객의 서비스 접근 및 사용을 관리합니다. 이 계약은 고객이 이 계약을 제시받고 서비스를 사용하기 시작하거나(‘효력 발생일’), 자산을 수령하거나 배포할 때 효력이 발생합니다. 이 조항은 시간이 지남에 따라 업데이트되어 고객에게 다시 제시될 수 있습니다. 서비스의 지속적인 사용은 업데이트된 조항에 대한 동의를 구성합니다. 이 계약에 동의하지 않는 경우, 서비스 사용을 중단해 주십시오. 여기에 언급된 기타 문서들도 고객의 서비스 사용에 구속될 수 있으며, 아래의 커뮤니티 가이드라인을 포함합니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p className='policy-title'>1. 서비스 가용성 및 품질</p>
                    <p>
                        MY04는 서비스를 지속적으로 개선하여 더 나은 서비스를 제공합니다. 서비스는 스타일, 생성 알고리즘, 고객에게 제공되는 기능 등을 포함한 수정 및 변경의 대상이 될 수 있습니다. 서비스의 품질, 안정성, 가동 시간 또는 신뢰성에 대한 보장은 없습니다. 서비스 또는 자산의 특성에 대한 종속성을 만들지 마십시오. 서비스에 대한 종속성으로 인한 손해에 대해 MY04는 고객이나 하위 고객에게 책임을 지지 않습니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        서비스와 자산 모두 고객에게 "있는 그대로" 제공되며, 명시적이든 암묵적이든 어떠한 종류의 보증이나 조건도 없으며, 이는 제한없이 권한, 비침해, 상품성 또는 특정 목적에의 적합성의 보증 또는 조건을 포함합니다. 고객은 자산 사용의 적절성을 단독으로 결정하고 서비스 사용과 관련된 모든 위험을 감수합니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        MY04는 언제든지 어떤 이유로든 서비스에 대한 고객의 액세스를 일시 중단하거나 금지할 권한을 가집니다. 고객은 경쟁 연구를 위해 서비스를 사용할 수 없습니다. 서비스 또는 자산을 리버스 엔지니어링할 수 없습니다. 자동화된 도구를 사용하여 서비스를 통해 자산에 액세스하거나 상호 작용하거나 생성할 수 없습니다. 등록된 계정당 한 명의 사용자만 서비스를 사용할 수 있습니다. 서비스의 각 사용자는 한 계정만 가질 수 있습니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        MY04는 서비스 이용 약관 위반에 대한 불만이나 신고를 조사하고 적절하다고 생각하는 모든 조치를 취할 수 있는 권한을 가집니다. 이는 의심되는 불법 행위를 법집행 기관, 규제 기관 또는 기타 제3자에게 보고하고 사용자 프로필, 이메일 주소, 사용 기록, 게시된 자료, IP 주소 및 트래픽 정보와 관련된 정보를 이러한 사람이나 기관에 필요하거나 적절한 것으로 공개하는 것을 포함합니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p className='policy-title'>2. 연령 요구 사항</p>
                    <p>
                        서비스에 액세스함으로써 고객은 만 13세 이상이고 고객의 국가에서 최소 디지털 동의 연령을 충족한다는 것을 확인합니다. 고객의 국가에서 서비스에 액세스할 수 있기 충분히 나이가 찼지만 서비스 약관에 동의할 수 있는 나이가 아닌 경우 고객의 부모 또는 보호자가 고객 대신 약관에 동의해야 합니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        부모님이나 법정 보호자에게 이 약관을 자녀와 함께 읽도록 요청하십시오. 고객이 부모님이나 법정 보호자이고 십대 자녀가 서비스를 사용하도록 허용하는 경우 이 약관은 고객에게도 적용되며 고객은 십대 자녀의 서비스 활동에 대한 책임이 있습니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        MY04는 서비스를 PG-13 및 가족 친화적으로 만들기 위해 노력하지만 자산은 사용자 질문에 따라 인공 지능 시스템에 의해 생성됩니다. 이것은 새로운 기술이며 항상 예상대로 작동하지는 않습니다. 자산의 적합성에 대한 보장은 없습니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p className='policy-title'>3. 개인 정보</p>
                    <p>
                        서비스를 사용함으로써 고객은 MY04에 이메일 주소, 사용자 이름, 청구 정보, 즐겨찾기, 이미지 출력, 입력한 텍스트 프롬프트 또는 서비스에 업로드한 샘플 이미지와 같은 개인 정보를 제공할 수 있습니다. MY04의 개인 정보 보호 정책은 여기에서 찾을 수 있습니다. <a href='/privacy'>개인 정보 보호 정책</a>
                    </p>
                </section>

                <section className='paragraph'>
                    <p className='policy-title'>4. 저작권 및 상표</p>
                    <p>
                        이 섹션에서 Paid Member는 유료 플랜을 구독한 고객을 의미합니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        고객이 MY04에 부여하는 권한
                        서비스를 사용함으로써 고객은 MY04, 그 후속자 및 양수인에게 서비스에 입력하거나 서비스의 지시에 따라 생성된 텍스트 및 이미지 프롬프트를 재생산, 파생 작품을 준비, 공개적으로 표시, 공개적으로 공연, 재허가 및 배포할 수 있는 영구적, 전 세계적, 비독점적, 양도 가능한 무료, 로열티 무료, 취소 불가한 저작권 라이선스를 부여합니다. 이 라이선스는 어떤 당사에 의해 어떤 이유로든 이 계약이 종료되어도 유효합니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        고객의 권리
                        위의 라이선스에 따라 고객은 본 계약에 따라 생성된 모든 자산의 소유자가 됩니다. 이는 다른 사람의 이미지를 업스케일링하는 것을 제외하며, 이 이미지는 원래 자산 제작자의 소유로 유지됩니다. MY04는 고객에게 적용될 수 있는 현재 법률에 대해 어떠한 진술이나 보증도 하지 않습니다. 관할구역의 현재 법률 상태에 대한 자세한 정보를 원하시면 고객의 자신의 변호사에게 문의하십시오. 고객이 후속 개월에 멤버십을 다운그레이드하거나 취소해도 만든 자산의 소유권은 유지됩니다. 그러나 다음과 같은 예외에 해당하는 경우 자산을 소유하지 않습니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        고객이 연간 매출액이 1,000,000 USD 이상인 회사의 직원 또는 소유자이며 고객 대신 서비스를 사용하는 경우 고객이 만든 자산을 소유하려면 고객 대신 서비스에 액세스하는 모든 개인에 대해 "Pro" 또는 "Brand" 멤버십을 구매해야 합니다. 사용이 고객 대신에 해당하는지 확실하지 않은 경우 고객 대신에 해당한다고 가정하십시오.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        유료 회원이 아닌 경우 고객은 만든 자산을 소유하지 않습니다. 대신 MY04는 크리에이티브 커먼즈 비영리 4.0 저작권 국제 라이선스(이하 "자산 라이선스")에 따라 자산에 대한 라이선스를 부여합니다.
                        전체 텍스트는 효력일 현재 여기에서 액세스할 수 있습니다: https://creativecommons.org/licenses/by-nc/4.0/legalcode.
                    </p>
                </section>



                <section className='paragraph'>
                    <p>
                        주의: MY04는 공개 설정에 게시될 때마다 다른 사람이 고객의 이미지와 프롬프트를 사용하고 리믹스할 수 있는 개방형 커뮤니티입니다. 기본적으로 고객의 이미지는 공개적으로 볼 수 있으며 리믹스할 수 있습니다. 위에서 설명한 대로 고객은 MY04에 이를 허용하는 라이선스를 부여합니다. "Pro" 또는 "Brand" 플랜을 구매하면 이러한 일부 공개 공유 기본값을 무시할 수 있습니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p className='policy-title'>5. DMCA 및 게시 취소 정책</p>
                    <p>
                        알림 절차
                        MY04는 다른 사람의 지적 재산권을 존중합니다. 서비스에 위치하거나 서비스에 연결된 자료가 고객의 저작권 또는 상표권을 침해한다고 생각하는 경우 "게시 취소 요청"이라는 제목으로 support@my04.net에 주장된 침해 통지를 보내 다음 내용을 포함해야 합니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        고객의 물리적 또는 전자 서명.
                        침해되었다고 생각하는 저작권 보호 작품(또는 상표)에 대한 식별 정보 또는 클레임에 여러 작품이 포함된 경우 해당 작품의 대표 목록.
                        MY04에서 해당 자료를 찾을 수 있도록 충분히 정확하고 상세한 방식으로 침해되었다고 생각하는 자료에 대한 식별 정보.
                        MY04에서 고객에게 연락할 수 있는 충분한 정보(이름, 우편 주소, 전화 번호 및 가능한 경우 이메일 주소 포함).
                        저작권 소유자, 대리인 또는 법률에 의해 저작권 보호된 자료의 사용이 허가되지 않았다고 생각하는 선의의 신념에 대한 진술.
                        성명서의 정보가 정확하다는 진술.
                        위증의 처벌을 받는 진술에 따라 고객이 저작권 소유자를 대신하여 행동할 수 있다는 진술.
                        저작권 소유자의 권리가 미국 이외의 국가 법률에 따라 발생하는 경우 국가를 식별하십시오.
                        또한 위에 요구된 정보가 포함된 통지를 다음 주소로 보낼 수 있습니다.
                    </p>
                </section>


                <section className='paragraph'>
                    <p>
                        MY04, Inc.
                        Attn: Customer Support Department
                        1101ho, 356, Seocho-daero
                        Seocho-gu, Seoul
                        Republic of Korea
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        앞에서 설명한 내용을 준수하는 통지를 접수한 후 MY04는 침해된 것으로 주장되는 자료에 대한 액세스를 제거하거나 비활성화하거나 해당 자료에 대한 링크를 비활성화할 수 있는 권한을 가집니다. 또한 식별된 자료에 대한 액세스를 제거하거나 비활성화했다고 침해된 것으로 고소된 당사자에게 통지하고, 반복적인 침해 행위에 관여하는 모든 사용자에 대한 서비스 액세스 및 사용을 종료할 수 있습니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        MY04의 서비스에서 자료나 활동이 귀하의 저작권을 침해한다고 고의로 잘못 나타내는 경우 DMCA의 512(f) 절에 따라 손해배상(비용 및 변호사 수임료 포함)에 대한 책임을 질 수 있습니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        반대 통지 절차
                        자료가 제거되거나 액세스가 실수로 또는 오인으로 비활성화되었다고 생각하는 경우 위에 지정된 저작권 대리인에게 서면 통지를 제출하여 반대 통지를 제출할 수 있습니다. 이러한 통지에는 다음과 같은 내용이 크게 포함되어야 합니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p>
                        고객의 물리적 또는 전자 서명.
                        제거되었거나 액세스가 비활성화된 자료에 대한 식별 정보 및 자료가 제거되거나 액세스가 비활성화되기 전에 나타난 위치.
                        MY04에서 고객에게 연락할 수 있는 충분한 정보(이름, 우편 주소, 전화 번호 및, 가능한 경우, 이메일 주소 포함).
                        위에 식별된 자료가 실수로 또는 제거 또는 비활성화될 자료의 오인으로 제거되거나 비활성화되었다고 생각하는 선의의 신념에 대한 진술.
                        귀하의 주소가 위치한 연방 지방 법원의 관할권에 동의하고 문제의 불만을 제기한 사람(또는 그 사람의 대리인)으로부터 서비스를 수락할 것임을 선언하는 진술.
                        반대 통지를 수신할 MY04의 지정 대리인은 위에 표시된 대리인과 동일합니다.
                        DMCA에 따르면 불만을 제기한 당사자가 해당 기간 동안 고객에 대해 법원 조치를 시작하고 이를 MY04에 통지하지 않는 경우 MY04는 제거된 콘텐츠를 10-14 영업일 내에 복원할 수 있습니다.
                        MY04의 서비스에서 자료나 활동이 실수로 또는 오인으로 제거되거나 비활성화되었다고 고의로 크게 잘못 나타내는 경우 DMCA의 512(f) 절에 따라 손해배상(비용 및 변호사 수임료 포함)에 대한 책임을 질 수 있습니다.
                    </p>
                </section>


                <section className='paragraph'>
                    <p className='policy-title'>6. 분쟁 해결 및 준거법</p>
                    <p>
                        본 계약으로부터 발생하거나 관련된 모든 법적 주장 (계약의 해석 또는 이행에 관한 분쟁을 포함하여) (“분쟁”)은 대한민국 법에 의해 지배됩니다. 대문자로 표시한 것에 대해서는 실례합니다. 그러나 우리는 심각하고 합리적인 법적 주장에 대해 이야기하고 있으며 경제적 손해를 발생시키지 않는 개인적인 분쟁은 아닙니다.
                        당사자는 분쟁 발생 후 30일 이내에 분쟁을 해결하기 위해 선의의 노력을 기울일 것입니다. 분쟁이 30일 이내에 해결되지 않은 경우, 본 계약 체결일 현재 유효한 신속한 상업 규칙에 따라 분쟁 해결을 위한 KCAB 국제 분쟁 해결 소원에 의해 중재로 해결됩니다.
                        당사자는 상호 합의에 의해 한 명의 중재인을 선정합니다. 중재는 대한민국 서울에서 영어로 진행됩니다.
                    </p>
                </section>


                <section className='paragraph'>
                    <p>
                        어느 한 측은 중재의 해결을 기다리는 동안 자신의 권리를 보호하기 위해 필요한 가처분 명령을 위해 관할 법원에 신청할 수 있습니다. 중재관은 계약의 구제 조치 및 제한 범위 내에서 공평한 구제 또는 가처분 명령을 명령할 수 있습니다.
                        중재 판정은 당사자들에게 최종적이고 구속력이 있으며, 그 집행은 당사자 중 한 측 또는 그 자산에 대한 관할권을 가진 법원을 포함한 모든 관할 법원에 제출될 수 있습니다.
                        중재관의 분쟁에 대한 최종 결정에 관계없이 각 당사자는 자신의 변호사 및 전문가 비용을 부담합니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p className='policy-title'>7. 무제한 서비스 및 속도 제한</p>
                    <p>
                        무제한 플랜을 구매한 경우, 당사자는 서비스에 대한 무제한 액세스를 제공하기 위해 합리적인 노력을 기울일 것입니다. 그러나 당사자는 서비스의 품질 저하 또는 다른 고객의 중단을 방지하기 위해 속도 제한을 적용할 수 있습니다.
                    </p>
                </section>

                <section className='paragraph'>
                    <p className='policy-title'>8. 결제 및 청구</p>
                    <p>
                        당사자는 제 3자 결제 서비스 제공자를 통해 서비스 사용에 대한 청구서를 발행할 수 있습니다. 제 3자 서비스 제공자의 서비스 약관이 충돌하는 경우 본 계약을 지배하고 대체합니다.
                    </p>
                </section>


                <section className='paragraph'>
                    <p>
                        귀하는 언제든지 귀하의 계획을 취소할 수 있습니다. 또한 커뮤니티 가이드라인 위반 또는 기타 부적절한 서비스 이용을 포함하여 어떠한 이유로든 귀하의 서비스 접근을 종료할 수 있는 권리를 예약합니다. 커뮤니티 가이드라인을 위반하는 것은 본 계약을 위반하는 것입니다. 귀하는 현재 가입 기간 동안 환불되지 않지만 현재 가입 기간이 끝난 후에는 청구되지 않습니다.
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-title'>9. 커뮤니티 가이드라인</p>
                    <p>
                        친절하고 서로를 존중하고 직원을 존중합니다.
                        본질적으로 무례하거나 공격적이거나 다른 방식으로 폭력적인 이미지를 만들거나 텍스트 프롬프트를 사용하지 마십시오. 어떤 종류의 폭력이나 괴롭힘도 용인되지 않습니다.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        성인용 콘텐츠나 고어가 없습니다.
                        시각적으로 충격적이거나 방해가 되는 내용은 피하시기 바랍니다. 일부 문자 입력은 자동으로 차단하겠습니다.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        다른 사람의 창작물을 공유하는 것.
                        다른 사람의 허락 없이 창작물을 공개적으로 다시 게시하지 마십시오.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        공유에 주의하세요.
                        MY04 플랫폼 밖에서 창작물을 공유하는 것은 괜찮지만 다른 사람들이 당신의 콘텐츠를 어떻게 볼 수 있는지 고려해 주시기 바랍니다.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        서비스를 사용하여 정치 캠페인을 위한 이미지를 생성하거나 선거 결과에 영향을 미치기 위해 사용할 수 없습니다.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        이러한 규칙을 어기면 서비스를 금지할 수 있습니다. 저희는 민주주의 국가가 아닙니다. 예의를 지키거나 서비스를 이용할 권리를 잃으세요.
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-title'>10. 책임과 면책의 제한</p>
                    <p>
                        저희는 서비스를 그대로 제공하며, 이에 대한 약속이나 보장은 하지 않습니다.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        귀하는 당사가 이익, 사용, 영업권 또는 데이터의 손실 또는 부수적, 간접적, 특수적, 결과적 또는 모범적인 손해에 대해 귀하 또는 제3자에게 책임을 지지 않는다는 것을 이해하고 동의합니다.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        귀하의 서비스 이용에 대한 책임은 귀하에게 있습니다. 귀하가 다른 사람에게 피해를 주거나 다른 사람과 분쟁을 일으키면 당사는 관여하지 않습니다.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        만약 독자 분이 다른 사람의 지적 재산을 고의로 침해하고 그로 인해 우리가 돈을 쓰게 된다면, 우리는 독자 분을 찾아서 그 돈을 모을 거에요. 우리는 또한 독자 분이 우리의 변호사 비용을 지불하도록 법원을 구하려고 하는 것과 같은 다른 일을 할 수도 있습니다. 그렇게 하지 마세요.
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-title'>11. 미셀라 우리</p>
                    <p>
                        불가항력.
                        어느 당사자도 신의 행위, 자연재해, 테러, 폭동, 전쟁 등을 포함한 합리적인 통제가 불가능한 상황에 의해 발생한 범위 내에서 이행의 실패 또는 지연에 대해 책임을 지지 않습니다.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        대리점 없음.
                        본 계약은 당사자 간 대리점, 파트너십 또는 합작 회사를 만들지 않습니다.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        분리성.
                        본 계약의 일부가 유효하지 않거나, 불법적이거나, 집행 불가능한 경우, 본 계약의 나머지 부분은 계속 유효합니다.</p>
                </section >

                <section className='paragraph'>
                    <p>
                        타사 수혜자가 없습니다.
                        본 계약은 명시적으로 명시하지 않는 한 제3자에게 어떠한 혜택도 부여하지 않습니다.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        생존.
                        본 계약에서 합리적인 사람이 본 계약에서 존속하기를 기대하는 부분과 의무는 다음과 같습니다. 특히 IP와 개인 정보 보호에 관한 것입니다.
                    </p>
                </section>
            </div >
            <DropdownMenu
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen} />
            <Footer />
            <Toaster />
        </div >
    )
}