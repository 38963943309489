import React, { useState, useEffect } from "react";
import { ProductSmall } from "../../store";
import "./SimilarProducts.css";

const SimilarProducts = ({ products }) => {
    return (
        <div className="similar-products">
            <div className="similar-products-title">Similar products</div>
            <div className="similar-products-list">
                {products.map((product) => (
                    <ProductSmall key={product.id} product={product} />
                ))}
            </div>
        </div>
    )
}

export default SimilarProducts;