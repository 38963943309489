import React, { useState, useEffect } from "react";
import './BrowseDesignerItemPortfolio.css';

const BrowseDesignerItemPortfolio = ({ img }) => {
    return (
        <div className="browse-designer-item-portfolio">
            <img src={img} />
        </div>
    );
}

export default BrowseDesignerItemPortfolio;