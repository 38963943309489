import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../assets/styles/Studio.css';
import useToken from './auth/useToken';
import Header from './Header';
import Footer from './Footer';
import '../assets/styles/Header.css'
import 'font-awesome/css/font-awesome.min.css';
import toast, { Toaster } from 'react-hot-toast';
import DropdownMenu from './DropdownMenu';

export default function Home() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const { token, setToken } = useToken();

    return (
        <div className="DefaultApp">
            <Header toggleDropdown={toggleDropdown} />
            <div className="content mobile-padding not-selectable">
                <section>
                    <h2>Our commitment to protecting your privacy</h2>
                    <p>Learn more about how MY04 collects and uses data and your rights as a Hancut Studio user.</p>
                </section>

                <section className='paragraph'>
                    <p className='updated-info'>Updated Nov 8, 2023</p>
                    <p className='policy-title'>1. Introduction</p>
                    <p>
                        This privacy policy (the “Policy”) applies to My04, Inc., the my04.net website, and the My04 contents generation platform (the “Services”). My04, Inc. (“My04”) is a creator platform that provides contents generation services to connect indoor spaces with human creativity.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>As used in this Policy, “personal data” means any information that relates to, describes, could be used to identify an individual, directly or indirectly.
                    </p>
                </section >

                <section className='paragraph'>
                    <p><b className='policy-sub-title'>Applicability: </b>This Policy applies to personal data that My04 collects, uses, and discloses and which may include: (i) data collected through the Services, (ii) data collected through the process of training My04 machine learning algorithms, (iii) data collected through My04 websites, and (iv) data collected from third party sources. Third party sources may include, but not be limited to: public databases, commercial data sources, and the public internet. When you make purchases, we use third-party payment processors to collect credit card or other financial information. My04 does not store the credit card or payment information you provide, only confirmation that payment was made.
                    </p>
                </section >

                <section className='paragraph'>
                    <p><b className='policy-sub-title'>This Policy does not apply to the following information: </b>
                        Personal Data about My04 employees and candidates, and certain contractors and agents acting in similar roles.
                    </p>
                </section >

                <section className='paragraph'>
                    <p><b className='policy-sub-title'>Changes:</b> We may update this Policy from time-to-time to reflect changes in legal, regulatory, operational requirements, our practices, and other factors. Please check this Policy periodically for updates. If any of the changes are unacceptable to you, you should cease interacting with us. When required under applicable law, we will notify you of any changes to this Policy.
                    </p>
                </section >

                <section className='paragraph'>
                    <p><b className='policy-sub-title'>Definitions:</b> Through this Policy, You, or Your means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable. Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to MY04 LLC. Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-title'>2. Collecting and Using Your Personal Data </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-sub-title'>2.1 Types of Data Collected</p><p>
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-sub-title'>2.1.1 Personal Data</p><p>
                        While interacting with the Services, You may provide certain personally identifiable information that could be used to contact or identify You. Personally identifiable information may include, but is not limited to:
                        <br />Your user name for the Services
                        <br />Text or image prompts you input into the Services, or public chats you maintain on the service
                        <br />Your IP address
                        <br />Usage Data
                        <br />Tracking Technologies and Cookies
                        <br />Contact Information
                        <br />Organizational Information like your company title
                        <br />Your Email
                        <br />Cookies
                        <br />Other data that you elect to send to MY04
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-sub-title'>2.2 Use of Your Personal Data</p><p>
                        MY04 may use Personal Data for the following purposes:
                        To provide, maintain, and improve our Service, including to monitor the usage of our Service.
                        To manage Your account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
                        For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
                        To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
                        To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
                        To manage Your requests: To attend and manage Your requests to Us.
                        For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of

                        bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
                        For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-sub-title'>2.3 Sharing of Your Personal Data</p><p>
                        We may share Your personal information in the following situations:
                        <br />With Service Providers, Third Party Vendors, Consultants, and other Business Partners: We may share Your personal information with these parties in order to provide services on our behalf, monitor and analyze the use of our services, contact You, and for the reasons stated in the Agreement. Service Providers to monitor and analyze the use of our Service, to contact You.
                        <br />For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company. With Your consent: We may disclose Your personal information for any other purpose with Your consent.
                        <br />With Law Enforcement: Under certain circumstances, MY04 may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). To the extent we receive a request from Law Enforcement for Your personal data, we will promptly notify You and provide You with a copy of the request, unless we are legally prohibited from doing so.
                        <br />With Other Parties in order to:
                        <br />Comply with a legal obligation
                        <br />Protect and defend the rights or property of the Company
                        <br />revent or investigate possible wrongdoing in connection with the Service Protect the personal safety of Users of the Service or the public
                        <br />Protect against legal liability
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-sub-title'>2.4 Retention of Your Personal Data</p><p>
                        The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                        The Company will also retain Usage Data for internal analysis purposes. Usage

                        Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-sub-title'>2.5 Transfer of Your Personal Data</p><p>
                        Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ from those in Your jurisdiction.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-sub-title'>2.6 Security of Your Personal Data</p><p>
                        The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-title'>3. Children's Privacy</p><p>
                        Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-title'>4. Links to Other Websites</p><p>
                        Our Service may contain links to other websites that are not operated by Us. If
                    </p>
                </section >

                <section className='paragraph'>
                    <p>
                        You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-title'>5. Changes to this Privacy Policy</p><p>
                        We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-title'>6. Supplemental Terms and Conditions for Certain Regions</p>
                </section >

                <section className='paragraph'>
                    <p>Europe<br />
                        If You are located in the European Economic Area (the “EEA”), Switzerland, or the United Kingdom (the “UK”) Our legal basis for collecting and using the Personal Data described in this Policy will depend on the personal data concerned and the specific context in which we collect it. However, we will normally collect personal data from you only where we have your consent to do so, where we need the personal data to perform a contract with you, or where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect personal data from you.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>MY04 may share information internally or with third parties, as further described in this Policy. When we share Personal Data of individuals in the EEA, Switzerland or UK with third parties, we make use of a variety of legal mechanisms to safeguard the transfer including the European Commission-approved standard contractual clauses, as well as additional safeguards where appropriate.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>Additionally You have the following data protection rights:
                    </p>
                </section >

                <section className='paragraph'>
                    <p>You can request access, correction, updates or deletion of your Personal Data.
                        <br />You can object to our processing of your Personal Data, ask us to restrict processing of your Personal Data or request portability of your Personal Data.

                        <br />If we have collected and processed your Personal Data with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your Personal Data conducted in reliance on lawful processing grounds other than consent.
                        <br />You have the right to complain to a data protection authority about our collection and use of your Personal Data.
                        <br />In order to exercise Your rights please contact support@my04.net.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>California<br />
                        This Statement applies solely to residents of California or individuals whose information has been collected in California. MY04 has adopted and included this notice to comply with the California Consumer Privacy Act of 2018 (“CCPA”). Any terms used in this Statement that are defined in the CCPA have the same meaning given therein.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>INFORMATION WE collect</p>
                    <table>
                        <tr>
                            <th>Category</th>
                            <th>Collected</th>
                            <th>Disclosed</th>
                        </tr>
                        <tr>
                            <td>1 Identifiers. Name, alias, postal address, unique personal identifier, online identifier, Internet Protocol (IP) address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers </td> <td> YES </td> <td> YES </td>
                        </tr>
                        <tr><td>2 Personal information categories under the California Customer Records statute (Cal. Civ. Code § 1798.80(e)). A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories. </td> <td> YES </td> <td> YES </td>
                        </tr>
                        <tr><td>3 Protected classification characteristics under California or federal law. Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information). </td> <td> NO </td> <td> NO </td>
                        </tr>
                        <tr><td>4 Commercial information. Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies. </td> <td> YES </td> <td> YES </td>
                        </tr>
                        <tr><td>5 Biometric information. Genetic, physiological, behavioral, and biological
                            characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data. </td> <td> NO </td> <td> NO </td>
                        </tr>
                        <tr><td>6 Internet or other similar network activity. Browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement. </td> <td> NO </td> <td> NO </td>
                        </tr>
                        <tr><td>7 Geolocation data. Physical location or movements. </td> <td> NO </td> <td> NO </td>
                        </tr>
                        <tr><td>8 Sensory data. Audio, electronic, visual, thermal, olfactory, or similar
                            information. </td> <td style={{ fontWeight: "bold" }}> YES* </td> <td> YES </td>
                        </tr>
                        <tr><td>9 Professional or employment-related information. Current or past employment history or performance evaluations.</td> <td> NO </td> <td> NO </td>
                        </tr>
                        <tr><td>10 Education Information under California Family Educational Rights and Privacy Act (20 U.S.C. section 1232g, 34 C.F.R. Part 99) Information that is not “publicly available personally identifiable information” as defined in the California Family Educational Rights and Privacy Act (20 U.S.C. section 1232g, 34 C.F.R. Part 99). Includes education records directly related to a student maintained by an educational institution or party acting on its behalf, like grades, transcripts, class lists and student schedules, identification codes, financial information, or disciplinary records. </td> <td> NO </td> <td> NO </td>
                        </tr>
                        <tr><td>11 Inferences Conclusions that could be used to create a profile reflecting an individual’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, aptitude.</td> <td> YES </td> <td> YES </td>
                        </tr>
                    </table>
                </section >
                <p style={{
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "left",
                    color: "#000000",
                    fontSize: "14px",
                }}>* only images you upload to our services</p>
                <section className='paragraph'>
                    <p>SELLING INFORMATION<br />
                        MY04 does not sell Your Personal Information, as defined under CCPA. If in the future we do sell your personal information, we will notify you and you may have the right to opt-out of such sale.
                    </p>
                </section >
                <section className='paragraph'>
                    <p>YOUR RIGHTS AND CHOICES<br />
                        The CCPA provides individuals residing in California or whose Personal Information was collected in California with specific rights regarding their Personal information. The below describes your rights and how you may exercise them.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>Access to Specific Information and Data Portability Rights<br />
                        You have the right to request that MY04 disclose certain information to you about our collection and use of your Personal Information over the past twelve (12) months. Once MY04 receives and confirms your verifiable information access request, MY04 must disclose to you: (i) the categories of Personal Information we collected about you; (ii) the categories of sources for the Personal Information we collected about you; (iii) our business or commercial purpose for collecting or, if applicable, selling that Personal Information; (iv) the categories of third parties

                        with whom we share that Personal Information; (v) the specific data points or pieces of Personal Information we collected about you. If we disclosed for a business purpose or sold your Personal Information, MY04 must also provide separate lists that: (x) identify the personal information categories that were sold to each category of recipient in connection with sales of your Personal Information; and (y) identify the personal information categories that were provided to each category of recipient in connection with business purposes disclosures of your Personal Information..
                    </p>
                </section >

                <section className='paragraph'>
                    <p>Deletion Request Rights<br />
                        You have the right to request that MY04 delete any of your Personal Information that we collected from you and/or retained. Unless subject to a certain limited exception, once MY04 receives and confirms your verifiable data deletion request, we will delete (and direct our service providers to delete) your personal information from our records. MY04 will notify you promptly if it determines it must deny your deletion request.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>Do Not Sell Opt-Out Rights<br />
                        You have the right to opt-out of any sales, as defined by the CCPA, of Personal Information by MY04. However, MY04 does not sell your information.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>EXERCISING YOUR RIGHTS<br />
                        To exercise your access, data portability, and deletion or do not sell opt-out rights described above, you may submit a verifiable consumer request by any of the following means: By email to support@my04.net.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>You may only make a verifiable consumer request for access or data portability up to two times within a 12-month period. You may make a verifiable do not sell opt-out request at any time. Any such request must: (i) provide sufficient information that allows Us to reasonably verify that you are the person about whom we collected personal information or an authorized representative thereof; and (ii) describe your request with sufficient detail such that we may understand, evaluate, and respond to it. MY04 cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require you to create an account with MY04. MY04 will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request. Only you or a person registered with the California Secretary of State that you authorize to act on your behalf may make a verifiable consumer request related to your Personal Information. You may also make a verifiable consumer request on behalf of your

                        minor child.
                    </p>
                </section >

                <section className='paragraph'>
                    <p>Response Timing and Format
                        We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you in writing of the extension period and the reason for it. MY04 will deliver any required or requested responses or other communications in writing to you by email. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt. If applicable, the response we provide will also explain any reasons we cannot comply with a request. For data portability requests, MY04 will provide your personal information in a format that is readily usable and transferable. MY04 does not charge a fee to process or respond to your verifiable consumer request unless such requests become excessive, repetitive, or manifestly unfounded or as otherwise permitted by the CCPA. If we determine that a request warrants charging a fee, we will notify you and provide you with a cost estimate before completing your request.
                    </p>
                </section >

                <section className='paragraph'>
                    <p className='policy-title'>7. Contact Us</p><p>
                        If you have any questions about this Policy, or would otherwise like to exercise your rights under this Policy or applicable law, you can contact us at support@my04.net</p>
                </section>
            </div>
            <DropdownMenu
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen} />
            <Footer />
            <Toaster />
        </div >
    )
}