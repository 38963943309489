const featuredDesignersList = [
    {
        id: 1,
        name: "Sofia Alvarez",
        location: "Buenos Aires, Argentina",
        image: "https://images.unsplash.com/photo-1573496800808-56566a492b63?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {
        id: 2,
        name: "Kenji Takahashi",
        location: "Tokyo, Japan",
        image: "https://images.unsplash.com/photo-1557862921-37829c790f19?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    },
    {
        id: 3,
        name: "Amelia Dubois",
        location: "Paris, France",
        image: "https://images.unsplash.com/photo-1599110906447-f38264a9c345?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
    }
]

const getFeaturedDesigners = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(featuredDesignersList);
        }, 1000);
    });
}

export default getFeaturedDesigners;