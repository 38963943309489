import React, { useState, useEffect, useRef } from 'react';
import '../../assets/styles/Studio.css';
import '../../assets/styles/StudioBms.css';
import '../../assets/styles/Footer.css';
import '../../assets/styles/filter.css';
import useWebSocket from 'react-use-websocket';
import PromptInput from './PromptInput';
import Header from '../PcarpenterHeader';
import parsePhoneNumber from 'libphonenumber-js'

import ImageViewer from './ImageViewer';
import DropdownMenu from '../DropdownMenu';
import useToken from '../auth/useToken';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ProjectDialog from '../dialog/ProjectDialog';
import DefaultDialog from '../dialog/DefaultDialog';
import SubscriptionDialog from '../dialog/SubscriptionDialog';
import FeedbackDialog from '../dialog/FeedbackDialog'
import toast, { Toaster } from 'react-hot-toast';
import { basic_tips, protips_items } from '../utils/PromptTips';
import { isDesktop, isTablet } from '../utils/Helper';
import { useTranslation, Trans } from 'react-i18next';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from "react-tooltip";
import Select from 'react-select'
import axios from 'axios';
import CalendarDialog from '../dialog/CalendarDialog';
import PcarpenterLogo from '../../assets/images/pcarpenter_logo.png';
import CountUp from 'react-countup';

import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

import { living_room_1, living_room_2, living_room_3, living_room_4, living_room_5, living_room_6 } from '../../assets/images/studio';
import SocialLoginDialog from '../dialog/SocialLoginDialog';
import PcarpenterCTADialog from '../dialog/PcarpenterCTADialog';

function StudioBms() {
  const { t, i18n } = useTranslation();
  const generateHistoryScrollRef = useRef(null);
  const headerRef = useRef(null);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [freeMembershipMonthlyCount, setFreeMembershipMonthlyCount] = useState();
  const [freeMembershipMonthlyLimit, setFreeMembershipMonthlyLimit] = useState();
  const [freeMembershipDailyCount, setFreeMembershipDailyCount] = useState();
  const [freeMembershipDailyLimit, setFreeMembershipDailyLimit] = useState();
  const [myPromptsTotal, setMyPromptsTotal] = useState(0);
  const [myPromptsLimit, setMyPromptsLimit] = useState(0);
  const [isWelcomeDialogShow, setIsWelcomeDialogShow] = useState(true);
  const [isFirstWelcomeDialogShow, setIsFirstWelcomeDialogShow] = useState(false);
  const [isAllowBeta, setAllowBeta] = useState(false);
  const [isAllowJakomo, setIsAllowJakomo] = useState(false);
  const [isSocialLoginDialogShow, setIsSocialLoginDialogShow] = useState(false);
  const [isCTADialogSHow, setIsCTADialogShow] = useState(false);

  const [selected, setSelected] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const [isAgreed, setIsAgreed] = useState(true);

  const loginWithKakao = () => {
    window.Kakao.Auth.authorize({
      redirectUri: 'https://hancut.my04.net/oauth/kakao',
      throughTalk: false,
    });
  };

  useEffect(() => {
    // Load the Kakao SDK
    const script = document.createElement('script');
    script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.5.0/kakao.min.js';
    script.integrity = 'sha384-kYPsUbBPlktXsY6/oNHSUDZoTX6+YI51f63jCPEIPFP09ttByAdxd2mEjKuhdqn4';
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    script.onload = () => {
      // Initialize Kakao SDK
      window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
      // displayToken();
    };
  }, []);

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]

  const serviceOptions = [
    { value: 'essential', label: 'Essential' },
    { value: 'premium', label: 'Premimum' },
    { value: 'basic', label: 'Basic' }
  ]

  const monthOptions = [
    { value: '1', label: '2024년 1월' },
    { value: '2', label: '2024년 2월' },
    { value: '3', label: '2024년 3월' },
    { value: '4', label: '2024년 4월' },
    { value: '5', label: '2024년 5월' },
    { value: '6', label: '2024년 6월' }
  ]

  const areaOptions = [
    { value: '1', label: '150만 원 이하' },
    { value: '2', label: '150-180만 원' },
    { value: '3', label: '180-200만 원' },
    { value: '4', label: '200-230만 원' },
    { value: '5', label: '230-260만 원' },
    { value: '6', label: '260만 원 이상' }
  ]

  const seoulLocations = [
    "서울2", "서울3", "서울4", "서울5", "서울6", "서울8", "서울9", "서울10", "서울11", "서울12", "서울13", "서울15", "서울17", "서울18", "서울19", "서울21", "서울22", "서울23", "서울24", "서울26", "서울27", "서울29", "서울30", "서울31", "서울32", "서울33", "서울34", "서울35", "서울36", "서울37", "서울38", "서울39", "서울41", "서울46", "서울47", "서울51", "서울52", "서울54", "서울55", "서울56", "서울57", "서울59", "서울60", "서울61", "서울63", "서울64", "서울65", "서울66", "서울67", "서울68", "서울70", "서울71", "서울72", "서울76", "서울77", "인천2", "인천4", "인천5", "경기2", "경기5", "경기6", "경기7", "경기8", "경기10", "경기11", "경기12", "경기14", "경기15", "경기16", "경기17", "경기18", "경기22", "경기23", "경기24", "경기25", "경기26", "경기27", "경기28", "경기30", "경기31", "경기32", "경기33", "경기34", "경기35", "경기36", "경기37", "경기39", "경기40", "경기42", "경기43", "경기46", "경기48", "경기49", "경기50", "경기51", "경기53", "경기59", "경기60", "충청3", "강원1", "충청9", "가구1", "건축사1", "건축사2", "방수누수설비1", "단열결로1", "대행2", "도면설계1", "렌탈1", "목공1", "목공5", "빈티지바닥1", "새집증후군1", "욕실1", "이사1", "이사2", "입주청소1", "입주청소2", "입주청소3", "입주청소5", "전기조명1", "정리수납1", "중문1", "중문3", "창호1", "창호3", "철거1", "커튼블라인드3", "커튼블라인드5", "시스템에어컨4", "시스템에어컨5", "가전에어컨1", "친환경1", "페인트2", "페인트3"
  ];

  const tempLocationOptions = seoulLocations.map((location, index) => ({
    value: index + 1,
    label: location
  }));

  const [locationOptions, setLocationOptions] = useState(tempLocationOptions);

  // const locationOptions = [
  //   { value: '1', label: '광교' },
  //   { value: '2', label: '광진' },
  //   { value: '3', label: '동탄' },
  //   { value: '4', label: '마곡' },
  //   { value: '5', label: '마포' },
  //   { value: '6', label: '판교' }
  // ]

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const [protips, setProTips] = useState([]);

  const [isNoSignupPreviewShow, setIsNoSignupPreviewShow] = useState(false);

  const maxBasicTipsCount = 2;
  const getRandomBasicTips = (arr, n) => {
    let shuffled = arr.slice(),
      i = arr.length,
      temp,
      index;

    while (i--) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
    }
    return shuffled.slice(0, n);
  };
  const [basictips, setBasicTips] = useState(getRandomBasicTips(basic_tips, maxBasicTipsCount));

  const [userProfileImage, setUserProfileImage] = useState(null);
  const [isFeedbackDialogShow, setIsFeedbackDialogShow] = useState(false);
  const [feedbackItem, setFeedbackItem] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState({
    status: "none",
  });
  const [userType, setUserType] = useState(-1);

  const { project_id } = useParams();
  const [proejctId, setProjectId] = useState(project_id);
  const [projectTitle, setProjectTitle] = useState(null);
  const [projectDescription, setProjectDescription] = useState(null);

  const [loadingPrompt, setLoadingPrompt] = useState(null);
  const [loadingImg, setLoadingImg] = useState(null);

  const [isFullImageShow, setIsFullImageShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [prompt, setPrompt] = useState('');
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [inputImage, setInputImage] = useState(null);
  const [inputBase64Image, setInputBase64Image] = useState(null);

  const [isGenerationLoading, setIsGenerationLoading] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);

  const [generatedHistory, setGeneratedHistory] = useState([]);
  const [savedGeneratedHistory, setSavedGeneratedHistory] = useState([]);

  // const [selectedStructureOptions, setSelectedStructureOptions] = useState(-1);
  const [selectedRoomOptions, setSelectedRoomOptions] = useState(2);
  const [additionOptionsList, setAdditionOptionsList] = useState([]);
  const [stringifiedAdditionOptionsList, setStringifiedAdditionOptionsList] = useState([]);

  // const [selectedCategory, setSelectedCategory] = useState(0);
  // const [selectedTheme, setSelectedTheme] = useState(0);

  const [mobileViewOption, setMobileViewOption] = useState(0);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isErrorMessageShow, setIsErrorMessageShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isGoToSubscriptionShow, setIsGoToSubscriptionShow] = useState(false);
  const [subscriptionErrorMEssage, setSubscriptionErrorMEssage] = useState("");

  const [isDialogCancelable, setIsDialogCancelable] = useState(true);
  const [DialogAction, setDialogAction] = useState("");
  const [isGenerateMobileOpen, setIsGenerateMobileOpen] = useState(false);

  const [isConfirmDeleteDialogShow, setIsConfirmDeleteDialogShow] = useState(false);
  const [deleteGenerationId, setDeleteGenerationId] = useState("");

  /* WebSocket */
  const WS_URL = process.env.REACT_APP_WS;

  const { sendJsonMessage, readyState } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log('WebSocket connection established.');
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true
  });

  const [pcarpenterUserName, setPcarpenterUserName] = useState("");
  const [pcarpenterNaverId, setPcarpenterNaverId] = useState("");
  const [pcarpenterContact, setPcarpenterContact] = useState("");
  const [pcarpenterAddress, setPcarpenterAddress] = useState("");
  const [pcarpenterDetailedAddress, setPcarpenterDetailedAddress] = useState("");
  const [pcarpenterService, setPcarpenterService] = useState("");
  const [pcarpenterMonth, setPcarpenterMonth] = useState("");
  const [pcarpenterAmount, setPcarpenterAmount] = useState("");
  const [pcarpenterLocation, setPcarpenterLocation] = useState("");

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // useEffect(() => {
  //   if (proejctId && freeMembershipDailyCount && freeMembershipMonthlyCount) {
  //     const isLoginOnce = JSON.parse(localStorage.getItem('isLoginOnce'));
  //     if (isLoginOnce === null || (isLoginOnce !== null && isLoginOnce.status === false)) {
  //       localStorage.setItem('isLoginOnce', JSON.stringify({
  //         status: true,
  //       }));
  //       const tempGenerateData = JSON.parse(localStorage.getItem('tempGenerateData'));
  //       if (tempGenerateData !== null) {
  //         setInputImage(tempGenerateData.inputImage);
  //         setIsImageLoaded(true);
  //         setInputBase64Image(tempGenerateData.init_images[0]);
  //         setLoadingImg(tempGenerateData.init_images[0]);
  //         setPrompt(tempGenerateData.prompt);
  //         setProjectId(tempGenerateData.id);
  //         setSelectedRoomOptions(tempGenerateData.selectedRoomOptions);
  //         setAdditionOptionsList(tempGenerateData.additional_prompt);
  //         // localStorage.removeItem('tempGenerateData');
  //         handleGenerateImage();
  //       }
  //     }
  //   }
  //   else {
  //     console.log(proejctId + ', ' + freeMembershipDailyCount + ', ' + freeMembershipMonthlyCount);
  //   }
  // }, [proejctId, freeMembershipDailyCount, freeMembershipMonthlyCount]);

  const { lastJsonMessage } = useWebSocket(WS_URL, {
    share: true
  });

  const navigate = useNavigate();
  const location = useLocation();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [isProjectDialogShow, setIsProjectDialogShow] = useState(false);

  const defaultCfg = 16;
  const defaultDenoising = 0.70;
  const defaultSeed = -1;
  const defaultStep = 30;
  const maxWidth = 800;
  const maxHeight = 800;

  const [cfgScale, setCfg] = useState(defaultCfg);
  const [denoising, setDenoising] = useState(defaultDenoising);
  const [seed, setSeed] = useState(defaultSeed);
  const [generatingStep, setGeneratingStep] = useState(defaultStep);

  useEffect(() => {
    localStorage.setItem('login_force', 'default');
  }, []);

  // useEffect(() => {
  //   // alert('User Check');
  //   if (userId) {
  //     sendJsonMessage({
  //       userid: userId,
  //       type: 'check_progress'
  //     });
  //   }
  // }, [userId, readyState]);

  // useEffect(() => {
  //   if (JSON.stringify(lastJsonMessage) != null) {
  //     console.log('lastMessage useEffect: ' + JSON.stringify(lastJsonMessage));
  //     setIsGenerationLoading(lastJsonMessage?.in_progress);
  //     if (lastJsonMessage?.userUUID)
  //       console.log(lastJsonMessage?.userUUID);
  //     if (lastJsonMessage?.is_error) {
  //       setErrorMessage("Unexpected error occurs when generating.");
  //       setIsErrorMessageShow(true);
  //     }
  //     else {
  //       refreshUI();
  //     }
  //   }
  // }, [lastJsonMessage]);

  // useEffect(() => {
  //   setStringifiedAdditionOptionsList(additionOptionsList.map(item => JSON.stringify(item)));
  // }, [additionOptionsList]);

  // useEffect(() => {
  //   if (selectedRoomOptions === -1) {
  //     setCfg(defaultCfg);
  //     setDenoising(defaultDenoising);
  //   }
  //   else {
  //     setCfg(cfg_scales[selectedRoomOptions - 1]);
  //     setDenoising(denoising_scales[selectedRoomOptions - 1]);
  //   }
  // }, [selectedStructureOptions, selectedRoomOptions]);

  // useEffect(() => {
  //   if (token) {
  //     if (proejctId === undefined) {
  //       fetch(process.env.REACT_APP_API + '/v1/studio/project/all', {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Bearer ${token}`
  //         }
  //       })
  //         .then(response => {
  //           if (!response.ok) {
  //             return response.json().then(errorInfo => {
  //               // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
  //               setErrorMessage(errorInfo.message);
  //               setIsErrorMessageShow(true);
  //             });
  //           }
  //           else return response.json();
  //         })
  //         .then(data => {
  //           if (data.projects !== undefined) {
  //             if (data.projects.length <= 0) {
  //               loadUser();
  //               // setIsProjectDialogShow(true);
  //               const hasWelcomed = JSON.parse(localStorage.getItem('hasWelcomed'));
  //               if (hasWelcomed === null || (hasWelcomed !== null && hasWelcomed.token !== token)) {
  //                 // setIsWelcomeDialogShow(true);
  //                 localStorage.setItem('hasWelcomed', JSON.stringify({
  //                   status: true,
  //                   token: token
  //                 }));
  //               }
  //               else {
  //                 setIsProjectDialogShow(true);
  //               }
  //             }
  //             else {
  //               // navigate(`/profile`, { replace: true });
  //               setProjectId(data.projects[0]._id);
  //               // navigate(`/studio/${data.projects[0]._id}`, { replace: true });
  //             }
  //           }
  //         })
  //         .catch(error => {
  //           console.log(error.message);
  //         });
  //     }
  //     else if (proejctId) {
  //       loadProjectDetail();
  //       refreshUI();
  //     }
  //   }
  // }, [proejctId]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);

    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize]);

  const maxProTipsCount = 3;

  const getRandomProTips = (arr, n) => {
    let shuffled = arr.slice(),
      i = arr.length,
      temp,
      index;

    while (i--) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
    }
    return shuffled.slice(0, n);
  };

  useEffect(() => {
    if (protips.length === 0 && isSubscribed) {
      setTimeout(function () {
        setProTips(getRandomProTips(protips_items, maxProTipsCount));
      }, 1000);
    }

  }, [protips, isSubscribed]);

  useEffect(() => {
    if (!isSubscribed) {
      setProTips(getRandomProTips(protips_items, 3));
    }
  }, [isSubscribed]);

  const refreshUI = () => {
    loadUser();
  }

  useEffect(() => {
    if (basictips.length === 1) {
      setTimeout(function () {
        setBasicTips(getRandomBasicTips(basic_tips, maxBasicTipsCount));
      }, 1000);
    }
  }, [basictips]);

  const loadUser = () => {
    if (token) {
      fetch(process.env.REACT_APP_API + '/v1/profile', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          if (!response.ok) {
            return response.json().then(errorInfo => {
              setErrorMessage(errorInfo.message);
              setIsErrorMessageShow(true);
            });
          }
          else return response.json();
        })
        .then(data => {
          setPcarpenterUserName(data.user.lname + data.user.fname);

          if (data.user.naver && data.user.naver.id && data.user.naver.id !== "") {
            setPcarpenterNaverId(data.user.naver.id);
          }
          if (data.user.naver && data.user.naver.phone && data.user.naver.phone !== "") {
            setPcarpenterContact(data.user.naver.phone);
          }
          // loadHistory();
          // loadSavedHistory();
          // const user = data.user;
          // const free_membership = data.free_membership;
          // setUserId(user._id);
          // setUserName(user.fname + " " + user.lname);
          // setUserEmail(user.email);
          // setUserProfileImage(user.profile_img);
          // setFreeMembershipMonthlyCount(() => { return free_membership.count; });
          // setFreeMembershipMonthlyLimit(() => { return free_membership.limit; });
          // setFreeMembershipDailyLimit(() => { return free_membership.daily_limit; });
          // setFreeMembershipDailyCount(() => { return data.daily_count; });
          // setUserType(user.role);
          // setAllowBeta(data.allow_beta);
          // setIsAllowJakomo(data.allow_jakomo);
          // if (data.active_subscription) {
          //   setIsSubscribed(true);
          //   setActiveSubscription(data.active_subscription);
          //   setMyPromptsLimit(90);
          // }
          // else {
          //   setMyPromptsLimit(30);
          // }
          // if (data !== undefined) {
          //   if (data.status) {
          //     loadHistory();
          //     loadSavedHistory();
          //     const user = data.user;
          //     const free_membership = data.free_membership;
          //     setUserId(user._id);
          //     setUserName(user.fname + " " + user.lname);
          //     setUserEmail(user.email);
          //     setUserProfileImage(user.profile_img);
          //     setFreeMembershipMonthlyCount(() => { return free_membership.count; });
          //     setFreeMembershipMonthlyLimit(() => { return free_membership.limit; });
          //     setFreeMembershipDailyLimit(() => { return free_membership.daily_limit; });
          //     setFreeMembershipDailyCount(() => { return data.daily_count; });
          //     setUserType(user.role);
          //     setAllowBeta(data.allow_beta);
          //     setIsAllowJakomo(data.allow_jakomo);
          //     if (data.active_subscription) {
          //       setIsSubscribed(true);
          //       setActiveSubscription(data.active_subscription);
          //       setMyPromptsLimit(90);
          //     }
          //     else {
          //       setMyPromptsLimit(30);
          //     }
          //   }
          //   else {
          //     setIsDialogCancelable(false);
          //     setDialogAction('logout')
          //     setErrorMessage(data.message);
          //     setIsErrorMessageShow(true);
          //   }
          // }
        })
        .catch(error => {
          console.log(error.message);
          localStorage.removeItem('session');
          navigate("/login", { replace: true })
          setErrorMessage("Unexpected error occurs.");
          setIsErrorMessageShow(true);
        });
    }
  }

  useEffect(() => {
    // console.log('Checking free membership: ' + freeMembershipDailyCount + ', ' + freeMembershipMonthlyCount);
    if (freeMembershipDailyCount !== undefined && freeMembershipMonthlyCount !== undefined) {
      const isLoginOnce = true; //JSON.parse(localStorage.getItem('isLoginOnce'));
      if (isLoginOnce === null || (isLoginOnce !== null && isLoginOnce.status === false)) {
        const tempGenerateImage = JSON.parse(localStorage.getItem('tempGenerateImage'));
        const tempGenerateBaseImage = JSON.parse(localStorage.getItem('tempGenerateBaseImage'));
        const tempGenerateData = JSON.parse(localStorage.getItem('tempGenerateData'));
        if (tempGenerateImage !== null && tempGenerateBaseImage !== null && tempGenerateData !== null) {
          setInputImage(tempGenerateImage.inputImage);
          setIsImageLoaded(true);
          setInputBase64Image(tempGenerateBaseImage.init_images[0]);
          setLoadingImg(tempGenerateBaseImage.init_images[0]);
          setPrompt(tempGenerateData.prompt);
          setProjectId(tempGenerateData.id);
          setSelectedRoomOptions(tempGenerateData.selectedRoomOptions);
          setAdditionOptionsList(tempGenerateData.additional_prompt);
          localStorage.removeItem('tempGenerateData');
          // handleGenerateImage();
        }
      }
    }
  }, [freeMembershipDailyCount, freeMembershipMonthlyCount]);

  // useEffect(() => {
  //   if (inputBase64Image !== null && prompt !== undefined && proejctId !== undefined && selectedRoomOptions !== undefined && additionOptionsList !== undefined) {
  //     console.log('preparing to generate image for no login users');
  //     if (!isGenerationLoading) {
  //       const isLoginOnce = JSON.parse(localStorage.getItem('isLoginOnce'));
  //       if (isLoginOnce === null || (isLoginOnce !== null && isLoginOnce.status === false)) {
  //         localStorage.setItem('isLoginOnce', JSON.stringify({
  //           status: true,
  //         }));
  //         handleGenerateImage();
  //       }
  //     }
  //   }
  // }, [inputBase64Image, prompt, proejctId, selectedRoomOptions, additionOptionsList]);

  const loadHistory = () => {
    if (proejctId !== null && proejctId !== undefined) {
      fetch(process.env.REACT_APP_API + '/v1/studio/project/' + proejctId + '/history', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        // body: JSON.stringify({ id: proejctId })
      })
        .then(response => {
          if (!response.ok) {
            return response.json().then(errorInfo => {
              // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
              // setErrorMessage(errorInfo.message);
              // setIsErrorMessageShow(true);
            });
          }
          else return response.json();
        })
        .then(data => {
          if (data !== undefined) {
            setGeneratedHistory(data.history.slice(0).reverse());
            if (data.history.length === 0) setIsGenerateMobileOpen(true);
          }
        })
        .catch(error => {
          // console.log(error.message);
          // setErrorMessage("Unexpected error occurs.");
          // setIsErrorMessageShow(true);
        });
    }
  }

  const loadSavedHistory = () => {
    if (proejctId !== null && proejctId !== undefined) {
      fetch(process.env.REACT_APP_API + `/v1/studio/project/${proejctId}/history`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ saved: true })
      })
        .then(response => {
          if (!response.ok) {
            return response.json().then(errorInfo => {
              // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
              setErrorMessage(errorInfo.message);
              setIsErrorMessageShow(true);
            });
          }
          else return response.json();
        })
        .then(data => {
          if (data !== undefined) {
            setSavedGeneratedHistory(data.history.slice(0).reverse());
            setMyPromptsTotal(data.history?.length);
          }
        })
        .catch(error => {
          console.log(error.message);
          setErrorMessage("Unexpected error occurs.");
          setIsErrorMessageShow(true);
        });
    }
  }

  const loadProjectDetail = () => {
    if (proejctId !== undefined) {
      fetch(process.env.REACT_APP_API + '/v1/studio/project/' + proejctId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          if (!response.ok) {
            return response.json().then(errorInfo => {
              // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
              if (errorInfo.message === "Project not found") {
                navigate(`/profile`, { replace: true });
              }
            });
          }
          else return response.json();
        })
        .then(data => {
          if (data !== undefined) {
            setProjectTitle(data.project.title);
            setProjectDescription(data.project.title);

            const hasWelcomed = JSON.parse(localStorage.getItem('hasWelcomed'));
            if (hasWelcomed === null || (hasWelcomed !== null && hasWelcomed.token !== token)) {
              setIsFirstWelcomeDialogShow(true);
              localStorage.setItem('hasWelcomed', JSON.stringify({
                status: true,
                token: token
              }));
            }
          }
        })
        .catch(error => {
          console.log(error.message);
          setErrorMessage("Unexpected error occurs.");
          setIsErrorMessageShow(true);
        });
    }
  }

  const createProject = (credentials) => {
    fetch(process.env.REACT_APP_API + '/v1/studio/project', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(credentials)
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(errorInfo => {
            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
            setErrorMessage(errorInfo.message);
            setIsErrorMessageShow(true);
          });
        }
        else return response.json();
      })
      .then(data => {
        setProjectId(data.project._id);
        navigate(`/studio/${data.project._id}`, { replace: true });
        toast.success(t('project_created'));
      })
      .catch(error => {
        console.log(error.message);
        setErrorMessage("Unexpected error occurs.");
        setIsErrorMessageShow(true);
      });
  }

  const { token, setToken } = useToken();

  useEffect(() => {
    if (!token) setIsSocialLoginDialogShow(true);
  }, [token]);

  // useEffect(() => {
  //   const isLoginOnce = JSON.parse(localStorage.getItem('isLoginOnce'));
  //   if (!token & (isLoginOnce === null || (isLoginOnce !== null && isLoginOnce.status === false))) {
  //     setIsNoSignupPreviewShow(true);

  //     ReactGA.initialize("G-EVSWTBYQ7M");
  //     ReactGA.send({ hitType: "pageview", page: "/no_login_studio" });
  //     setIsFirstWelcomeDialogShow(true);
  //   }
  // }, []);

  // if (!token) {
  //   const isLoginOnce = JSON.parse(localStorage.getItem('isLoginOnce'));
  //   if (isLoginOnce === null || (isLoginOnce !== null && isLoginOnce.status === false)) {

  //   }
  //   else return <LoginForm setToken={setToken} />
  // }

  const addGeneratedHistory = (imgs, prompt, timestamp) => {
    const newGeneratedItem = {
      image_url: imgs, prompt: prompt, timestamp: timestamp,
      feedbacks: [
        { index: 0, feedback: 0 },
        { index: 1, feedback: 0 },
        { index: 2, feedback: 0 },
        { index: 3, feedback: 0 }]
    };
    setGeneratedHistory(prevItems => [newGeneratedItem, ...prevItems]);

    // Mock UI
    // setInputImage('');
    // setIsImageLoaded(false);
    // setInputBase64Image('');
  };

  const onItemSelect = () => {
    setIsFullImageShow(true);
  }

  const onBackgroundClick = () => {
    setIsFullImageShow(false);
  }

  const resetAdvancedSettings = () => {
    setCfg(defaultCfg);
    setDenoising(defaultDenoising);
    setSeed(defaultSeed);
  }

  const getRandomPrompt = () => {
    const promptList = [
      "Images of interior spaces designed in a modern and simple style, with only sofa and lug changed from existing images, the sofa is a modern style with clean lines and neutral colors, and the lug is a modern design with a simple pattern and soft texture",
      "Space image featuring Scandi Navian-style interiors, sofa typical of Nordic design, clean, simple lines, modern feel using natural wood materials and light colors, rugs have soft texture and simple yet sophisticated patterns",
      "Minimalist style change of sofa and lug alone, sofa in modular form or minimalist design, sophisticated color as a point and concise line, lug adds a modern and neat vibe to the space as a whole with a simple design"
    ]

    return promptList[Math.floor(Math.random() * promptList.length)];
  }

  const incrementValue = (type) => {
    if (type === 'cfg') {
      const newValue = Math.min(31, (Number(cfgScale) || 0) + 0.5);
      setCfg(newValue.toString());
    }
    if (type === 'denoise') {
      const newValue = Math.min(1, (Number(denoising) || 0) + 0.05);
      setDenoising(parseFloat(newValue).toFixed(2).toString());
    }
    if (type === 'step') {
      const newValue = Math.min(100, (Number(generatingStep) || 0) + 1);
      setGeneratingStep(newValue.toString());
    }
  };

  const decrementValue = (type) => {
    if (type === 'cfg') {
      const newValue = Math.max(1, (Number(cfgScale) || 0) - 0.5);
      setCfg(newValue.toString());
    }
    if (type === 'denoise') {
      const newValue = Math.max(0, (Number(denoising) || 0) - 0.05);
      setDenoising(parseFloat(newValue).toFixed(2).toString());
    }
    if (type === 'step') {
      const newValue = Math.max(1, (Number(generatingStep) || 0) - 1);
      setGeneratingStep(newValue.toString());
    }
  };

  const handleCfgChange = (event) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= 31) {
      setCfg(inputValue);
    }
  };

  const handleCfgFocusOut = () => {
    if (cfgScale === 0) {
      setCfg(1);
    }
  };

  const handleDenoiseChange = (event) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= 1) {
      setDenoising(inputValue);
    }
  };

  const handleDenoiseFocusOut = () => {
    if (denoising === 0) {
      setDenoising(0);
    }
  };

  const handleStepChange = (event) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= 100) {
      setGeneratingStep(inputValue);
    }
  };

  const handleStepFocusOut = () => {
    if (generatingStep === 0) {
      setGeneratingStep(1);
    }
  };

  const handleSeedChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue === "-" || (!isNaN(inputValue) && inputValue >= -10 && inputValue <= 100000)) {
      setSeed(inputValue);
    }
  };

  const handleSeedFocusOut = () => {
    if (seed <= -1 || seed === "") {
      setSeed(-1);
    }
  };

  const handleImageUpload = (files) => {
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          setInputImage(reader.result);
          setIsImageLoaded(true);
          const base64String = reader.result.replace("data:", "")
            .replace(/^.+,/, "");
          // setInputBase64Image(base64String);
          // setLoadingImg(base64String);

          // Get resized image base64 string
          // const resizedBase64 = canvas.toDataURL('image/jpeg');
          let resizedBase64 = canvas.toDataURL('image/jpeg');
          resizedBase64 = resizedBase64.replace(/^data:image\/jpeg;base64,/, "");
          setInputBase64Image(resizedBase64);
          setLoadingImg(resizedBase64);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleQuickImageBase64 = (image) => {
    try {
      fetch(image)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onloadend = function () {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
              let width = img.width;
              let height = img.height;

              if (width > height) {
                if (width > maxWidth) {
                  height *= maxWidth / width;
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width *= maxHeight / height;
                  height = maxHeight;
                }
              }

              const canvas = document.createElement('canvas');
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, width, height);

              setInputImage(reader.result);
              setIsImageLoaded(true);
              const base64String = reader.result.replace("data:", "")
                .replace(/^.+,/, "");
              // Get resized image base64 string
              let resizedBase64 = canvas.toDataURL('image/jpeg');
              resizedBase64 = resizedBase64.replace(/^data:image\/jpeg;base64,/, "");
              setInputBase64Image(resizedBase64);
              setLoadingImg(resizedBase64);
            };
          }
          reader.readAsDataURL(blob);
        });
      if (!prompt) {
        onImageGenerateItemSelect({ "option": "theme", "value": 0, "keyword": "미니멀리즘" });
        // setPrompt(getRandomPrompt());
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  // const handleImageUpload = (files) => {
  //   const file = files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setInputImage(reader.result);
  //       setIsImageLoaded(true);
  //       const base64String = reader.result.replace("data:", "")
  //         .replace(/^.+,/, "");
  //       setInputBase64Image(base64String);
  //       setLoadingImg(base64String);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // const handleQuickImageBase64 = (image) => {
  //   try {
  //     fetch(image)
  //       .then(response => response.blob())
  //       .then(blob => {
  //         const reader = new FileReader();
  //         reader.onloadend = function () {
  //           setInputImage(reader.result);
  //           setIsImageLoaded(true);
  //           const base64String = reader.result.replace("data:", "")
  //             .replace(/^.+,/, "");
  //           setInputBase64Image(base64String);
  //           setLoadingImg(base64String);
  //         }
  //         reader.readAsDataURL(blob);
  //       });
  //   }
  //   catch (e) {
  //     console.log(e);
  //   }
  // }

  const handleRemoveImage = () => {
    setInputImage('');
    setIsImageLoaded(false);
    setInputBase64Image('');
  }

  const savePrompt = (id, status) => {
    const toast_loading = status ? "Adding..." : "Removing...";
    const toast_message = status ? "Added to My Prompts!" : "Removed from My Prompts!";
    toast.promise(
      fetch(process.env.REACT_APP_API + "/v1/studio/history/" + id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ status: status }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then(errorInfo => {
              // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
              setErrorMessage(errorInfo.message);
              setIsErrorMessageShow(true);
            });
          }
          else return response.json();
        })
        .then((data) => {
          refreshUI();
        })
        .catch(error => {
          console.log("Error saving prompt: ", error);
          setErrorMessage("Unexpected error occurs when saving prompt.");
          setIsErrorMessageShow(true);
        }),
      {
        loading: toast_loading,
        success: <b>{toast_message}</b>,
        error: <b>Unable to update My Prompts.</b>
      },
    );
  };

  const handleGenerateImage = () => {
    if (isNoSignupPreviewShow) {
      // localStorage.setItem('tempGenerateImage', JSON.stringify({
      //   inputImage: inputImage
      // }));
      // localStorage.setItem('tempGenerateBaseImage', JSON.stringify({
      //   init_images: [inputBase64Image]
      // }));
      // localStorage.setItem('tempGenerateData', JSON.stringify({
      //   prompt: prompt,
      //   id: proejctId,
      //   selectedRoomOptions: selectedRoomOptions,
      //   additional_prompt: additionOptionsList,
      // }));

      // ReactGA.initialize("G-EVSWTBYQ7M");
      // ReactGA.event({
      //   category: "User Engagement",
      //   action: "No Login Generate",
      // });

      navigate("/login");
      return;
    }
    if (!isSubscribed) {
      if (freeMembershipDailyLimit - freeMembershipDailyCount <= 0) {
        setSubscriptionErrorMEssage("No remainimg generation left for today. Please try again tomorrow.");
        setIsGoToSubscriptionShow(true);
        return;
      }
      if (freeMembershipMonthlyLimit - freeMembershipDailyCount <= 0) {
        setSubscriptionErrorMEssage("No remainimg generation left for this month. Please try again next month.");
        setIsGoToSubscriptionShow(true);
        return;
      }
    }
    setLoadingPrompt(prompt);
    // setIsGenerationLoading(true);

    const currentDate = new Date();
    const timestamp = currentDate.toUTCString();

    const payload = {
      "enable_hr": true,
      "init_images": [inputBase64Image],
      "prompt": prompt,
      "id": proejctId,
      "selectedRoomOptions": selectedRoomOptions,
      "additional_prompt": additionOptionsList,
    }

    fetch(process.env.REACT_APP_API + "/v1/studio/generate", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then(errorInfo => {
            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
            setErrorMessage(errorInfo.message);
            setIsErrorMessageShow(true);
            throw new Error(errorInfo.message);
          });
        }
        else return response.json();
      })
      .then((data) => {
        console.log(JSON.stringify(data));
        // data.data.images[0] = loadingImg;
        // addGeneratedHistory([loadingImg].concat(data.data.images), prompt, timestamp);
        // setIsGenerationLoading(false);

        // refreshUI();
        setIsGenerationLoading(true);
        setIsGenerateMobileOpen(false);
        if (generateHistoryScrollRef.current) {
          generateHistoryScrollRef.current.scrollTop = 0;
        }

        if (headerRef.current) {
          headerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      })
      .catch(error => {
        // setIsGenerationLoading(false);
        console.log("Error fetching data: ", error);
        setTimeout(function () {
          setErrorMessage(error.message);
          setIsErrorMessageShow(true);
        }, 500);
      });
  };

  const deleteGeneration = (id) => {

    const payload = {
      "id": id,
    }

    fetch(process.env.REACT_APP_API + "/v1/studio/history/" + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then(errorInfo => {
            console.log(errorInfo);
            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
            setErrorMessage(errorInfo.message);
            setIsErrorMessageShow(true);
            return new Error(errorInfo.message);
          });
        }
        else return response.json();
      })
      .then((data) => {
        console.log(JSON.stringify(data));
        if (data.message === "History deleted successfully") {
          refreshUI();
        }
      })
      .catch(error => {
        // setIsGenerationLoading(false);
        console.log("Error fetching data: ", error);
        setTimeout(function () {
          setErrorMessage("Unexpected error occurs when deleting.");
          setIsErrorMessageShow(true);
        }, 500);
      });
  };

  // const handleRefreshClick = useCallback(() => {
  //   setProTips(getRandomProTips(protips_items, maxProTipsCount));
  // }, [protips_items]);

  const handleProTips = (id) => {
    setProTips((prevKeywords) => prevKeywords.filter(k => k._id !== id));
  };

  const onPropTipsItemSelected = (item, prefix) => {
    const time = Date.now();
    setPrompt(item.description);

    // const box = document.getElementById(prefix + String(item._id));
    // const destination = document.getElementById("promptInput");
    // destination.focus();
    // destination.select();

    // const boxRect = box.getBoundingClientRect();
    // const destinationRect = destination.getBoundingClientRect();

    // const moveX = destinationRect.left - boxRect.left;
    // const moveY = destinationRect.top - boxRect.top;

    // box.style.position = 'absolute';
    // box.style.transform = `translate(${moveX}px, ${moveY}px) scale(0.5)`;
    // box.style.opacity = 0;
    // box.style.transition = 'transform 1s, opacity 1s';

    // box.addEventListener("transitionend", () => {
    //   // destination.appendChild(box);
    //   // setTimeout(function () {
    //   //   box.style.animation = "";
    //   //   box.style.transition = '';
    //   //   box.style.transform = 'none';
    //   //   box.style.opacity = 0;
    //   //   // box.style.position = 'relative';
    //   // }, 0);

    //   box.style.animation = "";
    //   box.style.transition = '';
    //   box.style.transform = 'none';
    //   box.style.opacity = 0;
    //   handleProTips(item._id);
    // });

    // const prompt_toast = document.getElementById('prompt-keyword-container-toast');
    // prompt_toast.style.opacity = 1;
    // prompt_toast.style.transition = 'opacity 2s';

    // prompt_toast.addEventListener("transitionend", () => {
    //   prompt_toast.style.opacity = 0;
    // });

    // const prompt_keywords = document.getElementById('prompt-keyword-container');
    // prompt_keywords.style.opacity = 0.5;
    // prompt_keywords.style.filter = "blur(5px)";
    // prompt_keywords.style.transition = 'opacity 2s, filter 2s';

    // prompt_keywords.addEventListener("transitionend", () => {
    //   prompt_keywords.style.opacity = 1;
    //   prompt_keywords.style.filter = "";
    // });
  }

  const handleBasicTips = (id) => {
    setBasicTips((prevKeywords) => prevKeywords.filter(k => k._id !== id));
  };

  const onBasicTipsItemSelected = (item, prefix) => {
    const time = Date.now();
    setPrompt(item.description);

    // const box = document.getElementById(prefix + String(item._id));
    // const destination = document.getElementById("promptInput");
    // destination.focus();
    // destination.select();

    // const boxRect = box.getBoundingClientRect();
    // const destinationRect = destination.getBoundingClientRect();

    // const moveX = destinationRect.left - boxRect.left;
    // const moveY = destinationRect.top - boxRect.top;

    // box.style.position = 'absolute';
    // box.style.transform = `translate(${moveX}px, ${moveY}px) scale(0.5)`;
    // box.style.opacity = 0;
    // box.style.transition = 'transform 1s, opacity 1s';

    // box.addEventListener("transitionend", () => {
    //   // destination.appendChild(box);
    //   // setTimeout(function () {
    //   //   box.style.animation = "";
    //   //   box.style.transition = '';
    //   //   box.style.transform = 'none';
    //   //   box.style.opacity = 0;
    //   //   // box.style.position = 'relative';
    //   // }, 0);

    //   box.style.animation = "";
    //   box.style.transition = '';
    //   box.style.transform = 'none';
    //   box.style.opacity = 0;
    //   console.log('animation ended');
    //   handleBasicTips(item._id);
    // });

    // const prompt_toast = document.getElementById('prompt-keyword-container-toast');
    // prompt_toast.style.opacity = 1;
    // prompt_toast.style.transition = 'opacity 2s';

    // prompt_toast.addEventListener("transitionend", () => {
    //   prompt_toast.style.opacity = 0;
    // });

    // const prompt_keywords = document.getElementById('prompt-keyword-container');
    // prompt_keywords.style.opacity = 0.5;
    // prompt_keywords.style.filter = "blur(5px)";
    // prompt_keywords.style.transition = 'opacity 2s, filter 2s';

    // prompt_keywords.addEventListener("transitionend", () => {
    //   prompt_keywords.style.opacity = 1;
    //   prompt_keywords.style.filter = "";
    // });
  }

  const onMyPromptsItemSelected = (item, prefix) => {
    setPrompt(item.prompt);
  }

  const onImageGenerateItemSelect = (item) => {
    if (!stringifiedAdditionOptionsList.includes(JSON.stringify(item))) {
      setAdditionOptionsList([...additionOptionsList, item]);
    }
    else {
      const newAdditionOptionsList = additionOptionsList.filter(option => option.keyword !== item.keyword);
      setAdditionOptionsList(newAdditionOptionsList);
    }
  }

  const interiorStylesEn = [
    "Minimalism",
    "Mid-century modern",
    "Bohemian",
    "Scandinavian",
    "Industrial",
    "Traditional",
    "Art Deco",
    "Farmhouse",
    "Oriental Style",
    "Mediterranean Style"
  ];

  const roomTypesEn = [
    "Bedroom", "Living Room", "Kitchen", "Study Room", "Dining Room", "Bathroom", "Office", "Cafe", "Restaurant"
  ];

  const interiorStylesKr = [
    "미니멀리즘",
    "미드센추리 모던",
    "보헤미안",
    "스칸디나비안",
    "인더스트리얼",
    "엔틱",
    "아르데코",
    "팜하우스",
    "오리엔탈",
    "지중해 스타일"
  ];

  const roomTypesKr = [
    "침실", "거실", "주방", "서재", "다이닝룸", "욕실", "사무실", "카페", "레스토랑"
  ];

  const interiorStyles = i18n.language === "kr" ? interiorStylesKr : interiorStylesEn;

  const roomTypes = i18n.language === "kr" ? roomTypesKr : roomTypesEn;

  const isValidPhoneNumber = () => {
    if (pcarpenterContact === "") return true;
    if (isNaN(pcarpenterContact)) return false;

    const phoneNumber = parsePhoneNumber(pcarpenterContact, 'KR')

    // const isValid = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(pcarpenterContact);
    return phoneNumber.isValid();
  }

  return (
    <div className="StudioApp">
      <Header headerRef={headerRef} userType={userType} userName={userName} userImg={userProfileImage} token={token} toggleDropdown={toggleDropdown} project={""} />

      {
        (true) &&
        <div className="StudioApp content">
          <div className="holy-grail-grid-bms">
            <section className="left-sidebar-bms studio-scroll" style={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}>
              <div style={{
                display: "flex",
                width: "100%",
                flex: "1",
                maxWidth: "600px",
                flexDirection: "column",
                paddingButton: "200px"
              }}>
                <div className='pcarpenter-header'>
                  <img src={PcarpenterLogo} style={{
                    width: "200px",
                  }}
                    alt='pcarpenter-logo' />
                  {/* <h3 className='pcarpenter-title'></h3> */}
                  <div style={{
                    marginTop: "33px",
                  }} />
                  <p className='pcarpenter-subtitle'>박목수의 열린견적서에 의뢰 주셔서 감사합니다.</p>
                  <p className='pcarpenter-subtitle'>박목수의열린견적서는 모든 견적서를 네이버 카페에 투명하게 일반 공개해 드립니다.</p>
                  <p className='pcarpenter-subtitle'>아래 항목을 작성해 주시면, 빠른 시간 내에 상담 및 견적 가능한 업체를 선정해 드리겠습니다.</p>
                  <p className='pcarpenter-subtitle'>감사합니다.</p>
                </div>

                {/* {
                  isDesktop() || isTablet() ?
                    <>
                      <div className='pcarpenter-counts'>
                        <p className='pcarpenter-counts-subtitle'>누적 시공건수</p>
                        <div className='pcarpenter-counts-row'>
                          <p className='pcarpenter-counts-number'><CountUp end={41025} /><span className='pcarpenter-counts-span'></span></p>
                        </div>
                      </div>

                      <div className='pcarpenter-counts'>
                        <p className='pcarpenter-counts-subtitle'>누적 시공 실적</p>
                        <div className='pcarpenter-counts-row'>
                          <p className='pcarpenter-counts-number'><CountUp end={4215} /><span className='pcarpenter-counts-span'> 억</span></p>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}>
                        <div className='pcarpenter-counts'>
                          <p className='pcarpenter-counts-subtitle'>누적 시공건수</p>
                          <div className='pcarpenter-counts-row'>
                            <p className='pcarpenter-counts-number'><CountUp end={41025} /><span className='pcarpenter-counts-span'></span></p>
                          </div>
                        </div>

                        <div className='pcarpenter-counts'>
                          <p className='pcarpenter-counts-subtitle'>누적 시공 실적</p>
                          <div className='pcarpenter-counts-row'>
                            <p className='pcarpenter-counts-number'><CountUp end={4215} /><span className='pcarpenter-counts-span'> 억</span></p>
                          </div>
                        </div>
                      </div>
                    </>
                } */}
                {/* align-items: stretch;
                  flex-wrap: wrap; */}
                {
                  isDesktop() || isTablet() ?
                    <div style={{
                      display: "flex", flexWrap: "wrap", alignItems: "stretch"
                    }}>
                      <div className='pcarpenter-counts'>
                        <p className='pcarpenter-counts-subtitle'>누적 시공건수</p>
                        <div className='pcarpenter-counts-row'>
                          <p className='pcarpenter-counts-number'><CountUp end={41025} /><span className='pcarpenter-counts-span'></span></p>
                        </div>
                      </div>

                      <div className='pcarpenter-counts'>
                        <p className='pcarpenter-counts-subtitle'>누적 시공 실적</p>
                        <div className='pcarpenter-counts-row'>
                          <p className='pcarpenter-counts-number'><CountUp end={4215} /><span className='pcarpenter-counts-span'> 억</span></p>
                        </div>
                      </div>
                      <div className='pcarpenter-counts'>
                        <p className='pcarpenter-counts-subtitle'>견적의뢰 및 시공후기 </p>
                        <div className='pcarpenter-counts-row'>
                          <p className='pcarpenter-counts-number'><CountUp end={62125} /><span className='pcarpenter-counts-span'> 건</span></p>
                        </div>
                      </div>
                    </div>
                    :
                    <>
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}>
                        <div className='pcarpenter-counts'>
                          <p className='pcarpenter-counts-subtitle'>누적 시공건수</p>
                          <div className='pcarpenter-counts-row'>
                            <p className='pcarpenter-counts-number'><CountUp end={41025} /><span className='pcarpenter-counts-span'></span></p>
                          </div>
                        </div>

                        <div className='pcarpenter-counts'>
                          <p className='pcarpenter-counts-subtitle'>누적 시공 실적</p>
                          <div className='pcarpenter-counts-row'>
                            <p className='pcarpenter-counts-number'><CountUp end={4215} /><span className='pcarpenter-counts-span'> 억</span></p>
                          </div>
                        </div>
                      </div>
                      <div className='pcarpenter-counts'>
                        <p className='pcarpenter-counts-subtitle'>견적의뢰 및 시공후기 </p>
                        <div className='pcarpenter-counts-row'>
                          <p className='pcarpenter-counts-number'><CountUp end={62125} /><span className='pcarpenter-counts-span'> 건</span></p>
                        </div>
                      </div>
                    </>
                }
              </div>

            </section>
            <main className="main-content-bms studio-scroll"
              ref={generateHistoryScrollRef}
              onScroll={() => { setIsGenerateMobileOpen(false) }}
              onTouchStart={() => { setIsGenerateMobileOpen(false) }}
            >

              <div className='pcarpenter-wrapper'>
                <div className='pcarpenter-header'>
                  <h3 className='pcarpenter-title'>시공 상담 신청</h3>
                  <p className='pcarpenter-subtitle'>아래 항목을 작성해 주시면,<br />
                    빠른 시간 내에 상담 및 견적 가능한 업체를<br />
                    선정해 드리겠습니다.<br /></p>
                  <p className='pcarpenter-subtitle'>감사합니다.</p>
                </div>
                <div className='formWrapper'>
                  {
                    isDesktop() && isTablet() ?
                      <div className='formRow firstRow'>
                        <div className='formFirstElement' style={{
                          flex: 1
                        }}>
                          <p style={{
                            whiteSpace: "nowrap",
                          }}>이름</p>
                          <div style={{
                            width: "100%",
                          }}>
                            {
                              pcarpenterNaverId &&
                              <p style={{
                                padding: "0",
                                margin: "0",
                                fontSize: "12px"
                              }}>Naver id: {pcarpenterNaverId}</p>
                            }
                            <input style={{ fontSize: "16px", width: "100%" }} type="text" placeholder="고객님의 성함" value={pcarpenterUserName} onChange={e => setPcarpenterUserName(e.target.value)} />
                          </div>
                        </div>
                        <div className='formSecondElement'>
                          <p>연락처</p>
                          <div>
                            <p style={{
                              padding: "0",
                              margin: "0",
                              fontSize: "12px",
                              color: "#00000000"
                            }}>{pcarpenterNaverId}</p>
                            <input style={{ fontSize: "16px" }} type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="상담이 가능한 핸드폰(문자 안내)" value={pcarpenterContact}
                              onChange={e => setPcarpenterContact(e.target.value.replace("-", ""))}
                              onBlur={() => {
                                if (isValidPhoneNumber()) {
                                  setPcarpenterContact(pcarpenterContact.replace("+82", '0'));
                                }
                              }
                              } />
                          </div>
                        </div>

                      </div>
                      :
                      <>
                        <div className='formRow firstRow' style={{
                          flexDirection: "column",
                          width: "100%",
                        }}>
                          {
                            pcarpenterNaverId &&
                            <p style={{
                              width: "100%",
                              textAlign: "left",
                              padding: "0",
                              margin: "0",
                              fontSize: "12px"
                            }}>Naver id: {pcarpenterNaverId}</p>
                          }
                          <div className='formFirstElement' style={{
                            width: "100%",
                            marginRight: "0px"
                          }}>
                            <p>이름</p>
                            <input style={{ fontSize: "16px" }} type="text" placeholder="고객님의 성함" value={pcarpenterUserName} onChange={e => setPcarpenterUserName(e.target.value)} />
                          </div>
                        </div>
                        <div className='formRow firstRow' style={{
                          marginTop: "0px",
                          marginBottom: "0px"
                        }}>
                          <div className='formFirstElement' style={{
                            marginRight: "0px",
                            marginTop: "0px",
                            marginBottom: "0px"
                          }}>
                            <p style={{
                              marginBottom: "0px",
                            }}>연락처</p>
                            <input style={{ fontSize: "16px" }} type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="상담이 가능한 연락처" value={pcarpenterContact}
                              onChange={e => setPcarpenterContact(e.target.value.replace("-", ""))}
                              onBlur={() => {
                                if (isValidPhoneNumber()) {
                                  setPcarpenterContact(pcarpenterContact.replace("+82", '0'));
                                }
                              }
                              } />
                          </div>
                        </div>
                      </>
                  }
                  {/* <div className='formSearch'>
                    <input type="text" placeholder="Naver Id" value={pcarpenterNaverId} onChange={e => setPcarpenterNaverId(e.target.value)} />
                  </div> */}
                  <div className='formSearch'>
                    <p style={{
                      marginTop: "0",
                      marginBottom: "0",
                      paddingTop: "0",
                      paddingBottom: "0"
                    }}>
                      공사
                    </p>
                    <input type="text" placeholder="주소 선택" value={pcarpenterAddress} onClick={() => {
                      new window.daum.Postcode({
                        oncomplete: function (data) {
                          // Your code here
                          console.log(JSON.stringify(data));
                          setPcarpenterAddress(data.address);
                        }
                      }).open();
                    }} />
                  </div>
                  <div className='formSearch'>
                    <p style={{
                      marginTop: "0",
                      marginBottom: "0",
                      paddingTop: "0",
                      paddingBottom: "0",
                      opacity: "0",
                    }}>
                      공사
                    </p>
                    <input style={{ fontSize: "16px" }} type="text" placeholder="나머지  주소 작성" value={pcarpenterDetailedAddress} onChange={e => setPcarpenterDetailedAddress(e.target.value)} />
                  </div>
                  <div className='formSearch'
                    onClick={() => {
                      setShowCalendar(true);
                    }}
                    data-tooltip-id="my-tooltip" data-tooltip-content="공사 예정일이 5개월 이후인 경우에는 '열린견적서' 가 불가능할 수 있음을 양해해 주시기 바랍니다. (부분공사의 경우 3개월 적용)">

                    <p style={{
                      marginTop: "0",
                      marginBottom: "0",
                      paddingTop: "0",
                      paddingBottom: "0",
                      opacity: "0",
                    }}>
                      공사
                    </p>
                    <input style={{ fontSize: "16px" }} type="text" placeholder="공사(또는 의뢰) 예정일" value={pcarpenterMonth} readonly="readonly" />
                  </div>
                  {/* <div className='formRow'>
                    <div className='formRowSingle'>
                      <Select options={areaOptions} className='select' placeholder='평당 예산' onChange={(e) => {
                        setPcarpenterAmount(e.label);
                      }} />
                    </div>
                  </div> */}
                  <div className='formSearch'
                    data-tooltip-id="my-tooltip"
                    placeholder=''
                    data-tooltip-content="기재해주시지 않은 경우에는, 카페 본부에서 공사 지역과 공사 내용을 고려하여 최적의 업체를 선정해 드립니다. ' 공동구매 신청'인 경우는 공동구매 회수(예: '100회')를 적어주시고, '열린업체 지정'인 경우에는 열린업체명(예 :  '서울1호')을 적어주세요. 업체 지정을 하시더라도, 해당 업체의 사정상 상담이 불가능한 경우에는 본부에서 다른 최적의 업체를 선정해 드립니다. 이점 양해 바랍니다.">
                    <p style={{
                      marginTop: "0",
                      marginBottom: "0",
                      paddingTop: "0",
                      paddingBottom: "0"
                    }}>
                      내용
                    </p>
                    <textarea style={{ fontSize: "16px", lineHeight: "30px" }} type="text" placeholder="평형과 구체적인 공사 내용을 적어주세요." value={pcarpenterAmount} onChange={e => setPcarpenterAmount(e.target.value)} />
                  </div>
                  <div className='formRow'>
                    <div className='formRowSingle' data-tooltip-id="my-tooltip" data-tooltip-content="희망 열린업체 선택이 없을 시에는 최적 업체를 박열견 본부에서 선정해드립니다.">
                      <p style={{
                        marginTop: "0",
                        marginBottom: "0",
                        paddingTop: "0",
                        paddingBottom: "0"
                      }}>
                        상담
                      </p>
                      <Select
                        options={locationOptions} className='select' placeholder='희망 열린업체 선택이 없을 시에는 최적 업체를 박열견 본부에서 선정해드립니다.' onChange={(e) => {
                          setPcarpenterLocation(e.label);
                        }} />
                    </div>
                  </div>
                  {
                    isDesktop() || isTablet() &&
                    <ReactTooltip id="my-tooltip" style={{
                      maxWidth: isDesktop() ? "500px" : "300px",
                      wordBreak: "keep-all",
                    }} />
                  }

                  <p style={{
                    fontSize: "13px",
                  }}>
                    <label>
                      <Checkbox
                        onChange={() => {
                          setIsAgreed(!isAgreed);
                        }}
                        defaultChecked={isAgreed}
                      />
                      &nbsp; <a style={{ color: 'gray' }} href="http://cafe.naver.com/pcarpenter/373170" target="_blank">개인정보 수집/이용 동의 및 제3자 제공에 동의</a>
                    </label>
                    &nbsp;&nbsp;
                  </p>
                  <button className="generate-button" style={{
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                    onClick={async () => {
                      if (!isAgreed) {
                        alert("개인정보 수집/이용 동의 및 제3자 제공에 동의먼저 해주세요.");
                        return;
                      }
                      if (pcarpenterUserName === "") {
                        alert("이름을 입력해주세요.");
                        return;
                      }
                      if (pcarpenterContact === "") {
                        alert("연락처를 입력해주세요.");
                        return;
                      }
                      if (!isValidPhoneNumber()) {
                        alert("연락처를 정확히 입력해주세요.");
                        return;
                      }
                      // const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                      // if (!regex.test(pcarpenterContact)) {
                      //   alert("연락처를 정확히 입력해주세요.");
                      //   return;
                      // }
                      if (pcarpenterAddress === "") {
                        alert("주소 선택해주세요.");
                        return;
                      }
                      if (pcarpenterMonth === "") {
                        alert("공사(또는 의뢰) 예정일을 선택해주세요.");
                        return;
                      }
                      if (pcarpenterAmount === "") {
                        alert("공사 내용을 입력해주세요.");
                        return;
                      }
                      try {
                        await axios.post(process.env.REACT_APP_API + "/create_pcarpenter", {
                          name: pcarpenterUserName,
                          naverid: pcarpenterNaverId,
                          contact: pcarpenterContact,
                          address: pcarpenterAddress,
                          detailedAddress: pcarpenterDetailedAddress,
                          service: pcarpenterService,
                          month: pcarpenterMonth,
                          amount: pcarpenterAmount,
                          location: pcarpenterLocation,
                        });
                        setIsCTADialogShow(true);
                      }
                      catch (e) {
                        console.log(e);
                      }
                    }}><Trans>상담 신청하기</Trans></button>
                  <button className="generate-button" style={{
                    marginTop: "0px",
                    marginBottom: "30px",
                  }}
                    onClick={() => {
                      window.open("https://cafe.naver.com/pcarpenter?iframe_url_utf8=%2FArticleRead.nhn%253Fclubid%3D17593353%2526menuid%3D1724%2526boardtype%3DI%2526page%3D1%2526specialmenutype%3D%2526userDisplay%3D20%2526articleid%3D771016", "_blank", "noreferrer");
                    }} >이벤트</button>
                </div>
              </div>
            </main>
          </div>
        </div >
      }

      <ProjectDialog
        title={"Create New Project"}
        description={"Create project for each of your spatial styling project, or each of your space."}
        show={isProjectDialogShow}
        isCancellable={false}
        onClose={() => { setIsProjectDialogShow(false) }}
        onOk={(title, description) => {
          setIsProjectDialogShow(false);
          createProject({ title: title, description: description });
        }}
        onCancel={() => { setIsProjectDialogShow(false) }}
        enableBackgroundClick={false} />

      {/* {
        isWelcomeDialogShow &&
        <DefaultDialog
          title={"Welcome to Hancut Studio by MY04"}
          description={t('welcome_description')}
          show={isWelcomeDialogShow}
          isCancellable={false}
          onClose={() => {
            setIsWelcomeDialogShow(false);
          }}
          onOk={() => {
            setIsProjectDialogShow(true);
            setIsWelcomeDialogShow(false);
          }}
          okBtn='Create Your First Project'
          onCancel={() => { setIsWelcomeDialogShow(false); }}
          enableBackgroundClick={false} />
      } */}

      {/* {
        isFirstWelcomeDialogShow &&
        <DefaultDialog
          title={"Welcome to Hancut Studio by MY04"}
          description={t('welcome_description')}
          show={isFirstWelcomeDialogShow}
          isCancellable={false}
          onClose={() => {
            setIsFirstWelcomeDialogShow(false);
          }}
          onOk={() => {
            setIsFirstWelcomeDialogShow(false);
          }}
          okBtn='Continue To Create'
          onCancel={() => { setIsFirstWelcomeDialogShow(false); }}
          enableBackgroundClick={true} />
      } */}

      {
        isConfirmDeleteDialogShow && <DefaultDialog
          title={"Confirm Delete"}
          description={"Are you sure want to delete this generation? This action cannot be undone."}
          show={isConfirmDeleteDialogShow}
          isCancellable={true}
          onClose={() => {
            setIsConfirmDeleteDialogShow(false);
            setDeleteGenerationId("");
          }}
          onOk={() => {
            setIsConfirmDeleteDialogShow(false);
            deleteGeneration(deleteGenerationId)
            setDeleteGenerationId("");
          }}
          okBtn='Delete'
          onCancel={() => { setIsConfirmDeleteDialogShow(false); }}
          enableBackgroundClick={true} />
      }

      {
        isErrorMessageShow && <DefaultDialog
          title={"Unable to perform request"}
          description={errorMessage}
          show={isErrorMessageShow}
          isCancellable={isDialogCancelable}
          onClose={() => {
            setIsErrorMessageShow(false);
          }}
          onOk={() => {
            setIsErrorMessageShow(false);
            if (DialogAction === 'logout') {
              localStorage.removeItem('session');
              navigate("/login", { replace: true })
            }
          }}
          okBtn='Ok'
          onCancel={() => {
            setIsErrorMessageShow(false);
          }}
          enableBackgroundClick={true} />
      }

      {
        isGoToSubscriptionShow && <SubscriptionDialog
          title={"Unable to generate new images"}
          description={subscriptionErrorMEssage}
          show={isGoToSubscriptionShow}
          isCancellable={true}
          onClose={() => {
            setIsGoToSubscriptionShow(false);
          }}
          onOk={() => {
            setIsGoToSubscriptionShow(false);
            navigate("/pricing");
          }}
          onCancel={() => {
            setIsGoToSubscriptionShow(false);
          }}
          enableBackgroundClick={true} />
      }

      {
        isFeedbackDialogShow &&
        <FeedbackDialog
          item={feedbackItem}
          isCancellable={true}
          onClose={() => {
            setIsFeedbackDialogShow(false)
          }}
          onOk={() => {
            setIsFeedbackDialogShow(false);
          }}
          show={isFeedbackDialogShow}
          onCancel={() => { setIsFeedbackDialogShow(false) }}
          enableBackgroundClick={true} />
      }

      <DropdownMenu
        userName={userName}
        userEmail={userEmail}
        userImg={userProfileImage}
        isSubscribed={isSubscribed}
        activeSubscription={activeSubscription}
        isDropdownOpen={isDropdownOpen} />

      {
        isFullImageShow ?
          <ImageViewer
            generated_history={generatedHistory}
            currentIndex={currentIndex}
            isSubscribed={isSubscribed}
            setCurrentIndex={setCurrentIndex}
            onBackgroundClick={onBackgroundClick} /> : <div></div>
      }
      {
        isSocialLoginDialogShow &&
        <SocialLoginDialog
          title={"Login"}
          description={"Please login to continue."}
          show={isSocialLoginDialogShow}
          isCancellable={true}
          onClose={() => {
            setIsSocialLoginDialogShow(false);

            const session = localStorage.getItem('session');
            if (session) {
              const user = JSON.parse(localStorage.getItem("user"));
              setPcarpenterUserName(user.lname + user.fname);
            }
          }}
          onOk={() => {
            setIsSocialLoginDialogShow(false);
          }}
          onCancel={() => {
            setIsSocialLoginDialogShow(false);
          }}
          enableBackgroundClick={true}
          kakaoLogin={loginWithKakao} />
      }
      <CalendarDialog
        show={showCalendar}
        isCancellable={true}
        onCancel={() => {
          setShowCalendar(false);
        }}
        enableBackgroundClick={true}
        selected={selected}
        onDaySelect={(e) => {
          setShowCalendar(false);
          if (e) {
            setSelected(e);
            setPcarpenterMonth((e.getMonth() + 1) + "월 " + e.getDate() + "일");
          }
        }} />
      <PcarpenterCTADialog
        show={isCTADialogSHow}
        isCancellable={true}
        onCancel={() => {
          setIsCTADialogShow(false);
        }}
        enableBackgroundClick={true}
        onOk={() => {
          setIsCTADialogShow(false);
        }}
      />
      <Toaster />
    </div >
  );
}

export default StudioBms;