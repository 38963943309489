import { useRef, useEffect, useState } from "react";

const LazyImage = ({
    placeholderSrc,
    placeholderClassName,
    placeholderStyle,
    src,
    alt,
    className,
    style,
    onItemClick
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const placeholderRef = useRef(null);

    return (
        <>
            {isLoading && (
                <div style={!isLoading ? { display: "none" } : placeholderStyle} className={placeholderClassName} ref={placeholderRef} alt=""></div>
            )}
            <img
                src={src}
                className={className}
                style={isLoading ? {
                    width: '100%',
                    height: '1px',
                    position: "absolute"
                } : style}
                alt={alt}
                onClick={() => onItemClick(src)}
                onLoad={() => setIsLoading(false)}
            />
        </>
    );
};
export default LazyImage;