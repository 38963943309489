import React from "react";
import './DesignerProfileHM.css';

const DesignerProfileHM = ({ item }) => {
    const { name, location, image } = item;

    return (
        <div className="browse-designer-item-profile-wrapper">
            <div className="browse-designer-item-image">
                <img src={image} />
            </div>
            <div className="browse-designer-item-profile-info">
                <div className="browse-designer-item-info-name">
                    <h3>{name}</h3>
                </div>
                <div className="browse-designer-item-info-location">
                    <p>{location}</p>
                </div>
            </div>
        </div>
    )
}

export default DesignerProfileHM;