import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import useToken from "../../../components/auth/useToken";

const useKakaoLogin = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { token, setToken } = useToken();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Load the Kakao SDK
        const script = document.createElement('script');
        script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.5.0/kakao.min.js';
        script.integrity = 'sha384-kYPsUbBPlktXsY6/oNHSUDZoTX6+YI51f63jCPEIPFP09ttByAdxd2mEjKuhdqn4';
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);

        script.onload = () => {
            // Initialize Kakao SDK
            window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
        };

        const share = searchParams.get("share");
        if (share) {
            // setShareId(share); TO CHECK WHAT IT DOES
        }
    }, []);

    const handleKakaoAuthAuthorize = async () => {
        try {
            window.Kakao.Auth.authorize({
                redirectUri: 'https://hancut.my04.net/oauth/kakao',
            });
        }
        catch (error) {
            setError(error.message || 'Failed to login using kakao!');
        }
    };

    return { handleKakaoAuthAuthorize, loading, error };
};

export default useKakaoLogin;