import React from "react";
import { ArrowRight } from "react-feather";
import "./StoreCategoryHeader.css";

const StoreCategoryHeader = () => {
    return (
        <div className="store-category-header">
            <div className="store-category-label">
                <h2>Best 가구 모음</h2>
                <p>가구를 구매하고 싶은데 어떤 가구를 구매해야 할지 모르겠다면? Best 가구를 구매해보세요!</p>
            </div>

            {/* This should move to general components */}
            <div className="store-category-see-more">
                See More
                <ArrowRight size={16} style={{
                    marginTop: "2px",
                    marginLeft: "5px"
                }} />
            </div>
        </div>
    );
}

export default StoreCategoryHeader;