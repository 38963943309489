import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Header from '../../components/Header';
import Footer from "../../components/Footer";
import { BeforeAfter } from "../../components/images";
import { GetStartedButton, GoogleLoginLogoButton } from "../../components/buttons";
import { useGoogleLogin, useKakaoLogin } from "../../features/auth";

import './LandingRedesign.css';

import kakao_img from '../../assets/images/kakao_img.png';
import landing_compose from '../../assets/images/landing/landing_compose.png';
import img2img_row1 from '../../assets/images/landing/img2img_row1.png';
import img2img_row2 from '../../assets/images/landing/img2img_row2.png';
import unlimited_grid from '../../assets/images/landing/unlimited_grid.png';
import studio_highlight from '../../assets/images/landing/landing_product_highlight_4.png';
import before_image from '../../assets/images/landing/before_image.png'
import after_image from '../../assets/images/landing/after_image.png'

import { Image, ShoppingBag, Users } from "react-feather";
import ReactGA from "react-ga4";

import useToken from "../../components/auth/useToken";

// TO FIX
import DropdownMenu from '../../components/DropdownMenu';

function LandingPage() {

    const navigate = useNavigate();
    const { token, setToken } = useToken();

    const { handleGoogleLogin, loading: googleLoginLoading, error: loginError } = useGoogleLogin();
    const { handleKakaoAuthAuthorize, error: kakaoLoginError } = useKakaoLogin();

    const { t } = useTranslation();

    // TO FIX USING HOOK AND HEADER COMPONENT
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleGetStarted = () => {
        if (token) {
            navigate("/studio");
        } else {
            const isLoginOnce = JSON.parse(localStorage.getItem('isLoginOnce'));
            if (isLoginOnce !== null && isLoginOnce.status === true) {
                navigate("/login");
                return;
            }
            else {
                // ReactGA.initialize("G-EVSWTBYQ7M");
                // ReactGA.event({
                //     category: "User Engagement",
                //     action: "No Login Get Started",
                // });
                navigate("/login");
                return;
            }
        }
    }

    return (
        <div>
            <div className="landing-page">
                <div className="landing-page-container">
                    <Header toggleDropdown={toggleDropdown} />
                    <div className="first-row-background">
                        <div className="first-row-wrapper">
                            <div className="first-row">
                                <div className="first-row-image-layer">
                                    <div className="first-row-image-left" />
                                    <div className="first-row-image-right">
                                        <img src={landing_compose} />
                                    </div>
                                </div>
                                <div className="first-row-left">
                                    <p className="first-row-title"><Trans>Revolutionize Your Home with AI Interior Design</Trans></p>
                                    <p className="first-row-subtitle"><Trans>Transform your living space effortlessly with our cutting-edge AI Interior Design tool. Simply upload a photo of your room, and watch as our intelligent system interprets your style preferences, crafting a personalized interior design tailored just for you. Gone are the days of envying others' homes; now, you can realize your dream space with ease and efficiency. Experience the future of home styling with our AI Space Styling Design Tool – where your imagination meets reality.</Trans></p>
                                    <div className="first-row-button-container">

                                        <GetStartedButton text={t('start_1_year_free')} onClick={handleGetStarted} />

                                        <button href="javascript:;"
                                            className='card-view-shadow2'
                                            style={{
                                                borderRadius: "30px",
                                                width: "63px",
                                                height: "63px",
                                                backgroundColor: "#FEE500",
                                            }}
                                            onClick={handleKakaoAuthAuthorize}>
                                            <img src={kakao_img} style={{
                                                width: "18px",
                                                height: "auto",
                                                marginTop: "6px",
                                            }}></img>
                                        </button>

                                        <GoogleLoginLogoButton
                                            onSuccessResponse={(credentialResponse) => {
                                                console.log(JSON.stringify(credentialResponse));
                                                if (credentialResponse.access_token) {
                                                    handleGoogleLogin({ "access_token": credentialResponse.access_token });
                                                }
                                            }} onFailureResponse={(error) => {

                                            }} />
                                    </div>
                                    <p className="no-commitment-label"><Trans>* Only apply during beta lunching period.</Trans></p>

                                    <div className="first-row-key-components">
                                        <div className="first-row-key-component">
                                            <p className="first-row-key-component-key">20+</p>
                                            <p className="first-row-key-component-description">Style choices</p>
                                        </div>
                                        <div className="first-row-key-component">
                                            <p className="first-row-key-component-key">300+</p>
                                            <p className="first-row-key-component-description">Trusted designers</p>
                                        </div>
                                        <div className="first-row-key-component">
                                            <p className="first-row-key-component-key">Unlimited</p>
                                            <p className="first-row-key-component-description">Image generation</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="first-row-right">
                                    <img src={landing_compose} />
                                </div>

                                {/* <img src={landing_compose} className="first-row-compose" /> */}
                            </div>
                        </div>
                    </div>

                    <div className="two-column-grid-content">
                        <div className="two-column-grid-content-wrapper">
                            <div className="two-column-grid-content-row">

                                <div className="two-column-grid-content-left">
                                    <img src={img2img_row1} />
                                </div>
                                <div className="two-column-grid-content-right">
                                    <p className="two-column-row-title"><Trans>Unleash Your Inner Designer</Trans></p>
                                    <p className="two-column-row-subtitle"><Trans>Tired of the same old look? Dreaming of a fresh, stylish interior but worried about the time and expense? Hancut Studio is here to change all that. Hancut Studio empowers you to reimagine and transform your living space, combining cost-effectiveness with creative freedom. Experience the future of interior design: visualize your ideas in real-time, tailored to the unique layout of your home. Save time, save money, and step into the role of a space designer with confidence.</Trans></p>
                                    <div className="two-column-button-container">
                                        <GetStartedButton text={t('start_free')} onClick={handleGetStarted} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="two-column-grid-content">
                        <div className="two-column-grid-content-wrapper">
                            <div className="two-column-grid-content-col-reverse two-column-grid-content-row">
                                <div className="two-column-grid-content-left">
                                    <p className="two-column-row-title"><Trans>1 Minute is Enough to Design Your Room</Trans></p>
                                    <p className="two-column-row-subtitle"><Trans>Transform your room in the blink of an eye with our revolutionary design tool. Our tool invites you to experiment with styles, colors, and layouts effortlessly, giving you the freedom to explore and refine your vision without any commitment. Skip the months-long planning process and see your dream room just in your coffee break. With Hancut Studio, every minute is an opportunity to bring your interior design dreams to life.</Trans></p>
                                    <div className="two-column-button-container">
                                        <GetStartedButton text={t('start_free')} onClick={handleGetStarted} />
                                    </div>
                                </div>
                                <div className="two-column-grid-content-right">
                                    <img src={img2img_row2} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="two-column-grid-content">
                        <div className="two-column-grid-content-wrapper">
                            <div className="two-column-grid-content-row">

                                <div className="two-column-grid-content-left">
                                    <img src={unlimited_grid} />
                                </div>
                                <div className="two-column-grid-content-right">
                                    <p className="two-column-row-title"><Trans>Everyone Deserves a Room They Love</Trans></p>
                                    <p className="two-column-row-subtitle"><Trans>Imagine a space that whispers your name, a haven where every corner sings your story. A perfect room that reflects your spirit, fuels your creativity, and wraps you in comfort. Embrace the joy of designing a space that's uniquely yours with Hancut Studio. Our belief is simple: everyone deserves to live in a room they adore, a place that reflects their personal style and comfort. Are you a minimalist at heart? Or perhaps the cozy warmth of Scandinavian design speaks to you. With our platform, you're not just limited to these; explore various design themes, each offering a unique aesthetic and mood.</Trans></p>
                                    <div className="two-column-button-container">
                                        <GetStartedButton text={t('start_free')} onClick={handleGetStarted} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="product-showcase-wrapper">
                        <div className="product-showcase">
                            <div className="product-showcase-left">
                                <p className="two-column-row-title"><Trans>How to use?</Trans></p>
                                <p className="two-column-row-subtitle"><Trans>Step 1. 공간사진을 업로드 합니다.
                                    <br />Step 2. 방타입과 스타일타입을 선택합니다.
                                    <br />Step 3. 단계를 선택합니다.
                                    <br />마지막으로 공간스타일링 만들기를 선택합니다. 공간스타일링 만들기를 누르시면 4장의 이미지가 제공됩니다.
                                </Trans></p>
                                <div className="two-column-button-container">
                                    <GetStartedButton text={t('try_now')} onClick={handleGetStarted} />
                                </div>
                            </div>
                            <div className="product-showcase-right">
                                {/* <img src={studio_highlight} className="img" /> */}
                                <img src={studio_highlight} className="img-hidden" />
                            </div>
                        </div>
                    </div>

                    <div className="example-showcase-wrapper">
                        <div className="example-showcase">
                            <p className="example-showcase-row-title"><Trans>See How It Works</Trans></p>
                            {/* <p className="example-showcase-row-subtitle"><Trans>Visualize the transformation of your space with Hancut Studio's powerful AI design tool.</Trans></p> */}
                            <div className="example-showcase-image-container">
                                <BeforeAfter
                                    // pointerMove
                                    beforeImage={before_image}
                                    afterImage={after_image}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="features-section-wrapper">
                        <div className="features-section">
                            <p className="features-section-row-title"><Trans>Why Hancut Studio?</Trans></p>
                            <p className="features-section-row-subtitle"><Trans>Revolutionizing the way you envision and create your living spaces. Our platform is a comprehensive solution for all your space styling needs, offering a suite of services to transform your living spaces. </Trans></p>
                            <div className="features-section-row-container">
                                <div className="features-section-row">
                                    <div className="features">
                                        {/* <img className="features-image" src="https://hancut.my04.net/api/uploads/hancutstudio_1701741188851963_0.jpeg" /> */}

                                        <Image className="features-image" color="#FFC529" />
                                        <p className="features-title">
                                            <Trans>AI Interior Design</Trans>
                                        </p>
                                        <p className="features-subtitle">
                                            <Trans>Instantly transform any room with customized design options tailored to your preferences.</Trans>
                                        </p>
                                    </div>
                                    <div className="features">
                                        {/* <img className="features-image" src="https://hancut.my04.net/api/uploads/hancutstudio_1701741188851963_0.jpeg" /> */}
                                        <ShoppingBag className="features-image" color="#FFC529" />
                                        <p className="features-title">
                                            <Trans>E-Commerce</Trans>
                                        </p>
                                        <p className="features-subtitle">
                                            <Trans>After designing your room, effortlessly purchase the items directly through our platform.</Trans>
                                        </p>
                                    </div>
                                    <div className="features">
                                        {/* <img className="features-image" src="https://hancut.my04.net/api/uploads/hancutstudio_1701741188851963_0.jpeg" /> */}
                                        <Users className="features-image" color="#FFC529" />
                                        <p className="features-title">
                                            <Trans>Community</Trans>
                                        </p>
                                        <p className="features-subtitle">
                                            <Trans>Join our vibrant community of design enthusiasts and interior design professionals.</Trans>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="inspiration-image-grid-sections-wrapper">
                        <div className="inspiration-image-grid-sections">
                            <p className="inspiration-image-grid-title"><Trans>Inspiration</Trans></p>
                            <p className="inspiration-image-grid-subtitle"><Trans>Latest generated inspiration interior design</Trans></p>
                            <div className="inspiration-image-grid-sections-row-container">
                                <div className="inspiration-image-grid-sections-row">
                                    <div className="inspiration-image-grid-sections-row-item">
                                        <div className="inspiration-image-grid-sections-row-item-image-container">
                                            <img src="https://hancut.my04.net/api/uploads/hancutstudio_1701741188851326_1.jpeg" />
                                            <p className="inspiration-image-grid-sections-row-item-description-title">Minimalism room decorated with calm and peach surrounding.</p>
                                        </div>
                                    </div>
                                    <div className="inspiration-image-grid-sections-row-item">
                                        <div className="inspiration-image-grid-sections-row-item-image-container">
                                            <img src="https://hancut.my04.net/api/uploads/hancutstudio_1701741188851326_1.jpeg" />
                                            <p className="inspiration-image-grid-sections-row-item-description-title">Minimalism room decorated with calm and peach surrounding.</p>
                                        </div>
                                    </div>
                                    <div className="inspiration-image-grid-sections-row-item">
                                        <div className="inspiration-image-grid-sections-row-item-image-container">
                                            <img src="https://hancut.my04.net/api/uploads/hancutstudio_1701741188851326_1.jpeg" />
                                            <p className="inspiration-image-grid-sections-row-item-description-title">Minimalism room decorated with calm and peach surrounding.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="inspiration-image-grid-sections-row">
                                    <div className="inspiration-image-grid-sections-row-item">
                                        <div className="inspiration-image-grid-sections-row-item-image-container">
                                            <img src="https://hancut.my04.net/api/uploads/hancutstudio_1701741188851326_1.jpeg" />
                                            <p className="inspiration-image-grid-sections-row-item-description-title">Minimalism room decorated with calm and peach surrounding.</p>
                                        </div>
                                    </div>
                                    <div className="inspiration-image-grid-sections-row-item">
                                        <div className="inspiration-image-grid-sections-row-item-image-container">
                                            <img src="https://hancut.my04.net/api/uploads/hancutstudio_1701741188851326_1.jpeg" />
                                            <p className="inspiration-image-grid-sections-row-item-description-title">Minimalism room decorated with calm and peach surrounding.</p>
                                        </div>
                                    </div>
                                    <div className="inspiration-image-grid-sections-row-item">
                                        <div className="inspiration-image-grid-sections-row-item-image-container">
                                            <img src="https://hancut.my04.net/api/uploads/hancutstudio_1701741188851326_1.jpeg" />
                                            <p className="inspiration-image-grid-sections-row-item-description-title">Minimalism room decorated with calm and peach surrounding.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="get-started-wrapper">
                        <div className="get-started">
                            <div className="get-started-left-fill-width">
                                <p className="get-started-left-title"><Trans>2024년 2월 2일 개최한 ㈜마이공사의 이사회에서 아래와 같이 신주발행을 결의하였기에 이를 공고합니다.</Trans></p>
                                <p className="get-started-left-subtitle">
                                    신주의 종류와 수: 기명식 보통주 100,000주<br />
                                    유상증자의 목적: 운영자금<br />
                                    1. 신주의 배정 방법: 크라우드펀딩을 통한 공모<br />
                                    2. 신주의 발행가액: 1주당 500원<br />
                                    3. 청약기간: 2024년 2월 8일~ 2024년 2월 19일<br />
                                    4. 청약취급처: ㈜오마이컴퍼니<br />
                                    5. 신주의 주금납입일: 2024년 2월 29일<br />
                                    6. 신주의 발행일: 2024년 3월 1일<br />
                                    7. 주금 납입은행: 신한은행 방배중앙기업금융센터<br />
                                    8. 신주의 배당 기산일: 2024년 1월 1일<br />
                                    9. 신주권 교부일: 2024년 3월 20일<br />
                                    10. 기타사항<br />
                                    가. 이 유상증자와 관련하여 이사회에서 정하지 아니한 사항은 대표이사에게 위임함.<br />
                                    나. 위 일정은 관계기관과의 협의과정에서 변경될 수 있음.<br />
                                </p>
                            </div>

                            <div className="get-started-right">
                                <GetStartedButton text={t('register_now')} onClick={handleGetStarted} showArrow={false} />
                            </div>
                        </div>
                    </div> */}

                </div>
                <DropdownMenu
                    isDropdownOpen={isDropdownOpen}
                    setIsDropdownOpen={setIsDropdownOpen} />
                <Footer />
            </div>
        </div>
    );
}

export default LandingPage;