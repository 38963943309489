import { useState } from 'react';
import { getFeaturedDesigners } from '../../designer';

const useFeaturedDesigners = () => {
    const [error, setError] = useState(null);
    const [featuredDesigners, setFeaturedDesigners] = useState([]);

    const handleFeaturedDesigners = async (credentials) => {
        try {
            const responseData = await getFeaturedDesigners();
            setFeaturedDesigners(responseData);
            setError(null);
        } catch (error) {
            setError(error.message || 'Failed to load featured designers!');
        }
    };

    return { featuredDesigners, handleFeaturedDesigners, error };
};

export default useFeaturedDesigners;