import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LoginForm from '../../components/auth/LoginForm'
import useToken from '../auth/useToken';
import profileImg1 from '../../assets/images/profiles/ToyFaces_Colored_BG_8.jpg'
import profileImg2 from '../../assets/images/profiles/ToyFaces_Colored_BG_29.jpg'
import profileImg3 from '../../assets/images/profiles/ToyFaces_Colored_BG_32.jpg'
import profileImg4 from '../../assets/images/profiles/ToyFaces_Colored_BG_37.jpg'
import profileImg5 from '../../assets/images/profiles/ToyFaces_Colored_BG_56.jpg'
import profileImg6 from '../../assets/images/profiles/ToyFaces_Colored_BG_59.jpg'
import DropdownMenu from '../DropdownMenu';
import DefaultDialog from '../dialog/DefaultDialog';
import { formatShortDate } from '../utils/Helper'
import Avatar from 'react-avatar';
import { Award } from 'react-feather';
import toast, { Toaster } from 'react-hot-toast';
import { Trans } from 'react-i18next';

function ProfileEditPage() {
    const [selectedMenu, setSelectedMenu] = useState('editProfile');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [userName, setUserName] = useState();
    const [userType, setUserType] = useState(-1);
    const [userFullName, setUserFullName] = useState();
    const [email, setEmail] = useState();
    const [profileImage, setProfileImage] = useState(null);

    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPassword, setIsPassword] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState({
        status: "none",
    });

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const default_user_profile_images = [
        profileImg1,
        profileImg2,
        profileImg3,
        profileImg4,
        profileImg5,
        profileImg6];

    useEffect(() => { loadUser() }, []);

    const { token, setToken } = useToken();

    const loadUser = () => {
        if (token) {
            fetch(process.env.REACT_APP_API + '/v1/profile', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(errorInfo => {
                            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        });
                    }
                    else return response.json();
                })
                .then(data => {
                    if (data !== undefined) {
                        const user = data.user;
                        const free_tier = data.free_tier;
                        setUserName(user.user_name);
                        setUserFullName(user.fname + " " + user.lname);
                        setEmail(user.email);
                        setProfileImage(user.profile_img);
                        setUserType(user.role);
                        setIsPassword(user.password !== '');

                        if (data.active_subscription) {
                            setIsSubscribed(true);
                            setActiveSubscription(data.active_subscription);
                        }
                    }
                })
                .catch(error => {
                    console.log(error.message);
                });
        }
    }

    if (!token) {
        return <LoginForm setToken={setToken} />
    }

    return (
        <div className="ProfileApp">
            <Header userType={userType} userName={userFullName} userImg={profileImage} token={token} toggleDropdown={toggleDropdown} />
            <div className="profile-user-section">

                {profileImage === null ?
                    <div><Avatar name={userFullName} size="55px" round={true} color={'black'} maxInitials={1} /></div>
                    :
                    <img src={!isNaN(profileImage) ? default_user_profile_images[profileImage] : profileImage.includes('http://') || profileImage.includes('https://') ? `${profileImage}` : `data:image/png;base64,${profileImage}`} alt="Profile" />
                }
                <div className='detail'>
                    <p className="profile-username">{userFullName} / <> </>
                        <Trans>{selectedMenu === 'editProfile' && "Edit Profile"}</Trans>
                        <Trans>{selectedMenu === 'password' && "Password"}</Trans>
                        <Trans>{selectedMenu === 'payments' && "Subscriptions"}</Trans></p>
                    <p className="profile-description"><Trans>Edit your Hancut user profile</Trans></p>
                </div>
            </div>
            <div className="profileEditPage">
                <div className="leftPanel">
                    <ul>
                        <li className={selectedMenu === 'editProfile' ? 'active' : ''} onClick={() => setSelectedMenu('editProfile')}><Trans>Edit Profile</Trans></li>
                        {
                            isPassword && <li className={selectedMenu === 'password' ? 'active' : ''} onClick={() => setSelectedMenu('password')}><Trans>Password</Trans></li>
                        }
                        <li className={selectedMenu === 'payments' ? 'active' : ''} onClick={() => setSelectedMenu('payments')}><Trans>Subscriptions</Trans></li>
                    </ul>
                </div>
                <div className="rightPanel">
                    {selectedMenu === 'editProfile' && <ProfileEdit onUpdate={() => { loadUser() }} />}
                    {selectedMenu === 'password' && <PasswordReset />}
                    {selectedMenu === 'payments' && <Payments />}
                </div>
            </div>
            <Footer />
            <DropdownMenu
                userName={userFullName}
                userEmail={email}
                userImg={profileImage}
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen}
                isSubscribed={isSubscribed}
                activeSubscription={activeSubscription} />
            <Toaster />
        </div>
    );
}

function ProfileEdit({ onUpdate }) {
    const { token, setToken } = useToken();
    const fileInputRef = useRef(null);

    const [isProfileImageChange, setIsProfileImageChange] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [fullName, setUserFullName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [isErrorMessageShow, setIsErrorMessageShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [fnameError, setFirstNameError] = useState("");
    const [lnameError, setLastNameError] = useState("");
    const [usernameError, setUserNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [isInputStarted, setIsInputStarted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => { loadUser() }, []);

    const default_user_profile_images = [
        profileImg1,
        profileImg2,
        profileImg3,
        profileImg4,
        profileImg5,
        profileImg6];

    const loadUser = () => {
        fetch(process.env.REACT_APP_API + '/v1/profile', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        setErrorMessage("Unexpected error occurs.");
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data !== undefined) {
                    const user = data.user;
                    const subscription = data.subscription;
                    setUserName(user.user_name);
                    setUserFullName(user.fname);
                    setFirstName(user.fname);
                    setLastName(user.lname);
                    setEmail(user.email);
                    setProfileImage(user.profile_img);
                    // setFreeTireCount(subscription.total - subscription.consume);
                }
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    const handleImageChange = (e) => {
        console.log("handleImageChange");
        // setProfileImage(URL.createObjectURL(e.target.files[0]));
        setIsProfileImageChange(true);

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.replace("data:", "")
                    .replace(/^.+,/, "");
                setProfileImage(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const removeImage = () => {
        setProfileImage(null);
        setIsProfileImageChange(true);
    };

    const handleSubmit = async () => {
        // e.preventDefault();
        let error = false;
        if (!firstName) {
            setFirstNameError('Name cannot be empty.');
            error = true;
        }
        if (!lastName) {
            setLastNameError('Name cannot be empty.');
            error = true;
        }
        if (!userName) {
            setUserNameError('Username cannot be empty.');
            error = true;
        }
        if (!email) {
            setEmailError('Email cannot be empty.');
            error = true;
        }

        if (!error && !fnameError && !lnameError && !usernameError && !emailError) {
            toast.promise(
                updateUser({
                    username: userName,
                    firstName: firstName,
                    lastName: lastName,
                    profileImage: profileImage
                }),
                {
                    loading: 'Updating...',
                    success: <b>Profile updated!</b>,
                    error: <b>Unable to update your profile.</b>
                },
            );
        }
    }

    async function updateUser(credentials) {
        setIsLoading(true);

        return fetch(process.env.REACT_APP_API + '/v1/profile/edit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                setIsLoading(false);
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        // setErrorMessage(errorInfo.message);
                        // setIsErrorMessageShow(true);
                        throw "Cannot update profile.";
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data != undefined) {
                    setIsProfileImageChange(false);
                    loadUser();
                    onUpdate();
                }
            })
            .catch(error => {
                console.log(error.message);
                // setErrorMessage("Unexpected error occurs.");
                // setIsErrorMessageShow(true);
                throw error;
            });
    }

    return (
        <div>
            <div className='edit-profile-picture-container'>
                <p className='' style={{
                    fontSize: "0.9em"
                }}><Trans>Profile Picture</Trans></p>
                <div className='edit-profile-picture'>
                    {profileImage ? (
                        <img src={!isNaN(profileImage) ? default_user_profile_images[profileImage] : profileImage.includes('http://') || profileImage.includes('https://') ? `${profileImage}` : `data:image/png;base64,${profileImage}`} alt="Profile" onClick={() => { fileInputRef.current.value = ''; fileInputRef.current.click(); }} />
                    ) : (
                        <Avatar name={fullName} size="80px" round={true} color={'black'} maxInitials={1} />
                    )}
                    <input type="file" onChange={handleImageChange}
                        style={{ display: 'none' }}
                        accept="image/jpeg, image/jpg, image/png"
                        ref={fileInputRef} />
                    <div className='edit-profile-options-container'>
                        <div className='edit-profile-options'>
                            <p className='update' onClick={() => { fileInputRef.current.value = ''; fileInputRef.current.click(); }}><Trans>Change Photo</Trans></p>
                            <p className='remove' onClick={removeImage}><Trans>Remove</Trans></p>
                        </div>
                        <p className='description'><Trans>Recommended: Square JPG, PNG, or GIF, at least 1,000 pixels per side.</Trans></p>
                    </div>
                </div>
            </div>
            <div className='profile-form' onSubmit={handleSubmit}>
                <div style={{
                    display: "flex",
                    gap: "12px",
                    width: "100%"
                }}>
                    <label style={{
                        flex: 1,
                    }}>
                        <p style={{
                            marginTop: '0px',
                            marginBottom: "10px",
                            color: "#00000080"
                        }}><Trans>First Name</Trans></p>
                        <input value={firstName} type="text" onChange={e => setFirstName(e.target.value)} onBlur={() => {
                            setIsInputStarted(true);
                            if (!firstName)
                                setFirstNameError('Name cannot be empty.');
                            else
                                setFirstNameError('');
                        }} />
                        {fnameError && (
                            <div className="input-feedback">{fnameError}</div>
                        )}
                    </label>
                    <label style={{
                        flex: 1,
                    }}>
                        <p style={{
                            marginTop: '0px',
                            marginBottom: "10px",
                            color: "#00000080"
                        }}><Trans>Last Name</Trans></p>
                        <input value={lastName} type="text" onChange={e => setLastName(e.target.value)} onBlur={() => {
                            setIsInputStarted(true);
                            if (!lastName)
                                setLastNameError('Name cannot be empty.');
                            else
                                setLastNameError('');
                        }} />
                        {lnameError && (
                            <div className="input-feedback">{lnameError}</div>
                        )}
                    </label>
                </div>
                <label>
                    <p style={{
                        marginTop: "12px",
                        marginBottom: "10px",
                        color: "#00000080"
                    }}><Trans>Username</Trans></p>
                    <input value={userName} type="text" onChange={e => setUserName(e.target.value.toLowerCase())} onBlur={() => {
                        setIsInputStarted(true);
                        if (!userName)
                            setUserNameError('Username cannot be empty.');
                        else
                            setUserNameError('');
                    }} />
                    {usernameError && (
                        <div className="input-feedback"><Trans>{usernameError}</Trans></div>
                    )}
                </label>
                <label>
                    <p style={{
                        marginTop: "12px",
                        marginBottom: "10px",
                        color: "#00000080"
                    }}><Trans>Email</Trans></p>
                    <input readonly="readonly" value={email} type="text" onChange={e => setEmail(e.target.value)} onBlur={() => {
                        setIsInputStarted(true);
                        if (!email)
                            setEmailError('Email cannot be empty.');
                        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
                            setEmailError('Invalid email address');
                        else
                            setEmailError('');
                    }} />
                    {emailError && (
                        <div className="input-feedback"><Trans>{emailError}</Trans></div>
                    )}
                </label>
                <div>
                    {
                        (!isLoading)
                            ? <button className="loginBtn" style={{
                                marginTop: "28px"
                            }} type="submit" onClick={() => handleSubmit()}><Trans>Save Changes</Trans></button>
                            :
                            <button className="loginBtn" style={{
                                marginTop: "28px",
                                cursor: "not-allowed"
                            }} type="submit"><Trans>Save Changes</Trans></button>
                    }
                </div>
            </div>
            {isErrorMessageShow && <DefaultDialog
                title={"Error"}
                description={errorMessage}
                show={isErrorMessageShow}
                isCancellable={false}
                onClose={() => {
                    setIsErrorMessageShow(false);
                }}
                onOk={() => {
                    setIsErrorMessageShow(false);
                }}
                okBtn='Ok'
                onCancel={() => { }}
                enableBackgroundClick={true} />}
        </div>
    );
}

function PasswordReset() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isErrorMessageShow, setIsErrorMessageShow] = useState(false);
    const [isConfirmUpdatePassword, setIsConfirmUpdatePassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [newPasswordError, setNewPasswordError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [isInputStarted, setIsInputStarted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isPasswordSet, setIsPasswordSet] = useState(false);

    const { token, setToken } = useToken();

    useEffect(() => { loadUser() }, []);

    const loadUser = () => {
        fetch(process.env.REACT_APP_API + '/v1/profile', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        // setErrorMessage("Unexpected error occurs.");
                        // setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data !== undefined) {
                    const user = data.user;
                    console.log("user.password: " + user.password);
                    setIsPasswordSet(user.password !== 'false');
                    // setFreeTireCount(subscription.total - subscription.consume);
                }
            })
            .catch(error => {
                console.log(error.message);
                // setErrorMessage("Unexpected error occurs.");
                // setIsErrorMessageShow(true);
            });
    }

    const handlePasswordReset = async () => {
        if (newPassword === confirmPassword) {
            toast.promise(
                updatePassword({
                    oldPassword: oldPassword,
                    password: newPassword
                }),
                {
                    loading: 'Updating...',
                    success: <b>Profile updated!</b>,
                    error: <b>Unable to update your profile.</b>
                },
            );
        }
    }

    async function updatePassword(credentials) {
        setIsLoading(true);
        return fetch(process.env.REACT_APP_API + '/v1/auth/update_password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                setIsLoading(false);
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                        throw errorInfo.message;
                    });
                }
                else return response.json();
            })
            .then(data => {
                // if (data != undefined) {

                // }
            })
            .catch(error => {
                console.log(error.message);
                throw error;
            });
    }

    return (
        <div className="">
            <div className='profile-form' style={{
                marginTop: "0px",
            }} onSubmit={handlePasswordReset}>
                {
                    isPasswordSet &&
                    <label>
                        <p style={{
                            marginTop: "12px",
                            marginBottom: "10px",
                            color: "#00000080"
                        }}><Trans>Old Password</Trans></p>
                        <input type="password" onChange={e => setOldPassword(e.target.value)} onBlur={() => {
                            setIsInputStarted(true);
                            if (!oldPassword)
                                setPasswordError('Password cannot be empty.');
                            else if (oldPassword.length < 5)
                                setPasswordError('Invalid password. Password must be at least 5 characters.');
                            else
                                setPasswordError('');
                        }} />
                        {passwordError && (
                            <div className="input-feedback"><Trans>{passwordError}</Trans></div>
                        )}
                    </label>
                }
                <label>
                    <p style={{
                        marginTop: "12px",
                        marginBottom: "10px",
                        color: "#00000080"
                    }}><Trans>New Password</Trans></p>
                    <input type="password" onChange={e => setNewPassword(e.target.value)} onBlur={() => {
                        setIsInputStarted(true);
                        if (!newPassword)
                            setNewPasswordError('Password cannot be empty.');
                        else if (newPassword.length < 5)
                            setNewPasswordError('Password must be at least 5 characters.');
                        else
                            setNewPasswordError('');
                    }} />
                    {newPasswordError && (
                        <div className="input-feedback"><Trans>{newPasswordError}</Trans></div>
                    )}
                </label>
                <label>
                    <p style={{
                        marginTop: "12px",
                        marginBottom: "10px",
                        color: "#00000080"
                    }}><Trans>Confrirm New Password</Trans></p>
                    <input type="password" onChange={e => setConfirmPassword(e.target.value)} onBlur={() => {
                        setIsInputStarted(true);
                        if (!confirmPassword || confirmPassword !== newPassword)
                            setConfirmPasswordError('Confirm password does not match.');
                        else
                            setConfirmPasswordError('');
                    }} />
                    {confirmPasswordError && (
                        <div className="input-feedback"><Trans>{confirmPasswordError}</Trans></div>
                    )}
                </label>
                <div>
                    {
                        (!isLoading)
                            ? <button className="loginBtn" style={{
                                marginTop: "28px"
                            }} type="submit" onClick={() => {
                                let error = false;

                                if (!oldPassword && isPasswordSet) {
                                    setPasswordError('Password cannot be empty.');
                                    error = true;
                                }
                                if (!newPassword) {
                                    setNewPasswordError('Password cannot be empty.');
                                    error = true;
                                }
                                if (!confirmPassword) {
                                    setConfirmPasswordError('Confirm password cannot be empty.');
                                    error = true;
                                }
                                if (!error) setIsConfirmUpdatePassword(true);
                            }
                            }><Trans>Update Password</Trans></button>
                            :
                            <button className="loginBtn" style={{
                                marginTop: "28px",
                                cursor: "not-allowed"
                            }} type="submit"></button>
                    }

                </div>
            </div>
            {
                isConfirmUpdatePassword && <DefaultDialog
                    title={"Confirm Update"}
                    description={"Are you sure want to update your password?"}
                    show={isConfirmUpdatePassword}
                    isCancellable={true}
                    onClose={() => {
                        setIsConfirmUpdatePassword(false);
                    }}
                    onOk={() => {
                        setIsConfirmUpdatePassword(false);
                        handlePasswordReset();
                    }}
                    okBtn='Update'
                    onCancel={() => { setIsConfirmUpdatePassword(false); }}
                    enableBackgroundClick={true} />
            }
            {
                isErrorMessageShow && <DefaultDialog
                    title={"Error"}
                    description={errorMessage}
                    show={isErrorMessageShow}
                    isCancellable={false}
                    onClose={() => {
                        setIsErrorMessageShow(false);
                    }}
                    onOk={() => {
                        setIsErrorMessageShow(false);
                    }}
                    okBtn='Ok'
                    onCancel={() => { }}
                    enableBackgroundClick={true} />
            }
        </div>
    );
}

function Payments() {
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState({
        status: "none",
    });

    const [freeMembershipMonthlyCount, setFreeMembershipMonthlyCount] = useState(0);
    const [freeMembershipMonthlyLimit, setFreeMembershipMonthlyLimit] = useState(0);
    const [freeMembershipDailyCount, setFreeMembershipDailyCount] = useState(0);
    const [freeMembershipDailyLimit, setFreeMembershipDailyLimit] = useState(0);
    const navigate = useNavigate();

    const { token, setToken } = useToken();

    useEffect(() => {
        loadUser();
    }, []);

    const loadUser = () => {
        fetch(process.env.REACT_APP_API + '/v1/profile', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        // setErrorMessage(errorInfo.message);
                        // setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data != undefined) {
                    const user = data.user;
                    const free_membership = data.free_membership;
                    setFreeMembershipMonthlyCount(free_membership.count);
                    setFreeMembershipMonthlyLimit(free_membership.limit);
                    setFreeMembershipDailyLimit(free_membership.daily_limit);
                    setFreeMembershipDailyCount(data.daily_count);
                    const subscription = data.active_subscription;
                    if (subscription) {
                        setIsSubscribed(true);
                        setActiveSubscription(subscription);
                    }
                }
            })
            .catch(error => {
                console.log(error.message);
                // setErrorMessage("Unexpected error occurs.");
                // setIsErrorMessageShow(true);
            });
    }

    return (
        <div>
            <div>
                <div>
                    <p style={{
                        color: "black",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        marginTop: "14px"
                    }}><Trans>Subscription</Trans></p>
                    {
                        isSubscribed
                            ?
                            <div>
                                <div className='edit-profile-pro-membership-wrapper'>
                                    <Award className='' size={16} color='black' onClick={() => { }} />
                                    <p><Trans>Pro Membership since</Trans> {formatShortDate(activeSubscription.start_date)}</p>
                                </div>
                                <p
                                    className='subscribe-plan'
                                    style={{
                                        width: "min-content",
                                        whiteSpace: "nowrap"
                                    }}
                                    onClick={() => {
                                        toast.promise(
                                            fetch(process.env.REACT_APP_API + '/v1/subscription/cancel', {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': `Bearer ${token}`
                                                }
                                            }).then(response => {
                                                if (!response.ok) {
                                                    return response.json().then(errorInfo => {
                                                        throw errorInfo.message;
                                                    });
                                                }
                                                else return response.json();
                                            }).then(data => {
                                                setIsSubscribed(false);
                                                setActiveSubscription({ status: "none" });
                                            }).catch(error => {
                                                console.log(error.message);
                                                alert("Unexpected error occurs.");
                                                throw error;
                                            }),
                                            {
                                                loading: 'Processing...',
                                                success: <b>Successfully cancelled subscription!</b>,
                                                error: <b>Unable to cancel subscription.</b>
                                            },
                                        );
                                    }}><Trans>Cancel Subscription</Trans></p>
                            </div>
                            :
                            <div>
                                <div className='edit-profile-pro-membership-wrapper'>
                                    <p style={{
                                        paddingLeft: "0px"
                                    }}><Trans>You have no active subscription.</Trans></p>
                                </div>
                                <p
                                    className='subscribe-plan'
                                    style={{
                                        width: "min-content",
                                        whiteSpace: "nowrap"
                                    }}
                                    onClick={() => {
                                        navigate(`/pricing`)
                                    }}><Trans>Subscribe Pro Membership Now</Trans></p>
                            </div>
                    }
                </div>
                {!isSubscribed &&
                    <div
                        style={{
                            marginTop: "40px",
                        }}>
                        <p style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "bold"
                        }}>Free Membership</p>
                        <p style={{
                            color: "black",
                        }}>Daily Limit: {freeMembershipDailyCount} / {freeMembershipDailyLimit}</p>
                        <p style={{
                            color: "black",
                        }}>Monthly Limit: {freeMembershipMonthlyCount} / {freeMembershipMonthlyLimit}</p>
                        {/* <p >Your free tier will reset every month automatically.</p> */}
                    </div>}

            </div>
        </div>
    );
}

export default ProfileEditPage;
