import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import useToken from './auth/useToken';
import Header from './Header';
import Footer from './Footer';
import '../assets/styles/Header.css'
import '../assets/styles/Studio.css';
import 'font-awesome/css/font-awesome.min.css';
import toast, { Toaster } from 'react-hot-toast';
import DefaultDialog from './dialog/DefaultDialog'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import DropdownMenu from './DropdownMenu';
import { useTranslation, Trans } from 'react-i18next';


export default function Contact() {
    const { t } = useTranslation();
    const [userName, setUserName] = useState();
    const [userType, setUserType] = useState();
    const [userFullName, setUserFullName] = useState();
    const [userEmail, setUserEmail] = useState(null);
    const [userProfileImage, setUserProfileImage] = useState(null);
    const [showPaypal, setShowPaypal] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [plans, setPlans] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [isErrorMessageShow, setIsErrorMessageShow] = useState(false);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const { token, setToken } = useToken();

    const [billingCycle, setBillingCycle] = useState('monthly');

    const loadUser = () => {
        fetch(process.env.REACT_APP_API + '/v1/profile', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                console.log('response: ' + JSON.stringify(response));
                if (!response.ok) {
                    return response.json().then(errorInfo => {

                    });
                }
                else {
                    return response.json();
                }
            })
            .then(data => {
                console.log('data: ' + JSON.stringify(data));
                if (data !== undefined) {
                    const user = data.user;
                    console.log(JSON.stringify(user));
                    setUserName(user.user_name);
                    setUserFullName(user.fname + " " + user.lname);
                    setUserEmail(user.email);
                    setUserProfileImage(user.profile_img);
                    setUserType(user.role);
                    const subscription = data.active_subscription;
                    if (subscription && subscription.status === "active") setSubscribed(true);
                }
            })
            .catch(error => {

            });
    }

    const createApprovedSubscription = (plan_id, payment_gateway_subscription_id) => {
        fetch(process.env.REACT_APP_API + '/create_subscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ plan_id: plan_id, payment_gateway_subscription_id: payment_gateway_subscription_id })
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {

                    });
                }
                // else if (response.status) {
                //     setErrorMessage("Error");
                //     setIsErrorMessageShow(true);
                // }
                else {
                    return response.json();
                }
            })
            .then(data => {
                console.log('data: ' + JSON.stringify(data));
                if (data !== undefined && data.status) {
                    toast('Your subscription is active now!',
                        {
                            icon: '❤️',
                            style: {
                                borderRadius: '10px',
                                background: '#fff',
                                color: '#000',
                                borderColor: "#000",
                                borderWidth: "2px",
                            },
                        }
                    );

                    navigate(`/studio`);
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
                setIsErrorMessageShow(true);
            });
    }

    const create_free_tier_subscription = () => {
        fetch(process.env.REACT_APP_API + '/v1/subscription/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify()
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error');
                }
                else {
                    return response.json();
                }
            })
            .then(data => {
                console.log('data: ' + JSON.stringify(data));
                toast('Your subscription is active now!',
                    {
                        icon: '❤️',
                        style: {
                            borderRadius: '10px',
                            background: '#fff',
                            color: '#000',
                            borderColor: "#000",
                            borderWidth: "2px",
                        },
                    }
                );

                navigate(`/studio`);
            })
            .catch(error => {
                setErrorMessage(error.message);
                setIsErrorMessageShow(true);
            });
    }

    useEffect(() => {
        loadUser();
    }, []);

    useEffect(() => {
        fetch(process.env.REACT_APP_API + '/v1/subscription/plans', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {

                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data !== undefined) {
                    setPlans(data);
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }, []);

    return (
        <div className="PricingApp">
            <Header userType={userType} userName={userName} userImg={userProfileImage} token={token} toggleDropdown={toggleDropdown} />
            <div className="content">
                <div className='wrap-content'>
                    <section>
                        {/* <p className='pricing-label'><Trans>PRICING</Trans></p> */}
                        <h2 className='pricing-title'><Trans>Subscription plans for every creators</Trans></h2>
                        {/* <p className='pricing-description'><Trans>Choose an affordable plan that's packed with the best features for<br />spatial styling using generative AI by MY04.</Trans></p> */}
                    </section>
                    <div className="pricing-page">
                        <div className="billing-cycle">
                            <button
                                className={billingCycle === 'monthly' ? 'active' : ''}
                                onClick={() => {
                                    setBillingCycle('monthly');
                                    setShowPaypal(false);
                                }}
                            >
                                <Trans>Monthly</Trans>
                            </button>
                            <button
                                className={billingCycle === 'yearly' ? 'active' : ''}
                                onClick={() => {
                                    setBillingCycle('yearly');
                                    setShowPaypal(false);
                                }}
                            >
                                <Trans>Annually</Trans>
                            </button>
                        </div>

                        <div className="plans">
                            {plans.map((plan) => (
                                <div key={plan.name} className={plan.name === 'Pro' || plan.name === 'Education' ? "plan plan_pro" : "plan"}>
                                    {/* <p className='plan_image' >🌱</p> */}
                                    {
                                        (plan.name === "Basic" || plan.name === "Pro") ?
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}>
                                                <p style={{
                                                    width: "160px",
                                                    textAlign: "center",
                                                    backgroundColor: "#FFC529",
                                                    borderRadius: "30px",
                                                    padding: "5px",
                                                    color: "#000",
                                                    fontSize: "14px"
                                                }}>
                                                    <Trans>Apply within beta launch period</Trans>
                                                </p>
                                            </div>
                                            :
                                            <div style={{
                                                height: "62px"
                                            }} />
                                    }
                                    <p className="plan_title"><Trans>{plan.name}</Trans></p>
                                    <p className="plan_description">
                                        <Trans>{plan.description}</Trans>
                                    </p>
                                    <p className="price">
                                        <Trans>{billingCycle === 'monthly'
                                            ? plan.monthlyPrice
                                            : plan.yearlyPrice}</Trans> <b style={{
                                                color: "#CB5151",
                                                fontSize: "0.5em"
                                            }}><Trans>{(billingCycle !== 'monthly' && plan.name === "Pro") && plan.discount + " discount"}</Trans> </b>
                                    </p>
                                    <p className="price_label">
                                        <Trans>{billingCycle === 'monthly'
                                            ? "per month, billed monthly"
                                            : "per year, billed annually"}</Trans>
                                    </p>

                                    {
                                        (plan.name === "Basic") &&
                                        <p className='subscribe-plan subscribe-plan-free' onClick={() => {
                                            token ? navigate(`/studio`) : navigate(`/login`)
                                        }}><Trans>Get Started</Trans></p>
                                    }
                                    {
                                        (plan.name === "Pro" && !showPaypal && !subscribed) &&
                                        <p className='subscribe-plan' onClick={() => {
                                            // token ? setShowPaypal(true) : navigate(`/login`)
                                            token ? create_free_tier_subscription() : navigate(`/login`)
                                        }}><Trans>{token ? 'Claim 1 Year Free' : 'Subscribe'}</Trans></p>
                                    }
                                    {
                                        (plan.name === "Education") &&
                                        <p className='subscribe-plan subscribe-plan-free' onClick={() => {
                                            navigate(`/contact`)
                                        }}><Trans>Contact</Trans></p>
                                    }
                                    {
                                        (plan.name === "Pro" && subscribed) &&
                                        <p className='subscribe-plan subscribe-plan-free' onClick={() => {

                                        }}><Trans>Already Subscribed</Trans></p>
                                    }

                                    {
                                        // (plan.name === "Pro" && billingCycle === 'monthly' && showPaypal && !subscribed) &&
                                        // <PayPalScriptProvider options={{
                                        //     clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                        //     vault: true,
                                        //     intent: "subscription"
                                        // }}>
                                        //     <PayPalButtons style={{ layout: "vertical" }}
                                        //         createSubscription={(data, actions) => {
                                        //             console.log('PayPalButtons: ' + JSON.stringify(data));
                                        //             console.log('PayPalButtons: ' + JSON.stringify(actions));
                                        //             return actions.subscription.create({
                                        //                 plan_id: 'P-4RN8777290069545FMVBDIIY'
                                        //             });
                                        //         }}
                                        //         onApprove={(data) => {
                                        //             console.log('Subscribed: ' + data.subscriptionID);
                                        //             createApprovedSubscription(plan._id, data.subscriptionID);
                                        //         }} />
                                        // </PayPalScriptProvider>
                                    }

                                    {
                                        // (plan.name === "Pro" && billingCycle === 'yearly' && showPaypal && !subscribed) &&
                                        // <PayPalScriptProvider options={{
                                        //     clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                        //     vault: true,
                                        //     intent: "subscription"
                                        // }}>
                                        //     <PayPalButtons style={{ layout: "vertical" }}
                                        //         createSubscription={(data, actions) => {
                                        //             console.log('PayPalButtons: ' + data);
                                        //             console.log('PayPalButtons: ' + actions);
                                        //             return actions.subscription.create({
                                        //                 plan_id: 'P-0BK96391MG0346511MVBDJJI'
                                        //             });
                                        //         }}
                                        //         onApprove={(data) => {
                                        //             console.log('Subscribed: ' + data.subscriptionID);
                                        //             createApprovedSubscription(plan._id, data.subscriptionID);
                                        //         }} />
                                        // </PayPalScriptProvider>
                                    }

                                    <div className='features-wrap'>
                                        <ul className="features">
                                            {plan.features.map((feature) => (
                                                <li key={feature} className='price-list-item'><Trans>{feature}</Trans></li>
                                            ))}
                                        </ul>
                                        {plan.missing.length > 0 && (
                                            <ul className="missing">
                                                {plan.missing.map((item) => (
                                                    <li key={item} className="price-list-item missing-item"><Trans>{item}</Trans></li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {isErrorMessageShow && <DefaultDialog
                title={"Error"}
                description={errorMessage}
                show={isErrorMessageShow}
                isCancellable={false}
                onClose={() => {
                    setIsErrorMessageShow(false);
                }}
                onOk={() => {
                    setIsErrorMessageShow(false);
                }}
                okBtn='Ok'
                onCancel={() => { }}
                enableBackgroundClick={true} />}

            {/* <DropdownMenu userName={userFullName} userEmail={userEmail} userImg={userProfileImage} isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} /> */}
            <DropdownMenu
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen} />
            <Toaster />
        </div>
    )
}