import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useToken from "./useToken";

const NaverLoginCallback = () => {
    const { _, setToken } = useToken();
    let isLoaded = false;
    const navigate = useNavigate();
    const getUserProfile = async (code) => {
        return fetch(process.env.REACT_APP_API + '/v1/auth/naver_login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: code
            })
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        alert(JSON.stringify(errorInfo));
                    });
                }
                else return response.json();
            })
            .then(data => {
                setToken(data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
                if (localStorage.getItem('login_force') === 'pcarpenter')
                    navigate("/pcarpenter", { replace: true });
                else
                    if (localStorage.getItem('login_remark') === 'pcarpenter')
                        navigate("/pcarpenter", { replace: true });
                    else
                        navigate("/studio", { replace: true });
            })
            .catch(error => {
                console.log(error.message);
                alert(error.message);
                navigate("/login", { replace: true });
            });
    }

    useEffect(() => {
        const code = new URLSearchParams(window.location.search).get('code');
        if (code && !isLoaded) {
            isLoaded = true;
            getUserProfile(code);
        }
    }, []);
}

export default NaverLoginCallback;