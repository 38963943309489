import React, { useRef, useEffect, useState } from 'react';
import useToken from '../auth/useToken';
import { useWindowResize } from '../../hooks/useWindowsResize';

const ImageEditor = ({ base64ImageList, canvasRef, isBrush, setIsBrush }) => {
    const imageRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [offsets, setOffsets] = useState({ left: 0, top: 0 });
    const { width, height } = useWindowResize();

    const { token } = useToken();

    const startDrawing = (e) => {
        const canvas = canvasRef.current;
        const clientX = e.clientX || e.touches[0].clientX;
        const clientY = e.clientY || e.touches[0].clientY;
        const ctx = canvas.getContext('2d');

        if (isBrush) {
            ctx.strokeStyle = 'rgba(255,255,255,0.8)';
            ctx.lineWidth = 30; // Adjust brush size as needed
            ctx.lineJoin = 'round'; // Make the junction between lines rounded
            ctx.lineCap = 'round'; // Make the end of the line rounded

            const newRect = canvas.getBoundingClientRect();
            setOffsets({ left: newRect.left, top: newRect.top });

            ctx.beginPath();
            ctx.moveTo(clientX - newRect.left, clientY - newRect.top); // Use offsets for accurate positioning
        }
        else {
            ctx.globalCompositeOperation = 'destination-out'; // Use destination-out to erase
            ctx.strokeStyle = 'rgba(255,255,255,0.8)';
            ctx.lineWidth = 30; // Adjust brush size as needed
            ctx.lineJoin = 'round'; // Make the junction between lines rounded
            ctx.lineCap = 'round'; // Make the end of the line rounded

            const newRect = canvas.getBoundingClientRect();
            setOffsets({ left: newRect.left, top: newRect.top });

            ctx.beginPath();
            ctx.moveTo(clientX - newRect.left, clientY - newRect.top); // Use offsets for accurate positioning
            ctx.globalCompositeOperation = 'source-over'; // Reset globalCompositeOperation
        }
        setIsDrawing(true);
    };

    const draw = (e) => {
        if (!isDrawing) return;
        const canvas = canvasRef.current;
        const clientX = e.clientX || e.touches[0].clientX;
        const clientY = e.clientY || e.touches[0].clientY;
        const ctx = canvas.getContext('2d');

        if (isBrush) {
            ctx.lineTo(clientX - offsets.left, clientY - offsets.top);
            ctx.stroke();
        }
        else {
            ctx.globalCompositeOperation = 'destination-out'; // Use destination-out to erase
            ctx.lineTo(clientX - offsets.left, clientY - offsets.top);
            ctx.stroke();
            ctx.globalCompositeOperation = 'source-over'; // Reset globalCompositeOperation
        }
    };

    const stopDrawing = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.closePath();
        setIsDrawing(false);
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const image = imageRef.current;
        const rect = canvas.getBoundingClientRect();
        setOffsets({ left: rect.left, top: rect.top });
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.style.position = 'absolute';
        canvas.style.left = image.offsetLeft + 'px';
        canvas.style.top = image.offsetTop + 'px';
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const rect = canvas.getBoundingClientRect();
            setOffsets({ left: rect.left, top: rect.top });
            const image = imageRef.current;
        }
    }, [width, height]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const image = imageRef.current;
        const rect = canvas.getBoundingClientRect();
        setOffsets({ left: rect.left, top: rect.top });
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.style.position = 'absolute';
        canvas.style.left = image.offsetLeft + 'px';
        canvas.style.top = image.offsetTop + 'px';
    }, []);

    return (
        <>
            <div style={{ position: 'relative' }}>
                <img className='canvas-image' ref={imageRef} src={"data:image/jpeg;base64," + base64ImageList[base64ImageList.length - 1]} alt="Editable" onLoad={() => {
                    const canvas = canvasRef.current;
                    const image = imageRef.current;
                    const rect = canvas.getBoundingClientRect();
                    setOffsets({ left: rect.left, top: rect.top - 15 });
                    canvas.width = image.width;
                    canvas.height = image.height;
                    canvas.style.left = image.offsetLeft + 'px';
                    canvas.style.top = image.offsetTop + 'px';
                }} />
                <canvas
                    style={{
                        opacity: 0.5,
                    }}
                    ref={canvasRef}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseLeave={stopDrawing}
                    onTouchStart={startDrawing}
                    onTouchMove={draw}
                    onTouchEnd={stopDrawing}
                    onTouchCancel={stopDrawing}
                />
            </div>
        </>
    );
};

export default ImageEditor;