import React, { useState, useEffect } from "react";
import "./ProductSmall.css";
import { useFormatPrice } from "../../store";
import { Heart } from "react-feather";

const Product = ({ product }) => {
    // const dispatch = useDispatch();
    // const history = useHistory();
    const { id, name, desc, price, priceUnit, image } = product;

    const handleAddToCartClick = () => {
        // const action = addToCart(product);
        // dispatch(action);
    };

    const handleViewDetailClick = () => {
        // history.push(`/products/${id}`);
    };

    return (
        <div className="product">
            <div className="product-image">
                <img src={image} alt={name} />
            </div>

            <div className="product-info">
                <h3 className="product-name">{name}</h3>
                <div className="product-price">{useFormatPrice(price, priceUnit)}</div>
                <div className="product-btns">
                    {/* <Heart /> */}
                    {/* <Button onClick={handleAddToCartClick} size="small">
                        Add to cart
                    </Button>
                    <Button onClick={handleViewDetailClick} size="small" outline>
                        View detail
                    </Button> */}
                </div>
            </div>
        </div>
    );
};

export default Product;