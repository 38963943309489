// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-tab {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-weight: 500;
    padding: 20px 10px;
}

.category-tab-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ececec;
    margin: 20px 10px;
}

.category-tab-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.category-tab-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--secondary-gray);
    height: 55px;
    min-width: 0;
    flex-grow: 1;
    margin-right: 22px;
    border-bottom: 3px solid transparent;
}

.category-tab-header .active {
    color: #000;
    border-bottom: 3px solid #000;
}

.category-tab-item:hover {
    color: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/tabs/CategoryTab.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,2BAA2B;IAC3B,WAAW;IACX,SAAS;IACT,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,2BAA2B;IAC3B,4BAA4B;IAC5B,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,oCAAoC;AACxC;;AAEA;IACI,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,WAAW;AACf","sourcesContent":[".category-tab {\n    position: relative;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-start;\n    width: 100%;\n    margin: 0;\n    padding: 0;\n    cursor: pointer;\n    font-weight: 500;\n    padding: 20px 10px;\n}\n\n.category-tab-bar {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 1px;\n    background-color: #ececec;\n    margin: 20px 10px;\n}\n\n.category-tab-header {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.category-tab-item {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-start;\n    color: var(--secondary-gray);\n    height: 55px;\n    min-width: 0;\n    flex-grow: 1;\n    margin-right: 22px;\n    border-bottom: 3px solid transparent;\n}\n\n.category-tab-header .active {\n    color: #000;\n    border-bottom: 3px solid #000;\n}\n\n.category-tab-item:hover {\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
