import React, { useState, useCallback } from "react";
import { RefreshCw } from "react-feather";
import { useTranslation, Trans } from 'react-i18next';

const PromptKeywordSelector = ({ onKeywordChange, maxCount = 8 }) => {
    // Initial set of keywords. This can be replaced with any predefined list.
    const interiorStyles = [
        "Minimalism", "White", "Black", "Steel", "Simplicity", "Space", "Functionality", "Neutral Colors", "Clean Lines", "Natural Light", "Orderliness", "Minimal Decoration", "Modern Elements", "Texture and Materials", "Urban Modern", "Black", "Poserine Tile", "White Silk Wallpaper", "Provence", "Romantic", "Pastel Colors", "Natural Materials", "Ornate Fabrics", "frill", "Flowers and Vases", "Vintage Furniture", "Artwork and Murals", "Costal", "overall white feel", "point color blue", "polishing", "vintage interior", "brown", "leather", "Antique Furniture", "Retro Patterns", "Art Deco Elements", "Handmade Items", "Vintage Accessories", "Modern Farm House", "Shiplap", "Shiplap Curtains", "Pottery and Ceramic", "Pendant Lighting", "Wall Decor", "Modern Furniture", "New Nordic", "Scandinavian Minimalism", "Functional Design Elements", "Handmade or Artisan Accessories", "Indoor Plants for Freshness", "Soft Lighting for Coziness", "Art deco", "Bold Geometric Patterns", "Rich Color Palette", "Luxurious Materials", "Sunburst or Fan Motifs", "Glamorous Lighting", "Mirrors and Chrome Accents", "Artwork and Sculptures", "High Contrast", "Traditional", "Classic Furniture Styles", "Rich Color Palette", "Detailed Woodwork and Moulding", "Luxurious Fabrics", "Antique Pieces or Artworks", "Ornate Details", "Symmetry in Layout", "Beach", "Surf Style", "white", "Bright and Airy Colors", "Natural Materials", "Beach-Inspired Decorations", "Casual and Comfortable Furniture", "Nautical Elements", "Lightweight Fabrics"
    ];
    const { t } = useTranslation();

    const getRandomKeywords = (arr, n) => {
        let shuffled = arr.slice(),
            i = arr.length,
            temp,
            index;

        while (i--) {
            index = Math.floor((i + 1) * Math.random());
            temp = shuffled[index];
            shuffled[index] = shuffled[i];
            shuffled[i] = temp;
        }
        return shuffled.slice(0, n);
    };

    const [keywords, setKeywords] = useState([...getRandomKeywords(interiorStyles, maxCount)]);

    const handleRefreshClick = useCallback(() => {
        setKeywords([...getRandomKeywords(interiorStyles, maxCount)]);
    }, [interiorStyles]);

    const handleKeywordClick = (keyword) => {
        if (onKeywordChange) {
            onKeywordChange(keyword);
        }
        setKeywords((prevKeywords) => prevKeywords.filter(k => k !== keyword));
        if (keywords.length === 1) {
            setKeywords([...getRandomKeywords(interiorStyles, maxCount)]);
        }
    };

    return (
        <div style={{
            position: "relative"
        }}>
            <div className="prompt-keyword-tag-container" id="prompt-keyword-container"
                style={{
                    flexFlow: "row wrap",
                }}>

                <div className="prompt-keyword-tags-refresh not-selectable"
                    onClick={handleRefreshClick}>
                    <RefreshCw
                        key='refresh'
                        size={12}
                        className="prompt-keyword-tags-refresh-icon"
                    >
                    </RefreshCw>
                </div>
                {keywords.map((keyword, index) => (
                    <span
                        key={keyword + index}
                        onClick={() => handleKeywordClick(keyword)}
                        className="prompt-keyword-tags not-selectable"
                    >
                        {keyword}
                    </span>
                ))}
            </div>

            <div className="prompt-keyword-container-toast" id="prompt-keyword-container-toast">
                <p>
                    Updated using Prompt Tips!
                </p>
            </div>
        </div >
    );
}

export default PromptKeywordSelector;