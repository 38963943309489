import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import '../../assets/styles/Studio.css';
import LoginForm from '../auth/LoginForm'
import { Award } from 'react-feather';
import useToken from '../auth/useToken';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DropdownMenu from '../../components/DropdownMenu';
import '../../assets/styles/Header.css';
import 'font-awesome/css/font-awesome.min.css';
import Projects from '../collection/ProjectsHistory'
import MyHancutsHistory from '../packages/hancut/MyHancutsHistory';
import MyPropmtHistory from '../packages/prompt/MyPropmtHistory';
import profileImg1 from '../../assets/images/profiles/ToyFaces_Colored_BG_8.jpg';
import profileImg2 from '../../assets/images/profiles/ToyFaces_Colored_BG_29.jpg';
import profileImg3 from '../../assets/images/profiles/ToyFaces_Colored_BG_32.jpg';
import profileImg4 from '../../assets/images/profiles/ToyFaces_Colored_BG_37.jpg';
import profileImg5 from '../../assets/images/profiles/ToyFaces_Colored_BG_56.jpg';
import profileImg6 from '../../assets/images/profiles/ToyFaces_Colored_BG_59.jpg';
import ProjectDialog from '../dialog/ProjectDialog';
import DefaultDialog from '../dialog/DefaultDialog';
import Avatar from 'react-avatar';
import toast, { Toaster } from 'react-hot-toast';
import FeedbackDialog from '../dialog/FeedbackDialog';
import { formatShortDate, isDesktop, isTablet } from '../utils/Helper';
import ImageViewer from '../packages/hancut/ImageViewer';
import { Trans, useTranslation } from 'react-i18next';

export default function Profile() {
    const { t } = useTranslation();
    const [userName, setUserName] = useState();
    const [userType, setUserType] = useState();
    const [userFullName, setUserFullName] = useState();
    const [userEmail, setUserEmail] = useState(null);
    const [userProfileImage, setUserProfileImage] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState(null);
    const [isCreateProjectDialogShow, setIsCreateProjectDialogShow] = useState(false);

    const [isSubscribed, setIsSubscribed] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState({
        status: "none",
    });

    const [isEditProjectDialogShow, setIsEditProjectDialogShow] = useState(false);
    const [isConfirmDeleteProjectDialogShow, setIsConfirmDeleteProjectDialogShow] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [isErrorMessageShow, setIsErrorMessageShow] = useState(false);

    const [editProjectId, setEditProjectId] = useState("");
    const [editProjectTitle, setEditProjectTitle] = useState("");
    const [editProjectDescription, setEditProjectDescription] = useState("");
    const [deleteProjectID, setDeleteProjectID] = useState("");

    const [viewOption, setViewOption] = useState(0);

    const [savedGeneratedHistory, setSavedGeneratedHistory] = useState([

    ]);

    const [myHancuts, setMyHancuts] = useState([

    ]);
    const [isFullImageShow, setIsFullImageShow] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const onBackgroundClick = () => {
        setIsFullImageShow(false);
    }

    const [isFeedbackDialogShow, setIsFeedbackDialogShow] = useState(false);
    const [feedbackItem, setFeedbackItem] = useState("");

    const selected_tab = Number(new URLSearchParams(document.location.search).get("_t") || 0);
    useEffect(() => {
        console.log('selected_tab: ' + selected_tab);
        if (selected_tab >= 0 && selected_tab <= 3) {
            setViewOption(selected_tab);
        }
        if (selected_tab === 1) {
            loadMyHancuts();
        }
        if (selected_tab === 2) {
            loadSavedHistory();
        }
    }, [selected_tab]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const { token, setToken } = useToken();

    useEffect(() => {
        refreshUI();
    }, []);

    const refreshUI = () => {
        if (token) {
            loadUser();
            loadProjects();
            loadMyHancuts();
            loadSavedHistory();
        }
    }

    if (!token) {
        return <LoginForm setToken={setToken} />
    }

    const loadUser = () => {
        fetch(process.env.REACT_APP_API + '/v1/profile', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                console.log('response: ' + JSON.stringify(response));
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else {
                    console.log("response from user: " + response.error);
                    return response.json();
                }
            })
            .then(data => {
                console.log('data' + data);
                if (data !== undefined) {
                    const user = data.user;
                    const free_tier = data.free_tier;
                    setUserName(user.user_name);
                    setUserFullName(user.fname + " " + user.lname);
                    setUserEmail(user.email);
                    setUserProfileImage(user.profile_img);
                    setUserType(user.role);
                    // setFreeTireCount(subscription.total - subscription.consume);

                    if (data.active_subscription) {
                        setIsSubscribed(true);
                        setActiveSubscription(data.active_subscription);
                    }
                }
            })
            .catch(error => {
                localStorage.removeItem('session');
                navigate("/login", { replace: true })
                console.log('Some error in Profile /user');
                console.log(error);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    const loadProjects = () => {
        fetch(process.env.REACT_APP_API + '/v1/studio/project/all', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data.projects !== undefined) {
                    updateProjects(data.projects);
                }
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    const createProject = (credentials) => {
        fetch(process.env.REACT_APP_API + '/v1/studio/project', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                loadProjects();
                if (data.status) {
                    navigate(`/studio/${data.new_created_project._id}`);
                }
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    const editProject = (credentials) => {
        fetch(process.env.REACT_APP_API + '/v1/studio/project/' + credentials.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                loadProjects();
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    const deleteProject = (credentials) => {
        fetch(process.env.REACT_APP_API + '/v1/studio/project/' + credentials.id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                loadProjects();
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    const updateProjects = (projects) => {
        const createProjectItem = { title: "test title", description: "description", type: "create" };
        setProjects([...projects, createProjectItem]);
    }

    const loadMyHancuts = () => {
        fetch(process.env.REACT_APP_API + '/v1/studio/history/saved/images', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data !== undefined) {
                    setMyHancuts(data.hancuts.slice(0).reverse());
                }
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    const loadSavedHistory = () => {
        fetch(process.env.REACT_APP_API + `/v1/studio/history/all`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ saved: true })
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data !== undefined) {
                    setSavedGeneratedHistory(data.history.slice(0).reverse());
                }
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    const deletePrompt = (id, status) => {
        const toast_loading = status ? "Adding..." : "Removing...";
        const toast_message = status ? "Added to My Prompts!" : "Removed from My Prompts!";
        toast.promise(
            fetch(process.env.REACT_APP_API + "/v1/studio/history/" + id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id: id, status: status }),
            })
                .then((response) => {
                    if (!response.ok) {
                        return response.json().then(errorInfo => {
                            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                            setErrorMessage(errorInfo.message);
                            setIsErrorMessageShow(true);
                        });
                    }
                    else return response.json();
                })
                .then((data) => {
                    loadSavedHistory();
                })
                .catch(error => {
                    console.log("Error saving prompt: ", error);
                    setErrorMessage("Unexpected error occurs when saving prompt.");
                    setIsErrorMessageShow(true);
                    throw error;
                }),
            {
                loading: toast_loading,
                success: <b>{toast_message}</b>,
                error: <b>Unable to update My Prompts.</b>
            },
        );
    };

    const copyPrompt = async (text) => {
        toast('Copied selected prompt.',
            {
                icon: '📋',
                style: {
                    borderRadius: '10px',
                    background: '#fff',
                    color: '#000',
                    borderColor: "#000",
                    borderWidth: "2px",
                },
            }
        );
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const default_user_profile_images = [
        profileImg1,
        profileImg2,
        profileImg3,
        profileImg4,
        profileImg5,
        profileImg6];

    // createProject({ title: "New York Live Concepts", description: "Require to generate around 10 images for each song." });
    if (!token) {
        return <LoginForm setToken={setToken} />
    }

    return (
        <div className="ProfileApp">
            <Header userType={userType} userName={userFullName} userImg={userProfileImage} token={token} toggleDropdown={toggleDropdown} />
            <div className="ProfileApp content">
                <div className='profile-info-section-bg'>

                </div>
                <div className='profile-inner'>
                    <div className='profile-info-section'>
                        {userProfileImage === null ?
                            <Avatar name={userFullName} size="55px" round={true} color={'black'} maxInitials={1} />
                            :
                            <img src={!isNaN(userProfileImage) ? default_user_profile_images[userProfileImage] : userProfileImage.includes('http://') || userProfileImage.includes('https://') ? `${userProfileImage}` : `data:image/png;base64,${userProfileImage}`} alt="Profile" className="profile-picture" />
                        }

                        <p className='user-fullname'>{userFullName}</p>

                        {
                            isSubscribed &&
                            <div className='profile-pro-membership-wrapper'>
                                <Award className='' size={13} color='black' onClick={() => { }} />
                                <p>Pro Membership</p>
                            </div>
                        }

                        <p className='user-name'>@{userName}</p>
                        <p className='user-email'>{userEmail}</p>
                        {/* <button className='edit-profile' onClick={() =>
                        navigate("/profile/editor")
                    }>Edit Profile</button> */}
                    </div>
                    {
                        (isDesktop() || isTablet()) &&
                        <div className='project-selection-menu'>
                            <p className={viewOption === 0 ? 'items selected' : 'items'}
                                onClick={() => {
                                    if (window.location.pathname === "/profile" && window.location.search !== '?_t=0')
                                        navigate("/profile?_t=0");
                                }}><Trans>My Projects</Trans></p>
                            <p className={viewOption === 1 ? 'items selected' : 'items'}
                                onClick={() => {
                                    if (window.location.pathname === "/profile" && window.location.search !== '?_t=1')
                                        navigate("/profile?_t=1");
                                }}><Trans>My Hancuts</Trans></p>
                            <p className={viewOption === 2 ? 'items selected' : 'items'}
                                onClick={() => {
                                    if (window.location.pathname === "/profile" && window.location.search !== '?_t=2')
                                        navigate("/profile?_t=2");
                                }}><Trans>My Prompts</Trans><br /></p>
                        </div>
                    }

                    {
                        (!isDesktop() || !isTablet()) &&
                        <div className='project-selection-menu'>
                            <p className={viewOption === 0 ? 'items selected' : 'items'}
                                onClick={() => {
                                    if (window.location.pathname === "/profile" && window.location.search !== '?_t=0')
                                        navigate("/profile?_t=0");
                                }}><Trans>My</Trans><br /><Trans>Projects</Trans></p>
                            <p className={viewOption === 1 ? 'items selected' : 'items'}
                                onClick={() => {
                                    if (window.location.pathname === "/profile" && window.location.search !== '?_t=1')
                                        navigate("/profile?_t=1");
                                }}><Trans>My</Trans><br /><Trans>Hancuts</Trans></p>
                            <p className={viewOption === 2 ? 'items selected' : 'items'}
                                onClick={() => {
                                    if (window.location.pathname === "/profile" && window.location.search !== '?_t=2')
                                        navigate("/profile?_t=2");
                                }}><Trans>My</Trans><br /><Trans>Prompts</Trans><br /></p>
                        </div>
                    }
                    <div style={{
                        width: "100%",
                        height: "1px",
                        maxWidth: "1200px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        marginTop: "20px"
                    }}>
                        <div style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: isDesktop() || isTablet() ? "#fff" : "#fff",
                        }}></div>
                    </div>
                    <div className='project-history-container'>
                        {viewOption === 0 &&
                            <Projects
                                isMobile={!isDesktop() && !isTablet()}
                                projectHistory={projects}
                                isLoading={isLoading}
                                onCreateSelected={() => setIsCreateProjectDialogShow(true)}
                                onItemEdit={(e) => {
                                    setEditProjectId(e.id);
                                    setEditProjectTitle(e.title);
                                    setEditProjectDescription(e.description);
                                    setIsEditProjectDialogShow(true);
                                }}
                                onItemDelete={(e) => {
                                    setDeleteProjectID(e);
                                    setIsConfirmDeleteProjectDialogShow(true);
                                }} />}
                        {viewOption === 1 &&
                            <MyHancutsHistory
                                items={myHancuts}
                                onFeedback={(item) => {
                                    setFeedbackItem(item);
                                    setIsFeedbackDialogShow(true);
                                }}
                                onUpdate={() => {
                                    loadMyHancuts();
                                }}

                                onItemSelect={() => setIsFullImageShow(true)}
                                setCurrentIndex={setCurrentIndex}
                                isSubscribed={isSubscribed} />}
                        {viewOption === 2 &&
                            <MyPropmtHistory items={savedGeneratedHistory} onItemSelected={(text) => { copyPrompt(text); }} onItemDeleted={deletePrompt} />}

                    </div>
                    <Footer />
                </div>
            </div>

            {
                isFeedbackDialogShow &&
                <FeedbackDialog
                    item={feedbackItem}
                    isCancellable={true}
                    onClose={() => {
                        setIsFeedbackDialogShow(false)
                    }}
                    onOk={() => {
                        setIsFeedbackDialogShow(false);
                    }}
                    show={isFeedbackDialogShow}
                    onCancel={() => { setIsFeedbackDialogShow(false) }}
                    enableBackgroundClick={true} />
            }

            <ProjectDialog
                title={"Create New Project"}
                description={"Create project for each of your spatial styling project, or each of your space."}
                show={isCreateProjectDialogShow}
                isCancellable={true}
                onClose={() => { setIsCreateProjectDialogShow(false) }}
                onOk={(title, description) => {
                    setIsCreateProjectDialogShow(false);
                    createProject({ title: title, description: description });
                }}
                onCancel={() => { setIsCreateProjectDialogShow(false) }}
                enableBackgroundClick={true} />

            {isEditProjectDialogShow && <ProjectDialog
                title={"Edit Project"}
                description={""}
                show={isEditProjectDialogShow}
                isCancellable={true}
                onClose={() => { setIsEditProjectDialogShow(false) }}
                onOk={(title, description) => {
                    setIsEditProjectDialogShow(false);
                    editProject({ id: editProjectId, title: title, description: description });
                }}
                okBtn='Save'
                setPreTitle={editProjectTitle}
                setPreDescription={editProjectDescription}
                onCancel={() => {
                    setIsEditProjectDialogShow(false);
                    setDeleteProjectID("");
                }}
                enableBackgroundClick={true} />}
            {isConfirmDeleteProjectDialogShow && <DefaultDialog
                title={"Confirm Delete"}
                description={"Are you sure want to delete your project? This action cannot be undone."}
                show={isConfirmDeleteProjectDialogShow}
                isCancellable={true}
                onClose={() => {
                    setIsConfirmDeleteProjectDialogShow(false);
                    setDeleteProjectID("");
                }}
                onOk={() => {
                    setIsConfirmDeleteProjectDialogShow(false);
                    deleteProject(deleteProjectID);
                    setDeleteProjectID("");
                }}
                okBtn='Delete'
                onCancel={() => { setIsConfirmDeleteProjectDialogShow(false); }}
                enableBackgroundClick={true} />}
            {isErrorMessageShow && <DefaultDialog
                title={"Error"}
                description={errorMessage}
                show={isErrorMessageShow}
                isCancellable={false}
                onClose={() => {
                    setIsErrorMessageShow(false);
                }}
                onOk={() => {
                    setIsErrorMessageShow(false);
                }}
                okBtn='Ok'
                onCancel={() => { }}
                enableBackgroundClick={true} />}

            {
                isFullImageShow &&
                <ImageViewer
                    generated_history={myHancuts}
                    currentIndex={currentIndex}
                    isSubscribed={isSubscribed}
                    setCurrentIndex={setCurrentIndex}
                    onBackgroundClick={onBackgroundClick} />
            }

            <DropdownMenu
                userName={userFullName}
                userEmail={userEmail}
                userImg={userProfileImage}
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen}
                isSubscribed={isSubscribed}
                activeSubscription={activeSubscription} />

            <Toaster />
        </div>
    )
}