import React, { useState } from "react";
import { Trans } from "react-i18next";
// import noDataImg from '../../assets/images/empty-folder.svg';

function NoMyPromptHistory() {
    return (
        <div className="no-history-wrapper" >
            {/* <img src={noDataImg} alt="No Data" className="img" /> */}
            <h4 className="" style={{ fontWeight: "bold" }}><Trans>My Hancuts</Trans></h4>
            <p className="text-md lg:text-xl mb-5">
                <Trans>Add your favorite generated images to My Hancuts to access them quickly.</Trans>
            </p>
        </div>
    );
}

export default NoMyPromptHistory;