import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Trash, Edit, PlusCircle } from 'react-feather';
import { Trans } from "react-i18next";

function ProjectItem({ id, title, description, type, onCreateSelected, images, onItemEdit, onItemDelete }) {

    const [currentDate, setCurrentDate] = useState(Date.now());
    const navigate = useNavigate();
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(Date.now());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="project-item-container not-selectable">
            {
                (type === "item") ?
                    (images.length > 0) ?
                        (images.length < 4) ?
                            <div>
                                <div className="image-preview br" onClick={() => navigate(`/studio/${id}`)}>
                                    <img className="preview-one br" src={`${images[images.length - 1].image_url}`} alt={"Generated Image"} />
                                    <div className="project-overlay"></div>
                                    <div className='project-options-container' >
                                        <Edit className="project-delete-btn" color="white" onClick={(e) => {
                                            e.stopPropagation();
                                            onItemEdit({ id: id, title: title, description: description });
                                        }
                                        } />
                                        <Trash className="project-delete-btn" color="white" onClick={(e) => {
                                            e.stopPropagation();
                                            onItemDelete({ id: id });
                                        }
                                        } />
                                    </div>
                                </div>
                                <p className="project-name">{title}</p>
                                <p className="project-description">{images.length} <Trans>{images.length <= 1 ? "generation" : "generations"}</Trans></p>
                            </div>
                            :
                            <div>
                                <div className="image-preview-four" onClick={() => navigate(`/studio/${id}`)}>
                                    <img className="preview-four" style={{
                                        borderRadius: "13px 0px 0px 0px",
                                    }} src={`${images[images.length - 1].image_url}`} alt={"Generated Image"} />
                                    <img className="preview-four" style={{
                                        borderRadius: "0px 13px 0px 0px",
                                    }} src={`${images[images.length - 2].image_url}`} alt={"Generated Image"} />
                                    <img className="preview-four" style={{
                                        borderRadius: "0px 0px 0px 13px",
                                    }} src={`${images[images.length - 3].image_url}`} alt={"Generated Image"} />
                                    <img className="preview-four" style={{
                                        borderRadius: "0px 0px 13px 0px",
                                    }} src={`${images[images.length - 4].image_url}`} alt={"Generated Image"} />
                                    <div className="project-overlay"></div>
                                    <div className='project-options-container' >
                                        <Edit className="project-delete-btn" color="white" onClick={(e) => {
                                            e.stopPropagation();
                                            onItemEdit({ id: id, title: title, description: description });
                                        }
                                        } />
                                        <Trash className="project-delete-btn" color="white" onClick={(e) => {
                                            e.stopPropagation();
                                            onItemDelete({ id: id });
                                        }
                                        } />
                                    </div>
                                </div>
                                <p className="project-name">{title}</p>
                                <p className="project-description">{images.length} <Trans>{images.length <= 1 ? "generation" : "generations"}</Trans></p>
                            </div>
                        :
                        <div>
                            <div
                                className="no-image-project-box br" onClick={() => navigate(`/studio/${id}`)}>
                                <div className="project-overlay"></div>
                                <div className='project-options-container' >
                                    <Edit className="project-delete-btn" color="white" onClick={(e) => {
                                        e.stopPropagation();
                                        onItemEdit({ id: id, title: title, description: description });
                                    }
                                    } />
                                    <Trash className="project-delete-btn" color="white" onClick={(e) => {
                                        e.stopPropagation();
                                        onItemDelete({ id: id });
                                    }
                                    } />
                                </div>
                                {/* <div><p className="no-image-project-txt">You have no generated image for this project. Start creating your curated interior decorations.</p></div> */}
                            </div>
                            <p className="project-name">{title}</p>
                            <p className="project-description">{images.length} <Trans>{images.length <= 1 ? "generation" : "generations"}</Trans></p>

                        </div>
                    :
                    <div>
                        <div
                            className="create-new-project-box br" onClick={() => onCreateSelected()}
                        >
                            <PlusCircle color="black" />
                            <div><p className="create-new-project-txt"><Trans>Create New Project</Trans></p></div>
                        </div>
                        <p style={{
                            color: "#fff"
                        }}>Create</p>
                        <p style={{
                            color: "#fff"
                        }} className="project-description">-</p>
                    </div>
            }
        </div>

    );
}

function ProjectItemMobile({ id, title, description, type, onCreateSelected, images, onItemEdit, onItemDelete }) {

    const [currentDate, setCurrentDate] = useState(Date.now());
    const navigate = useNavigate();
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(Date.now());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="project-item-container not-selectable">
            {
                (type === "item") ?
                    (images.length > 0) ?
                        <div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }} onClick={() => navigate(`/studio/${id}`)}>
                                <div className="image-preview br"
                                    style={{
                                        width: "80px",
                                        height: "80px",
                                    }} >

                                    <img
                                        style={{
                                            width: "80px",
                                            height: "80px",
                                        }}
                                        className="preview-one br" src={`${images[images.length - 1].image_url}`} alt={"Generated Image"} />
                                </div>
                                <div style={{
                                    flex: "1",
                                    paddingLeft: "10px",
                                }}>

                                    <p className="project-name" style={{
                                        marginTop: "0px",
                                        textAlign: "left",
                                        fontSize: "16px",
                                    }}>{title}</p>
                                    <p className="project-description"
                                        style={{
                                            textAlign: "left",
                                            fontSize: "14px",
                                        }}>{images.length} <Trans>{images.length <= 1 ? "generation" : "generations"}</Trans></p>

                                </div>
                                <div style={{
                                    position: "relative",
                                }}>
                                    <Edit color="black" size={16}
                                        style={{
                                            marginRight: "20px",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onItemEdit({ id: id, title: title, description: description });
                                        }
                                        } />
                                    <Trash color="black"
                                        style={{
                                            marginRight: "10px",
                                        }}
                                        size={16} onClick={(e) => {
                                            e.stopPropagation();
                                            onItemDelete({ id: id });
                                        }
                                        } />
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }} onClick={() => navigate(`/studio/${id}`)}>
                                <div
                                    className="no-image-project-box br"
                                    style={{
                                        width: "80px",
                                        height: "80px",
                                    }}
                                >
                                </div>
                                <div style={{
                                    flex: "1",
                                    paddingLeft: "10px",
                                }}>

                                    <p className="project-name" style={{
                                        marginTop: "0px",
                                        textAlign: "left",
                                        fontSize: "16px",
                                    }}>{title}</p>
                                    <p className="project-description"
                                        style={{
                                            textAlign: "left",
                                            fontSize: "14px",
                                        }}>{images.length} <Trans>{images.length <= 1 ? "generation" : "generations"}</Trans></p>

                                </div>
                                <div style={{
                                    position: "relative",
                                }}>
                                    <Edit color="black" size={16}
                                        style={{
                                            marginRight: "20px",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onItemEdit({ id: id, title: title, description: description });
                                        }
                                        } />
                                    <Trash color="black"
                                        style={{
                                            marginRight: "10px",
                                        }}
                                        size={16} onClick={(e) => {
                                            e.stopPropagation();
                                            onItemDelete({ id: id });
                                        }
                                        } />
                                </div>
                            </div>
                        </div>
                    :
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                        onClick={() => onCreateSelected()}>
                        <div
                            className="create-new-project-box br"
                            style={{
                                width: "80px",
                                height: "80px",
                            }}
                        >
                            <PlusCircle color="black" />
                        </div>
                        <div style={{
                            paddingLeft: "10px",
                        }}><p className="create-new-project-txt" style={{
                            margin: "0px",
                            fontSize: "16px",
                        }}><Trans>Create New Project</Trans></p></div>
                    </div>
            }
        </div>

    );
}

function ProjectHistory({ isMobile, projectHistory, isLoading, onCreateSelected, onItemEdit, onItemDelete }) {
    return (
        <div >

            {/* {isLoading ? <LoadingPreviewImages loadingImg={loadingImg} loadingPrompt={loadingPrompt} /> : <div></div>} */}
            {// projectHistory.length > 0
                (projectHistory !== null) ? <div className="project-history">
                    {projectHistory.slice(0).reverse().map((item, index) => (
                        isMobile ?
                            <ProjectItemMobile key={index} id={item._id} title={item.title} description={item.description} type={item.type ? item.type : "item"} images={item.images} onCreateSelected={onCreateSelected} onItemEdit={onItemEdit} onItemDelete={onItemDelete} />
                            : <ProjectItem key={index} id={item._id} title={item.title} description={item.description} type={item.type ? item.type : "item"} images={item.images} onCreateSelected={onCreateSelected} onItemEdit={onItemEdit} onItemDelete={onItemDelete} />
                    ))}
                </div> : !isLoading ? <div /> : <div></div>
            }
        </div>
    );
}

export default ProjectHistory;