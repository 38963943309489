import React, { useState, useEffect } from 'react';
import { Square, Columns, X, Download, ChevronLeft, ChevronRight, Edit } from 'react-feather';
import { getLocalImageUrl, isTablet } from '../utils/Helper'
import { useTranslation, Trans } from 'react-i18next';
import { GetStartedButton } from '../buttons';
import { useNavigate } from 'react-router';
import useToken from '../auth/useToken';
import { BeforeAfter } from '../images';

const ImageViewer = ({ generated_history, currentIndex, setCurrentIndex, onBackgroundClick, isSubscribed }) => {

    const { t } = useTranslation();
    const [viewOption, setViewOption] = useState(1);
    const [currentDate, setCurrentDate] = useState(Date.now());
    const navigate = useNavigate();
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(Date.now());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const downloadBase64Image = (encodedImage) => {
        const link = document.createElement('a');
        link.href = encodedImage;
        link.download = 'hancutstudio_' + Math.floor((Math.random() * 999) + 100) + "_" + currentDate + '.jpeg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const { token, setToken } = useToken();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight' && currentIndex < (generated_history.length * 4) - 1) {
                setCurrentIndex((prevIndex) => prevIndex + 1);
            } else if (event.key === 'ArrowLeft' && currentIndex > 0) {
                setCurrentIndex((prevIndex) => prevIndex - 1);
            } else if (event.key === 'Escape') {
                onBackgroundClick();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex, generated_history.length]);

    const shareLink = (id) => {
        fetch(process.env.REACT_APP_API + `/v1/studio/image/${id}/share`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ image_id: id }),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        console.log(errorInfo);
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        // setErrorMessage(errorInfo.message);
                        // setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then((data) => {
                console.log(JSON.stringify(data));
                if (data._id) {
                    const link = `https://hancut.my04.net/share/v1/studio/share/${data._id}/kr`;
                    console.log(link);

                    if (navigator.share) {
                        navigator.share({
                            // title: 'Share Hancut Studio', // Optional, title of the thing you want to share.
                            // text: 'Hancut Studio.', // Optional, text to share.
                            url: link,
                        })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                    }
                    else {
                        alert("Link is copied.");
                        navigator.clipboard.writeText(link).then(() => {
                            console.log('Link copied to clipboard!');
                        }).catch(err => {
                            console.error('Failed to copy: ', err);
                        });
                    }
                }
                else {
                    // alert(JSON.stringify(data));
                }
            })
            .catch(error => {
                // setIsGenerationLoading(false);
                console.log("Error fetching data: ", error);
            });
    }

    return (
        <div className='image-viewer-container no-selectable'>

            {
                viewOption === 1 ?
                    <div className='image-viewer-original'>
                        {generated_history.length > 0 && (
                            <img
                                style={{
                                    marginLeft: "4%",
                                    marginRight: "1%"
                                }}
                                className='no-selectable br'
                                src={(generated_history[Math.floor(currentIndex / 4)].images[currentIndex % 4].original_image_url.includes("jpeg") ? `${process.env.REACT_APP_API_URL}` : `data:image/png;base64,`) + generated_history[Math.floor(currentIndex / 4)].images[(currentIndex % 4)].original_image_url} alt={`Image ${currentIndex + 1}`}
                            />
                        )}
                        {generated_history.length > 0 && (
                            <img
                                style={{
                                    marginRight: "4%",
                                    marginLeft: "1%"
                                }}
                                className='no-selectable br' src={getLocalImageUrl(generated_history[Math.floor(currentIndex / 4)].images[(currentIndex % 4)], isSubscribed)} alt={`Image ${currentIndex}`} />
                        )}
                    </div> :
                    <div className='image-viewer not-selectable'>
                        {generated_history.length > 0 && (
                            viewOption === 1 ?
                                <img
                                    className='no-selectable br' src={(generated_history[Math.floor(currentIndex / 4)].images[currentIndex % 4].original_image_url.includes("jpeg") ? `${process.env.REACT_APP_API_URL}` : `data:image/png;base64,`) + generated_history[Math.floor(currentIndex / 4)].images[(currentIndex % 4)].original_image_url} alt={`Image ${currentIndex + 1}`} />

                                : <img className='no-selectable br'
                                    src={
                                        // (generated_history[Math.floor(currentIndex / 4)].images[currentIndex % 4].thumbnail.includes("jpeg") ? `${process.env.REACT_APP_API_URL}` : `data:image/png;base64,`) + generated_history[Math.floor(currentIndex / 4)].images[(currentIndex % 4)].image_url
                                        getLocalImageUrl(generated_history[Math.floor(currentIndex / 4)].images[(currentIndex % 4)], isSubscribed)
                                    }
                                    alt={`Image ${currentIndex}`
                                    } />
                        )}
                    </div>
                // <div className="example-showcase-wrapper" style={{
                //     height: "100%",
                // }}>
                //     <div className="example-showcase" style={{
                //         height: "100%",
                //     }}>
                //         <div className="example-showcase-image-container" style={{
                //             height: "100%",
                //             marginBottom: "50px",
                //         }}>
                //             <BeforeAfter
                //                 // pointerMove
                //                 beforeImage={(generated_history[Math.floor(currentIndex / 4)].images[currentIndex % 4].original_image_url.includes("jpeg") ? `${process.env.REACT_APP_API_URL}` : `data:image/png;base64,`) + generated_history[Math.floor(currentIndex / 4)].images[(currentIndex % 4)].original_image_url}
                //                 afterImage={getLocalImageUrl(generated_history[Math.floor(currentIndex / 4)].images[(currentIndex % 4)], isSubscribed)}
                //                 style={{
                //                     height: "100%",
                //                 }}
                //                 afterStyle={{
                //                     height: "100%",
                //                 }
                //                 }
                //             />
                //         </div>
                //     </div>
                // </div>
            }

            <div className='preview-images-background no-selectable'></div>

            <div className='x-btn-container not-selectable'>
                <X className='x-btn' color='white' onClick={() => onBackgroundClick()} />
                <Download className='x-btn' color='white' onClick={() => downloadBase64Image(
                    getLocalImageUrl(generated_history[Math.floor(currentIndex / 4)].images[(currentIndex % 4)], isSubscribed)
                )} />
                <Edit className='x-btn' size={22} color='white' onClick={() => window.location.href = `/studio/${generated_history[Math.floor(currentIndex / 4)].images[(currentIndex % 4)]._id}/edit`} />
            </div>

            <div className='preview-images-show-generated-option not-selectable'>
                <div className='image-viewer-show-original not-selectable' onClick={() => { setViewOption(1) }}>
                    <Columns color={viewOption === 1 ? "#fff" : "#A0A0A0"} className='not-selectable' />
                </div>
                <div className='image-viewer-show-generated not-selectable image-viewer-show-original-selected' onClick={() => { setViewOption(0) }}>
                    <Square color={viewOption === 0 ? "#fff" : "#A0A0A0"} className='not-selectable' /></div>

            </div>

            <div style={{
                position: "absolute",
                top: "50%",
                left: "0",
                height: "fit-content",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(0,0,0,0.5)",
                padding: "12px 10px 10px 10px",
                opacity: currentIndex > 0 ? "1" : "0.25",
                borderRadius: "8px",
                marginLeft: "5px"
            }} onClick={() => {
                if (currentIndex > 0) {
                    setCurrentIndex((prevIndex) => prevIndex - 1);
                }
            }}>
                <ChevronLeft color="#fff"
                    className='not-selectable'
                    style={{
                        marginTop: "5px",
                        cursor: currentIndex > 0 ? "pointer" : "default"
                    }} />
            </div>

            <div style={{
                position: "absolute",
                top: "50%",
                right: "0",
                height: "fit-content",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(0,0,0,0.5)",
                padding: "12px 10px 10px 10px",
                opacity: currentIndex < (generated_history.length * 4) - 1 ? "1" : "0.25",
                borderRadius: "8px",
                marginRight: "5px"
            }} onClick={() => {
                if (currentIndex < (generated_history.length * 4) - 1) {
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                }
            }}>
                <ChevronRight color="#fff"
                    className='not-selectable'
                    style={{
                        marginTop: "5px",
                        cursor: currentIndex < (generated_history.length * 4) - 1 ? "pointer" : "default"
                    }} />
            </div>

            <div className='image-viewer-button-landing' style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexWrap: "wrap",
                transform: "translateY(-50%)",
                marginBottom: "0px",
            }}>
                <button className="button-landing"
                    style={isTablet() ? {
                        padding: "20px 40px",
                    } : {
                        padding: "10px 20px",
                    }
                    }
                    text={"이벤트"} onClick={() => {
                        window.open("https://cafe.naver.com/pcarpenter/775905", "_blank", "noreferrer");
                    }} >이벤트</button>
                <div style={{
                    width: "10px",
                    height: "10px",
                }}></div>
                <button className="button-landing" text={"공유"}
                    style={isTablet() ? {
                        padding: "20px 40px",
                    } : {
                        padding: "10px 20px",
                    }
                    } onClick={() => {
                        shareLink(generated_history[Math.floor(currentIndex / 4)].images[(currentIndex % 4)]._id);
                    }} >공유</button>
                <div style={{
                    width: "10px",
                    height: "10px",
                }}></div>
                <button className="button-landing" text={"견적내기"}
                    style={isTablet() ? {
                        padding: "20px 40px",
                    } : {
                        padding: "10px 20px",
                    }
                    } onClick={() => {
                        navigate("/pcarpenter");
                    }}>견적내기</button>
            </div>
        </div>
    );
}

export default ImageViewer;