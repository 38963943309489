import React, { useState, useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import '../../assets/styles/SwipeableGallery.css';

const SwipeableGallery = ({ data, onItemSelected }) => {
    const scrollContainerRef = useRef(null);
    const isScrollingRef = useRef(false);
    const [scrollWidth, setScrollWidth] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const [startScrollPosition, setStartScrollPosition] = useState(0);
    const [totalScrollDistance, setTotalScrollDistance] = useState(0);
    const [scrollDirection, setScrollDirection] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(2);
    const startScrollXRef = useRef(0);
    const startTouchXRef = useRef(0);
    const startTouchYRef = useRef(0);
    const [imageWidth, setImageWidth] = useState(0);

    // const handleScroll = () => {
    //     const containerScrollPosition = scrollContainerRef.current.scrollLeft;
    //     const containerWidth = scrollContainerRef.current.offsetWidth;

    //     images.forEach((image, index) => {
    //         const imageElement = scrollContainerRef.current.children[index];
    //         const imageOffsetLeft = imageElement.offsetLeft - containerScrollPosition;
    //         const imageOffsetRight = imageOffsetLeft + imageElement.offsetWidth;

    //         console.log(`Image ${index} left offset: ${imageOffsetLeft}, right offset: ${imageOffsetRight}`);

    //     });
    // };

    useEffect(() => {
        setContainerWidth(scrollContainerRef.current.offsetWidth);
        setScrollWidth(scrollContainerRef.current.scrollWidth);
        scrollToIndex(scrollPosition);
    }, [scrollPosition]);

    const scrollToIndex = (middleImageIndex = 2) => {
        if (middleImageIndex > 3) {
            middleImageIndex = 3;
            setScrollPosition(3);
        }
        if (middleImageIndex < 1) {
            middleImageIndex = 1;
            setScrollPosition(1);
        }
        const imageWidth = scrollContainerRef.current.children[middleImageIndex].offsetWidth;
        setImageWidth(imageWidth);
        const scrollToValue = (imageWidth * middleImageIndex) - (scrollContainerRef.current.offsetWidth / 2 - imageWidth / 2);
        scrollContainerRef.current.scrollLeft = scrollToValue;
    }

    const adjustImageWidths = () => {
        const containerCenter = scrollContainerRef.current.scrollLeft + (scrollContainerRef.current.offsetWidth / 2);
        Array.from(scrollContainerRef.current.children).forEach((div, index) => {
            const divCenter = div.offsetLeft + (div.offsetWidth / 2);
            const distanceFromCenter = Math.abs(containerCenter - divCenter);
            const widthPercentage = Math.max(100 - (distanceFromCenter / containerCenter) * 100, 80); // The 50 is the minimum width percentage
            // div.children[0].children[0].style.width = `${widthPercentage}%`;
            const scale = 1 - (distanceFromCenter / containerCenter) * 0.5; // The scale factor will decrease by up to 0.5 based on distance from the center
            div.style.transform = `scale(${Math.max(scale, 0.5)})`; // Ensure the scale does not go below 0.5
        });
    };

    useEffect(() => {
        // Adjust image widths on initial render
        adjustImageWidths();
    }, [scrollWidth]);

    const handleScroll = () => {
        // setScrollPosition(scrollContainerRef.current.scrollLeft);
    };

    const handleScrollbar = (e) => {
        const newScrollPosition = (e.target.value / 100) * (scrollWidth - containerWidth);
        scrollContainerRef.current.scrollLeft = newScrollPosition;
    };

    const handleTouchStart = (event) => {
        event.preventDefault();

        const touchX = event.touches[0].clientX;
        startTouchXRef.current = touchX;
        const touchY = event.touches[0].clientY;
        startTouchYRef.current = touchY;
        startScrollXRef.current = scrollContainerRef.current.scrollLeft;
        isScrollingRef.current = true;

        setStartScrollPosition(scrollContainerRef.current.scrollLeft);
    };

    const handleTouchMove = (event) => {
        const touchX = event.touches[0].clientX;
        const touchY = event.touches[0].clientY;
        const deltaX = startTouchXRef.current - touchX;
        const deltaY = startTouchYRef.current - touchY;

        if (deltaX > deltaY && !isScrollingRef.current) return;

        // const newScrollX = startScrollXRef.current + deltaX;
        // scrollContainerRef.current.scrollLeft = newScrollX;
    };

    const handleTouchEnd = () => {
        isScrollingRef.current = false;

        const endScrollPosition = scrollContainerRef.current.scrollLeft;
        const distanceScrolled = endScrollPosition - startScrollPosition;
        setTotalScrollDistance(Math.abs(distanceScrolled));

        if (distanceScrolled > 0) {
            setScrollDirection('right');
            setScrollPosition(scrollPosition + 1);
        } else if (distanceScrolled < 0) {
            setScrollDirection('left');
            setScrollPosition(scrollPosition - 1);
        }

        // const currentScrollLeft = scrollContainerRef.current.scrollLeft;
        // const imagesInView = Math.round(currentScrollLeft / imageWidth);
        // scrollToIndex(imagesInView);
    };

    return (
        <div className="scroll-container" ref={scrollContainerRef}
            onScroll={adjustImageWidths}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div key={0} className="image-container">
                <div className="scroll-image not-selectable" >
                    <div className="scroll-image not-selectable" />
                </div>
            </div>
            {data.map((item, index) => (
                <div key={index} className="image-container"
                    onClick={() => {
                        onItemSelected(index);
                    }}>
                    <div className="scroll-image not-selectable" >
                        <img className='preview-img' src={item.img} alt={`Item ${index}`}
                            style={{
                                width: "100%",
                            }} />
                        <p className='try-now' style={{
                            fontSize: "12px"
                        }}><Trans>Try Now</Trans></p>
                        <p className='preview-title' style={{
                            color: "#000",
                            fontSize: "16px",
                        }}><Trans>{item.title}</Trans></p>
                        <p className='preview-description' style={{
                            fontSize: "15px",
                            color: "#0d0c2280"
                        }}><Trans>{item.desc}</Trans></p>
                    </div>
                </div>
            ))}

            <div key={data.length - 2} className="image-container"
                onClick={() => onItemSelected(2)}
            >
                <div className="scroll-image not-selectable" >
                    <div className='preview-img upload-wrapper' alt={`Item ${data.length - 2}`}
                        style={{
                            width: "100%",
                            backgroundColor: "#D8D8D8",
                        }} >
                        <p className='upload-btn' style={{
                            fontSize: "16px"
                        }}><Trans>Upload Image</Trans></p>
                    </div>
                    <p className='try-now' style={{
                        fontSize: "13px",
                        opacity: "0"
                    }}><Trans>Try Now</Trans></p>
                    <p className='preview-title' style={{
                        color: "#000"
                    }}><Trans>Try Your Own Image</Trans></p>
                    <p className='preview-description' style={{
                        fontSize: "15px",
                        color: "#0d0c2280"
                    }}><Trans>Want to try with your own images and personalized prompt?</Trans></p>
                </div>
            </div>

            <div key={data.length - 1} className="image-container">
                <div className="scroll-image not-selectable" >
                    <div className="scroll-image not-selectable" />
                </div>
            </div>
        </div>
    );
};

export default SwipeableGallery;