import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import NoGenerationHistory from "./NoGenerationHistory";
import LoadingPreviewImages from "./LoadingPreviewImages";
import TimeAgo from 'react-timeago'
import { FaBeer } from 'react-icons/fa';
import { Download, ThumbsUp, ThumbsDown, Edit2, AlertCircle, Plus, Trash2 } from 'react-feather';
import useToken from '../../components/auth/useToken';
import { getLocalImageUrl, isDesktop, isTablet } from '../utils/Helper'
import { useTranslation, Trans } from 'react-i18next';
import { Tooltip as ReactTooltip } from "react-tooltip";

const optionsLabel = ["Creative", "Standard", "Jakomo", "Interior", "빙반 1 (D: 0.65, CFG: 8) [S.S]", "빙반 2 (D: 0.63, CFG: 15) [S.C.S]", "인테리어 1 (D: 0.60, CFG: 8) [S.C.S]", "인테리어 2 (D: 0.60, CFG: 15) [S]", "Curtain", "Sofa"];
function GeneratedImage({ id, index, imgIndex, imgFeedback, img, onItemSelect, setCurrentIndex, currentDate, onFeedback, isSubscribed }) {

    const [feedback, setFeedback] = useState(imgFeedback);
    const { token, setToken } = useToken();

    const { t } = useTranslation();
    const feedbackHandle = (payload) => {
        setFeedback(payload);
        fetch(process.env.REACT_APP_API + "/v1/studio/image/" + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ feedback: payload }),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {

                    });
                }
                else return response.json();
            })
            .then((data) => {
                if (payload === 2 && data.status) {
                    toast('Added to My Hancuts.',
                        {
                            icon: '❤️',
                            style: {
                                borderRadius: '10px',
                                background: '#fff',
                                color: '#000',
                                borderColor: "#000",
                                borderWidth: "2px",
                            },
                        }
                    );
                }
                if (payload === 1) {
                    onFeedback({ id: id, img: getLocalImageUrl(img, isSubscribed) });
                }
            })
            .catch(error => {
                console.log("Error fetching data: ", error);
            });
    }

    const downloadBase64Image = (encodedImage) => {
        const link = document.createElement('a');
        link.href = encodedImage;
        link.download = 'hancutstudio_' + Math.floor((Math.random() * 999) + 100) + "_" + currentDate + '.jpeg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className="generated-img">
            <img className="br not-selectable"
                style={{
                    cursor: "pointer"
                }}
                src={getLocalImageUrl(img, isSubscribed)} alt={"Generated"} onClick={() => {
                    onItemSelect(imgIndex);
                    setCurrentIndex((index * 4) + imgIndex);
                }} />
            <ThumbsDown color={feedback === 1 ? "#CB5151" : "#ffffff"} className='download-image-btn not-selectable'
                onClick={(e) => {
                    e.stopPropagation();
                    feedbackHandle(feedback === 1 ? 0 : 1);
                }
                } />
            {/* <Download color="white" className='download-image-btn not-selectable' onClick={(e) => {
                e.stopPropagation();
                downloadBase64Image(`${img.includes("jpeg") ? `${process.env.REACT_APP_API_URL}` : 'data:image/png;base64,'}${img}`);
            }
            } /> */}
            <div className="optional-btn-container not-selectable">
                <ThumbsUp color={feedback === 2 ? "#1A8917" : "#ffffff"} className='optional-btn'
                    style={{
                        color: "#1A8917"
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        feedbackHandle(feedback === 2 ? 0 : 2);
                    }
                    } />
                {/* <ThumbsDown color={feedback === 1 ? "#CB5151" : "#ffffff"} className='optional-btn'
                    onClick={(e) => {
                        e.stopPropagation();
                        feedbackHandle(feedback === 1 ? 0 : 1);
                    }
                    } /> */}
                {/* <Edit2 color="white" className='optional-btn' onClick={(e) => {
                    e.stopPropagation();
                }
                } /> */}
            </div>
        </div>
    );
}

function GeneratedItem({ id, index, options, imgs, text, timestamp, onItemSelect, setCurrentIndex, savePrompt, onFeedback, isSubscribed, generateOptions, onItemDelete }) {

    const { t } = useTranslation();
    const [currentDate, setCurrentDate] = useState(Date.now());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(Date.now());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const copyPrompt = async (text) => {
        toast(t('copySelectedPrompt'),
            {
                icon: '📋',
                style: {
                    borderRadius: '10px',
                    background: '#fff',
                    color: '#000',
                    borderColor: "#000",
                    borderWidth: "2px",
                },
            }
        );
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);

        // Select the text
        textarea.select();
        textarea.setSelectionRange(0, 99999); // For mobile devices

        try {
            // Copy the text inside the textarea
            document.execCommand('copy');
        } catch (err) {
            console.error("Failed to copy text: ", err);
        } finally {
            document.body.removeChild(textarea);
        }
    }

    return (
        <div className="generated-image-section" key={index}>
            <div style={{ overflow: "auto" }}>
                <p className="" style={{ marginBottom: "0px", fontWeight: "bold", float: "left", fontSize: "13.5px" }}><Trans>Prompt</Trans></p>
                <p className="" style={{ marginBottom: "0px", float: "right", fontSize: "13px" }}>{(((currentDate - Date.parse(timestamp)) / 1000) < 60) ? "Now" : <TimeAgo date={timestamp} />}</p>
            </div>
            <div className="loading-prompt-wrapper">
                <div style={{
                    width: "100%",
                    display: "flex",
                    paddingTop: "10px",
                    flexDirection: "column",
                }} >
                    {
                        text.length > 0 &&
                        <p data-tooltip-id="prompt_text_display" className="mb-5 display-prompt" style={{ width: "100%", marginTop: "0px", marginBottom: "10px", fontSize: "14px" }} onClick={() => {
                            copyPrompt(text);
                        }}>
                            {text}
                        </p>
                    }
                    {
                        generateOptions &&
                        <div >
                            {
                                generateOptions.map((item, index) => (
                                    isDesktop() || isTablet() ?
                                        <div
                                            key={index}
                                            style={{
                                                display: "inline-block",
                                                width: "auto",
                                                marginTop: "0px",
                                                marginBottom: "15px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                background: "#f0f0f0",
                                                borderRadius: "13px",
                                                padding: "4px 7px",
                                                marginRight: "10px"
                                            }}>
                                            <p style={{
                                                width: "auto",
                                                fontSize: "0.75em",
                                                fontWeight: "bold",
                                                margin: "0px",
                                                color: "#000",
                                                padding: "2px 5px",
                                            }}><Trans>{item.keyword}</Trans>
                                            </p>
                                        </div>
                                        :
                                        index <= 2 ?
                                            <div
                                                key={index}
                                                style={{
                                                    display: "inline-block",
                                                    width: "auto",
                                                    marginTop: "0px",
                                                    marginBottom: "15px",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    background: "#f0f0f0",
                                                    borderRadius: "13px",
                                                    padding: "4px 7px",
                                                    marginRight: "10px"
                                                }}>
                                                <p style={{
                                                    width: "auto",
                                                    fontSize: "0.75em",
                                                    fontWeight: "bold",
                                                    margin: "0px",
                                                    color: "#000",
                                                    padding: "2px 5px",
                                                }}><Trans>{item.keyword}</Trans>
                                                </p>
                                            </div> :
                                            index == 3 ? <div style={{
                                                display: "inline-block",
                                                width: "auto",
                                                marginTop: "0px",
                                                marginBottom: "15px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                background: "#f0f0f0",
                                                borderRadius: "13px",
                                                padding: "4px 7px",
                                                marginRight: "10px"
                                            }}>
                                                <p style={{
                                                    width: "auto",
                                                    fontSize: "0.75em",
                                                    fontWeight: "bold",
                                                    margin: "0px",
                                                    color: "#000",
                                                    padding: "2px 5px",
                                                }}>{generateOptions.length - 3}+ more
                                                </p>
                                            </div> : <></>
                                ))
                            }
                            <div style={{
                                display: "inline-block",
                                width: "auto",
                                marginTop: "0px",
                                marginBottom: "15px",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#f0f0f0",
                                borderRadius: "13px",
                                padding: "4px 7px",
                                marginRight: "10px"
                            }}>
                                <p style={{
                                    width: "auto",
                                    fontSize: "0.75em",
                                    fontWeight: "bold",
                                    margin: "0px",
                                    color: "#000",
                                    padding: "2px 5px",
                                }}><Trans>{optionsLabel[options - 1]}</Trans>
                                </p>
                            </div>
                        </div>
                    }
                </div>
                {/* <div style={{
                    cursor: "pointer",
                    marginTop: "14px"
                }}
                    onClick={(e) => {
                        e.stopPropagation();
                        savePrompt(id, true);
                    }
                    } >
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path fill="#696969" d="M512 416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H192c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8H448c35.3 0 64 28.7 64 64V416zM232 376c0 13.3 10.7 24 24 24s24-10.7 24-24V312h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V200c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z" /></svg>
                </div> */}
                <Plus color="black"
                    style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        marginLeft: "10px",
                        marginRight: "10px"
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        savePrompt(id, true);
                    }
                    } />
                <Trash2 color="black"
                    style={{
                        cursor: "pointer",
                        marginTop: "12px"
                    }}
                    size={20}
                    onClick={(e) => {
                        e.stopPropagation();
                        onItemDelete(id, true);
                    }
                    } />
            </div>

            {/* {
                options &&
                // <p className="room-type-tag not-selectable">
                //     {(options === "1" ? "Empty Space" : "Regular Space")}
                // </p>
                <div style={{
                    display: "inline-block",
                    width: "auto",
                    marginTop: "0px",
                    marginBottom: "15px",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#f0f0f0",
                    borderRadius: "13px",
                    padding: "4px 7px",
                }}>
                    <p style={{
                        width: "auto",
                        fontSize: "0.75em",
                        fontWeight: "bold",
                        margin: "0px",
                        color: "#000",
                        padding: "2px 5px",
                    }}>{(options === "1" ? "Empty Space" : "Regular Space")}
                    </p>
                </div>
            } */}
            <div className="generated-img-container not-selectable br">
                {/* <img className="generated-preview-img br" src={`data:image/png;base64,${img1}`} alt={text} /> */}
                <div className="generated-img not-selectable generated-img-container-left">
                    {imgs[0] &&
                        <GeneratedImage isSubscribed={isSubscribed} id={imgs[0]._id} index={index} imgIndex={0} img={imgs[0]} imgFeedback={imgs[0].feedback} onItemSelect={onItemSelect} setCurrentIndex={setCurrentIndex} currentDate={currentDate} onFeedback={onFeedback} />
                    }
                </div>
                <div className="generated-img not-selectable generated-img-container-right">
                    {imgs[1] &&
                        <GeneratedImage isSubscribed={isSubscribed} id={imgs[1]._id} index={index} imgIndex={1} img={imgs[1]} imgFeedback={imgs[1].feedback} onItemSelect={onItemSelect} setCurrentIndex={setCurrentIndex} currentDate={currentDate} onFeedback={onFeedback} />
                    }</div>
            </div>
            <div className="generated-img-container generated-img-container-top br">
                <div className="generated-img not-selectable generated-img-container-left">
                    {imgs[2] &&
                        <GeneratedImage isSubscribed={isSubscribed} id={imgs[2]._id} index={index} imgIndex={2} img={imgs[2]} imgFeedback={imgs[2].feedback} onItemSelect={onItemSelect} setCurrentIndex={setCurrentIndex} currentDate={currentDate} onFeedback={onFeedback} />
                    }
                </div>
                <div className="generated-img not-selectable generated-img-container-right">
                    {imgs[3] &&
                        <GeneratedImage isSubscribed={isSubscribed} id={imgs[3]._id} index={index} imgIndex={3} img={imgs[3]} imgFeedback={imgs[3].feedback} onItemSelect={onItemSelect} setCurrentIndex={setCurrentIndex} currentDate={currentDate} onFeedback={onFeedback} />

                    }
                </div>
            </div>
            <ReactTooltip
                id="prompt_text_display"
                place="bottom"
                content={t('copyPrompt')}
            />
        </div >
    );
}

function GeneratedHistory({ generatedHistory, isGenerationLoading, loadingImg, loadingPrompt, onItemSelect, setCurrentIndex, savePrompt, onFeedback, isSubscribed, onItemDelete }) {
    return (
        <div className="generated-history">
            <div>
                {isGenerationLoading ? <LoadingPreviewImages loadingImg={loadingImg} loadingPrompt={loadingPrompt} isGenerationLoading={isGenerationLoading} /> : <div></div>}
                {(generatedHistory.length > 0) ?
                    <div>
                        {generatedHistory.map((item, index) => (
                            <GeneratedItem key={item._id} isSubscribed={isSubscribed} onFeedback={onFeedback} id={item._id} imgs={item.images} options={item.room_type} text={item.prompt} timestamp={item.timestamp} onItemSelect={onItemSelect} setCurrentIndex={setCurrentIndex} index={index} savePrompt={savePrompt} generateOptions={item.options} onItemDelete={onItemDelete} />
                        ))}
                    </div> : !isGenerationLoading ? <NoGenerationHistory /> : <div></div>}
            </div>
        </div>
    );
}

export default GeneratedHistory;
