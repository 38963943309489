import { useState } from 'react';

export function formatShortDate(inputDate) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const date = new Date(inputDate);
    return date.toLocaleDateString(undefined, options);
}

export function getLocalImageUrl(img, imageAccess = false) {
    let imgToLoad = undefined;
    if (imageAccess) imgToLoad = img.image_url;
    else imgToLoad = img.thumbnail;
    return imgToLoad.includes("jpeg") ? `${process.env.REACT_APP_API_URL}` + imgToLoad : `data:image/png;base64,${imgToLoad}`
}

function getCurrentDimension() {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
}

export const isTablet = () => {
    return getCurrentDimension().width >= 768;
}
export const isDesktop = () => {
    return getCurrentDimension().width >= 1280;
}