import React, { useState, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ArrowRight } from 'react-feather'
import { useWindowResize } from '../../hooks/useWindowsResize';
import jakomoLogo from '../../assets/images/studio/jakomo_logo.png';
import jakomoProduct200 from '../../assets/images/studio/jakomo_products/sofa_200.png';
import jakomoProduct201 from '../../assets/images/studio/jakomo_products/sofa_201.png';
import jakomoProduct300 from '../../assets/images/studio/jakomo_products/sofa_300.png';
import jakomoProduct400 from '../../assets/images/studio/jakomo_products/sofa_400_1.png';

function ImageGenerationOptions({ onItemSelected, selectedRoomOptions, setSelectedRoomOptions, roomTypes, interiorStyles, stringifiedAdditionOptionsList, isAllowBeta, isAllowJakomo }) {

    const { t } = useTranslation();
    const { isTablet, isDesktop } = useWindowResize();
    const handleRoomtypeClick = (keyword, index) => {
        onItemSelected({
            option: "roomType",
            value: index,
            keyword: keyword,
        });
    };

    const handleThemeClick = (keyword, index) => {
        onItemSelected({
            option: "theme",
            value: index,
            keyword: keyword,
        });
    };

    return (
        <div className="image-generate-options not-selectable" style={{
            marginTop: "0px",
        }}>
            {/* <div style={{ height: "20px" }} /> */}
            {/* <h4 className="image-generate-options-label" style={{ fontWeight: "bold", marginTop: "0px", fontSize: "16px" }}><Trans>Select Generate Options</Trans> <span style={{ fontWeight: "normal", color: "#00000099" }}></span></h4> */}

            {/* <p className="text-md lg:text-xl mb-5" style={{
                marginTop: "0px",
                fontSize: "13px",
            }}>
                <Trans>Type</Trans>
            </p> */}

            <div className="" style={{
                marginTop: "20px",
                display: "flex",
            }}><h4 style={{
                fontWeight: "bold", fontSize: "16px",
                flex: 1
            }}>
                    <Trans>Type</Trans>
                </h4>
                {
                    !isTablet && !isDesktop &&
                    <ArrowRight color='black' style={{

                    }} />
                }
            </div>

            <div style={{
                position: "relative"
            }}>
                <div className="prompt-keyword-container prompt-keyword-container-mobile" id="prompt-keyword-container">
                    {roomTypes.map((keyword, index) => (
                        <span
                            key={keyword + index}
                            onClick={() => handleRoomtypeClick(keyword, index)}
                            className={stringifiedAdditionOptionsList.includes(JSON.stringify({
                                option: "roomType",
                                value: index,
                                keyword: keyword,
                            })) ? "prompt-keyword-items not-selectable prompt-keyword-items-selected" : "prompt-keyword-items not-selectable"}
                        >
                            {keyword}
                        </span>
                    ))}
                </div>
            </div>

            {/* <p className="text-md lg:text-xl mb-5" style={{
                marginTop: "20px",
                fontSize: "13px",
            }}>
                <Trans>Style</Trans>
            </p> */}
            <div className="" style={{
                marginTop: "20px",
                display: "flex",
            }}><h4 style={{
                fontWeight: "bold", fontSize: "16px",
                flex: 1
            }}>
                    <Trans>Style</Trans>
                </h4>
                {
                    !isTablet && !isDesktop &&
                    <ArrowRight color='black' style={{

                    }} />
                }
            </div>

            <div style={{
                position: "relative"
            }}>
                <div className="prompt-keyword-container prompt-keyword-container-mobile" id="prompt-keyword-container">
                    {interiorStyles.map((keyword, index) => (
                        <span
                            key={keyword + index}
                            onClick={() => handleThemeClick(keyword, index)}
                            className={stringifiedAdditionOptionsList.includes(JSON.stringify({
                                option: "theme",
                                value: index,
                                keyword: keyword,
                            })) ? "prompt-keyword-items not-selectable prompt-keyword-items-selected" : "prompt-keyword-items not-selectable"}
                        >
                            {keyword}
                        </span>
                    ))}
                </div>
            </div>

            {/* <p className="text-md lg:text-xl mb-5" style={{
                marginTop: "20px",
                fontSize: "13px",
            }}>
                <Trans>Generative Style</Trans>
            </p> */}
            <div className="" style={{
                marginTop: "20px",
                display: "flex",
            }}><h4 style={{
                fontWeight: "bold", fontSize: "16px",
                flex: 1
            }}>
                    <Trans>Generative Style</Trans>
                </h4>
                {/* <ArrowRight color='black' style={{

                }} /> */}
            </div>
            <div className='generate-options-container'>
                <div
                    data-tooltip-id='standard-tooltip'
                    className="generate-options-item" style={selectedRoomOptions === 2 ? {
                        border: "2px solid #383838",
                        color: "#383838",
                    } : {}}
                    onClick={() => {
                        setSelectedRoomOptions(selectedRoomOptions === 2 ? 2 : 2);
                    }}>
                    {/* <img src={normalRoom} alt="Normal Room" className="img" /> */}
                    <p><Trans>Standard</Trans></p>
                </div>
                <div
                    data-tooltip-id='creative-tooltip'
                    className="generate-options-item" style={selectedRoomOptions === 1 ? {
                        border: "2px solid #383838",
                        color: "#383838",
                    } : {}}
                    onClick={() => {
                        setSelectedRoomOptions(selectedRoomOptions === 1 ? 1 : 1);
                    }}>
                    {/* <img src={cleanRoom} alt="Clean Room" className="img" /> */}
                    <p><Trans>Creative</Trans></p>
                </div>
                {/* {
                    isAllowBeta && ( */}
                <div
                    data-tooltip-id='creative-tooltip'
                    className="generate-options-item" style={selectedRoomOptions === 4 ? {
                        border: "2px solid #383838",
                        color: "#383838",
                    } : {}}
                    onClick={() => {
                        setSelectedRoomOptions(selectedRoomOptions === 4 ? 4 : 4);
                    }}>
                    {/* <img src={cleanRoom} alt="Clean Room" className="img" /> */}
                    <p><Trans>Home Styling</Trans></p>
                </div>
                {/* )
                } */}
                {/* <div
                    data-tooltip-id='creative-tooltip'
                    className="generate-options-item" style={selectedRoomOptions === 4 ? {
                        border: "2px solid #383838",
                        color: "#383838",
                    } : {}}
                    onClick={() => {
                        setSelectedRoomOptions(selectedRoomOptions === 4 ? 4 : 4);
                    }}>
                    <p><Trans>스타일링</Trans></p>
                </div> */}
            </div>

            {
                isAllowBeta && false &&
                <>
                    <div className='generate-options-container' style={{
                        marginTop: "20px",
                    }}>
                        <div
                            data-tooltip-id='creative-tooltip'
                            className="generate-options-item" style={selectedRoomOptions === 5 ? {
                                border: "2px solid #383838",
                                color: "#383838",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            } : {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            onClick={() => {
                                setSelectedRoomOptions(selectedRoomOptions === 5 ? 5 : 5);
                            }}>
                            <p><Trans>빙반 1 (D: 0.65, CFG: 8) [S.S]</Trans></p>
                        </div>
                    </div>

                    <div className='generate-options-container' style={{
                        marginTop: "20px",
                    }}>
                        <div
                            data-tooltip-id='creative-tooltip'
                            className="generate-options-item" style={selectedRoomOptions === 6 ? {
                                border: "2px solid #383838",
                                color: "#383838",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            } : {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            onClick={() => {
                                setSelectedRoomOptions(selectedRoomOptions === 6 ? 6 : 6);
                            }}>
                            <p><Trans>빙반 2 (D: 0.63, CFG: 15) [S.C.S]</Trans></p>
                        </div>
                    </div>

                    <div className='generate-options-container' style={{
                        marginTop: "20px",
                    }}>
                        <div
                            data-tooltip-id='creative-tooltip'
                            className="generate-options-item" style={selectedRoomOptions === 7 ? {
                                border: "2px solid #383838",
                                color: "#383838",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            } : {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            onClick={() => {
                                setSelectedRoomOptions(selectedRoomOptions === 7 ? 7 : 7);
                            }}>
                            <p><Trans>인테리어 1 (D: 0.60, CFG: 8) [S.C.S]</Trans></p>
                        </div>
                    </div>

                    <div className='generate-options-container' style={{
                        marginTop: "20px",
                    }}>
                        <div
                            data-tooltip-id='creative-tooltip'
                            className="generate-options-item" style={selectedRoomOptions === 8 ? {
                                border: "2px solid #383838",
                                color: "#383838",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            } : {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            onClick={() => {
                                setSelectedRoomOptions(selectedRoomOptions === 8 ? 8 : 8);
                            }}>
                            <p><Trans>인테리어 2 (D: 0.60, CFG: 15) [S]</Trans></p>
                        </div>
                    </div>
                </>
            }



            {/* {
                isAllowBeta &&
                <>
                    <div className="" style={{
                        marginTop: "20px",
                        display: "flex",
                    }}><h4 style={{
                        fontWeight: "bold", fontSize: "16px",
                        flex: 1
                    }}>
                            <Trans>Test 1.0</Trans>
                        </h4>
                    </div>
                    <div className='generate-options-container'>
                        <div
                            data-tooltip-id='standard-tooltip'
                            className="generate-options-item" style={selectedRoomOptions === 200 ? {
                                border: "2px solid #383838",
                                color: "#383838",
                                padding: "0px"
                            } : { padding: "0px" }}
                            onClick={() => {
                                setSelectedRoomOptions(selectedRoomOptions === 200 ? 200 : 200);
                            }}>
                            <img src={jakomoProduct200} alt="Normal Room" className="img"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "fill"
                                }} />
                        </div>
                        <div
                            data-tooltip-id='standard-tooltip'
                            className="generate-options-item" style={selectedRoomOptions === 201 ? {
                                border: "2px solid #383838",
                                color: "#383838",
                                padding: "0px"
                            } : { padding: "0px" }}
                            onClick={() => {
                                setSelectedRoomOptions(selectedRoomOptions === 201 ? 201 : 201);
                            }}>
                            <img src={jakomoProduct201} alt="Normal Room" className="img"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "fill"
                                }} />
                        </div>
                    </div>
                </>
            } */}

            {
                isAllowBeta &&
                <>
                    <div className="" style={{
                        marginTop: "20px",
                        display: "flex",
                    }}><h4 style={{
                        fontWeight: "bold", fontSize: "16px",
                        flex: 1
                    }}>
                            <Trans>Test 1.1</Trans>
                        </h4>
                    </div>
                    <div className='generate-options-container'>
                        <div
                            data-tooltip-id='standard-tooltip'
                            className="generate-options-item" style={selectedRoomOptions === 300 ? {
                                border: "2px solid #383838",
                                color: "#383838",
                                padding: "0px"
                            } : { padding: "0px" }}
                            onClick={() => {
                                setSelectedRoomOptions(selectedRoomOptions === 300 ? 300 : 300);
                            }}>
                            <img src={jakomoProduct300} alt="Normal Room" className="img"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "fill"
                                }} />
                        </div>
                        <div
                            data-tooltip-id='standard-tooltip'
                            className="generate-options-item" style={selectedRoomOptions === 301 ? {
                                border: "2px solid #383838",
                                color: "#383838",
                                padding: "0px"
                            } : { padding: "0px" }}
                            onClick={() => {
                                setSelectedRoomOptions(selectedRoomOptions === 301 ? 301 : 301);
                            }}>
                            <img src="https://igaboro.kr/data/goods/21/06/23/1000958747/1000958747_detail_078.jpg" alt="Normal Room" className="img"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "fill"
                                }} />
                        </div>

                        {/* <div
                            data-tooltip-id='standard-tooltip'
                            className="generate-options-item" style={selectedRoomOptions === 302 ? {
                                border: "2px solid #383838",
                                color: "#383838",
                                padding: "0px"
                            } : { padding: "0px" }}
                            onClick={() => {
                                setSelectedRoomOptions(selectedRoomOptions === 302 ? 302 : 302);
                            }}>
                            <img src="https://i.pinimg.com/736x/c0/f1/ab/c0f1abd442ba57f1a2b14727aa9cfc48.jpg" alt="Normal Room" className="img"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "fill"
                                }} />
                        </div> */}
                    </div>
                </>
            }

            {/* {
                isAllowBeta &&
                <>
                    <div className="" style={{
                        marginTop: "20px",
                        display: "flex",
                    }}><h4 style={{
                        fontWeight: "bold", fontSize: "16px",
                        flex: 1
                    }}>
                            <Trans>Test 1.2</Trans>
                        </h4>
                    </div>
                    <div className='generate-options-container'>
                        <div
                            data-tooltip-id='standard-tooltip'
                            className="generate-options-item" style={selectedRoomOptions === 400 ? {
                                border: "2px solid #383838",
                                color: "#383838",
                                padding: "0px"
                            } : { padding: "0px" }}
                            onClick={() => {
                                setSelectedRoomOptions(selectedRoomOptions === 400 ? 400 : 400);
                            }}>
                            <img src={jakomoProduct400} alt="Normal Room" className="img"
                                style={{
                                    width: "100%",
                                    height: "85%",
                                    objectFit: "fill"
                                }} />
                            <p style={{
                                margin: "0px",
                                color: "#000000"
                            }}>2.1.1</p>
                        </div>
                        <div
                            data-tooltip-id='standard-tooltip'
                            className="generate-options-item" style={selectedRoomOptions === 401 ? {
                                border: "2px solid #383838",
                                color: "#383838",
                                padding: "0px"
                            } : { padding: "0px" }}
                            onClick={() => {
                                setSelectedRoomOptions(selectedRoomOptions === 401 ? 401 : 401);
                            }}>
                            <img src={jakomoProduct400} alt="Normal Room" className="img"
                                style={{
                                    width: "100%",
                                    height: "85%",
                                    objectFit: "fill"
                                }} />
                            <p style={{
                                margin: "0px",
                                color: "#000000"
                            }}>2.1.2</p>
                        </div>
                    </div>
                </>
            } */}
        </div>
    );
}

export default ImageGenerationOptions;