import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        // const tokenString = sessionStorage.getItem('token');
        const tokenString = localStorage.getItem('session');
        // const userToken = JSON.parse(tokenString);
        // return userToken?.token
        return tokenString
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        // sessionStorage.setItem('token', JSON.stringify(userToken));
        localStorage.setItem('session', userToken);
        setToken(userToken.token);
    };

    return {
        setToken: saveToken,
        token
    }
}