import React from 'react';
import '../assets/styles/Footer.css';
import logo from '../assets/images/hancut_logo_small.png';
import useToken from './auth/useToken';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
    const { t, i18n } = useTranslation();
    const { token, setToken } = useToken();
    const currentLanguage = i18n.language;
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="footer not-selectable">
            <div className='footer-wrapper'>
                <div className="footer-row1">
                    <img src={logo} alt="Company Logo" className="company-logo" />
                    <div className="menu">
                        <a href={token ? "/studio" : "/home"}><Trans>Home</Trans></a>
                        {/* <a href="/">Blog</a>
                        <a href="/">Careers</a> */}
                        <a href="/about"><Trans>About</Trans></a>
                        <a href="/contact"><Trans>Contact</Trans></a>
                        <a href="/getstarted"><Trans>Get Started</Trans></a>
                        <a href="/pricing"><Trans>Pricing</Trans></a>
                        <a href="https://medium.com/@my04" target="_blank"><Trans>Our Story</Trans></a>
                        <a href={currentLanguage === "kr" ? `/terms_kr` : `/terms`} className='tnc_mobile'><Trans>Terms</Trans></a>
                        <a href={currentLanguage === "kr" ? `/privacy_kr` : `/privacy`} className='tnc_mobile'><Trans>Privacy</Trans></a>
                        {/* <a href="https://my04.net">MY04</a> */}
                    </div>
                </div>
                <div className="footer-row2">
                    <div className="left">
                        <a href={currentLanguage === "kr" ? `/terms_kr` : `/terms`}><Trans>Terms</Trans></a>
                        <a href={currentLanguage === "kr" ? `/privacy_kr` : `/privacy`}><Trans>Privacy</Trans></a>
                        {/* <a href="/cookie">Cookie</a> */}
                    </div>
                    <div className='languageSwitcher'>
                        <button className={currentLanguage === "kr" ? `langBtn selected` : `langBtn`} onClick={() => {
                            i18n.changeLanguage("kr");
                            if (location.pathname.includes("/terms")) navigate("/terms_kr");
                            else if (location.pathname.includes("/privacy")) navigate("/privacy_kr");
                            else
                                window.location.reload();
                        }}>KR</button>
                        <button className={currentLanguage === "en" ? `langBtn selected` : `langBtn`} onClick={() => {
                            i18n.changeLanguage("en");
                            if (location.pathname.includes("/terms_kr")) navigate("/terms");
                            else if (location.pathname.includes("/privacy_kr")) navigate("/privacy");
                            else
                                window.location.reload();
                        }}>EN</button>
                    </div>

                    {/* <p style={{
                        width: "100%",
                        textAlign: "center",
                        color: "#A0A0A0",
                        position: "absolute",
                    }}><b style={{
                        fontSize: "1.2em",
                    }}>&#169;</b> 2023 MY04</p> */}

                    <div className="right">
                        <a href="https://twitter.com/hancutstudio" className="social-icon twitter" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM351.3 199.3v0c0 86.7-66 186.6-186.6 186.6c-37.2 0-71.7-10.8-100.7-29.4c5.3 .6 10.4 .8 15.8 .8c30.7 0 58.9-10.4 81.4-28c-28.8-.6-53-19.5-61.3-45.5c10.1 1.5 19.2 1.5 29.6-1.2c-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3c-9-6-16.4-14.1-21.5-23.6s-7.8-20.2-7.7-31c0-12.2 3.2-23.4 8.9-33.1c32.3 39.8 80.8 65.8 135.2 68.6c-9.3-44.5 24-80.6 64-80.6c18.9 0 35.9 7.9 47.9 20.7c14.8-2.8 29-8.3 41.6-15.8c-4.9 15.2-15.2 28-28.8 36.1c13.2-1.4 26-5.1 37.8-10.2c-8.9 13.1-20.1 24.7-32.9 34c.2 2.8 .2 5.7 .2 8.5z" /></svg>
                        </a>
                        <a href="https://www.instagram.com/hancutstudio/" className="social-icon instagram" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;