import { useState, useEffect } from 'react';
import { Download, ThumbsUp, ThumbsDown, Edit2, Plus } from 'react-feather';
import {
    Box,
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import NoMyHancutHistory from './NoMyHancutHistory';
import useToken from '../../../components/auth/useToken';
import LazyImage from '../../utils/LazyImage';
import { getLocalImageUrl } from '../../utils/Helper';
import { Trans } from 'react-i18next';

export default function MyHancutComponent({ items, onUpdate, isSubscribed, onFeedback, onItemSelect }) {
    const { token, setToken } = useToken();
    const [currentDate, setCurrentDate] = useState(Date.now());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(Date.now());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize]);
    const isTablet = () => {
        return screenSize.width > 768;
    }
    const isDesktop = () => {
        return screenSize.width > 1280;
    }

    const feedbackHandle = (id, item, payload) => {
        fetch(process.env.REACT_APP_API + "/v1/studio/image/" + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ feedback: payload }),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {

                    });
                }
                else return response.json();
            })
            .then((data) => {
                onUpdate();
                if (payload === 1) {
                    onFeedback({ id: id, img: getLocalImageUrl(item, isSubscribed) });
                }
            })
            .catch(error => {
                console.log("Error fetching data: ", error);
            });
    }

    const downloadBase64Image = (encodedImage) => {
        const link = document.createElement('a');
        link.href = encodedImage;
        link.download = 'hancutstudio_' + Math.floor((Math.random() * 999) + 100) + "_" + currentDate + '.jpeg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div style={{
            position: "relative",
            minHeight: "250px"
        }}>
            {(items?.length > 0) ?
                <div className="prompt-history-list-container">
                    <Box>
                        <Masonry columns={isTablet() ? 3 : 1} spacing={2}>
                            {items.map((item, index) => (
                                <Box className="prompt-history-list-item" key={index}>
                                    <LazyImage
                                        style={{
                                            width: '100%',
                                            display: 'block',
                                            borderRadius: '13px',
                                            marginBottom: '8px',
                                            cursor: 'pointer',
                                            PointerEvent: "none"
                                            // < picture style="pointer-events:none"><source srcset="https://www.shutterstock.com/image-photo/beautiful-table-setting-thanksgiving-dinner-600w-1067518658.jpg" media="(min-width: 601px)"><source srcset="https://www.shutterstock.com/image-photo/beautiful-table-setting-thanksgiving-dinner-260nw-1067518658.jpg" media="(max-width: 600px)"><img fetchpriority="high" alt="A beautiful table setting for Thanksgiving dinner in a restaurant. Private dining. Horizontal image, indoors, selective focus." aria-label="Image" class="mui-1qvdywb-image" data-automation="ImageContainer_Image" src="https://www.shutterstock.com/image-photo/beautiful-table-setting-thanksgiving-dinner-260nw-1067518658.jpg" width="600" height="400" style="background:url(&quot;https://www.shutterstock.com/image-photo/beautiful-table-setting-thanksgiving-dinner-260nw-1067518658.jpg&quot;) center top/cover"></picture>
                                        }}
                                        placeholderStyle={{
                                            width: '100%',
                                            height: '300px',
                                            display: 'block',
                                            borderRadius: '13px',
                                            marginBottom: '8px',
                                            cursor: 'pointer',
                                            background: "#f7f7f7",
                                            PointerEvent: "none"
                                        }}
                                        src={getLocalImageUrl(item, isSubscribed)}
                                        onItemClick={() => onItemSelect(item)}
                                    />
                                    {/* <img
                                        style={{
                                            width: '100%',
                                            display: 'block',
                                            borderRadius: '13px',
                                            marginBottom: '8px',
                                            cursor: 'pointer',
                                        }}
                                        src={item.image_url.includes("jpeg") ? `${process.env.REACT_APP_API_URL}` + item.image_url : `data:image/png;base64,${item.image_url}`}
                                    /> */}

                                    {/* <Download color="white" className='download-image-btn not-selectable' onClick={(e) => {
                                        e.stopPropagation();
                                        downloadBase64Image(`${item.thumbnail.includes("jpeg") ? `${process.env.REACT_APP_API_URL}` : 'data:image/png;base64,'}${item.thumbnail}`);
                                    }
                                    } /> */}

                                    <ThumbsDown color={item.feedback === 1 ? "#CB5151" : "#ffffff"} className='download-image-btn not-selectable'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            feedbackHandle(item._id, item, item.feedback === 1 ? 0 : 1);
                                        }
                                        } />
                                    <div className="optional-btn-container not-selectable">
                                        <ThumbsUp color={item.feedback === 2 ? "#1A8917" : "#ffffff"} className='optional-btn'
                                            style={{
                                                color: "#1A8917"
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                feedbackHandle(item._id, item, item.feedback === 2 ? 0 : 2);
                                            }
                                            } />
                                        {/* <ThumbsDown color={item.feedback === 1 ? "#CB5151" : "#ffffff"} className='optional-btn'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                feedbackHandle(item.feedback === 1 ? 0 : 1);
                                            }
                                            } /> */}
                                        {/* <Edit2 color="white" className='optional-btn' onClick={(e) => {
                                            e.stopPropagation();
                                        }
                                        } /> */}
                                    </div>
                                </Box>
                            ))}
                        </Masonry>
                    </Box>
                </div>
                : <NoMyHancutHistory />}
        </div>
    );
}