import React, { useState } from "react";
import Header from "../../components/Header";
import { CategoryRow, StoreCategory, StoreBanner, getCategories, getTrendingCategories } from "../../features/store";
import "./StoreHome.css";

const ShopHome = () => {

    const categories = getCategories();
    const trendingCategories = getTrendingCategories();

    return (
        <div>
            <div className="store-home-wrapper">
                <Header />
                <div className="store-home">
                    <StoreBanner />
                    {/* <CategoryRow categories={categories} /> */}
                    <StoreCategory categories={trendingCategories} />
                </div>
            </div>
            <div style={{
                position: "absolute",
                width: "100%",
                height: "calc(100vh - 55px)",
                marginTop: "55px",
                backgroundColor: "#00000050",
                top: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pointerEvents: "none"
            }}>
                <p style={{
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "16px"
                }}>
                    준비중입니다 ...
                </p>
            </div>
        </div>
    );
}

export default ShopHome;