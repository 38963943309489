import React, { useState } from "react";
import { Trans } from "react-i18next";
import { ArrowRight } from "react-feather";
import welcomeIllustration from "../../assets/images/welcome_illustration_1.jpeg";

function DefaultDialog({
    title,
    description,
    show,
    onClose,
    isCancellable = true,
    enableBackgroundClick = true,
    onOk,
    onCancel,
    okBtn = "Ok"
}) {

    const handleBackgroundClick = () => {
        if (enableBackgroundClick) {
            onClose();
        }
    };

    const handleOkClick = () => {
        onOk();
    };

    const handleCancelClick = () => {
        onCancel();
    };

    return (
        show && (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                    overflow: 'auto'
                }}
                onClick={handleBackgroundClick}
            >
                <div
                    className="dialog-container"
                    style={{
                        width: 500,
                        backgroundColor: "white",
                        borderRadius: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    {/* {
                        (okBtn === "Create Your First Project" || okBtn === "Continue To Create") &&
                        <img src={welcomeIllustration} alt="welcome" style={{ width: '100%', height: 150, objectFit: 'cover', borderRadius: 13 }} />
                    } */}
                    <h4 style={{ fontWeight: "bold" }}><Trans>{title}</Trans></h4>
                    <p className="dialog-description" style={{
                        whiteSpace: 'pre-line',
                        wordBreak: 'keep-all'
                    }}><Trans>{description}</Trans></p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        {
                            isCancellable &&
                            <button style={{ marginRight: 20 }} onClick={handleCancelClick} className="dialog-button cancel-dialog"><Trans>Cancel</Trans></button>
                        }
                        {
                            (okBtn === "Create Your First Project" || okBtn === "Continue To Create") ?
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: '#000',
                                    borderRadius: 11,
                                    padding: '8px 20px',
                                }}>
                                    <button onClick={handleOkClick} className="dialog-button-single" style={{
                                        backgroundColor: 'transparent',
                                        color: '#fff',

                                    }}><Trans>{okBtn}</Trans></button>
                                    <ArrowRight size={16} color="#fff" />
                                </div>
                                :
                                isCancellable
                                    ? <button onClick={handleOkClick} className="dialog-button"><Trans>{okBtn}</Trans></button>
                                    : <button onClick={handleOkClick} className="dialog-button-single"><Trans>{okBtn}</Trans></button>

                        }

                    </div>
                </div>
            </div>
        )
    );
}

export default DefaultDialog;