import React, { useState, useEffect } from "react";
import { StoreCategoryHeader, StoreProductsGrid, getProductsByCategories } from "../../store";
import { CategoryTab } from "../../../components/tabs";
import "./StoreCategory.css";

const StoreCategory = ({ categories }) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        setProducts(getProductsByCategories());
    }, []);

    return (
        <div className="store-category-wrapper">
            <StoreCategoryHeader />
            <CategoryTab categories={categories} />
            <StoreProductsGrid products={products} />
        </div>
    );
}

export default StoreCategory;