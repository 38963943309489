import { useState } from 'react';
import { getBrowseDesigners } from '../../designer';

const useBrowseDesigners = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [browseDesigners, setBrowseDesigners] = useState([]);

    const handleBrowseDesigners = async (credentials) => {
        setLoading(true);
        try {
            const responseData = await getBrowseDesigners();
            setBrowseDesigners(responseData);
            setError(null);
        }
        catch (error) {
            setError(error.message || 'Failed to load browse designers!');
        }
        setLoading(false);
    };

    return { browseDesigners, handleBrowseDesigners, error };
};

export default useBrowseDesigners;