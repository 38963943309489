import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../assets/styles/Studio.css';
import useToken from './auth/useToken';
import Header from './Header';
import Footer from './Footer';
import '../assets/styles/Header.css'
import 'font-awesome/css/font-awesome.min.css';
import toast, { Toaster } from 'react-hot-toast';
import DropdownMenu from './DropdownMenu';

export default function Home() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const { token, setToken } = useToken();

    return (
        <div className="DefaultApp">
            <Header toggleDropdown={toggleDropdown} />
            <div className="content mobile-padding not-selectable">
                <section>
                    <h2>개인 정보 보호에 대한 우리의 약속</h2>
                    <p>MY04가 데이터를 수집하고 사용하는 방법과 Hancut Studio 사용자로서의 권리에 대해 자세히 알아보세요.</p>
                </section>

                <section className="paragraph">
                    <p className="updated-info">2023년 11월 8일 업데이트됨</p>
                    <p className="policy-title">1. 서론</p>
                    <p>
                        이 개인정보보호정책(이하 "정책")은 My04, Inc., my04.net 웹사이트 및 My04 콘텐츠 생성 플랫폼(이하 "서비스")에 적용됩니다. My04, Inc.("My04")는 실내 공간을 인간의 창의성과 연결하는 콘텐츠 생성 플랫폼입니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        이 정책에서 사용하는 "개인 데이터"는 직접적 또는 간접적으로 개인을 식별하는 데 사용될 수 있는 모든 정보를 의미합니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        <b>정책 적용 범위:</b> 이 정책은 My04가 수집, 사용 및 공개하는 개인 데이터에 적용되며 다음과 같은 항목을 포함할 수 있습니다: (i) 서비스를 통해 수집된 데이터, (ii) My04 머신 러닝 알고리즘을 훈련하는 과정을 통해 수집된 데이터, (iii) My04 웹사이트를 통해 수집된 데이터 및 (iv) 제3자 출처에서 수집된 데이터. 제3자 출처는 공공 데이터베이스, 상업용 데이터 출처 및 인터넷을 포함할 수 있지만 이에 국한되지 않습니다. 구매를 할 때 우리는 제3자 결제 처리자를 사용하여 신용 카드 또는 기타 재정 정보를 수집합니다. My04는 귀하가 제공하는 신용 카드 또는 결제 정보를 저장하지 않으며 지불이 완료되었다는 확인만 저장합니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        <b>이 정책은 다음 정보에 적용되지 않습니다:</b> My04 직원 및 후보자와 유사한 역할로 활동하는 일부 계약자 및 대리인에 대한 개인 데이터.
                    </p>
                </section>


                <section className="paragraph">
                    <p>
                        <b>변경:</b> 법적, 규제적, 운영적 요구 사항, 당사의 관행 및 기타 요인의 변화를 반영하기 위해 당사는 이 정책을 수시로 업데이트할 수 있습니다. 업데이트를 확인하려면 정기적으로 이 정책을 확인하십시오. 변경 사항이 귀하에게 허용되지 않는 경우 당사와의 상호 작용을 중단해야 합니다. 적용 가능한 법률에 따라 필요한 경우 당사는 이 정책의 변경 사항을 귀하에게 통지할 것입니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        <b>정의:</b> 이 정책을 통해 귀하, 또는 귀하의 의미는 서비스에 액세스하거나 사용하는 개인 또는 해당 개인이 대신 서비스에 액세스하거나 사용하는 회사 또는 기타 법적 주체입니다. 회사(이 계약에서 "회사", "당사", "우리" 또는 "우리의"로 지칭됨)는 MY04 LLC를 지칭합니다. 사용량 데이터는 서비스 사용에 의해 생성되거나 서비스 인프라 자체에서 생성되는 데이터(예: 페이지 방문 지속 시간)를 자동으로 수집된 데이터를 의미합니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p className="policy-title">2. 개인 데이터 수집 및 사용</p>
                </section>

                <section className="paragraph">
                    <p className="policy-sub-title">2.1 수집된 데이터 유형</p>
                    <p></p>
                </section>


                <section className="paragraph">
                    <p className="policy-sub-title">2.1.1 개인 데이터</p>
                    <p>
                        서비스와 상호 작용하는 동안 개인을 식별하거나 연락하는 데 사용할 수 있는 개인 식별 정보를 제공할 수 있습니다. 개인 식별 정보는 다음을 포함할 수 있지만 이에 국한되지 않습니다:
                        <br />서비스에 대한 사용자 이름
                        <br />서비스에 입력한 텍스트 또는 이미지 프롬프트 또는 서비스에서 유지 관리하는 공개 채팅
                        <br />IP 주소
                        <br />사용량 데이터
                        <br />추적 기술 및 쿠키
                        <br />연락처 정보
                        <br />회사 직함과 같은 조직 정보
                        <br />귀하의 이메일
                        <br />쿠키
                        <br />MY04에 전송하도록 선택한 기타 데이터
                    </p>
                </section>

                <section className="paragraph">
                    <p className="policy-sub-title">2.2 개인 데이터 사용</p>
                    <p>
                        MY04는 다음과 같은 목적을 위해 개인 데이터를 사용할 수 있습니다:
                        <br />서비스의 사용을 모니터링하는 것을 포함하여 서비스를 제공, 유지 관리 및 개선하기 위해.
                        <br />귀하의 계정 관리: 서비스 사용자로서 귀하의 등록을 관리하기 위해. 귀하가 제공하는 개인 데이터는 귀하가 등록 사용자로서 사용할 수 있는 서비스의 다양한 기능에 액세스할 수 있도록 합니다.
                        <br />계약의 이행을 위해: 귀하가 구매한 제품, 항목 또는 서비스에 대한 구매 계약 또는 서비스를 통해 당사와 체결한 다른 계약의 개발, 준수 및 수행.
                        <br />귀하에게 연락: 보안 업데이트와 같은 업데이트 또는 기능, 제품 또는 계약 서비스에 관한 정보 통신과 관련하여 모바일 애플리케이션의 푸시 알림과 같은 전자 통신의 동등한 형식으로 이메일, 전화, SMS 또는 기타 유사한 형식으로 연락합니다. 필요하거나 합리적일 때.
                        <br />귀하에게 뉴스, 특별 제안 및 당사가 제공하는 기타 상품, 서비스 및 이벤트에 대한 일반 정보를 제공하기 위해 귀하가 이미 구매하거나 문의한 유사한 상품, 서비스 및 이벤트를 제외하고 이러한 정보를 수신하지 않도록 선택한 경우.
                        <br />귀하의 요청 관리: 귀하의 요청을 처리하고 관리하기 위해.
                        <br />사업 양도: 당사는 당사가 서비스 사용자에 대해 보유한 개인 데이터가 양도된 자산 중에 포함되는지 여부에 관계없이, 합병, 양도, 구조 조정, 재편성, 해산 또는 기타 일부 또는 모든 자산의 매매 또는 양도를 평가하거나 수행하기 위해 귀하의 정보를 사용할 수 있습니다. 또는 파산, 청산 또는 유사한 절차의 일부로서.
                        <br />기타 목적: 당사는 데이터 분석, 사용량 추세 파악, 프로모션 캠페인의 효과성 평가 및 서비스, 제품, 서비스, 마케팅 및 귀하의 경험을 평가하고 개선하기 위해 귀하의 정보를 사용할 수 있습니다.
                    </p>
                </section>


                <section className="paragraph">
                    <p className="policy-sub-title">2.3 개인 데이터 공유</p>
                    <p>
                        우리는 다음과 같은 경우 귀하의 개인 정보를 공유할 수 있습니다:
                        <br />서비스 제공자, 제3자 공급자, 컨설턴트 및 기타 비즈니스 파트너와 함께: 당사는 당사를 대신하여 서비스를 제공하고, 당사 서비스 사용을 모니터링 및 분석하고, 귀하에게 연락하고, 계약에 명시된 이유로 이러한 당사와 귀하의 개인 정보를 공유할 수 있습니다. 서비스 제공자에게 서비스 사용을 모니터링하고 분석하고 귀하에게 연락하기 위해 서비스 제공자와 공유합니다.
                        <br />사업 양도를 위해: 당사는 다른 회사에 대한 당사 사업의 전체 또는 일부의 합병, 매매, 자금 조달 또는 인수와 관련하여 또는 협상 중에 귀하의 개인 정보를 공유하거나 양도할 수 있습니다.
                        <br />귀하의 동의로: 당사는 귀하의 동의로 다른 목적으로 귀하의 개인 정보를 공개할 수 있습니다.
                        <br />법 집행 기관과 함께: 특정 상황에서 MY04는 법률에 의해 또는 공공 기관(예: 법원 또는 정부 기관)의 정식 요청에 의해 요구되는 경우 귀하의 개인 데이터를 공개해야 할 수 있습니다. 귀하의 개인 데이터에 대한 법 집행 기관의 요청을 받으면, 법적으로 금지되지 않는 경우 즉시 귀하에게 통지하고 요청 사본을 제공합니다.
                        <br />다른 당사와 함께 다음을 위해:
                        <br />법적 의무 준수
                        <br />회사의 권리 또는 재산 보호 및 방어
                        <br />서비스와 관련된 가능한 잘못을 예방 또는 조사
                        <br />서비스 사용자 또는 대중의 개인 안전 보호
                        <br />법적 책임으로부터 보호
                    </p>
                </section>


                <section className="paragraph">
                    <p className="policy-sub-title">2.4 개인 데이터 보존</p>
                    <p>
                        회사는 이 개인 정보 보호 정책에 명시된 목적을 위해 필요한 기간 동안만 귀하의 개인 데이터를 보유합니다. 당사는 법적 의무를 준수하기 위해 필요한 범위에서 귀하의 개인 데이터를 보유하고 사용합니다(예: 적용 가능한 법률을 준수하기 위해 귀하의 데이터를 보유해야 하는 경우), 분쟁을 해결하고 당사의 법적 계약 및 정책을 시행합니다.
                        회사는 또한 내부 분석 목적을 위해 사용량 데이터를 유지 관리합니다. 사용량 데이터는 일반적으로 더 짧은 기간 동안 유지 관리되지만, 이 데이터가 보안을 강화하거나 서비스의 기능을 개선하는 데 사용되는 경우 또는 당사가 이 데이터를 보다 긴 기간 동안 유지 관리해야 하는 법적 의무가 있는 경우 예외입니다.
                    </p>
                </section>


                <section className="paragraph">
                    <p className="policy-sub-title">2.5 개인 데이터 전송</p>
                    <p>
                        개인 데이터를 포함한 귀하의 정보는 회사의 운영 사무소 및 처리에 관여하는 당사자가 위치한 다른 모든 장소에서 처리됩니다. 즉, 이 정보는 귀하의 관할 구역, 주, 국가 또는 기타 관할 구역 외부에 위치한 컴퓨터로 전송될 수 있으며, 그곳의 데이터 보호 법률은 귀하의 관할 구역의 데이터 보호 법률과 다를 수 있습니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        이 개인 정보 보호 정책에 대한 귀하의 동의와 함께 귀하가 이러한 정보를 제출한 경우 이는 귀하가 해당 전송에 동의함을 나타냅니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        회사는 귀하의 데이터가 안전하게 처리되고 이 개인 정보 보호 정책에 따라 취급되도록 합리적으로 필요한 모든 조치를 취할 것이며, 귀하의 데이터 보호 및 기타 개인 정보가 포함된 적절한 통제 조치가 없으면 귀하의 개인 데이터를 조직 또는 국가로 전송하지 않습니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p className="policy-sub-title">2.6 개인 데이터 보안</p>
                    <p>
                        귀하의 개인 데이터의 보안은 당사에게 중요하지만 인터넷을 통한 전송 방법이나 전자 저장 방법이 100% 안전하지는 않다는 것을 기억하십시오. 당사는 귀하의 개인 데이터를 보호하기 위해 상업적으로 허용되는 수단을 사용하기 위해 노력하지만 그 절대적인 보안을 보장할 수 없습니다.
                    </p>
                </section>


                <section className="paragraph">
                    <p className="policy-title">3. 어린이의 개인 정보 보호</p>
                    <p>
                        당사의 서비스는 13세 미만의 어린이를 대상으로 하지 않습니다. 당사는 13세 미만의 어린이로부터 의도적으로 개인 식별 정보를 수집하지 않습니다. 귀하가 부모 또는 보호자이며 귀하의 자녀가 당사에 개인 데이터를 제공했다고 생각되는 경우 당사에 연락하십시오. 당사가 부모의 동의 확인 없이 13세 미만의 어린이로부터 개인 데이터를 수집했다는 사실을 알게 되면 당사는 해당 정보를 당사 서버에서 제거하기 위한 조치를 취합니다.
                    </p>
                    {/* <img src="https://i.imgur.com/6Vb2t7j.png" alt="어린이의 개인 정보 보호 이미지" /> */}
                </section>

                <section className="paragraph">
                    <p>
                        귀하의 정보를 처리하는 법적 근거로 동의에 의존해야 하며 귀하의 국가에서 부모의 동의가 필요한 경우 당사는 귀하가 해당 정보를 수집하고 사용하기 전에 부모의 동의를 요구할 수 있습니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p className="policy-title">4. 다른 웹사이트 링크</p>
                    <p>
                        당사의 서비스에는 당사가 운영하지 않는 다른 웹사이트에 대한 링크가 포함될 수 있습니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        귀하가 제3자 링크를 클릭하면 해당 제3자의 사이트로 이동하게 됩니다. 당사는 귀하가 방문하는 모든 사이트의 개인 정보 보호 정책을 검토할 것을 강력히 권장합니다.
                    </p>
                    {/* <img src="https://i.imgur.com/w483n07.png" alt="다른 웹사이트 링크 이미지" /> */}
                </section>


                <section className="paragraph">
                    <p>
                        당사는 제3자 사이트 또는 서비스의 콘텐츠, 개인 정보 보호 정책 또는 관행에 대한 통제력이 없으며 책임을 지지 않습니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p className="policy-title">5. 이 개인 정보 보호 정책의 변경</p>
                    <p>
                        당사는 당사의 개인 정보 보호 정책을 수시로 업데이트할 수 있습니다. 당사는 새 개인 정보 보호 정책을 이 페이지에 게시하여 모든 변경 사항을 귀하에게 통지합니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        변경 사항이 적용되기 전에 당사는 이메일 및/또는 당사 서비스에 대한 눈에 띄는 공지를 통해 귀하에게 알리고 이 개인 정보 보호 정책의 맨 위에 있는 "마지막 업데이트" 날짜를 업데이트합니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        변경 사항이 있는지 정기적으로 이 개인 정보 보호 정책을 검토하는 것이 좋습니다. 이 개인 정보 보호 정책에 대한 변경 사항은 이 페이지에 게시될 때부터 효력을 발생합니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p className="policy-title">6. 특정 지역에 대한 보충적인 약관</p>
                </section>

                <section className="paragraph">
                    <p>
                        유럽<br />
                        귀하가 유럽 경제 지역(EEA), 스위스 또는 영국(영국)에 거주하는 경우 이 정책에 설명된 개인 데이터를 수집 및 사용하는 당사의 법적 근거는 관련 개인 데이터와 수집하는 특정 상황에 따라 다릅니다. 그러나 일반적으로 당사는 귀하의 동의가 있는 경우에만 귀하의 개인 데이터를 수집하고, 귀하와의 계약을 이행하기 위해 개인 데이터가 필요한 경우 또는 처리가 당사의 합법적 이익에 부합하고 귀하의 데이터 보호 이익 또는 기본적인 권리와 자유에 의해 무효화되지 않는 경우에만 귀하의 개인 데이터를 수집합니다. 일부 경우에는 당사가 귀하의 개인 데이터를 수집할 법적 의무가 있을 수도 있습니다.
                    </p>
                </section>


                <section className="paragraph">
                    <p>
                        MY04는 이 정책에 자세히 설명된 대로 내부적으로 또는 제3자와 정보를 공유할 수 있습니다. 당사가 EEA, 스위스 또는 영국의 개인의 개인 데이터를 제3자와 공유할 때 당사는 유럽 위원회 승인 표준 계약 조항을 비롯한 다양한 법적 메커니즘을 사용하여 전송을 보호합니다. 또한 적절한 경우 추가 보호 조치를 사용합니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        또한 귀하는 다음과 같은 데이터 보호 권리가 있습니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        귀하는 귀하의 개인 데이터에 대한 액세스, 수정, 업데이트 또는 삭제를 요청할 수 있습니다.
                        <br />귀하는 당사의 개인 데이터 처리에 이의를 제기하고, 당사에 귀하의 개인 데이터 처리를 제한하도록 요청하거나 귀하의 개인 데이터의 이식성을 요청할 수 있습니다.
                        <br />당사가 귀하의 동의를 통해 귀하의 개인 데이터를 수집하고 처리한 경우 언제든지 귀하의 동의를 철회할 수 있습니다. 귀하의 동의 철회는 귀하의 철회 전에 당사가 수행한 처리의 합법성에 영향을 미치지 않으며 동의 외의 합법적 처리 근거에 의해 수행된 귀하의 개인 데이터 처리에 영향을 미치지 않습니다.
                        <br />귀하는 당사의 귀하의 개인 데이터 수집 및 사용에 대해 데이터 보호 기관에 불만을 제기할 권리가 있습니다.
                        <br />귀하의 권리를 행사하려면 support@my04.net에 연락하십시오.
                    </p>
                </section>


                <section className="paragraph">
                    <p>
                        캘리포니아<br />
                        이 진술은 캘리포니아 거주자 또는 캘리포니아에서 수집된 정보를 가진 개인에게만 적용됩니다. MY04는 캘리포니아 소비자 개인 정보 보호법(CCPA)을 준수하기 위해이 공지를 채택하고 포함했습니다. 이 진술에서 사용되는 CCPA에 정의된 용어는 모두 동일한 의미를 갖습니다.
                    </p>
                </section>


                <section className='paragraph'>
                    <p>우리가 수집하는 정보</p>
                    <table>
                        <tr>
                            <th>카테고리</th>
                            <th>수집됨</th>
                            <th>공개됨</th>
                        </tr>
                        <tr>
                            <td>1 식별자. 이름, 별칭, 우편 주소, 고유 개인 식별자, 온라인 식별자, 인터넷 프로토콜 (IP) 주소, 이메일 주소, 계정 이름, 사회 보장 번호, 운전면허증 번호, 여권 번호 또는 기타 유사 식별자 </td> <td> 예 </td> <td> 예 </td>
                        </tr>
                        <tr><td>2 캘리포니아 고객 기록 법규에 따른 개인 정보 카테고리 (Cal. Civ. Code § 1798.80(e)). 이름, 서명, 사회 보장 번호, 신체적 특징 또는 설명, 주소, 전화 번호, 여권 번호, 운전면허증 또는 주정부 신분증 번호, 보험 증권 번호, 교육, 직업, 직업 이력, 은행 계좌 번호, 신용 카드 번호, 직불 카드 번호 또는 기타 재정 정보, 의료 정보 또는 건강 보험 정보. 이 카테고리에 포함된 일부 개인 정보는 다른 카테고리와 중복될 수 있습니다. </td> <td> 예 </td> <td> 예 </td>
                        </tr>
                        <tr><td>3 캘리포니아 또는 연방 법에 따른 보호된 분류 특성. 연령 (40세 이상), 인종, 색, 출신, 국적, 시민권, 종교 또는 신조, 결혼 상태, 건강 상태, 신체적 또는 정신적 장애, 성 (성별, 성 정체성, 성 표현, 임신 또는 출산과 관련된 의료 상태 포함), 성적 지향, 베테랑 또는 군사 상태, 유전 정보 (가족 유전 정보 포함). 이 카테고리에 포함된 일부 개인 정보는 다른 카테고리와 중복될 수 있습니다. </td> <td> 아니오 </td> <td> 아니오 </td>
                        </tr>
                        <tr><td>4 상업 정보. 구매, 취득 또는 고려된 개인 재산, 제품 또는 서비스의 기록 또는 기타 구매 또는 소비 이력 또는 경향. </td> <td> 예 </td> <td> 예 </td>
                        </tr>
                        <tr><td>5 생체 정보. 유전적, 생리적, 행동적 및 생물학적 특성 또는 템플릿 또는 기타 식별자 또는 식별 정보를 추출하는 데 사용되는 활동 패턴, 예: 지문, 얼굴 인쇄 및 음성 인쇄, 홍채 또는 망막 스캔, 키스트로크, 보행 또는 기타 신체적 패턴 및 수면, 건강 또는 운동 데이터. </td> <td> 아니오 </td> <td> 아니오 </td>
                        </tr>
                        <tr><td>6 인터넷 또는 기타 유사 네트워크 활동. 브라우징 기록, 검색 기록, 웹사이트, 애플리케이션 또는 광고와의 상호 작용에 대한 정보. </td> <td> 아니오 </td> <td> 아니오 </td>
                        </tr>
                        <tr><td>7 위치 정보. 물리적 위치 또는 이동. </td> <td> 아니오 </td> <td> 아니오 </td>
                        </tr>
                        <tr><td>8 감각 데이터. 음향, 전자, 시각, 열, 후각 또는 유사 정보. </td> <td style={{ fontWeight: "bold" }}> 예* </td> <td> 예 </td>
                        </tr>
                        <tr><td>9 전문 또는 직업 관련 정보. 현재 또는 과거의 고용 이력 또는 업적 평가.</td> <td> 아니오 </td> <td> 아니오 </td>
                        </tr>
                        <tr>
                            <td>10 교육 정보 - 캘리포니아 가족 교육 권리 및 개인 정보 보호 법(20 U.S.C. section 1232g, 34 C.F.R. Part 99)에 의거, 캘리포니아 가족 교육 권리 및 개인 정보 보호 법(20 U.S.C. section 1232g, 34 C.F.R. Part 99)에서 정의된 "공개적으로 이용 가능한 개인 식별 정보"가 아닌 정보. 교육 기관 또는 그 대리인이 유지 관리하는 학생과 직접 관련된 교육 기록을 포함합니다. 여기에는 성적, 성적표, 수업 명단 및 학생 일정, 식별 코드, 재정 정보 또는 징계 기록과 같은 정보가 포함됩니다.</td>
                            <td>아니오</td>
                            <td>아니오</td>
                        </tr>
                        <tr>
                            <td>11 추론 - 개인의 선호도, 특성, 심리적 경향, 선호도, 행동, 태도, 지능, 능력, 적성을 반영하는 프로필을 생성하는 데 사용될 수 있는 결론</td>
                            <td>예</td>
                            <td>예</td>
                        </tr>
                    </table>
                </section >
                <p style={{
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "left",
                    color: "#000000",
                    fontSize: "14px",
                }}>* 오직 귀하가 당사 서비스에 업로드한 이미지만</p>

                <section className="paragraph">
                    <p>
                        개인 정보 판매<br />
                        MY04는 CCPA에서 정의된 대로 귀하의 개인 정보를 판매하지 않습니다. 앞으로 귀하의 개인 정보를 판매하는 경우 귀하에게 통지하고 귀하는 해당 판매를 거절할 권리가 있을 수 있습니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        귀하의 권리 및 선택<br />
                        CCPA는 캘리포니아에 거주하거나 캘리포니아에서 개인 정보가 수집된 개인에게 자신의 개인 정보에 대한 특정 권리를 부여합니다. 이하에서 귀하의 권리와 이를 행사하는 방법에 대해 설명합니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        특정 정보 및 데이터 이식성 권리 액세스<br />
                        귀하는 MY04에 대해 지난 12개월 동안 귀하의 개인 정보 수집 및 사용에 대한 특정 정보를 공개하도록 요청할 수 있습니다. MY04가 귀하의 검증 가능한 정보 액세스 요청을 수령하고 확인하면 MY04는 다음을 공개해야 합니다. (i) 당사가 귀하에 대해 수집한 개인 정보의 범주; (ii) 당사가 귀하에 대해 수집한 개인 정보의 출처 범주; (iii) 해당 개인 정보를 수집하거나, 해당되는 경우 판매하는 당사의 사업 또는 상업적 목적; (iv) 당사가 해당 개인 정보를 공유하는 제3자의 범주; (v) 당사가 귀하에 대해 수집한 개인 정보의 특정 데이터 포인트 또는 개인 정보 조각. MY04가 사업 목적으로 귀하의 개인 정보를 공개하거나 판매한 경우 MY04는 또한 다음을 식별하는 별도 목록을 제공해야 합니다. (x) 귀하의 개인 정보 판매와 관련하여 각 수신자 범주에 판매된 개인 정보 범주; 및 (y) 귀하의 개인 정보의 사업 목적 공개와 관련하여 각 수신자 범주에 제공된 개인 정보 범주입니다.
                    </p>
                </section>


                <section className="paragraph">
                    <p>
                        삭제 요청 권리<br />
                        귀하는 당사가 귀하로부터 수집하고/또는 보관한 개인 정보를 삭제하도록 요청할 수 있습니다. 특정 제한된 예외 적용 대상이 아닌 경우 MY04가 귀하의 검증 가능한 데이터 삭제 요청을 수령하고 확인하면 당사는 귀하의 개인 정보를 삭제하고 서비스 제공자에게 귀하의 개인 정보를 삭제하도록 지시합니다. MY04가 귀하의 삭제 요청을 거부해야 하는 경우 즉시 귀하에게 통지합니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        판매 금지 선택권<br />
                        귀하는 CCPA에서 정의된 대로 MY04의 개인 정보 판매를 거부할 수 있습니다. 그러나 MY04는 귀하의 정보를 판매하지 않습니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        귀하의 권리 행사<br />
                        위에서 설명한 액세스, 데이터 이식성 및 삭제 또는 판매 금지 선택권을 행사하려면 다음 방법 중 하나를 통해 검증 가능한 소비자 요청을 제출할 수 있습니다. support@my04.net으로 보내는 이메일
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        귀하는 12개월 이내에 액세스 또는 데이터 이식성에 대한 검증 가능한 소비자 요청을 두 번까지 제출할 수 있습니다. 귀하는 언제든지 검증 가능한 판매 금지 선택권 요청을 제출할 수 있습니다. 이러한 요청은 다음 사항을 포함해야 합니다. (i) 당사가 개인 정보를 수집한 사람 또는 그 사람의 권한 있는 대리인임을 합리적으로 확인할 수 있도록 충분한 정보를 제공하고; (ii) 당사가 이해하고 평가하고 대응할 수 있도록 요청을 충분히 자세히 설명합니다. MY04는 귀하의 신원 또는 요청을 제출할 수 있는 권한을 확인하고 귀하의 개인 정보가 귀하와 관련되어 있는지 확인할 수 없는 경우 귀하의 요청에 응답하거나 귀하에게 개인 정보를 제공할 수 없습니다. 검증 가능한 소비자 요청을 수행하기 위해 MY04 계정을 만들 필요 없습니다. MY04는 검증 가능한 소비자 요청에 제공된 개인 정보를 요청자의 신원 또는 요청을 제출할 수 있는 권한을 확인하는 데만 사용합니다. 귀하 또는 캘리포니아 주무장관에 등록된 사람으로 귀하가 대리인으로 지정한 사람만이 귀하의 개인 정보와 관련된 검증 가능한 소비자 요청을 제출할 수 있습니다. 귀하는 또한 미성년 자녀를 대신하여 검증 가능한 소비자 요청을 제출할 수 있습니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p>
                        응답 타이밍 및 형식<br />
                        당사는 접수 후 45일 이내에 검증 가능한 소비자 요청에 응답하기 위해 노력합니다. 추가 시간(최대 90일)이 필요한 경우 연장 기간 및 그 이유를 서면으로 알려드리겠습니다. MY04는 필요하거나 요청된 모든 응답 또는 기타 통신을 이메일로 귀하에게 서면으로 전달합니다. 당사가 제공하는 모든 공개는 검증 가능한 소비자 요청을 수신하기 전 12개월 기간에만 적용됩니다. 해당하는 경우 당사가 제공하는 응답은 당사가 요청을 준수할 수 없는 이유도 설명합니다. 데이터 이식성 요청의 경우 MY04는 귀하의 개인 정보를 즉시 사용하고 전송할 수 있는 형식으로 제공합니다. MY04는 이러한 요청이 과도하거나 반복적이거나 명백히 근거가 없거나 CCPA에서 허용하는 경우를 제외하고 귀하의 검증 가능한 소비자 요청을 처리하거나 응답하기 위해 비용을 청구하지 않습니다. 요청에 대해 요금을 청구해야 한다고 판단하는 경우, 귀하에게 통지하고 요청을 완료하기 전에 비용 견적을 제공합니다.
                    </p>
                </section>

                <section className="paragraph">
                    <p className="policy-title">7. 문의하기</p>
                    <p>
                        이 정책에 대해 질문이 있거나 이 정책 또는 관련 법률에 따라 권리를 행사하려는 경우 support@my04.net으로 문의하실 수 있습니다.
                    </p>
                </section>

            </div>
            <DropdownMenu
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen} />
            <Footer />
            <Toaster />
        </div >
    )
}