import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Header';
import useToken from '../auth/useToken';
import DropdownMenu from '../DropdownMenu';
import './EditImage.css';
import ImageEditor from './ImageEditor';

const EditImage = () => {
    const preventTouchMove = (e) => {
        const touch = e.touches[0];
        const canvas = canvasRef.current;
        const canvasRect = canvas.getBoundingClientRect();

        if (
            touch.clientX >= canvasRect.left &&
            touch.clientX <= canvasRect.right &&
            touch.clientY >= canvasRect.top &&
            touch.clientY <= canvasRect.bottom
        ) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener('touchmove', preventTouchMove, { passive: false });

        return () => {
            document.removeEventListener('touchmove', preventTouchMove);
        };
    }, []);

    const { image_id } = useParams();
    const [originalImage, setOriginalImage] = React.useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [projectId, setProjectId] = React.useState("");
    const [prompt, setPrompt] = React.useState("");
    const [isBrush, setIsBrush] = React.useState(true);

    const canvasRef = useRef(null);

    const { token } = useToken();

    useEffect(() => {
        if (!token) {
            window.location.href = "/login";
        }

        fetch(`https://hancut.my04.net/api/v1/studio/image/${image_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(response => {
            if (response.status === 401) {
                window.location.href = "/login";
            }
            else if (response.status === 404) {
                window.location.href = "/";
            }
            else {
                return response.json();
            }
        }).then(async (data) => {
            if (data && data._id && data.image_url) {
                let base64string = await getBase64FromImageUrl("https://hancut.my04.net/api/" + data.image_url);
                base64string = base64string.replace(/data:image\/(jpeg|jpg|png);base64,/g, '');
                setOriginalImage([...originalImage, base64string]);
                setProjectId(data.project_id);
            }
        })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [token]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const exportCanvasAsImage = () => {
        const originalCanvas = canvasRef.current;

        // Create a temporary canvas
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = originalCanvas.width;
        tempCanvas.height = originalCanvas.height;
        const tempContext = tempCanvas.getContext('2d');

        // Draw a black background on the temporary canvas
        tempContext.fillStyle = '#000';
        tempContext.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

        // Draw the original canvas content on top of the black background
        tempContext.drawImage(originalCanvas, 0, 0);

        // Export the temporary canvas as an image
        const imageExportURL = tempCanvas.toDataURL('image/png');

        // Get the base64 string from the imageExportURL
        const base64String = imageExportURL.split(',')[1];

        return base64String;
    };

    const getBase64FromImageUrl = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error fetching image:', error);
            return null;
        }
    };

    const addAnimation = () => {
        // Add a CSS class to the canvas element to apply the fade in and fade out animation
        canvasRef.current.classList.add('fade-animation');

        // CSS styles for the fade animation
        // You can customize the animation duration and other properties as needed
        const fadeAnimationStyles = `
                    @keyframes fade {
                        0% { opacity: 0; }
                        50% { opacity: 3; }
                        100% { opacity: 0; }
                    }

                    .fade-animation {
                        animation: fade 6s infinite;
                    }
                `;

        // Append the fade animation styles to the document head
        const styleElement = document.createElement('style');
        styleElement.innerHTML = fadeAnimationStyles;
        document.head.appendChild(styleElement);
    }

    const handleReplace = async () => {
        if (prompt.length === 0 || isLoading)
            return;
        const markedBase64String = exportCanvasAsImage();
        setIsLoading(true);

        addAnimation();
        fetch(process.env.REACT_APP_API + "/v1/studio/generate/inpaint/replace", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                "prompt": prompt,
                "base64": originalImage[originalImage.length - 1],
                "maskBase64": markedBase64String,
            })
        }).then(response => {
            if (response.status === 401) {
                window.location.href = "/login";
            }
            else if (response.status === 404 || response.status === 500) {
                alert("Failed to generate image");
            }
            else {
                return response.json();
            }
        }).then(data => {
            setIsLoading(false);
            canvasRef.current.classList.remove('fade-animation');
            if (data && data.inpaint_replace) {
                setOriginalImage([...originalImage, data.inpaint_replace]);
            }
        })
            .catch(error => {
                setIsLoading(false);
                canvasRef.current.classList.remove('fade-animation');
                console.error('Error:', error);
            });
    };

    const handleRemove = async () => {
        if (isLoading)
            return;
        const markedBase64String = exportCanvasAsImage();
        setIsLoading(true);

        addAnimation();

        fetch(process.env.REACT_APP_API + "/v1/studio/generate/inpaint/erase", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                "base64": originalImage[originalImage.length - 1],
                "maskBase64": markedBase64String,
            })
        }).then(response => {
            if (response.status === 401) {
                window.location.href = "/login";
            }
            else if (response.status === 404 || response.status === 500) {
                alert("Failed to generate image");
            }
            else {
                return response.json();
            }
        }).then(data => {
            setIsLoading(false);
            canvasRef.current.classList.remove('fade-animation');
            if (data && data.inpaint_erase) {
                setOriginalImage([...originalImage, data.inpaint_erase]);
            }
        })
            .catch(error => {
                setIsLoading(false);
                canvasRef.current.classList.remove('fade-animation');
                console.error('Error:', error);
            });
    };

    const handleSave = () => {
        fetch(`https://hancut.my04.net/api/v1/studio/image/${image_id}/edit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            },
            body: JSON.stringify({
                "base64": originalImage[originalImage.length - 1],
            })
        }).then(response => {
            if (response.status === 401) {
                window.location.href = "/login";
            }
            else if (response.status === 404 || response.status === 500) {
                alert("Failed to save image");
            }
            else {
                return response.json();
            }
        }).then(data => {
            alert("이미지가 저장되었습니다.");
            window.location.href = `/studio/${projectId}`;
        })
            .catch(error => {
                alert("Failed to save the image");
            });
    }

    return (
        <div className="StudioApp">
            <Header toggleDropdown={toggleDropdown} />
            <div className='canvas-container'>
                <div className='canvas-container-panel'>
                    {
                        originalImage.length > 0 && (
                            <ImageEditor base64ImageList={originalImage} canvasRef={canvasRef} isBrush={isBrush} setIsBrush={setIsBrush} />
                        )
                    }
                </div>
                <div className='canvas-container-buttons'>
                    <p className='title'>이미지 편집 (데모)</p>
                    <div className='' style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                    }}>
                        <div className='btn' style={{
                            backgroundColor: isBrush ? '#f0f0f0' : '#f7f7f7',
                            border: isBrush ? '1px solid #00000030' : '1px solid #f7f7f7',
                        }} onClick={() => { setIsBrush(true) }}>🖌️</div>
                        <div className='btn' style={{
                            backgroundColor: !isBrush ? '#f0f0f0' : '#f7f7f7',
                            border: !isBrush ? '1px solid #00000030' : '1px solid #f7f7f7',
                        }} onClick={() => { setIsBrush(false) }}>🧽</div>
                    </div>
                    <textarea className='textarea' placeholder='재생성하려면 프롬프트를 작성해야 합니다...' onChange={(e) => {
                        setPrompt(e.target.value);
                    }} />
                    <div className='btn' onClick={handleReplace} style={{
                        opacity: prompt.length > 0 ? isLoading ? 0.5 : 1 : 0.5,
                    }}>
                        {
                            isLoading ? (
                                <span>생성중...</span>
                            )
                                : (
                                    <span>재생성</span>
                                )
                        }
                    </div>
                    <div style={{
                        margin: '10px 0',
                        height: '1px',
                        backgroundColor: '#00000020',
                        width: '100%',
                    }}></div>
                    <div className='btn' onClick={handleRemove} style={{
                        opacity: isLoading ? 0.5 : 1,
                    }}>
                        {
                            isLoading ? (
                                <span>생성중...</span>
                            )
                                : (
                                    <span>지우기</span>
                                )
                        }
                    </div>
                    {
                        originalImage.length > 1 && (

                            <>
                                <div style={{
                                    margin: '10px 0',
                                    height: '1px',
                                    backgroundColor: '#00000020',
                                    width: '100%',
                                }}></div>
                                <div className='btn' style={{
                                    backgroundColor: '#fff',
                                    color: '#000',
                                    border: '1px solid #00000020',
                                }} onClick={() => {
                                    setOriginalImage(prevOriginalImage => prevOriginalImage.slice(0, -1));
                                }}>
                                    되돌리기
                                </div>
                            </>
                        )
                    }
                    {
                        originalImage.length > 1 && (
                            <div className='btn' style={{
                                backgroundColor: '#4CAF50',
                                color: '#fff',

                            }} onClick={handleSave}>
                                저장
                            </div>
                        )
                    }
                </div>
            </div>

            <DropdownMenu
                isDropdownOpen={isDropdownOpen} />
        </div>
    )
}

export default EditImage