import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import profileImg1 from '../assets/images/profiles/ToyFaces_Colored_BG_8.jpg'
import profileImg2 from '../assets/images/profiles/ToyFaces_Colored_BG_29.jpg'
import profileImg3 from '../assets/images/profiles/ToyFaces_Colored_BG_32.jpg'
import profileImg4 from '../assets/images/profiles/ToyFaces_Colored_BG_37.jpg'
import profileImg5 from '../assets/images/profiles/ToyFaces_Colored_BG_56.jpg'
import profileImg6 from '../assets/images/profiles/ToyFaces_Colored_BG_59.jpg'
import { formatShortDate } from './utils/Helper'
import { Award } from 'react-feather';
import Avatar from 'react-avatar';
import useToken from './auth/useToken';
import { useTranslation, Trans } from 'react-i18next';

function DropdownMenu({ isDropdownOpen, setIsDropdownOpen }) {
    const location = useLocation();
    const { t } = useTranslation();

    const default_user_profile_images = [
        profileImg1,
        profileImg2,
        profileImg3,
        profileImg4,
        profileImg5,
        profileImg6];

    const [userType, setUserType] = useState(-1);
    const [userName, setUserName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userImg, setUserProfileImage] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState({
        status: "none",
    });

    const { token, setToken } = useToken();

    const navigate = useNavigate();

    const handleLogout = (navigate) => {
        localStorage.removeItem('session');
        navigate("/login", { replace: true })
    };

    const settings = (navigate) => {
        if (location.pathname !== "/profile/editor")
            navigate("/profile/editor")
        else
            setIsDropdownOpen(false);
    };

    const ourstory = (navigate) => {
        window.open("https://medium.com/@my04", "_blank", "noreferrer");
    };

    const profile = (navigate) => {
        if (location.pathname !== "/profile")
            navigate("/profile");
        else if (location.pathname === "/profile" && location.search) {
            navigate("/profile");
            setIsDropdownOpen(false);
        }
        else
            setIsDropdownOpen(false);
    }

    const my_hancuts = (navigate) => {
        if (location.pathname !== "/profile")
            navigate("/profile?_t=1");
        else if (location.pathname === "/profile" && location.search !== '?_t=1') {
            navigate("/profile?_t=1");
            setIsDropdownOpen(false);
        }
        else
            setIsDropdownOpen(false);
    }

    const my_prompts = (navigate) => {
        if (location.pathname !== "/profile")
            navigate("/profile?_t=2");
        else if (location.pathname === "/profile" && location.search !== '?_t=2') {
            navigate("/profile?_t=2");
            setIsDropdownOpen(false);
        }
        else
            setIsDropdownOpen(false);
    }

    const pricing = (navigate) => {
        if (location.pathname !== "/pricing") navigate("/pricing")
        else setIsDropdownOpen(false);
    }

    const loadUser = () => {
        if (token) {
            fetch(process.env.REACT_APP_API + '/v1/profile', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(errorInfo => {
                            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);

                        });
                    }
                    else return response.json();
                })
                .then(data => {
                    const user = data.user;
                    setUserName(user.fname + " " + user.lname);
                    setUserEmail(user.email);
                    setUserProfileImage(user.profile_img);
                    setUserType(user.role);
                    if (data.active_subscription) {
                        setIsSubscribed(true);
                        setActiveSubscription(data.active_subscription);
                    }
                    // if (data !== undefined) {
                    //     // console.log(JSON.stringify(data));
                    //     if (data.status) {
                    //         const user = data.user;
                    //         setUserName(user.fname + " " + user.lname);
                    //         setUserEmail(user.email);
                    //         setUserProfileImage(user.profile_img);
                    //         setUserType(user.role);
                    //         if (data.active_subscription) {
                    //             setIsSubscribed(true);
                    //             setActiveSubscription(data.active_subscription);
                    //         }
                    //     }
                    // }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
    useEffect(() => {
        loadUser();
    }, [token]);

    return (
        <div>
            {isDropdownOpen && (
                <div className="dropdown-menu">
                    {
                        isSubscribed &&
                        <div className='dropdown-pro-membership-wrapper'>
                            <Award className='' size={13} color='black' onClick={() => { }} />
                            <p><Trans>Pro Membership since</Trans> {formatShortDate(activeSubscription.start_date)}</p>
                        </div>
                    }
                    <div className="user-section" onClick={() => {
                        profile(navigate);
                    }}>
                        {userImg === null
                            ? <Avatar name={userName} size="36px" round={true} color={'black'} textSizeRatio={2.2} maxInitials={1} />
                            :
                            <img src={!isNaN(userImg) ? default_user_profile_images[userImg] : userImg.includes('http://') || userImg.includes('https://') ? `${userImg}` : `data:image/png;base64,${userImg}`} alt="Profile" className="dropdown-profile-picture" />
                        }
                        <div className='detail'>
                            <p className="profile-name">{userName}</p>
                            <p className="profile-email">{userEmail}</p>
                        </div>
                    </div>
                    <div style={{
                        width: "100%",
                        height: "1px",
                        marginBottom: "10px",
                        backgroundColor: "#dddddd90"
                    }} />
                    <button className="dropdown-button" onClick={() => profile(navigate)}><Trans>My Projects</Trans></button>
                    <button className="dropdown-button" onClick={() => my_hancuts(navigate)}><Trans>My Hancuts</Trans></button>
                    <button className="dropdown-button" onClick={() => my_prompts(navigate)}><Trans>My Prompts</Trans></button>
                    <div style={{
                        width: "100%",
                        height: "1px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#dddddd90"
                    }} />
                    <button className="dropdown-button" onClick={() => window.open("https://v2.hancut.my04.net/showcase", "_blank", "noreferrer")}><Trans>Showcase</Trans></button>
                    <button className="dropdown-button" onClick={() => navigate('/store')}><Trans>Store</Trans></button>
                    <button className="dropdown-button" onClick={() => navigate("/designer")}><Trans>04연구소</Trans></button>
                    <div style={{
                        width: "100%",
                        height: "1px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        backgroundColor: "#dddddd90"
                    }} />
                    <button className="dropdown-button" onClick={() => pricing(navigate)}><Trans>Pricing</Trans></button>
                    <button className="dropdown-button" onClick={() => settings(navigate)}><Trans>Settings</Trans></button>
                    <button className="dropdown-button" onClick={() => ourstory(navigate)}><Trans>Our Story</Trans></button>
                    <button className="dropdown-button" onClick={() => handleLogout(navigate)}><Trans>Log Out</Trans></button>
                </div>
            )}
        </div>
    );
}

export default DropdownMenu;