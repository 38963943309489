/**
 * TODO: Replace with API call
*/

const categories = [
    {
        name: "Bed",
        image: "https://interiorteacher.com/_next/image?url=https%3A%2F%2Fstatic.interiorteacher.com%2Fmall%2Ficon%2Fproduct-bed.png&w=96&q=80",
    },
    {
        name: "Chair",
        image: "https://interiorteacher.com/_next/image?url=https%3A%2F%2Fstatic.interiorteacher.com%2Fmall%2Ficon%2Fproduct-sofa.png&w=96&q=80",
    },
    {
        name: "Lighting",
        image: "https://interiorteacher.com/_next/image?url=https%3A%2F%2Fstatic.interiorteacher.com%2Fmall%2Ficon%2Fproduct-light.png&w=96&q=80",
    },
    {
        name: "Decoration",
        image: "https://interiorteacher.com/_next/image?url=https%3A%2F%2Fstatic.interiorteacher.com%2Fmall%2Ficon%2Fproduct-homedeco.png&w=96&q=80",
    },
    {
        name: "Table",
        image: "https://interiorteacher.com/_next/image?url=https%3A%2F%2Fstatic.interiorteacher.com%2Fmall%2Ficon%2Fproduct-table.png&w=96&q=80",
    },
    {
        name: "Outdoor",
        image: "https://interiorteacher.com/_next/image?url=https%3A%2F%2Fstatic.interiorteacher.com%2Fmall%2Ficon%2Fproduct-chair.png&w=96&q=80",
    },
    {
        name: "Stationery",
        image: "https://interiorteacher.com/_next/image?url=https%3A%2F%2Fstatic.interiorteacher.com%2Fmall%2Ficon%2Fproduct-drawer.png&w=96&q=80",
    },
    {
        name: "Lighting",
        image: "https://interiorteacher.com/_next/image?url=https%3A%2F%2Fstatic.interiorteacher.com%2Fmall%2Ficon%2Fproduct-light.png&w=96&q=80",
    },
    {
        name: "Accessories",
        image: "https://interiorteacher.com/_next/image?url=https%3A%2F%2Fstatic.interiorteacher.com%2Fmall%2Ficon%2Fproduct-sofa.png&w=96&q=80",
    },
    {
        name: "Decoration",
        image: "https://interiorteacher.com/_next/image?url=https%3A%2F%2Fstatic.interiorteacher.com%2Fmall%2Ficon%2Fproduct-homedeco.png&w=96&q=80",
    },
]

const getCategories = () => {
    console.log("getCategories");
    return categories;
}

export default getCategories;