import React, { useState, useEffect } from "react";
import './FeaturedDesigners.css';
import { FeaturedDesignerItem, useFeaturedDesigners } from "../../designer";
const FeaturedDesigners = () => {
    const { featuredDesigners, handleFeaturedDesigners, error } = useFeaturedDesigners();

    useEffect(() => {
        handleFeaturedDesigners();
    }, []);

    return (
        <div className="featured-designers-wrapper">
            <div className="featured-designers">
                <div className="featured-designers-title">
                    <h1>Featured Designers</h1>
                    <p>Meet the world best interior designers</p>
                </div>

                <div className="featured-designers-list">
                    {
                        featuredDesigners.map((designer, index) => {
                            return <FeaturedDesignerItem designer={designer} key={index} />
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default FeaturedDesigners;