import React, { useState, useEffect, useRef } from "react";
// import { Player, Controls } from '@lottiefiles/react-lottie-player';
// import LogoAnimation from '../../assets/lottie/hancut_studio_loading_icon.json'
// import { Trans } from "react-i18next";
import useToken from '../auth/useToken';
import ProgressBar from "@ramonak/react-progress-bar";

function LoadingPreviewImages({ loadingPrompt, loadingImg, isGenerationLoading }) {
    const [progress, setProgress] = useState(0);
    const previousProgress = useRef(0);
    const [etaRelative, setEtaRelative] = useState(null);
    const [etaRelativeTime, setEtaRelativeTime] = useState(null);
    const [progressPreviewImage1, setProgressPreviewImage1] = useState(null);
    const [progressPreviewImage2, setProgressPreviewImage2] = useState(null);
    const [progressPreviewImage3, setProgressPreviewImage3] = useState(null);
    const [progressPreviewImage4, setProgressPreviewImage4] = useState(null);
    const [isGenerationProcessing, setIsGenerationProcessing] = useState(false);
    const { token } = useToken();

    useEffect(() => {
        console.log(progress);
        if (progress >= previousProgress.currentGeneratingIndex) {
            previousProgress.currentGeneratingIndex = progress;
        }
        console.log(previousProgress.currentGeneratingIndex);
    }, [progress]);

    useEffect(() => {
        const checkCurrentProgress = () => {
            fetch(process.env.REACT_APP_API + "/v1/progress", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        return response.json().then(errorInfo => {
                            console.log(errorInfo);
                        });
                    }
                    else return response.json();
                })
                .then((data) => {

                    if (data.progress) {
                        if (data.progress.status === "generating" || data.progress.status === "regenerating") {
                            setIsGenerationProcessing(true);
                            if (data.progress.progress[0]) {
                                let newProgress = Math.round(data.progress.progress[0].progress * 100);
                                if (newProgress !== 0 && newProgress > previousProgress.currentGeneratingIndex)
                                    setProgress(newProgress);

                                if (data.progress.progress[0].eta_relative !== 0) {
                                    setEtaRelative(Math.round(isNaN(data.progress.progress[0].eta_relative) ? 60 : data.progress.progress[0].eta_relative));
                                    setEtaRelativeTime("seconds remaining");
                                }

                                if (data.progress.progress[0].current_image) {
                                    setProgressPreviewImage1(data.progress.progress[0].current_image);
                                }

                                if (data.progress.progress[1].current_image) {
                                    setProgressPreviewImage2(data.progress.progress[1].current_image);
                                }

                                if (data.progress.progress[2].current_image) {
                                    setProgressPreviewImage3(data.progress.progress[2].current_image);
                                }

                                if (data.progress.progress[3].current_image) {
                                    setProgressPreviewImage4(data.progress.progress[3].current_image);
                                }

                            }
                        }
                        else if (data.progress.status === "requeued") {
                            setEtaRelative(null);
                            setEtaRelativeTime("-");
                        }
                        else {
                            if (data.currentGeneratingIndex !== null && data.progress.order !== null) {
                                setEtaRelative((data.progress.order - data.currentGeneratingIndex + 1) * 1);
                                setEtaRelativeTime("minutes remaining");
                            }
                            if (data.progress.request) {
                                // setProgressPreviewImage(data.generation.request);
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log("Error fetching data: ", error);
                });
        }

        let intervalId;

        if (isGenerationLoading) {
            intervalId = setInterval(() => {
                checkCurrentProgress();
            }, 2000);
        }
        else if (intervalId) {
            clearInterval(intervalId);
        }
        else if (progress !== 0 && etaRelative != null && progressPreviewImage1 != null) {
            setProgress(0);
            setEtaRelative(0);
            setEtaRelativeTime(null);
            setProgressPreviewImage1(null);
            console.log("Loading preview generation completed!");
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        }
    }, [etaRelative, isGenerationLoading, progress, progressPreviewImage1, token]);

    // const cancelCurrentProgress = () => {
    //     fetch(process.env.REACT_APP_API + "/cancel_generation_progress", {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}`
    //         },
    //     })
    //         .then((response) => {
    //             if (!response.ok) {
    //                 return response.json().then(errorInfo => {
    //                     console.log(errorInfo);
    //                 });
    //             }
    //             else return response.json();
    //         })
    //         .then((data) => {

    //         })
    //         .catch(error => {
    //             console.log("Error fetching data: ", error);
    //         });
    // }

    return (
        <div className="loading-image-preview-section" style={{
            position: "sticky",
        }}>
            <div style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
            }}>
                <div style={{
                    width: "auto",
                    height: "auto",
                }}>
                    {
                        progressPreviewImage1 ?
                            <img src={`data:image/png;base64,${progressPreviewImage1}`}
                                className="loading-preview-img br animate"
                                style={{
                                    width: "24%",
                                    aspectRatio: "1/1",
                                    objectFit: "cover",
                                }}
                                alt="loading-preview" />
                            :
                            <div className="loading-preview-img br animate"
                                style={{
                                    width: "24%",
                                    height: "auto",
                                    aspectRatio: "1/1",
                                    objectFit: "cover",
                                }} ></div>
                    }
                    {
                        progressPreviewImage2 ?
                            <img src={`data:image/png;base64,${progressPreviewImage2}`}
                                className="loading-preview-img br animate"
                                style={{
                                    width: "24%",
                                    aspectRatio: "1/1",
                                    objectFit: "cover",
                                }}
                                alt="loading-preview" />
                            :
                            <div className="loading-preview-img br animate"
                                style={{
                                    width: "24%",
                                    height: "auto",
                                    aspectRatio: "1/1",
                                    objectFit: "cover",
                                }} ></div>
                    }
                    {
                        progressPreviewImage3 ?
                            <img src={`data:image/png;base64,${progressPreviewImage3}`}
                                className="loading-preview-img br animate"
                                style={{
                                    width: "24%",
                                    aspectRatio: "1/1",
                                    objectFit: "cover",
                                }}
                                alt="loading-preview" />
                            :
                            <div className="loading-preview-img br animate"
                                style={{
                                    width: "24%",
                                    height: "auto",
                                    aspectRatio: "1/1",
                                    objectFit: "cover",
                                }} ></div>
                    }
                    {
                        progressPreviewImage4 ?
                            <img src={`data:image/png;base64,${progressPreviewImage4}`}
                                className="loading-preview-img br animate"
                                style={{
                                    width: "24%",
                                    aspectRatio: "1/1",
                                    objectFit: "cover",
                                }}
                                alt="loading-preview" />
                            :
                            <div className="loading-preview-img br animate"
                                style={{
                                    width: "24%",
                                    height: "auto",
                                    aspectRatio: "1/1",
                                    objectFit: "cover",
                                }} ></div>
                    }
                </div>
                <div style={{
                    width: "auto",
                    flex: "1",
                }}>
                    <p style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: "16px",
                    }}>
                        {isGenerationProcessing ? "Time remaining: " + (etaRelative || "-") + " " + (etaRelativeTime || "-") : "⌛ Waiting:"}
                    </p>
                    {
                        isGenerationProcessing && <ProgressBar completed={progress} labelSize={10} bgColor={"#000000"} />
                    }
                </div>
            </div>

            {/* <p style={{
                wordBreak: "keep-all",
                position: "absolute",
                bottom: "10%",
                width: "100%",
                textAlign: "center",
                color: "#000",
                padding: "0px 10px",
            }}><Trans>Please wait 10 seconds to 1 minute during generating the room images.</Trans></p> */}
        </div>
    );
}

export default LoadingPreviewImages;