import React, { useState, useEffect } from "react";
import { Product } from "../../store";
import "./StoreProductsGrid.css";

const StoreProductsGrid = ({ products }) => {
    return (
        <div>
            <div className="store-products-grid-wrapper">
                <div className="store-products-grid">
                    {
                        products.map((product, index) => {
                            return (
                                <Product product={product} key={index} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default StoreProductsGrid;