import React, { useState, useEffect } from "react";
import { DesignerHomeHeader, FeaturedDesigners, BrowseDesigners } from "../../features/designer";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import './DesignerHome.css';

const DesignerHome = () => {
    return (
        <div className="designer-home-wrapper">
            <Header />
            <div className="designer-home">
                <DesignerHomeHeader />
                <FeaturedDesigners />
                <BrowseDesigners />
            </div>
            <Footer />
            <div style={{
                position: "absolute",
                width: "100%",
                height: "calc(100vh - 55px)",
                marginTop: "55px",
                backgroundColor: "#00000050",
                top: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pointerEvents: "none"
            }}>
                <p style={{
                    color: "#fff",
                    fontWeight: "700",
                    fontSize: "16px"
                }}>
                    준비중입니다 ...
                </p>
            </div>
        </div>
    );
}

export default DesignerHome;