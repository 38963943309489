import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../../assets/styles/Studio.css';
import DefaultDialog from '../../components/dialog/DefaultDialog';
import 'font-awesome/css/font-awesome.min.css';
import Header from '../Header';
import useToken from '../auth/useToken';
import GoogleLoginButton from './GoogleLoginButton';
import Footer from '../Footer';
import { Toaster } from 'react-hot-toast';
import { useTranslation, Trans } from 'react-i18next';
import KakaoLogin from 'react-kakao-login';
import NaverLoginIcon from '../../assets/images/naver-icon.svg';
import ReactGA from "react-ga4";
import KakaoImg from '../../assets/images/kakao_img.png';

export default function Login({ _setToken }) {
    //https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=YmmXMDX0SD7VMUSjiZJJ&redirect_uri=http://localhost:3003/oauth/naver&state=12345
    //https://nid.naver.com/oauth2.0/token?grant_type=authorization_code&client_id=YmmXMDX0SD7VMUSjiZJJ&client_secret=XEbJuRkZXB&redirect_uri=http://localhost:3003/oauth/naver&code=m427VjDyfjQxPw6DwZ&state=12345
    const kakaoToken = process.env.REACT_APP_KAKAO_ID;
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const [isErrorMessageShow, setIsErrorMessageShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isInputStarted, setIsInputStarted] = useState(false);
    const [usernameError, setUserNameError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const { token, setToken } = useToken();
    const navigate = useNavigate();

    const loginWithKakao = () => {
        localStorage.setItem('login_remark', 'default');
        window.Kakao.Auth.authorize({
            redirectUri: 'https://hancut.my04.net/oauth/kakao',
            throughTalk: false,
        });
    };

    // const displayToken = () => {
    //     const token = getCookie('authorize-access-token');
    //     if (token) {
    //         window.Kakao.Auth.setAccessToken(token);
    //         window.Kakao.Auth.getStatusInfo()
    //             .then((res) => {
    //                 if (res.status === 'connected') {
    //                     // setToken('login success, token: ' + Kakao.Auth.getAccessToken());
    //                 }
    //             })
    //             .catch((err) => {
    //                 window.Kakao.Auth.setAccessToken(null);
    //             });
    //     }
    // };

    // const getCookie = (name) => {
    //     const parts = document.cookie.split(name + '=');
    //     if (parts.length === 2) return parts[1].split(';')[0];
    // };

    useEffect(() => {
        // Load the Kakao SDK
        const script = document.createElement('script');
        script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.5.0/kakao.min.js';
        script.integrity = 'sha384-kYPsUbBPlktXsY6/oNHSUDZoTX6+YI51f63jCPEIPFP09ttByAdxd2mEjKuhdqn4';
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);

        script.onload = () => {
            // Initialize Kakao SDK
            window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
            // displayToken();
        };

        localStorage.setItem('login_type', 'none');
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                handleSubmit();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [username, password]);

    useEffect(() => {
        if (token) {
            navigate("/studio", { replace: true });
        }
    }, [token]);

    useEffect(() => {
        ReactGA.initialize("G-GNSL6M6VMP");
        ReactGA.event({
            category: "Page Visit",
            action: "Login Page",
        });
    }, []);

    async function loginUser(credentials, setToken, _setToken, navigate) {

        return fetch(process.env.REACT_APP_API + '/v1/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        // setErrorMessage(errorInfo.message);
                        // setIsErrorMessageShow(true);
                        if (errorInfo.message === "User not verified") {
                            // navigate(`/verify?_a=${credentials.username}&_r=true`);
                            throw new Error("Email is not verified. Please check your email and verify your account first.");
                        }
                        else
                            throw new Error(errorInfo.message);
                    });
                }
                else return response.json();
            })
            .then(data => {
                setToken(data.token);
                navigate("/studio", { replace: true });
                // if (data !== undefined) {
                //     if (data.status) {
                //         if (_setToken)
                //             _setToken(data.token);
                //         else {
                //             setToken(data.token);
                //             navigate("/studio", { replace: true });
                //         }
                //     }
                //     else {
                //         setErrorMessage(data.message);
                //         setIsErrorMessageShow(true);

                //         if (data.action === 'verify' && data.email) {
                //             navigate(`/verify?_a=${data.email}&_r=true`);
                //         }
                //     }
                // }
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage(error.message);
                setIsErrorMessageShow(true);
            });
    }

    async function googleLogin(credentials, setToken, _setToken, navigate) {

        return fetch(process.env.REACT_APP_API + '/v1/auth/google_login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data != undefined) {
                    setToken(data.token);
                    navigate("/studio", { replace: true });
                }
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    async function kakaoLogin(credentials, setToken, _setToken, navigate) {

        return fetch(process.env.REACT_APP_API + '/kakao_login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data != undefined) {
                    if (data.status) {
                        if (_setToken) {
                            _setToken(data.token);
                        }
                        else {
                            setToken(data.token);
                            navigate("/studio", { replace: true });
                        }
                    }
                    else {
                        setErrorMessage(data.message);
                        setIsErrorMessageShow(true);
                    }
                }
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    const handleGoogleLogin = async (access_token) => {
        const token = await googleLogin(access_token, setToken, _setToken, navigate);
    }

    const handleSubmit = async () => {
        // e.preventDefault();

        let error = false;

        if (!username) {
            setUserNameError('Username cannot be empty.');
            error = true;
        }
        if (!password) {
            setPasswordError('Password cannot be empty.');
            error = true;
        }

        if (!error && isInputStarted && !usernameError && !passwordError) {
            const token = await loginUser({
                "username": username,
                "password": password
            }, setToken, _setToken, navigate);
        }
    }

    return (
        <div className="PricingApp">
            <Header toggleDropdown={() => { }} />
            <div className="content">
                <div className='wrap-content'>
                    {token}
                    <section>
                        <p className='pricing-label'><Trans>LOGIN</Trans></p>
                        <h2 className='pricing-title'><Trans>Welcome Back!</Trans></h2>
                        <p className='pricing-description'><Trans>Generate the best one indoor scene with Hancut Studio.</Trans></p>
                    </section>
                    <div className="pricing-page" style={{
                        padding: "0 20px 0 20px"
                    }}>
                        <div className='' style={{
                            width: "100%",
                            maxWidth: "1000px",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <div className='login-form'>

                                <div class="social-login-buttons">
                                    <button href="javascript:;" className='kakao' onClick={loginWithKakao}>
                                        <img src={KakaoImg} style={{
                                            width: "18px",
                                            height: "auto",
                                            marginRight: "12px"
                                        }}></img><Trans>Login with Kakao</Trans>
                                    </button>
                                </div>
                                {/* https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=YmmXMDX0SD7VMUSjiZJJ&redirect_uri=http://localhost:3003/oauth/naver&state=12345 */}
                                <div class="social-login-buttons" >
                                    <button className='naver' onClick={() => {
                                        localStorage.setItem('login_remark', 'default');
                                        window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&redirect_uri=${'https://hancut.my04.net/oauth/naver'}&state=12345`;
                                    }} style={{
                                        color: "#ffffff"
                                    }}>
                                        <img src={NaverLoginIcon} style={{
                                            width: "18px",
                                            height: "auto",
                                            marginRight: "12px"
                                        }}></img><Trans>Login with Naver</Trans>
                                    </button>
                                </div>
                                <GoogleLoginButton
                                    onSuccessResponse={(credentialResponse) => {
                                        console.log(JSON.stringify(credentialResponse));
                                        if (credentialResponse.access_token) {
                                            handleGoogleLogin(credentialResponse);
                                        }
                                        // if (credentialResponse.credential != null) {
                                        //     const USER_CREDENTIAL = jwtDecode(credentialResponse.credential);
                                        //     console.log(JSON.stringify(USER_CREDENTIAL));
                                        // }
                                    }} onFailureResponse={(error) => {
                                        setErrorMessage(error);
                                        setIsErrorMessageShow(true);
                                    }} />

                                <p style={{
                                    width: "100%",
                                    textAlign: "center",
                                    marginTop: "30px",
                                    marginBottom: "25px"
                                }}>Or login with email</p>
                                <label>
                                    <p style={{
                                        marginTop: "2px",
                                        marginBottom: "10px",
                                        color: "black"
                                    }}><Trans>Username or email</Trans></p>
                                    <input type="text" onChange={e => setUserName(e.target.value)} onBlur={() => {
                                        setIsInputStarted(true);
                                        if (!username)
                                            setUserNameError('Username or email cannot be empty.');
                                        else
                                            setUserNameError('');
                                    }} />
                                    {usernameError && (
                                        <div className="input-feedback"><Trans>{usernameError}</Trans></div>
                                    )}
                                </label>
                                <label>
                                    <p style={{
                                        marginTop: "2px",
                                        marginBottom: "10px",
                                        color: "black"
                                    }}><Trans>Password</Trans></p>
                                    <input type="password" onChange={e => setPassword(e.target.value)} onBlur={() => {
                                        setIsInputStarted(true);
                                        if (!password)
                                            setPasswordError('Password cannot be empty.');
                                        else if (password.length < 5)
                                            setPasswordError('Password must be at least 5 characters.');
                                        else
                                            setPasswordError('');
                                    }} />
                                    {passwordError && (
                                        <div className="input-feedback"><Trans>{passwordError}</Trans></div>
                                    )}
                                </label>
                                <p style={{
                                    marginTop: "0px",
                                    marginBottom: "40px",
                                    color: "black",
                                }} className='forget-password'
                                    onClick={() => navigate('/password/reset/')}><Trans>Forget Password?</Trans></p>
                                <div>
                                    <button className="loginBtn" style={{
                                        marginTop: "0px"
                                    }} type="submit" onClick={() => handleSubmit()}><Trans>Login</Trans></button>
                                </div>
                                {/* <div className="social-login-buttons">
                            <button style={{
                                color: "#DC5040"
                            }}><i className="fa fa-google" style={{ marginRight: '12px' }}></i>Login with Google</button>
                        </div> */}
                                {/* <KakaoLogin
                                    token={kakaoToken}
                                    onSuccess={(response) => {
                                        kakaoLogin({ kakao_response: response }, setToken, _setToken, navigate);
                                    }}
                                    onFail={(error) => {
                                        setErrorMessage(error);
                                        setIsErrorMessageShow(true);
                                    }}
                                    onLogout={console.info}
                                    style={{
                                        width: "100%",
                                        backgroundColor: "#FEE500",
                                        marginTop: "20px",
                                    }}
                                >
                                    <div style={{
                                        width: "100%",
                                        color: "black",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>

                                        <svg style={{
                                            marginTop: "0px",
                                            marginRight: "12px",
                                        }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 1792 1664"><path fill="currentColor" d="M896 12q243 0 449.5 94.5t326.5 257T1792 718t-120 355t-326 257.5t-450 94.5q-77 0-159-11q-356 247-379 250q-11 4-21-1q-4-3-6-8t-2-9v-4q6-39 91-325q-193-96-306.5-254.5T0 718q0-192 120-354.5t326.5-257T896 12zM442 905V598h79q16 0 33.5-11.5T572 555t-16-33.5t-31-13.5H265q-17 0-32 10t-15 33t16 35t36 12h79v307q0 25 12 41t34 16t34.5-18t12.5-39zm433 57q37-14 23-71q-4-13-56.5-155.5T781 573q-27-65-74-65q-51 0-79 65q-8 17-61 165.5T512 891q-7 9-3.5 33t21.5 33q20 9 39 2t26-21l24-66h174q11 36 16 47q5 10 11 20t22 19.5t33 3.5zm269 0q21 0 38.5-13t17.5-35t-16-34.5t-40-12.5h-111V565q0-21-12.5-39T986 508t-34 16t-12 41v340q0 25 12 41t34 16q1 0 3.5-.5t3.5-.5t3.5.5t3.5.5h144zm394-7q17-16 17-36.5t-13-36.5q-6-9-126-169q58-59 117-118q15-15 18.5-35.5T1540 521q-17-16-36.5-13t-36.5 20q-3 3-26 26.5t-59.5 60.5t-64.5 66V565q0-21-12.5-39t-34.5-18t-34 16t-12 41v340q0 25 12 41t34 16t34.5-18t12.5-39v-91q4-4 15-15.5t18-18.5q66 90 118 159q14 19 32.5 24.5t37.5-8.5zM646 782l61-179l60 179H646z" /></svg>
                                        <p style={{
                                            margin: "0px",
                                            padding: "0px",
                                            color: "black",
                                        }}>
                                            <Trans>Login with Kakao</Trans>
                                        </p></div>
                                </KakaoLogin> */}
                                {/* <div class="social-login-buttons">
                            <button className='kakao'>
                                <img src={kakaoIcon} style={{
                                    width: "23px",
                                    height: "auto",
                                    marginRight: "12px"
                                }}></img> Login with Kakao
                            </button>
                        </div>
                        <p style={{
                            width: "100%",
                            textAlign: "center",
                            marginTop: "30px",
                            marginBottom: "25px"
                        }}>or log in with</p>
                        <div class="social-login-buttons">
                            <button style={{
                                color: "#DC5040"
                            }}><i className="fa fa-google" style={{ marginRight: '0px' }}></i></button>
                            <button style={{
                                color: "#217CF3"
                            }}><i className="fa fa-facebook" style={{ marginRight: '0px' }}></i></button>
                            <button style={{
                                color: "#11C945"
                            }}><img src={naverIcon} style={{
                                width: "16px",
                                height: "auto",
                                marginTop: "3px"
                            }}></img></button>
                        </div> */}
                            </div>
                            <p style={{
                                fontSize: "small"
                            }}><Trans>Don't have an account?</Trans> <span style={{
                                color: "black",
                                fontSize: "1.05em",
                                cursor: "pointer",
                                fontWeight: "bold",
                            }} className='create-account' onClick={() => navigate("/register")}><Trans>Sign up for free</Trans></span></p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {isErrorMessageShow && <DefaultDialog
                title={"Error"}
                description={errorMessage}
                show={isErrorMessageShow}
                isCancellable={false}
                onClose={() => {
                    setIsErrorMessageShow(false);
                }}
                onOk={() => {
                    setIsErrorMessageShow(false);
                }}
                okBtn='Ok'
                onCancel={() => { }}
                enableBackgroundClick={true} />}
            <Toaster />
        </div>
    )
}