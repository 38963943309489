import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";

function CreateProjectDialog({
    title,
    description,
    show,
    onClose,
    isCancellable = true,
    enableBackgroundClick = true,
    onOk,
    onCancel,
    setPreTitle = "",
    setPreDescription = "",
    okBtn = "Create"
}) {
    const [titleInput, setTitleValue] = useState(setPreTitle);
    const [descriptionInput, setDescriptionValue] = useState(setPreDescription);
    const { t } = useTranslation();

    const handleBackgroundClick = () => {
        if (enableBackgroundClick) {
            onClose();
        }
    };

    const handleOkClick = () => {
        setTitleValue("");
        setDescriptionValue("");
        onOk(titleInput, descriptionInput);
    };

    const handleCancelClick = () => {
        onCancel();
    };

    return (
        show && (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                }}
                onClick={handleBackgroundClick}
            >
                <div
                    className="dialog-container"
                    style={{
                        width: 500,
                        backgroundColor: "white",
                        borderRadius: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <h4 style={{ fontWeight: "bold" }}><Trans>{title}</Trans></h4>
                    <p className="dialog-description" style={{
                        wordBreak: "keep-all",
                    }}><Trans>{description}</Trans></p>
                    <p className="dialog-description label" style={{
                        marginTop: "20px"
                    }}><Trans>Name</Trans></p>
                    <input
                        className="dialog-input"
                        type="text"
                        maxLength={50}
                        placeholder={t("createHint")}
                        value={titleInput}
                        onChange={(e) => setTitleValue(e.target.value)}
                    />
                    {/* <p className="dialog-description label" style={{
                        wordBreak: "keep-all",
                    }}><Trans>Description</Trans></p>
                    <textarea
                        className="dialog-input textarea"
                        type="text"
                        maxLength={200}
                        placeholder={t("createDescHint")}
                        value={descriptionInput}
                        onChange={(e) => setDescriptionValue(e.target.value)}
                    /> */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        {isCancellable ? <button style={{ marginRight: 20 }} onClick={handleCancelClick} className="dialog-button cancel-dialog"><Trans>Cancel</Trans></button> : <div></div>}
                        {(titleInput !== "")
                            ? <button onClick={handleOkClick} className="dialog-button"><Trans>{okBtn}</Trans></button>
                            : <button disabled onClick={handleOkClick} className="dialog-button"><Trans>{okBtn}</Trans></button>}
                    </div>
                </div>
            </div>
        )
    );
}

export default CreateProjectDialog;