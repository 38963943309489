import React, { useState, useEffect } from "react";
import './DesignerHomeHeader.css';
import designerImage from '../../../assets/images/designer/designer-home-header.jpeg';

const DesignerHomeHeader = () => {
    return (
        <div className="designer-home-header-wrapper">
            <div className="designer-home-header">
                <div className="designer-home-header-title-wrapper">
                    <div className="designer-home-header-title-left">
                        <div className="designer-home-header-title">
                            <h1>Meet the world best<br />interior designers<br /><span>anywhere, anytime</span></h1>
                            <p>The only ideal place to find <br />best designers suit for your ease</p>
                        </div>
                    </div>
                    <div className="designer-home-header-title-right">
                    </div>
                </div>
                <img src={designerImage} />
            </div>
        </div>
    );
}

export default DesignerHomeHeader;