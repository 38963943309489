import React from "react";
import { DesignerProfileHM } from "../../designer";
import './DesignerProfileDescHM.css';

const DesignerProfileDescHM = ({ item }) => {
    const { description } = item;

    return (
        <>
            <DesignerProfileHM item={item} />
            <div className="browse-designer-item-info-wrapper">
                <div className="browse-designer-item-info">
                    <div className="browse-designer-item-info-description">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DesignerProfileDescHM;