// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featured-designers-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    background-color: #000000;
}

.featured-designers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 200px 0;
    max-width: 1200px;
}

.featured-designers-title h1{
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 30px;
    line-height: 56px;
}

.featured-designers-title p {
    font-size: 18px;
    font-weight: 400;
    color: var(--secondary-gray);
    margin-bottom: 100px;
    line-height: 33px;
}

.featured-designers-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    column-gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/features/designer/components/FeaturedDesigners.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,WAAW;IACX,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,4BAA4B;IAC5B,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,2BAA2B;IAC3B,WAAW;IACX,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".featured-designers-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: center;\n    width: 100%;\n    margin: 0 auto;\n    background-color: #000000;\n}\n\n.featured-designers {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: center;\n    width: 100%;\n    margin: 0 auto;\n    padding: 200px 0;\n    max-width: 1200px;\n}\n\n.featured-designers-title h1{\n    font-size: 40px;\n    font-weight: 700;\n    color: #fff;\n    margin-bottom: 30px;\n    line-height: 56px;\n}\n\n.featured-designers-title p {\n    font-size: 18px;\n    font-weight: 400;\n    color: var(--secondary-gray);\n    margin-bottom: 100px;\n    line-height: 33px;\n}\n\n.featured-designers-list {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-start;\n    width: 100%;\n    margin: 0 auto;\n    flex-wrap: wrap;\n    column-gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
