import React, { useState, useRef } from 'react';
import ImageUploader from './ImageUploaderMobile';
import PromptInput from '../PromptInput';
import ImageGenerationOptions from '../ImageGenerationOptions';
import { ChevronUp, ChevronDown, Edit } from 'react-feather';
import { useTranslation, Trans } from 'react-i18next';
import { Tooltip as ReactTooltip } from "react-tooltip";
import QuickSelectImage from '../QuickSelectImage';
import { user_test_1, user_test_2, user_test_3, user_test_4, living_room_2, living_room_3, living_room_4, living_room_6 } from '../../../assets/images/studio';

const GenerateMobileComponent = ({ height, prompt, setPrompt, handleGenerateImage, handleImageUpload, inputImage, handleRemoveImage, isImageLoaded, isGenerationLoading,
    selectedRoomOptions, setSelectedRoomOptions, roomTypes, interiorStyles, isSubscribed,
    onItemSelected,
    additionOptionsList, setAdditionOptionsList, stringifiedAdditionOptionsList,
    freeMembershipDailyCount, freeMembershipDailyLimit, freeMembershipMonthlyCount, freeMembershipMonthlyLimit,
    isGenerating, setIsGenerating,
    isGenerateMobileOpen, setIsGenerateMobileOpen,
    isAllowBeta, isAllowJakomo, handleQuickImageBase64 }) => {
    const fileInputRef = useRef(null);
    const { t } = useTranslation();

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setIsGenerateMobileOpen(!isGenerateMobileOpen);
    };

    const handleClick = (e) => {
        e.stopPropagation();
        fileInputRef.current.click();
    };

    return (
        <div className={`generate-container-mobile`}>
            {/* <button onClick={toggleDropdown}>Advanced Options</button> */}
            <div className="dropdown-header" onClick={toggleDropdown} style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "6px 6px 0px 6px",
            }}>
                <div className="dropdown-header-icon" style={{
                    height: "24px",
                }}>
                    {isGenerateMobileOpen ? <ChevronDown size={18} /> : <ChevronUp size={18} />}
                </div>
            </div>
            <div className={`dropdown-preview ${isGenerateMobileOpen ? 'collapsed' : 'expanded'}`} onClick={toggleDropdown}>
                {inputImage ?
                    <div
                        className="uploader-input-image not-selectable"
                        style={{
                            cursor: 'pointer',
                            height: '38px',
                            width: 'auto',
                            aspectRatio: '1/1',
                            justifyContent: 'left',
                            alignItems: 'center',
                            backgroundColor: 'transparent',
                            marginRight: '6px',
                        }}
                    >
                        <div className="uploader-input-image wrap" style={{
                            height: '100%',
                            width: 'auto',
                            aspectRatio: '1/1',
                        }}>
                            <img src={inputImage} alt="Upload Image" className="thumbnail-image not-selectable"
                                style={{
                                    width: 'auto',
                                    aspectRatio: '1/1',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '6px',
                                }} />
                        </div>
                    </div> :
                    <div
                        className="uploader-input-box"
                        onClick={handleClick}
                        style={{
                            cursor: 'pointer',
                            height: '38px',
                            width: 'auto',
                            aspectRatio: '1/1',
                            borderRadius: '6px',
                            border: '1px dashed #ccc',
                            marginRight: '6px',
                        }}
                    >
                        <div style={{
                            height: '20px',
                        }}><img width="20" height="20" src="https://img.icons8.com/sf-regular/48/add-image.png" alt="add-image" /></div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={e => {
                                handleImageUpload(e.target.files);
                                toggleDropdown(e);
                            }}
                            accept="image/jpeg, image/jpg, image/png"
                            style={{ display: 'none' }}
                        />
                    </div>}
                <p style={{
                    width: "100%",
                    fontSize: "14px",
                    maxLines: "2",
                    margin: "0px",
                    color: prompt === "" ? "rgb(160, 160, 160)" : "#000",
                }}>
                    {
                        prompt === "" ? t('mobilePrompt') : prompt
                    }
                </p>
                <Edit size={20} color='#000'
                    style={{
                        marginTop: "1px",
                    }} />
            </div>
            <div style={{
                maxHeight: height - 180,
                overflowY: "scroll",
            }}>
                <div className={`dropdown-content ${isGenerateMobileOpen ? 'expanded' : 'collapsed'}`}>
                    <ImageUploader
                        onImageUpload={handleImageUpload}
                        loadedImg={inputImage}
                        removeImg={handleRemoveImage}
                    />
                    {/* {!isImageLoaded ?
                <QuickSelectImage img1={selectableImg6} img2={selectableImg5} handleQuickImageBase64={handleQuickImageBase64} />
                : <div></div>} */}

                    {isAllowJakomo && !isImageLoaded ?
                        <QuickSelectImage img1={living_room_2} img2={living_room_3} img3={living_room_6} img4={living_room_4} handleQuickImageBase64={handleQuickImageBase64} />
                        : <div></div>}
                    {!isAllowJakomo && !isImageLoaded ?
                        <QuickSelectImage img1={user_test_1} img2={user_test_2} img3={user_test_3} img4={user_test_4} handleQuickImageBase64={handleQuickImageBase64} />
                        : <div></div>}

                    <PromptInput value={prompt}
                        additionOptionsList={additionOptionsList}
                        setAdditionOptionsList={setAdditionOptionsList}
                        onChange={e => setPrompt(e.target.value)} onClick={handleGenerateImage} onUpdate={value => setPrompt(value)} isImageLoaded={isImageLoaded} isImageLoading={isGenerationLoading} />

                    <ImageGenerationOptions
                        additionOptionsList={additionOptionsList} setAdditionOptionsList={setAdditionOptionsList}
                        selectedRoomOptions={selectedRoomOptions}
                        setSelectedRoomOptions={setSelectedRoomOptions}
                        onItemSelected={onItemSelected}
                        roomTypes={roomTypes}
                        interiorStyles={interiorStyles}
                        stringifiedAdditionOptionsList={stringifiedAdditionOptionsList}
                        isAllowBeta={isAllowBeta}
                        isAllowJakomo={isAllowJakomo} />

                    {
                        !isSubscribed &&
                        <p
                            style={{
                                marginTop: "30px",
                                marginBottom: "0px",
                                fontSize: "13px",
                            }}
                        >
                            {t('freeGenerateTooltip')}
                        </p>
                        // <p style={{
                        //     marginTop: "30px",
                        //     marginBottom: "0px",
                        //     fontSize: "12px",
                        // }}>Daily Limit: {freeMembershipDailyCount} / {freeMembershipDailyLimit}<br />
                        //     Monthly Limit: {freeMembershipMonthlyCount} / {freeMembershipMonthlyLimit}</p>
                    }

                </div>
            </div>

            <div className='generate-button-view' style={{
                marginTop: "0px",
                padding: "0px 14px 14px 14px",
            }}>
                {(prompt || additionOptionsList.length > 0) && isImageLoaded && !isGenerationLoading ?
                    <button onClick={handleGenerateImage} className="generate-button generate-button-mobile"><Trans>Generate</Trans></button>
                    :
                    <button className="generate-button generate-button-disable generate-button-mobile" onClick={() => { setIsGenerateMobileOpen(true) }}><Trans>Generate</Trans></button>
                }
            </div>

            <ReactTooltip
                id="standard-tooltip"
                place="bottom"
                content={t('standardTooltip')}
            />
            <ReactTooltip
                id="creative-tooltip"
                place="bottom"
                content={t('creativeTooltip')}
            />
        </div >
    );
};

export default GenerateMobileComponent;