import React, { useRef, useEffect } from 'react';
import PromptKeywordSelector from './PromptKeywordSelector';
import { XCircle, AlertCircle, X } from 'react-feather';
import { useTranslation, Trans } from 'react-i18next';

function PromptInput({ value, additionOptionsList, setAdditionOptionsList, onChange, onClick, onUpdate, isImageLoaded, isImageLoading }) {
    const { t } = useTranslation();
    const textareaRef = useRef(null);
    const textremoveRef = useRef(null);

    const adjustHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }

    useEffect(() => {
        adjustHeight();
    }, [value]);

    const handleKeywordChange = (keyword) => {
        var input = document.getElementById('promptInput');
        input.value += (input.value === "" ? "" : input.value.charAt(input.value.length - 1) === "," ? " " : ", ") + keyword;
        onUpdate(input.value);
    };

    return (
        <div className="prompt-input-container" style={{ marginTop: "20px", position: "relative" }}>
            <h4 className="" style={{ fontWeight: "bold", fontSize: "16px" }}><Trans>Enter Prompt</Trans></h4>
            {/* <div style={{
                background: "#FFF3E880",
                borderRadius: "10px",
                padding: "10px",
                display: "flex",
                marginBottom: "16px",
            }}>
                <AlertCircle color='#FB5210' size={15} style={{
                    margin: "2px",
                }} />
                <p className="text-md lg:text-xl mb-5" style={{
                    color: "#FB5210",
                    fontSize: "13px",
                    padding: "0px 8px",
                    margin: "0px",
                }}>
                    For easier generation, try the prompt tips on the right panel.
                </p>
            </div> */}


            <div style={{
                width: "100%",
                position: "relative"
            }}
                className='prompt-wrapper'>
                {(additionOptionsList.length > 0) &&
                    <div className='prompt-additional-wrapper'>
                        {additionOptionsList.map((item, index) => (
                            <div
                                className='prompt-additional-items'
                                key={index} style={{
                                    // backgroundColor: item.option === "roomType" ? "#5b5ea610" : "#e2583e10",
                                    // color: item.option === "roomType" ? "#5b5ea6" : "#e2583e",
                                }}>
                                <p>{item.keyword}</p>
                                <X size={13} color={item.option === "roomType" ? "#383838" : "#383838"} style={{
                                    cursor: "pointer",
                                    marginTop: "0.5px",
                                    marginLeft: "4px",
                                }}
                                    onClick={() => {
                                        setAdditionOptionsList(additionOptionsList.filter((_, i) => i !== index));
                                    }} />
                            </div>
                        ))}
                    </div>
                }
                <textarea
                    id="promptInput"
                    value={value}
                    onChange={onChange}
                    placeholder={additionOptionsList.length > 0 ? t('dreamBedroomAdditional') : t('dreamBedroom')}
                    maxLength="10000"
                    ref={textareaRef}
                    onInput={adjustHeight}
                    className='prompt-input-textarea'
                />
                {
                    // ((textareaRef.current && textareaRef.current.value && textareaRef.current.value !== "") || additionOptionsList.length >= 0) &&
                    <XCircle
                        ref={textremoveRef}
                        className=""
                        color='black'
                        size={17}
                        style={{
                            display: ((textareaRef.current && textareaRef.current.value && textareaRef.current.value !== "") || additionOptionsList.length > 0) ? "block" : "none",
                            position: "absolute",
                            top: "9px",
                            right: "9px",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            textareaRef.current.value = null;
                            onUpdate("");
                            setAdditionOptionsList([]);
                        }} />
                }
            </div>
            {/* <PromptKeywordSelector onKeywordChange={handleKeywordChange} /> */}
        </div>
    );
}

export default PromptInput;
