import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/Studio.css";
import DefaultDialog from "../../components/dialog/DefaultDialog";
import "font-awesome/css/font-awesome.min.css";
import logoLabel from "../../assets/images/hancut_logo_small.png";
import useToken from "../auth/useToken";

export default function Verify() {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [isErrorMessageShow, setIsErrorMessageShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { token, setToken } = useToken();
  const navigate = useNavigate();

  const _email = String(
    new URLSearchParams(document.location.search).get("_a") || ""
  );
  const _token = String(
    new URLSearchParams(document.location.search).get("_t") || ""
  );
  const _status = Boolean(
    new URLSearchParams(document.location.search).get("_s") || false
  );
  const _reaction = Boolean(
    new URLSearchParams(document.location.search).get("_r") || false
  );

  useEffect(() => {
    if (_email && _token) {
      verify(setToken, navigate);
    }
    if (_email && _status) setMinutes(3);
  }, [_email, _token, _status]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  async function send_verify() {
    return fetch(
      process.env.REACT_APP_API + "/v1/auth/resend_verification_email",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: _email,
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorInfo) => {
            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
            setErrorMessage(errorInfo.message);
            setIsErrorMessageShow(true);
          });
        } else return response.json();
      })
      .then((data) => {
        if (data != undefined) {
          setMinutes(3);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setErrorMessage("Unexpected error occurs.");
        setIsErrorMessageShow(true);
      });
  }

  async function verify(setToken, navigate) {
    return fetch(process.env.REACT_APP_API + "/v1/auth/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: _email,
        token: _token,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorInfo) => {
            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
            setErrorMessage(errorInfo.message);
            setIsErrorMessageShow(true);
          });
        } else return response.json();
      })
      .then((data) => {
        if (data != undefined) {
          console.log("New user connected!");
          window.fbq("trackCustom", "UserCreated");
          if (data.token) {
            setToken(data.token);
            navigate("/studio", { replace: true });
          } else {
            navigate("/login", { replace: true });
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
        setErrorMessage("Unexpected error occurs.");
        setIsErrorMessageShow(true);
      });
  }

  return (
    <div className="">
      <div
        className="header"
        style={{
          position: "absolute",
          top: "0",
        }}
      >
        <img
          src={logoLabel}
          alt="Logo"
          className="logo"
          style={{
            width: "60px",
            height: "auto",
          }}
        />
      </div>

      {_email && _token && (
        <div className="verify">
          <p className="verify-text">
            The verification link for <b>{_email}</b> is invalid or expire.
            Please verify your email again by sending another email.
          </p>
          {minutes === 0 && seconds === 0 ? (
            <p
              className="verify-button"
              onClick={() => {
                send_verify();
              }}
            >
              Send Verification Email Again
            </p>
          ) : (
            <>
              <p disabled className="verify-button" onClick={() => {}}>
                Send Verification Email Again
              </p>
              <p className="verify-text">
                Please wait {minutes} minutes and {seconds} seconds before
                sending verification email again.
              </p>
            </>
          )}
        </div>
      )}

      {_status && _email && (
        <div className="verify">
          <p className="verify-text">
            We have send verification email to <b>{_email}</b>. Please confirm
            your account first to continue using <b>awesome</b> Hancut Studio.
          </p>
          {minutes === 0 && seconds === 0 ? (
            <p
              className="verify-button"
              onClick={() => {
                send_verify();
              }}
            >
              Send Verification Email Again
            </p>
          ) : (
            <>
              <p disabled className="verify-button" onClick={() => {}}>
                Send Verification Email Again
              </p>
              <p className="verify-text">
                Please wait {minutes} minutes and {seconds} seconds before
                sending verification email again.
              </p>
            </>
          )}
        </div>
      )}

      {_reaction && _email && (
        <div className="verify">
          <p className="verify-text">
            We have send verification email to <b>{_email}</b>. Please confirm
            your account first to continue using <b>awesome</b> Hancut Studio.
          </p>
          {minutes === 0 && seconds === 0 ? (
            <p
              className="verify-button"
              onClick={() => {
                send_verify();
              }}
            >
              Send Verification Email Again
            </p>
          ) : (
            <>
              <p disabled className="verify-button" onClick={() => {}}>
                Send Verification Email Again
              </p>
              <p className="verify-text">
                Please wait {minutes} minutes and {seconds} seconds before
                sending verification email again.
              </p>
            </>
          )}
        </div>
      )}

      {isErrorMessageShow && (
        <DefaultDialog
          title={"Error"}
          description={errorMessage}
          show={isErrorMessageShow}
          isCancellable={false}
          onClose={() => {
            setIsErrorMessageShow(false);
          }}
          onOk={() => {
            setIsErrorMessageShow(false);
          }}
          okBtn="Ok"
          onCancel={() => {}}
          enableBackgroundClick={true}
        />
      )}
    </div>
  );
}
