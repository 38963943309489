import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useToken from "./useToken";

const KakaoOAuthHandler = () => {
    const { _, setToken } = useToken();
    let isLoaded = false;
    const navigate = useNavigate();

    const getUserProfile = async (token) => {
        fetch('https://kapi.kakao.com/v2/user/me', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                kakaoLogin({ kakao_response: res, kakao_access_token: token });
            })
            .catch((err) => {

            });
    }

    const displayToken = async () => {
        const token = getCookie('authorize-access-token');
        if (token) {
            window.Kakao.Auth.setAccessToken(token);
            window.Kakao.Auth.getStatusInfo()
                .then((res) => {
                    if (res.status === 'connected') {
                        getUserProfile(token);
                    }
                })
                .catch((err) => {
                    window.Kakao.Auth.setAccessToken(null);
                });
        }
    };

    const getAccessToken = (code) => {
        const REDIRECT_URI = 'https://hancut.my04.net/oauth/kakao';
        fetch('https://kauth.kakao.com/oauth/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                grant_type: 'authorization_code',
                client_id: '534aa67a93242b913bd084f8ac8b3400',
                redirect_uri: REDIRECT_URI,
                code: code,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.access_token) {
                    document.cookie = 'authorize-access-token=' + res.access_token;
                    getUserProfile(res.access_token);
                } else {

                }
            })
            .catch((err) => {

            });
    }

    const getCookie = (name) => {
        const parts = document.cookie.split(name + '=');
        if (parts.length === 2) return parts[1].split(';')[0];
    };

    async function kakaoLogin(credentials) {

        return fetch(process.env.REACT_APP_API + '/v1/auth/kakao_login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        alert(JSON.stringify(errorInfo));
                    });
                }
                else return response.json();
            })
            .then(data => {
                setToken(data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
                if (localStorage.getItem('login_force') === 'pcarpenter')
                    navigate("/pcarpenter", { replace: true });
                else if (localStorage.getItem('login_remark') === 'pcarpenter')
                    navigate("/pcarpenter", { replace: true });
                else
                    navigate("/studio", { replace: true });
            })
            .catch(error => {
                console.log(error.message);
                alert("error.message");
            });
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.5.0/kakao.min.js';
        script.integrity = 'sha384-kYPsUbBPlktXsY6/oNHSUDZoTX6+YI51f63jCPEIPFP09ttByAdxd2mEjKuhdqn4';
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);

        script.onload = () => {
            window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
            // displayToken();
        };
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const error = urlParams.get('error');

        if (error) {
            const error_description = urlParams.get('error_description');
            if (error_description) {
                if (String(error_description) === "network error occurred")
                    window.location.href = "https://kauth.kakao.com/error/network";
            }
        }

        if (code && !isLoaded) {
            console.log(isLoaded);
            isLoaded = true;
            kakaoLogin({ code });
            // console.log(code);
        }
    }, []);
};

export default KakaoOAuthHandler;