// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-banner-item-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.store-banner-item-labels {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 50px 50px;
    text-align: center;
}

.store-banner-item-labels h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
}

.store-banner-item-labels p {
    font-size: 1rem;
    font-weight: 400;
    color: #ececec;
    margin: 10px 0 20px 0;
}

.store-banner-img img{
    width: 100%;
    height: 600px;
    object-fit: cover;
    object-position: center;
}

.store-banner-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    /* make gradient background */
    /* background-color: rgba(0, 0, 0, 0.5); */
    background-image: linear-gradient(transparent, #00000090);
}

.store-banner-item-wrapper .store-banner-item-labels .button-landing {
    background-color: #fff;
    color: #000;
}`, "",{"version":3,"sources":["webpack://./src/features/store/components/StoreBannerItem.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,yBAAyB;IACzB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,6BAA6B;IAC7B,0CAA0C;IAC1C,yDAAyD;AAC7D;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf","sourcesContent":[".store-banner-item-wrapper {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    overflow: hidden;\n}\n\n.store-banner-item-labels {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: flex-end;\n    padding: 50px 50px;\n    text-align: center;\n}\n\n.store-banner-item-labels h2 {\n    font-size: 2.5rem;\n    font-weight: 700;\n    color: #fff;\n    text-transform: uppercase;\n    margin: 0;\n}\n\n.store-banner-item-labels p {\n    font-size: 1rem;\n    font-weight: 400;\n    color: #ececec;\n    margin: 10px 0 20px 0;\n}\n\n.store-banner-img img{\n    width: 100%;\n    height: 600px;\n    object-fit: cover;\n    object-position: center;\n}\n\n.store-banner-item-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 600px;\n    /* make gradient background */\n    /* background-color: rgba(0, 0, 0, 0.5); */\n    background-image: linear-gradient(transparent, #00000090);\n}\n\n.store-banner-item-wrapper .store-banner-item-labels .button-landing {\n    background-color: #fff;\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
