import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../assets/styles/Studio.css';
import useToken from './auth/useToken';
import Header from './Header';
import Footer from './Footer';
import '../assets/styles/Header.css'
import 'font-awesome/css/font-awesome.min.css';
import toast, { Toaster } from 'react-hot-toast';
// import Stroke from '../assets/images/path1923.svg'
import Stroke from '../assets/images/line_thick.svg'
import DropdownMenu from './DropdownMenu';
import { Trans } from 'react-i18next';

export default function Contact() {
    const [userName, setUserName] = useState();
    const [userType, setUserType] = useState();
    const [userProfileImage, setUserProfileImage] = useState(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const { token, setToken } = useToken();
    // const email = "example@example.com";
    // const subject = encodeURIComponent("This is my subject");
    // const body = encodeURIComponent("This is the body of the email.");

    // <a
    //     href={`mailto:${emailAddress}?subject=${subject}&body=${body}`}
    //     className="email-link"
    // >
    //     Send Email
    // </a>

    // Use window.location to trigger the mailto link
    // window.location = `mailto:${email}?subject=${subject}&body=${body}`;
    return (
        <div className="ContactApp">
            <Header userType={userType} userName={userName} userImg={userProfileImage} token={undefined} toggleDropdown={toggleDropdown} />
            <div className="content mobile-padding">
                <div className='left'>
                    <div className='middle'>
                        <div className='wrap'>
                            <p><Trans>Wanna talk about the adventures behind our journey?</Trans></p><p><Trans>Drop us a line, we promise to catch it!</Trans></p>
                            <p className='email'><a href="mailto:contact@my04.net" className='email' style={{ textDecoration: "none", fontFamily: "Inter", fontWeight: "600" }}>support@my04.net</a></p>
                            <img className='email-stroke not-selectable' src={Stroke} />
                        </div>
                    </div>
                </div>
                <div className='right'>
                    <div className='middle'>
                        <div className='wrap'>
                            <div className='item'>
                                <p className='label'><Trans>BILLING</Trans></p>
                                {/* <p className='text'>billing@my04.net</p> */}
                                <p className='text'><a href="mailto:billing@my04.net" className='text' style={{ textDecoration: "none" }}><Trans>billing@my04.net</Trans></a></p>
                            </div>

                            <div className='item'>
                                <p className='label'><Trans>OFFICE</Trans></p>
                                <p className='text'><Trans>356, Seocho-daero, Seocho-gu,</Trans><br /><Trans>Seoul</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <DropdownMenu
                isDropdownOpen={isDropdownOpen} />
            <Toaster />
        </div>
    )
}