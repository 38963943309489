// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featured-designer-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 21%;
    margin: 0 auto;
    flex-grow: 1;
    position: relative;
}

.featured-designer-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
}

.featured-designer-item-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 3/2;
}

.featured-designer-item-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    aspect-ratio: 3/2;
}

.featured-designer-item-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 30px 20px;
}

.featured-designer-item-info-name {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 10px;
}

.featured-designer-item-info-location {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/features/designer/components/FeaturedDesignerItem.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,UAAU;IACV,cAAc;IACd,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,WAAW;IACX,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf","sourcesContent":[".featured-designer-item-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: center;\n    width: 21%;\n    margin: 0 auto;\n    flex-grow: 1;\n    position: relative;\n}\n\n.featured-designer-item {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: center;\n    width: 100%;\n    margin: 0 auto;\n    max-width: 1200px;\n}\n\n.featured-designer-item-image img {\n    width: 100%;\n    height: auto;\n    object-fit: cover;\n    aspect-ratio: 3/2;\n}\n\n.featured-designer-item-overlay {\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    position: absolute;\n    top: 0;\n    left: 0;\n    aspect-ratio: 3/2;\n}\n\n.featured-designer-item-info {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: center;\n    width: 100%;\n    position: absolute;\n    bottom: 0;\n    padding: 30px 20px;\n}\n\n.featured-designer-item-info-name {\n    font-size: 18px;\n    font-weight: 700;\n    color: #fff;\n    margin-bottom: 10px;\n}\n\n.featured-designer-item-info-location {\n    font-size: 14px;\n    font-weight: 400;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
