import React, { useState, useEffect, useRef } from "react";
import "../../assets/styles/Studio.css";
import "../../assets/styles/Footer.css";
import "../../assets/styles/filter.css";
import ImageUploader from "./ImageUploader";
import QuickSelectImage from "./QuickSelectImage";
import useWebSocket from "react-use-websocket";
import PromptInput from "./PromptInput";
import Header from "../Header";
import GeneratedHistory from "./GeneratedHistory";
import PromptGuideComponent from "./PromptGuideComponent";
import MyPromptComponent from "../packages/prompt/MyPromptComponent";
import ImageGenerationOptions from "./ImageGenerationOptions";
import ImageViewer from "./ImageViewer";
import DropdownMenu from "../DropdownMenu";
import { PlusCircle, MinusCircle, RefreshCw } from "react-feather";
import LoginForm from "../auth/LoginForm";
import useToken from "../auth/useToken";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ProjectDialog from "../dialog/ProjectDialog";
import DefaultDialog from "../dialog/DefaultDialog";
import SubscriptionDialog from "../dialog/SubscriptionDialog";
import FeedbackDialog from "../dialog/FeedbackDialog";
import toast, { Toaster } from "react-hot-toast";
import { basic_tips, protips_items } from "../utils/PromptTips";
import { isDesktop, isTablet } from "../utils/Helper";
import GenerateMobileComponent from "./mobile/GenerateMobileComponent";
import { useTranslation, Trans } from "react-i18next";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactGA from "react-ga4";

import {
  user_test_1,
  user_test_2,
  user_test_3,
  user_test_4,
  living_room_2,
  living_room_3,
  living_room_4,
  living_room_6,
  living_room_7,
} from "../../assets/images/studio";

function Studio() {
  const { t, i18n } = useTranslation();
  const generateHistoryScrollRef = useRef(null);
  const headerRef = useRef(null);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [freeMembershipMonthlyCount, setFreeMembershipMonthlyCount] =
    useState();
  const [freeMembershipMonthlyLimit, setFreeMembershipMonthlyLimit] =
    useState();
  const [freeMembershipDailyCount, setFreeMembershipDailyCount] = useState();
  const [freeMembershipDailyLimit, setFreeMembershipDailyLimit] = useState();
  const [myPromptsTotal, setMyPromptsTotal] = useState(0);
  const [myPromptsLimit, setMyPromptsLimit] = useState(0);
  const [isWelcomeDialogShow, setIsWelcomeDialogShow] = useState(false);
  const [isFirstWelcomeDialogShow, setIsFirstWelcomeDialogShow] =
    useState(false);
  const [isAllowBeta, setAllowBeta] = useState(false);
  const [isAllowJakomo, setIsAllowJakomo] = useState(false);

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const [protips, setProTips] = useState([]);

  const [isNoSignupPreviewShow, setIsNoSignupPreviewShow] = useState(false);

  const maxBasicTipsCount = 2;
  const getRandomBasicTips = (arr, n) => {
    let shuffled = arr.slice(),
      i = arr.length,
      temp,
      index;

    while (i--) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
    }
    return shuffled.slice(0, n);
  };
  const [basictips, setBasicTips] = useState(
    getRandomBasicTips(basic_tips, maxBasicTipsCount)
  );

  const [userProfileImage, setUserProfileImage] = useState(null);
  const [isFeedbackDialogShow, setIsFeedbackDialogShow] = useState(false);
  const [feedbackItem, setFeedbackItem] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState({
    status: "none",
  });
  const [userType, setUserType] = useState(-1);

  const { project_id } = useParams();
  const [proejctId, setProjectId] = useState(project_id);
  const [projectTitle, setProjectTitle] = useState(null);
  const [projectDescription, setProjectDescription] = useState(null);

  const [loadingPrompt, setLoadingPrompt] = useState(null);
  const [loadingImg, setLoadingImg] = useState(null);

  const [isFullImageShow, setIsFullImageShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [prompt, setPrompt] = useState("");
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [inputImage, setInputImage] = useState(null);
  const [inputBase64Image, setInputBase64Image] = useState(null);

  const [isGenerationLoading, setIsGenerationLoading] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);

  const [generatedHistory, setGeneratedHistory] = useState([]);
  const [savedGeneratedHistory, setSavedGeneratedHistory] = useState([]);

  // const [selectedStructureOptions, setSelectedStructureOptions] = useState(-1);
  const [selectedRoomOptions, setSelectedRoomOptions] = useState(2);
  const [additionOptionsList, setAdditionOptionsList] = useState([]);
  const [stringifiedAdditionOptionsList, setStringifiedAdditionOptionsList] =
    useState([]);

  // const [selectedCategory, setSelectedCategory] = useState(0);
  // const [selectedTheme, setSelectedTheme] = useState(0);

  const [mobileViewOption, setMobileViewOption] = useState(0);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isErrorMessageShow, setIsErrorMessageShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isGoToSubscriptionShow, setIsGoToSubscriptionShow] = useState(false);
  const [subscriptionErrorMEssage, setSubscriptionErrorMEssage] = useState("");

  const [isDialogCancelable, setIsDialogCancelable] = useState(true);
  const [DialogAction, setDialogAction] = useState("");
  const [isGenerateMobileOpen, setIsGenerateMobileOpen] = useState(true);

  const [isConfirmDeleteDialogShow, setIsConfirmDeleteDialogShow] =
    useState(false);
  const [deleteGenerationId, setDeleteGenerationId] = useState("");

  /* WebSocket */
  const WS_URL = process.env.REACT_APP_WS;

  const { sendJsonMessage, readyState } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log("WebSocket connection established.");
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    // API call when the page is started
    const createActivity = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API + "/v1/user/activity",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        console.log("ACTIVITY response:", data);
        // Process the data here
      } catch (error) {
        console.error("ACTIVITY error:", error);
      }
    };

    createActivity();
  }, []);

  // useEffect(() => {
  //   if (proejctId && freeMembershipDailyCount && freeMembershipMonthlyCount) {
  //     const isLoginOnce = JSON.parse(localStorage.getItem('isLoginOnce'));
  //     if (isLoginOnce === null || (isLoginOnce !== null && isLoginOnce.status === false)) {
  //       localStorage.setItem('isLoginOnce', JSON.stringify({
  //         status: true,
  //       }));
  //       const tempGenerateData = JSON.parse(localStorage.getItem('tempGenerateData'));
  //       if (tempGenerateData !== null) {
  //         setInputImage(tempGenerateData.inputImage);
  //         setIsImageLoaded(true);
  //         setInputBase64Image(tempGenerateData.init_images[0]);
  //         setLoadingImg(tempGenerateData.init_images[0]);
  //         setPrompt(tempGenerateData.prompt);
  //         setProjectId(tempGenerateData.id);
  //         setSelectedRoomOptions(tempGenerateData.selectedRoomOptions);
  //         setAdditionOptionsList(tempGenerateData.additional_prompt);
  //         // localStorage.removeItem('tempGenerateData');
  //         handleGenerateImage();
  //       }
  //     }
  //   }
  //   else {
  //     console.log(proejctId + ', ' + freeMembershipDailyCount + ', ' + freeMembershipMonthlyCount);
  //   }
  // }, [proejctId, freeMembershipDailyCount, freeMembershipMonthlyCount]);

  const { lastJsonMessage } = useWebSocket(WS_URL, {
    share: true,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [isProjectDialogShow, setIsProjectDialogShow] = useState(false);

  const defaultCfg = 16;
  const defaultDenoising = 0.7;
  const defaultSeed = -1;
  const defaultStep = 30;
  const maxWidth = 800;
  const maxHeight = 800;

  const [cfgScale, setCfg] = useState(defaultCfg);
  const [denoising, setDenoising] = useState(defaultDenoising);
  const [seed, setSeed] = useState(defaultSeed);
  const [generatingStep, setGeneratingStep] = useState(defaultStep);

  useEffect(() => {
    // alert('User Check');
    if (userId) {
      sendJsonMessage({
        userid: userId,
        type: "check_progress",
      });
    }
  }, [userId, readyState]);

  useEffect(() => {
    if (JSON.stringify(lastJsonMessage) != null) {
      console.log("lastMessage useEffect: " + JSON.stringify(lastJsonMessage));
      setIsGenerationLoading(lastJsonMessage?.in_progress);
      // if (lastJsonMessage?.userUUID)
      //   console.log(lastJsonMessage?.userUUID);
      // if (lastJsonMessage?.is_error) {
      //   setErrorMessage("Unexpected error occurs when generating.");
      //   setIsErrorMessageShow(true);
      // }
      // else {
      refreshUI();
      // }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    setStringifiedAdditionOptionsList(
      additionOptionsList.map((item) => JSON.stringify(item))
    );
  }, [additionOptionsList]);

  // useEffect(() => {
  //   if (selectedRoomOptions === -1) {
  //     setCfg(defaultCfg);
  //     setDenoising(defaultDenoising);
  //   }
  //   else {
  //     setCfg(cfg_scales[selectedRoomOptions - 1]);
  //     setDenoising(denoising_scales[selectedRoomOptions - 1]);
  //   }
  // }, [selectedStructureOptions, selectedRoomOptions]);

  useEffect(() => {
    if (token) {
      if (proejctId === undefined) {
        fetch(process.env.REACT_APP_API + "/v1/studio/project/all", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              return response.json().then((errorInfo) => {
                // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                setErrorMessage(errorInfo.message);
                setIsErrorMessageShow(true);
              });
            } else return response.json();
          })
          .then((data) => {
            if (data.projects !== undefined) {
              if (data.projects.length <= 0) {
                loadUser();
                // setIsProjectDialogShow(true);
                const hasWelcomed = JSON.parse(
                  localStorage.getItem("hasWelcomed")
                );
                if (
                  hasWelcomed === null ||
                  (hasWelcomed !== null && hasWelcomed.token !== token)
                ) {
                  setIsWelcomeDialogShow(true);
                  localStorage.setItem(
                    "hasWelcomed",
                    JSON.stringify({
                      status: true,
                      token: token,
                    })
                  );
                } else {
                  setIsProjectDialogShow(true);
                }
              } else {
                // navigate(`/profile`, { replace: true });
                setProjectId(data.projects[0]._id);
                navigate(`/studio/${data.projects[0]._id}`, { replace: true });
              }
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else if (proejctId) {
        loadProjectDetail();
        refreshUI();
      }
    }
  }, [proejctId]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const maxProTipsCount = 3;

  const getRandomProTips = (arr, n) => {
    let shuffled = arr.slice(),
      i = arr.length,
      temp,
      index;

    while (i--) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
    }
    return shuffled.slice(0, n);
  };

  useEffect(() => {
    if (protips.length === 0 && isSubscribed) {
      setTimeout(function () {
        setProTips(getRandomProTips(protips_items, maxProTipsCount));
      }, 1000);
    }
  }, [protips, isSubscribed]);

  useEffect(() => {
    if (!isSubscribed) {
      setProTips(getRandomProTips(protips_items, 3));
    }
  }, [isSubscribed]);

  const refreshUI = () => {
    loadUser();
  };

  useEffect(() => {
    if (basictips.length === 1) {
      setTimeout(function () {
        setBasicTips(getRandomBasicTips(basic_tips, maxBasicTipsCount));
      }, 1000);
    }
  }, [basictips]);

  const loadUser = () => {
    if (token) {
      fetch(process.env.REACT_APP_API + "/v1/profile", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorInfo) => {
              setErrorMessage(errorInfo.message);
              setIsErrorMessageShow(true);
            });
          } else return response.json();
        })
        .then((data) => {
          loadHistory();
          loadSavedHistory();
          const user = data.user;
          const free_membership = data.free_membership;
          setUserId(user._id);
          setUserName(user.fname + " " + user.lname);
          setUserEmail(user.email);
          setUserProfileImage(user.profile_img);
          setFreeMembershipMonthlyCount(() => {
            return free_membership.count;
          });
          setFreeMembershipMonthlyLimit(() => {
            return free_membership.limit;
          });
          setFreeMembershipDailyLimit(() => {
            return free_membership.daily_limit;
          });
          setFreeMembershipDailyCount(() => {
            return data.daily_count;
          });
          setUserType(user.role);
          setAllowBeta(data.allow_beta);
          setIsAllowJakomo(data.allow_jakomo);
          if (data.active_subscription) {
            setIsSubscribed(true);
            setActiveSubscription(data.active_subscription);
            setMyPromptsLimit(90);
          } else {
            setMyPromptsLimit(30);
          }
          // if (data !== undefined) {
          //   if (data.status) {
          //     loadHistory();
          //     loadSavedHistory();
          //     const user = data.user;
          //     const free_membership = data.free_membership;
          //     setUserId(user._id);
          //     setUserName(user.fname + " " + user.lname);
          //     setUserEmail(user.email);
          //     setUserProfileImage(user.profile_img);
          //     setFreeMembershipMonthlyCount(() => { return free_membership.count; });
          //     setFreeMembershipMonthlyLimit(() => { return free_membership.limit; });
          //     setFreeMembershipDailyLimit(() => { return free_membership.daily_limit; });
          //     setFreeMembershipDailyCount(() => { return data.daily_count; });
          //     setUserType(user.role);
          //     setAllowBeta(data.allow_beta);
          //     setIsAllowJakomo(data.allow_jakomo);
          //     if (data.active_subscription) {
          //       setIsSubscribed(true);
          //       setActiveSubscription(data.active_subscription);
          //       setMyPromptsLimit(90);
          //     }
          //     else {
          //       setMyPromptsLimit(30);
          //     }
          //   }
          //   else {
          //     setIsDialogCancelable(false);
          //     setDialogAction('logout')
          //     setErrorMessage(data.message);
          //     setIsErrorMessageShow(true);
          //   }
          // }
        })
        .catch((error) => {
          console.log(error.message);
          localStorage.removeItem("session");
          navigate("/login", { replace: true });
          setErrorMessage("Unexpected error occurs.");
          setIsErrorMessageShow(true);
        });
    }
  };

  useEffect(() => {
    // console.log('Checking free membership: ' + freeMembershipDailyCount + ', ' + freeMembershipMonthlyCount);
    if (
      freeMembershipDailyCount !== undefined &&
      freeMembershipMonthlyCount !== undefined
    ) {
      const isLoginOnce = true; //JSON.parse(localStorage.getItem('isLoginOnce'));
      if (
        isLoginOnce === null ||
        (isLoginOnce !== null && isLoginOnce.status === false)
      ) {
        const tempGenerateImage = JSON.parse(
          localStorage.getItem("tempGenerateImage")
        );
        const tempGenerateBaseImage = JSON.parse(
          localStorage.getItem("tempGenerateBaseImage")
        );
        const tempGenerateData = JSON.parse(
          localStorage.getItem("tempGenerateData")
        );
        if (
          tempGenerateImage !== null &&
          tempGenerateBaseImage !== null &&
          tempGenerateData !== null
        ) {
          setInputImage(tempGenerateImage.inputImage);
          setIsImageLoaded(true);
          setInputBase64Image(tempGenerateBaseImage.init_images[0]);
          setLoadingImg(tempGenerateBaseImage.init_images[0]);
          setPrompt(tempGenerateData.prompt);
          setProjectId(tempGenerateData.id);
          setSelectedRoomOptions(tempGenerateData.selectedRoomOptions);
          setAdditionOptionsList(tempGenerateData.additional_prompt);
          localStorage.removeItem("tempGenerateData");
          // handleGenerateImage();
        }
      }
    }
  }, [freeMembershipDailyCount, freeMembershipMonthlyCount]);

  // useEffect(() => {
  //   if (inputBase64Image !== null && prompt !== undefined && proejctId !== undefined && selectedRoomOptions !== undefined && additionOptionsList !== undefined) {
  //     console.log('preparing to generate image for no login users');
  //     if (!isGenerationLoading) {
  //       const isLoginOnce = JSON.parse(localStorage.getItem('isLoginOnce'));
  //       if (isLoginOnce === null || (isLoginOnce !== null && isLoginOnce.status === false)) {
  //         localStorage.setItem('isLoginOnce', JSON.stringify({
  //           status: true,
  //         }));
  //         handleGenerateImage();
  //       }
  //     }
  //   }
  // }, [inputBase64Image, prompt, proejctId, selectedRoomOptions, additionOptionsList]);

  const loadHistory = () => {
    if (proejctId !== null && proejctId !== undefined) {
      fetch(
        process.env.REACT_APP_API +
          "/v1/studio/project/" +
          proejctId +
          "/history",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify({ id: proejctId })
        }
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorInfo) => {
              // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
              // setErrorMessage(errorInfo.message);
              // setIsErrorMessageShow(true);
            });
          } else return response.json();
        })
        .then((data) => {
          if (data !== undefined) {
            setGeneratedHistory(data.history);
            if (data.history.length === 0) setIsGenerateMobileOpen(true);
          }
        })
        .catch((error) => {
          // console.log(error.message);
          // setErrorMessage("Unexpected error occurs.");
          // setIsErrorMessageShow(true);
        });
    }
  };

  const loadSavedHistory = () => {
    if (proejctId !== null && proejctId !== undefined) {
      fetch(
        process.env.REACT_APP_API + `/v1/studio/project/${proejctId}/history`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ saved: true }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorInfo) => {
              // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
              setErrorMessage(errorInfo.message);
              setIsErrorMessageShow(true);
            });
          } else return response.json();
        })
        .then((data) => {
          if (data !== undefined) {
            setSavedGeneratedHistory(data.history.slice(0).reverse());
            setMyPromptsTotal(data.history?.length);
          }
        })
        .catch((error) => {
          console.log(error.message);
          setErrorMessage("Unexpected error occurs.");
          setIsErrorMessageShow(true);
        });
    }
  };

  const loadProjectDetail = () => {
    if (proejctId !== undefined) {
      fetch(process.env.REACT_APP_API + "/v1/studio/project/" + proejctId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorInfo) => {
              // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
              if (errorInfo.message === "Project not found") {
                navigate(`/profile`, { replace: true });
              }
            });
          } else return response.json();
        })
        .then((data) => {
          if (data !== undefined) {
            setProjectTitle(data.project.title);
            setProjectDescription(data.project.title);

            const hasWelcomed = JSON.parse(localStorage.getItem("hasWelcomed"));
            if (
              hasWelcomed === null ||
              (hasWelcomed !== null && hasWelcomed.token !== token)
            ) {
              setIsFirstWelcomeDialogShow(true);
              localStorage.setItem(
                "hasWelcomed",
                JSON.stringify({
                  status: true,
                  token: token,
                })
              );
            }
          }
        })
        .catch((error) => {
          console.log(error.message);
          setErrorMessage("Unexpected error occurs.");
          setIsErrorMessageShow(true);
        });
    }
  };

  const createProject = (credentials) => {
    fetch(process.env.REACT_APP_API + "/v1/studio/project", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(credentials),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorInfo) => {
            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
            setErrorMessage(errorInfo.message);
            setIsErrorMessageShow(true);
          });
        } else return response.json();
      })
      .then((data) => {
        setProjectId(data.project._id);
        navigate(`/studio/${data.project._id}`, { replace: true });
        toast.success(t("project_created"));
      })
      .catch((error) => {
        console.log(error.message);
        setErrorMessage("Unexpected error occurs.");
        setIsErrorMessageShow(true);
      });
  };

  const { token, setToken } = useToken();

  useEffect(() => {
    const isLoginOnce = JSON.parse(localStorage.getItem("isLoginOnce"));
    if (
      !token &
      (isLoginOnce === null ||
        (isLoginOnce !== null && isLoginOnce.status === false))
    ) {
      setIsNoSignupPreviewShow(true);

      ReactGA.initialize("G-EVSWTBYQ7M");
      ReactGA.send({ hitType: "pageview", page: "/no_login_studio" });
      setIsFirstWelcomeDialogShow(true);
    }
  }, []);

  if (!token) {
    const isLoginOnce = JSON.parse(localStorage.getItem("isLoginOnce"));
    if (
      isLoginOnce === null ||
      (isLoginOnce !== null && isLoginOnce.status === false)
    ) {
    } else return <LoginForm setToken={setToken} />;
  }

  const addGeneratedHistory = (imgs, prompt, timestamp) => {
    const newGeneratedItem = {
      image_url: imgs,
      prompt: prompt,
      timestamp: timestamp,
      feedbacks: [
        { index: 0, feedback: 0 },
        { index: 1, feedback: 0 },
        { index: 2, feedback: 0 },
        { index: 3, feedback: 0 },
      ],
    };
    setGeneratedHistory((prevItems) => [newGeneratedItem, ...prevItems]);

    // Mock UI
    // setInputImage('');
    // setIsImageLoaded(false);
    // setInputBase64Image('');
  };

  const onItemSelect = () => {
    setIsFullImageShow(true);
  };

  const onBackgroundClick = () => {
    setIsFullImageShow(false);
  };

  const resetAdvancedSettings = () => {
    setCfg(defaultCfg);
    setDenoising(defaultDenoising);
    setSeed(defaultSeed);
  };

  const getRandomPrompt = () => {
    const promptList = [
      "Images of interior spaces designed in a modern and simple style, with only sofa and lug changed from existing images, the sofa is a modern style with clean lines and neutral colors, and the lug is a modern design with a simple pattern and soft texture",
      "Space image featuring Scandi Navian-style interiors, sofa typical of Nordic design, clean, simple lines, modern feel using natural wood materials and light colors, rugs have soft texture and simple yet sophisticated patterns",
      "Minimalist style change of sofa and lug alone, sofa in modular form or minimalist design, sophisticated color as a point and concise line, lug adds a modern and neat vibe to the space as a whole with a simple design",
    ];

    return promptList[Math.floor(Math.random() * promptList.length)];
  };

  const incrementValue = (type) => {
    if (type === "cfg") {
      const newValue = Math.min(31, (Number(cfgScale) || 0) + 0.5);
      setCfg(newValue.toString());
    }
    if (type === "denoise") {
      const newValue = Math.min(1, (Number(denoising) || 0) + 0.05);
      setDenoising(parseFloat(newValue).toFixed(2).toString());
    }
    if (type === "step") {
      const newValue = Math.min(100, (Number(generatingStep) || 0) + 1);
      setGeneratingStep(newValue.toString());
    }
  };

  const decrementValue = (type) => {
    if (type === "cfg") {
      const newValue = Math.max(1, (Number(cfgScale) || 0) - 0.5);
      setCfg(newValue.toString());
    }
    if (type === "denoise") {
      const newValue = Math.max(0, (Number(denoising) || 0) - 0.05);
      setDenoising(parseFloat(newValue).toFixed(2).toString());
    }
    if (type === "step") {
      const newValue = Math.max(1, (Number(generatingStep) || 0) - 1);
      setGeneratingStep(newValue.toString());
    }
  };

  const handleCfgChange = (event) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= 31) {
      setCfg(inputValue);
    }
  };

  const handleCfgFocusOut = () => {
    if (cfgScale === 0) {
      setCfg(1);
    }
  };

  const handleDenoiseChange = (event) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= 1) {
      setDenoising(inputValue);
    }
  };

  const handleDenoiseFocusOut = () => {
    if (denoising === 0) {
      setDenoising(0);
    }
  };

  const handleStepChange = (event) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= 100) {
      setGeneratingStep(inputValue);
    }
  };

  const handleStepFocusOut = () => {
    if (generatingStep === 0) {
      setGeneratingStep(1);
    }
  };

  const handleSeedChange = (event) => {
    const inputValue = event.target.value;

    if (
      inputValue === "-" ||
      (!isNaN(inputValue) && inputValue >= -10 && inputValue <= 100000)
    ) {
      setSeed(inputValue);
    }
  };

  const handleSeedFocusOut = () => {
    if (seed <= -1 || seed === "") {
      setSeed(-1);
    }
  };

  const handleImageUpload = (files) => {
    try {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = () => {
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }

            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            setInputImage(reader.result);
            setIsImageLoaded(true);
            const base64String = reader.result
              .replace("data:", "")
              .replace(/^.*?,/, "");
            // setInputBase64Image(base64String);
            // setLoadingImg(base64String);

            // Get resized image base64 string
            // const resizedBase64 = canvas.toDataURL('image/jpeg');
            let resizedBase64 = canvas.toDataURL("image/jpeg");
            resizedBase64 = resizedBase64.replace(
              /^data:image\/jpeg;base64,/,
              ""
            );
            setInputBase64Image(resizedBase64);
            setLoadingImg(resizedBase64);
            console.log("Resized Base64: " + resizedBase64);
          };
        };
        reader.readAsDataURL(file);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleQuickImageBase64 = (image) => {
    try {
      fetch(image)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = function () {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
              let width = img.width;
              let height = img.height;

              if (width > height) {
                if (width > maxWidth) {
                  height *= maxWidth / width;
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width *= maxHeight / height;
                  height = maxHeight;
                }
              }

              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);

              setInputImage(reader.result);
              setIsImageLoaded(true);
              const base64String = reader.result
                .replace("data:", "")
                .replace(/^.+,/, "");
              // Get resized image base64 string
              let resizedBase64 = canvas.toDataURL("image/jpeg");
              resizedBase64 = resizedBase64.replace(
                /^data:image\/jpeg;base64,/,
                ""
              );
              setInputBase64Image(resizedBase64);
              setLoadingImg(resizedBase64);
            };
          };
          reader.readAsDataURL(blob);
        });
      if (!prompt) {
        onImageGenerateItemSelect({
          option: "theme",
          value: 0,
          keyword: "미니멀리즘",
        });
        // setPrompt(getRandomPrompt());
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const handleImageUpload = (files) => {
  //   const file = files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setInputImage(reader.result);
  //       setIsImageLoaded(true);
  //       const base64String = reader.result.replace("data:", "")
  //         .replace(/^.+,/, "");
  //       setInputBase64Image(base64String);
  //       setLoadingImg(base64String);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // const handleQuickImageBase64 = (image) => {
  //   try {
  //     fetch(image)
  //       .then(response => response.blob())
  //       .then(blob => {
  //         const reader = new FileReader();
  //         reader.onloadend = function () {
  //           setInputImage(reader.result);
  //           setIsImageLoaded(true);
  //           const base64String = reader.result.replace("data:", "")
  //             .replace(/^.+,/, "");
  //           setInputBase64Image(base64String);
  //           setLoadingImg(base64String);
  //         }
  //         reader.readAsDataURL(blob);
  //       });
  //   }
  //   catch (e) {
  //     console.log(e);
  //   }
  // }

  const handleRemoveImage = () => {
    setInputImage("");
    setIsImageLoaded(false);
    setInputBase64Image("");
  };

  const savePrompt = (id, status) => {
    const toast_loading = status ? "Adding..." : "Removing...";
    const toast_message = status
      ? "Added to My Prompts!"
      : "Removed from My Prompts!";
    toast.promise(
      fetch(process.env.REACT_APP_API + "/v1/studio/history/" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ status: status }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorInfo) => {
              // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
              setErrorMessage(errorInfo.message);
              setIsErrorMessageShow(true);
            });
          } else return response.json();
        })
        .then((data) => {
          refreshUI();
        })
        .catch((error) => {
          console.log("Error saving prompt: ", error);
          setErrorMessage("Unexpected error occurs when saving prompt.");
          setIsErrorMessageShow(true);
        }),
      {
        loading: toast_loading,
        success: <b>{toast_message}</b>,
        error: <b>Unable to update My Prompts.</b>,
      }
    );
  };

  const handleGenerateImage = () => {
    // setErrorMessage(
    //   "2024-06-12(수) 오전 10시부터 11시가지 시스템 업데이트 중입니다. 그 후에 다시 시도해 주세요."
    // );
    // setIsErrorMessageShow(true);
    // return;
    if (isNoSignupPreviewShow) {
      // localStorage.setItem('tempGenerateImage', JSON.stringify({
      //   inputImage: inputImage
      // }));
      // localStorage.setItem('tempGenerateBaseImage', JSON.stringify({
      //   init_images: [inputBase64Image]
      // }));
      // localStorage.setItem('tempGenerateData', JSON.stringify({
      //   prompt: prompt,
      //   id: proejctId,
      //   selectedRoomOptions: selectedRoomOptions,
      //   additional_prompt: additionOptionsList,
      // }));

      // ReactGA.initialize("G-EVSWTBYQ7M");
      // ReactGA.event({
      //   category: "User Engagement",
      //   action: "No Login Generate",
      // });

      navigate("/login");
      return;
    }
    if (!isSubscribed) {
      if (freeMembershipDailyLimit - freeMembershipDailyCount <= 0) {
        setSubscriptionErrorMEssage(
          "No remainimg generation left for today. Please try again tomorrow."
        );
        setIsGoToSubscriptionShow(true);
        return;
      }
      if (freeMembershipMonthlyLimit - freeMembershipDailyCount <= 0) {
        setSubscriptionErrorMEssage(
          "No remainimg generation left for this month. Please try again next month."
        );
        setIsGoToSubscriptionShow(true);
        return;
      }
    }
    setLoadingPrompt(prompt);
    // setIsGenerationLoading(true);

    const currentDate = new Date();
    const timestamp = currentDate.toUTCString();

    const payload = {
      enable_hr: true,
      init_images: [inputBase64Image],
      prompt: prompt,
      id: proejctId,
      selectedRoomOptions: selectedRoomOptions,
      additional_prompt: additionOptionsList,
      model: 0,
    };

    fetch(process.env.REACT_APP_API + "/v1/studio/generate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorInfo) => {
            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
            if (errorInfo.message === "You are currently generating an image") {
              setIsGenerationLoading(true);
              setIsGenerateMobileOpen(false);
            } else {
              setErrorMessage(errorInfo.message);
              setIsErrorMessageShow(true);
            }
            throw new Error(errorInfo.message);
          });
        } else return response.json();
      })
      .then((data) => {
        console.log(JSON.stringify(data));
        // data.data.images[0] = loadingImg;
        // addGeneratedHistory([loadingImg].concat(data.data.images), prompt, timestamp);
        // setIsGenerationLoading(false);

        // refreshUI();
        setIsGenerationLoading(true);
        setIsGenerateMobileOpen(false);
        if (generateHistoryScrollRef.current) {
          generateHistoryScrollRef.current.scrollTop = 0;
        }

        if (headerRef.current) {
          headerRef.current.scrollIntoView({ behavior: "smooth" });
        }
      })
      .catch((error) => {
        // setIsGenerationLoading(false);
        console.log("Error fetching generate api: ", error.message);

        if (error.message === "You are currently generating an image") {
          console.log("You are currently generating an image error skipped");
          return;
        }
        setTimeout(function () {
          setErrorMessage(error.message);
          setIsErrorMessageShow(true);
        }, 500);
      });
  };

  const deleteGeneration = (id) => {
    const payload = {
      id: id,
    };

    fetch(process.env.REACT_APP_API + "/v1/studio/history/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorInfo) => {
            console.log(errorInfo);
            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
            setErrorMessage(errorInfo.message);
            setIsErrorMessageShow(true);
            return new Error(errorInfo.message);
          });
        } else return response.json();
      })
      .then((data) => {
        console.log(JSON.stringify(data));
        if (data.message === "History deleted successfully") {
          refreshUI();
        }
      })
      .catch((error) => {
        // setIsGenerationLoading(false);
        console.log("Error fetching data: ", error);
        setTimeout(function () {
          setErrorMessage("Unexpected error occurs when deleting.");
          setIsErrorMessageShow(true);
        }, 500);
      });
  };

  // const handleRefreshClick = useCallback(() => {
  //   setProTips(getRandomProTips(protips_items, maxProTipsCount));
  // }, [protips_items]);

  const handleProTips = (id) => {
    setProTips((prevKeywords) => prevKeywords.filter((k) => k._id !== id));
  };

  const onPropTipsItemSelected = (item, prefix) => {
    const time = Date.now();
    setPrompt(item.description);

    // const box = document.getElementById(prefix + String(item._id));
    // const destination = document.getElementById("promptInput");
    // destination.focus();
    // destination.select();

    // const boxRect = box.getBoundingClientRect();
    // const destinationRect = destination.getBoundingClientRect();

    // const moveX = destinationRect.left - boxRect.left;
    // const moveY = destinationRect.top - boxRect.top;

    // box.style.position = 'absolute';
    // box.style.transform = `translate(${moveX}px, ${moveY}px) scale(0.5)`;
    // box.style.opacity = 0;
    // box.style.transition = 'transform 1s, opacity 1s';

    // box.addEventListener("transitionend", () => {
    //   // destination.appendChild(box);
    //   // setTimeout(function () {
    //   //   box.style.animation = "";
    //   //   box.style.transition = '';
    //   //   box.style.transform = 'none';
    //   //   box.style.opacity = 0;
    //   //   // box.style.position = 'relative';
    //   // }, 0);

    //   box.style.animation = "";
    //   box.style.transition = '';
    //   box.style.transform = 'none';
    //   box.style.opacity = 0;
    //   handleProTips(item._id);
    // });

    // const prompt_toast = document.getElementById('prompt-keyword-container-toast');
    // prompt_toast.style.opacity = 1;
    // prompt_toast.style.transition = 'opacity 2s';

    // prompt_toast.addEventListener("transitionend", () => {
    //   prompt_toast.style.opacity = 0;
    // });

    // const prompt_keywords = document.getElementById('prompt-keyword-container');
    // prompt_keywords.style.opacity = 0.5;
    // prompt_keywords.style.filter = "blur(5px)";
    // prompt_keywords.style.transition = 'opacity 2s, filter 2s';

    // prompt_keywords.addEventListener("transitionend", () => {
    //   prompt_keywords.style.opacity = 1;
    //   prompt_keywords.style.filter = "";
    // });
  };

  const handleBasicTips = (id) => {
    setBasicTips((prevKeywords) => prevKeywords.filter((k) => k._id !== id));
  };

  const onBasicTipsItemSelected = (item, prefix) => {
    const time = Date.now();
    setPrompt(item.description);

    // const box = document.getElementById(prefix + String(item._id));
    // const destination = document.getElementById("promptInput");
    // destination.focus();
    // destination.select();

    // const boxRect = box.getBoundingClientRect();
    // const destinationRect = destination.getBoundingClientRect();

    // const moveX = destinationRect.left - boxRect.left;
    // const moveY = destinationRect.top - boxRect.top;

    // box.style.position = 'absolute';
    // box.style.transform = `translate(${moveX}px, ${moveY}px) scale(0.5)`;
    // box.style.opacity = 0;
    // box.style.transition = 'transform 1s, opacity 1s';

    // box.addEventListener("transitionend", () => {
    //   // destination.appendChild(box);
    //   // setTimeout(function () {
    //   //   box.style.animation = "";
    //   //   box.style.transition = '';
    //   //   box.style.transform = 'none';
    //   //   box.style.opacity = 0;
    //   //   // box.style.position = 'relative';
    //   // }, 0);

    //   box.style.animation = "";
    //   box.style.transition = '';
    //   box.style.transform = 'none';
    //   box.style.opacity = 0;
    //   console.log('animation ended');
    //   handleBasicTips(item._id);
    // });

    // const prompt_toast = document.getElementById('prompt-keyword-container-toast');
    // prompt_toast.style.opacity = 1;
    // prompt_toast.style.transition = 'opacity 2s';

    // prompt_toast.addEventListener("transitionend", () => {
    //   prompt_toast.style.opacity = 0;
    // });

    // const prompt_keywords = document.getElementById('prompt-keyword-container');
    // prompt_keywords.style.opacity = 0.5;
    // prompt_keywords.style.filter = "blur(5px)";
    // prompt_keywords.style.transition = 'opacity 2s, filter 2s';

    // prompt_keywords.addEventListener("transitionend", () => {
    //   prompt_keywords.style.opacity = 1;
    //   prompt_keywords.style.filter = "";
    // });
  };

  const onMyPromptsItemSelected = (item, prefix) => {
    setPrompt(item.prompt);
  };

  const onImageGenerateItemSelect = (item) => {
    if (!stringifiedAdditionOptionsList.includes(JSON.stringify(item))) {
      setAdditionOptionsList([...additionOptionsList, item]);
    } else {
      const newAdditionOptionsList = additionOptionsList.filter(
        (option) => option.keyword !== item.keyword
      );
      setAdditionOptionsList(newAdditionOptionsList);
    }
  };

  const interiorStylesEn = [
    "Minimalism",
    "Mid-century modern",
    "Bohemian",
    "Scandinavian",
    "Industrial",
    "Traditional",
    "Art Deco",
    "Farmhouse",
    "Oriental Style",
    "Mediterranean Style",
  ];

  const roomTypesEn = [
    "Bedroom",
    "Living Room",
    "Kitchen",
    "Study Room",
    "Dining Room",
    "Bathroom",
    "Office",
    "Cafe",
    "Restaurant",
  ];

  const interiorStylesKr = [
    "미니멀리즘",
    "미드센추리 모던",
    "보헤미안",
    "스칸디나비안",
    "인더스트리얼",
    "엔틱",
    "아르데코",
    "팜하우스",
    "오리엔탈",
    "지중해 스타일",
  ];

  const roomTypesKr = [
    "침실",
    "거실",
    "주방",
    "서재",
    "다이닝룸",
    "욕실",
    "사무실",
    "카페",
    "레스토랑",
  ];

  const interiorStyles =
    i18n.language === "kr" ? interiorStylesKr : interiorStylesEn;

  const roomTypes = i18n.language === "kr" ? roomTypesKr : roomTypesEn;

  return (
    <div className="StudioApp">
      <Header
        headerRef={headerRef}
        userType={userType}
        userName={userName}
        userImg={userProfileImage}
        token={token}
        toggleDropdown={toggleDropdown}
        project={projectTitle}
      />

      {true && (
        <div className="StudioApp content">
          <div className="holy-grail-grid">
            <section className="left-sidebar studio-scroll">
              {(isDesktop() || isTablet()) && (
                <div className="left-sidebar-content">
                  <ImageUploader
                    onImageUpload={handleImageUpload}
                    loadedImg={inputImage}
                    removeImg={handleRemoveImage}
                  />
                  {isAllowJakomo && !isImageLoaded ? (
                    <QuickSelectImage
                      img1={living_room_7}
                      img2={living_room_2}
                      img3={living_room_6}
                      img4={living_room_4}
                      handleQuickImageBase64={handleQuickImageBase64}
                    />
                  ) : (
                    <div></div>
                  )}
                  {!isAllowJakomo && !isImageLoaded ? (
                    <QuickSelectImage
                      img1={user_test_1}
                      img2={user_test_2}
                      img3={user_test_3}
                      img4={user_test_4}
                      handleQuickImageBase64={handleQuickImageBase64}
                    />
                  ) : (
                    <div></div>
                  )}

                  <PromptInput
                    value={prompt}
                    additionOptionsList={additionOptionsList}
                    setAdditionOptionsList={setAdditionOptionsList}
                    onChange={(e) => setPrompt(e.target.value)}
                    onClick={handleGenerateImage}
                    onUpdate={(value) => setPrompt(value)}
                    isImageLoaded={isImageLoaded}
                    isImageLoading={isGenerationLoading}
                  />
                  <ImageGenerationOptions
                    selectedRoomOptions={selectedRoomOptions}
                    setSelectedRoomOptions={setSelectedRoomOptions}
                    roomTypes={roomTypes}
                    interiorStyles={interiorStyles}
                    onItemSelected={onImageGenerateItemSelect}
                    stringifiedAdditionOptionsList={
                      stringifiedAdditionOptionsList
                    }
                    isAllowBeta={isAllowBeta}
                    isAllowJakomo={isAllowJakomo}
                  />

                  <div
                    className="generate-button-view"
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    {/* {
                      !isSubscribed &&
                      <p style={{
                        marginTop: "0px",
                        marginBottom: "5px",
                        fontSize: "0.8em",
                      }}><Trans>Daily Limit</Trans>: {freeMembershipDailyCount} / {freeMembershipDailyLimit}<br />
                        <Trans>Monthly Limit</Trans>: {freeMembershipMonthlyCount} / {freeMembershipMonthlyLimit}</p>
                    } */}
                    {(prompt || additionOptionsList.length > 0) &&
                    isImageLoaded &&
                    !isGenerationLoading ? (
                      <button
                        onClick={handleGenerateImage}
                        className="generate-button"
                        data-tooltip-id="freeGenerationTooltip"
                      >
                        <Trans>Generate</Trans>
                      </button>
                    ) : (
                      <button
                        disabled
                        className="generate-button"
                        data-tooltip-id="freeGenerationTooltip"
                      >
                        <Trans>Generate</Trans>
                      </button>
                    )}
                  </div>

                  {!isDesktop() && (
                    <div
                      className="project-selection-menu"
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "33px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <p
                        className={
                          mobileViewOption === 0 ? "items selected" : "items"
                        }
                        style={{
                          fontSize: "0.75em",
                        }}
                        onClick={() => {
                          setMobileViewOption(0);
                        }}
                      >
                        <Trans>My History</Trans>
                      </p>
                      <p
                        className={
                          mobileViewOption === 1 ? "items selected" : "items"
                        }
                        style={{
                          fontSize: "0.7em",
                        }}
                        onClick={() => {
                          setMobileViewOption(1);
                        }}
                      >
                        <Trans>Prompt Packages</Trans>
                      </p>
                      <p
                        className={
                          mobileViewOption === 2 ? "items selected" : "items"
                        }
                        style={{
                          fontSize: "0.7em",
                        }}
                        onClick={() => {
                          setMobileViewOption(2);
                        }}
                      >
                        <Trans>My Prompts</Trans>
                      </p>
                    </div>
                  )}

                  <ReactTooltip
                    id="standard-tooltip"
                    place="bottom"
                    content={t("standardTooltip")}
                  />
                  <ReactTooltip
                    id="creative-tooltip"
                    place="bottom"
                    content={t("creativeTooltip")}
                  />
                  {!isSubscribed && (
                    <ReactTooltip
                      id="freeGenerationTooltip"
                      place="bottom"
                      content={t("freeGenerateTooltip")}
                    />
                  )}
                </div>
              )}
            </section>
            {true && (
              <aside className="right-sidebar studio-scroll">
                {userType === 1 && (
                  <div>
                    {(isDesktop() ||
                      (!isDesktop() && mobileViewOption === 1)) &&
                      !isSubscribed && (
                        <div className="basictips br">
                          {/* 🔥🚀💡⚡ | Copy & Paste */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              position: "relative",
                            }}
                          >
                            <h4
                              className=""
                              style={{
                                fontWeight: "bold",
                                marginBottom: "0",
                              }}
                            >
                              <Trans>💡 Basic Tips</Trans>
                            </h4>
                            <RefreshCw
                              className="not-selectable"
                              size={16}
                              color="black"
                              style={{
                                cursor: "pointer",
                                right: "0",
                                marginBottom: "4px",
                                position: "absolute",
                              }}
                              onClick={() =>
                                setBasicTips(
                                  getRandomBasicTips(
                                    basic_tips,
                                    maxBasicTipsCount
                                  )
                                )
                              }
                            />
                          </div>
                          <p className="text-md lg:text-xl mb-5">
                            <Trans>
                              Experience various spatial styles using Basic
                              Tips.
                            </Trans>
                          </p>
                          <PromptGuideComponent
                            items={basictips}
                            onItemSelected={onBasicTipsItemSelected}
                            prefix="basictips_"
                          />
                        </div>
                      )}

                    {(isDesktop() ||
                      (!isDesktop() && mobileViewOption === 1)) &&
                      isSubscribed && (
                        <div className="protips br">
                          {/* 🔥🚀⚡ | Copy & Paste */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              position: "relative",
                            }}
                          >
                            <h4
                              className=""
                              style={{
                                fontWeight: "bold",
                                marginBottom: "0",
                              }}
                            >
                              <Trans>🔥 Pro Tips</Trans>
                            </h4>
                            <RefreshCw
                              className="not-selectable"
                              size={16}
                              color="black"
                              style={{
                                cursor: "pointer",
                                right: "0",
                                marginBottom: "4px",
                                position: "absolute",
                              }}
                              onClick={() =>
                                setProTips(
                                  getRandomProTips(
                                    protips_items,
                                    maxProTipsCount
                                  )
                                )
                              }
                            />
                          </div>

                          <p className="text-md lg:text-xl mb-5">
                            <Trans>
                              Create using best tips inspired by world's most
                              popular and trending creative ideas.
                            </Trans>
                          </p>
                          <PromptGuideComponent
                            items={protips}
                            onItemSelected={onPropTipsItemSelected}
                            prefix="protips_"
                          />
                        </div>
                      )}

                    {(isDesktop() ||
                      (!isDesktop() && mobileViewOption === 1)) &&
                      !isSubscribed && (
                        <div className="protips br">
                          {/* 🔥🚀⚡ | Copy & Paste */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              position: "relative",
                            }}
                          >
                            <h4
                              className=""
                              style={{
                                fontWeight: "bold",
                                marginBottom: "0",
                              }}
                            >
                              <Trans>🔥 Pro Tips</Trans>
                            </h4>
                          </div>

                          <p className="text-md lg:text-xl mb-5">
                            <Trans>
                              Create using best tips inspired by world's most
                              popular and trending creative ideas.
                            </Trans>
                            {/* 세계적으로 인기있고 트렌디한 창작 작품에서 영감을 받아 나의 공간을 연출해보는 베스트 팁입니다. */}
                          </p>
                          <PromptGuideComponent
                            items={protips}
                            onItemSelected={onPropTipsItemSelected}
                            prefix="protips_"
                            preview={true}
                          />
                        </div>
                      )}

                    {(isDesktop() ||
                      (!isDesktop() && mobileViewOption === 1)) &&
                      isSubscribed && (
                        <div className="basictips br">
                          {/* 🔥🚀💡⚡ | Copy & Paste */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "16px",
                              position: "relative",
                            }}
                          >
                            <h4
                              className=""
                              style={{
                                fontWeight: "bold",
                                marginBottom: "0",
                              }}
                            >
                              <Trans>💡 Basic Tips</Trans>
                            </h4>
                            <RefreshCw
                              className="not-selectable"
                              size={16}
                              color="black"
                              style={{
                                cursor: "pointer",
                                right: "0",
                                marginBottom: "4px",
                                position: "absolute",
                              }}
                              onClick={() =>
                                setBasicTips(
                                  getRandomBasicTips(
                                    basic_tips,
                                    maxBasicTipsCount
                                  )
                                )
                              }
                            />
                          </div>
                          <p className="text-md lg:text-xl mb-5">
                            <Trans>
                              Experience various spatial styles using Basic
                              Tips.
                            </Trans>
                          </p>
                          <PromptGuideComponent
                            items={basictips}
                            onItemSelected={onBasicTipsItemSelected}
                            prefix="basictips_"
                          />
                        </div>
                      )}

                    {(isDesktop() ||
                      (!isDesktop() && mobileViewOption === 2)) && (
                      <div className="myprompts br">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <svg
                            style={{}}
                            xmlns="http://www.w3.org/2000/svg"
                            height="1.3em"
                            viewBox="0 0 512 512"
                          >
                            <path d="M0 96C0 60.7 28.7 32 64 32H196.1c19.1 0 37.4 7.6 50.9 21.1L289.9 96H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16H286.6c-10.6 0-20.8-4.2-28.3-11.7L213.1 87c-4.5-4.5-10.6-7-17-7H64z" />
                          </svg>

                          <h4
                            className=""
                            style={{
                              fontWeight: "bold",
                              marginLeft: "7px",
                              marginTop: "0px",
                            }}
                          >
                            <Trans>My Prompts</Trans> ({myPromptsTotal}/
                            {myPromptsLimit})
                          </h4>
                        </div>
                        <p className="text-md lg:text-xl mb-5">
                          <Trans>My saved prompts appear here.</Trans>
                        </p>
                        <MyPromptComponent
                          items={savedGeneratedHistory}
                          onItemSelected={onMyPromptsItemSelected}
                          onItemDeleted={savePrompt}
                        />
                      </div>
                    )}
                  </div>
                )}{" "}
                {userType === 0 && (
                  <div className="advanced_settings">
                    <h4 className="" style={{ fontWeight: "bold" }}>
                      Advanced Settings
                    </h4>
                    {/* <RefreshCw className='btn increment-btn' color='black' onClick={() => resetAdvancedSettings()} /> */}
                    <p className="text-md lg:text-xl mb-5">CFG Scale</p>
                    <div className="advanced-input-container">
                      <input
                        type="text"
                        className=""
                        value={cfgScale}
                        onBlur={handleCfgFocusOut}
                        onChange={handleCfgChange}
                        placeholder="CFG Scale"
                      />
                      <div>
                        <PlusCircle
                          className="btn increment-btn"
                          color="black"
                          onClick={() => incrementValue("cfg")}
                        />
                        <MinusCircle
                          className="btn decrement-btn"
                          color="black"
                          onClick={() => decrementValue("cfg")}
                        />
                      </div>
                    </div>
                    <p className="text-md lg:text-xl mb-5">
                      Denoising Strength
                    </p>
                    <div className="advanced-input-container">
                      <input
                        type="text"
                        className=""
                        value={denoising}
                        onBlur={handleDenoiseFocusOut}
                        onChange={handleDenoiseChange}
                        placeholder="Denoising Strength"
                      />
                      <div>
                        <PlusCircle
                          className="btn increment-btn"
                          color="black"
                          onClick={() => incrementValue("denoise")}
                        />
                        <MinusCircle
                          className="btn decrement-btn"
                          color="black"
                          onClick={() => decrementValue("denoise")}
                        />
                      </div>
                    </div>
                    <p className="text-md lg:text-xl mb-5">Generating Step</p>
                    <div className="advanced-input-container">
                      <input
                        type="text"
                        className=""
                        value={generatingStep}
                        onBlur={handleStepFocusOut}
                        onChange={handleStepChange}
                        placeholder="Generating Step"
                      />
                      <div>
                        <PlusCircle
                          className="btn increment-btn"
                          color="black"
                          onClick={() => incrementValue("step")}
                        />
                        <MinusCircle
                          className="btn decrement-btn"
                          color="black"
                          onClick={() => decrementValue("step")}
                        />
                      </div>
                    </div>
                    <p className="text-md lg:text-xl mb-5">Seed</p>
                    <div className="advanced-input-container">
                      <input
                        type="text"
                        className=""
                        value={seed}
                        onBlur={handleSeedFocusOut}
                        onChange={handleSeedChange}
                        placeholder="Seed"
                      />
                    </div>
                  </div>
                )}
              </aside>
            )}
            <main
              className="main-content studio-scroll"
              ref={generateHistoryScrollRef}
              onScroll={() => {
                setIsGenerateMobileOpen(false);
              }}
              onTouchStart={() => {
                setIsGenerateMobileOpen(false);
              }}
            >
              {(isDesktop() || (!isDesktop() && mobileViewOption === 0)) && (
                <GeneratedHistory
                  generatedHistory={generatedHistory}
                  isGenerationLoading={isGenerationLoading}
                  loadingImg={inputImage}
                  loadingPrompt={loadingPrompt}
                  onItemSelect={onItemSelect}
                  setCurrentIndex={setCurrentIndex}
                  savePrompt={savePrompt}
                  isSubscribed={isSubscribed}
                  onFeedback={(item) => {
                    setFeedbackItem(item);
                    setIsFeedbackDialogShow(true);
                  }}
                  onItemDelete={(id) => {
                    setDeleteGenerationId(id);
                    setIsConfirmDeleteDialogShow(true);
                  }}
                />
              )}
            </main>

            {!isDesktop() && !isTablet() && (
              <>
                <div
                  style={{
                    height: "100vh",
                    width: "100vw",
                    top: "0",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    position: "absolute",
                  }}
                  className={`generate-container-mobile-background ${isGenerateMobileOpen && "expanded"}`}
                  onClick={() => {
                    setIsGenerateMobileOpen(false);
                  }}
                />
                {isGenerateMobileOpen && (
                  <div
                    style={{
                      height: "46px",
                      width: "100vw",
                      top: "0",
                      backgroundColor: "transparent",
                      position: "absolute",
                    }}
                    onTouchStart={() => {
                      setIsGenerateMobileOpen(false);
                    }}
                  />
                )}
                <GenerateMobileComponent
                  height={screenSize.height}
                  prompt={prompt}
                  setPrompt={setPrompt}
                  handleGenerateImage={handleGenerateImage}
                  handleImageUpload={handleImageUpload}
                  inputImage={inputImage}
                  handleRemoveImage={handleRemoveImage}
                  isImageLoaded={isImageLoaded}
                  isGenerationLoading={isGenerationLoading}
                  selectedRoomOptions={selectedRoomOptions}
                  setSelectedRoomOptions={setSelectedRoomOptions}
                  roomTypes={roomTypes}
                  interiorStyles={interiorStyles}
                  additionOptionsList={additionOptionsList}
                  setAdditionOptionsList={setAdditionOptionsList}
                  stringifiedAdditionOptionsList={
                    stringifiedAdditionOptionsList
                  }
                  onItemSelected={onImageGenerateItemSelect}
                  isSubscribed={isSubscribed}
                  freeMembershipDailyCount={freeMembershipDailyCount}
                  freeMembershipDailyLimit={freeMembershipDailyLimit}
                  freeMembershipMonthlyCount={freeMembershipMonthlyCount}
                  freeMembershipMonthlyLimit={freeMembershipMonthlyLimit}
                  isGenerating={isGenerationLoading}
                  setIsGenerating={setIsGenerationLoading}
                  isGenerateMobileOpen={isGenerateMobileOpen}
                  setIsGenerateMobileOpen={setIsGenerateMobileOpen}
                  isAllowBeta={isAllowBeta}
                  isAllowJakomo={isAllowJakomo}
                  handleQuickImageBase64={handleQuickImageBase64}
                />
              </>
            )}
          </div>
        </div>
      )}

      <ProjectDialog
        title={"Create New Project"}
        description={
          "Create project for each of your spatial styling project, or each of your space."
        }
        show={isProjectDialogShow}
        isCancellable={false}
        onClose={() => {
          setIsProjectDialogShow(false);
        }}
        onOk={(title, description) => {
          setIsProjectDialogShow(false);
          createProject({ title: title, description: description });
        }}
        onCancel={() => {
          setIsProjectDialogShow(false);
        }}
        enableBackgroundClick={false}
      />

      {isWelcomeDialogShow && (
        <DefaultDialog
          title={"Welcome to Hancut Studio by MY04"}
          description={t("welcome_description")}
          show={isWelcomeDialogShow}
          isCancellable={false}
          onClose={() => {
            setIsWelcomeDialogShow(false);
          }}
          onOk={() => {
            setIsProjectDialogShow(true);
            setIsWelcomeDialogShow(false);
          }}
          okBtn="Create Your First Project"
          onCancel={() => {
            setIsWelcomeDialogShow(false);
          }}
          enableBackgroundClick={false}
        />
      )}

      {isFirstWelcomeDialogShow && (
        <DefaultDialog
          title={"Welcome to Hancut Studio by MY04"}
          description={t("welcome_description")}
          show={isFirstWelcomeDialogShow}
          isCancellable={false}
          onClose={() => {
            setIsFirstWelcomeDialogShow(false);
          }}
          onOk={() => {
            setIsFirstWelcomeDialogShow(false);
          }}
          okBtn="Continue To Create"
          onCancel={() => {
            setIsFirstWelcomeDialogShow(false);
          }}
          enableBackgroundClick={true}
        />
      )}

      {isConfirmDeleteDialogShow && (
        <DefaultDialog
          title={"Confirm Delete"}
          description={
            "Are you sure want to delete this generation? This action cannot be undone."
          }
          show={isConfirmDeleteDialogShow}
          isCancellable={true}
          onClose={() => {
            setIsConfirmDeleteDialogShow(false);
            setDeleteGenerationId("");
          }}
          onOk={() => {
            setIsConfirmDeleteDialogShow(false);
            deleteGeneration(deleteGenerationId);
            setDeleteGenerationId("");
          }}
          okBtn="Delete"
          onCancel={() => {
            setIsConfirmDeleteDialogShow(false);
          }}
          enableBackgroundClick={true}
        />
      )}

      {isErrorMessageShow && (
        <DefaultDialog
          title={"Unable to perform request"}
          description={errorMessage}
          show={isErrorMessageShow}
          isCancellable={isDialogCancelable}
          onClose={() => {
            setIsErrorMessageShow(false);
          }}
          onOk={() => {
            setIsErrorMessageShow(false);
            if (DialogAction === "logout") {
              localStorage.removeItem("session");
              navigate("/login", { replace: true });
            }
          }}
          okBtn="Ok"
          onCancel={() => {
            setIsErrorMessageShow(false);
          }}
          enableBackgroundClick={true}
        />
      )}

      {isGoToSubscriptionShow && (
        <SubscriptionDialog
          title={"Unable to generate new images"}
          description={subscriptionErrorMEssage}
          show={isGoToSubscriptionShow}
          isCancellable={true}
          onClose={() => {
            setIsGoToSubscriptionShow(false);
          }}
          onOk={() => {
            setIsGoToSubscriptionShow(false);
            navigate("/pricing");
          }}
          onCancel={() => {
            setIsGoToSubscriptionShow(false);
          }}
          enableBackgroundClick={true}
        />
      )}

      {isFeedbackDialogShow && (
        <FeedbackDialog
          item={feedbackItem}
          isCancellable={true}
          onClose={() => {
            setIsFeedbackDialogShow(false);
          }}
          onOk={() => {
            setIsFeedbackDialogShow(false);
          }}
          show={isFeedbackDialogShow}
          onCancel={() => {
            setIsFeedbackDialogShow(false);
          }}
          enableBackgroundClick={true}
        />
      )}

      <DropdownMenu
        userName={userName}
        userEmail={userEmail}
        userImg={userProfileImage}
        isSubscribed={isSubscribed}
        activeSubscription={activeSubscription}
        isDropdownOpen={isDropdownOpen}
      />

      {isFullImageShow ? (
        <ImageViewer
          generated_history={generatedHistory}
          currentIndex={currentIndex}
          isSubscribed={isSubscribed}
          setCurrentIndex={setCurrentIndex}
          onBackgroundClick={onBackgroundClick}
        />
      ) : (
        <div></div>
      )}
      <Toaster />
    </div>
  );
}

export default Studio;
