import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../assets/styles/Studio.css';
import useToken from './auth/useToken';
import Header from './Header';
import Footer from './Footer';
import '../assets/styles/Header.css'
import 'font-awesome/css/font-awesome.min.css';
import toast, { Toaster } from 'react-hot-toast';
// import Stroke from '../assets/images/path1923.svg'
import Stroke from '../assets/images/line_thick.svg'

export default function Error() {
    const [userName, setUserName] = useState();
    const [userType, setUserType] = useState();
    const [userProfileImage, setUserProfileImage] = useState(null);

    const toggleDropdown = () => {
        // setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const { token, setToken } = useToken();

    return (
        <div className="ErrorApp">
            <Header userType={userType} userName={userName} userImg={userProfileImage} token={undefined} toggleDropdown={toggleDropdown} />
            <div className="content">
                <div className='left'>
                    <div className='middle'>
                        <div className='wrap'>
                            <p style={{
                                fontSize: "10rem",
                                fontWeight: "900"
                            }}>404</p>
                            <p className='small_description'>Looks like this page can't be found, may be it is eaten by one of our AI model?</p>
                            {/* <img className='email-stroke not-selectable' src={Stroke} /> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Toaster />
        </div>
    )
}