import React, { useState } from "react";
import { Trans } from "react-i18next";
import { DayPicker } from 'react-day-picker';

import { ko } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';

function SocialLoginDialog({
    show,
    isCancellable = true,
    enableBackgroundClick = true,
    selected,
    onDaySelect,
    onCancel
}) {

    const handleBackgroundClick = () => {
        if (enableBackgroundClick) {
            onCancel();
        }
    };

    const disabledDays = [
        { from: new Date(1990, 1, 1), to: new Date() }
    ];

    return (
        show && (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                    overflow: 'auto'
                }}
                onClick={handleBackgroundClick}
            >
                <div
                    className="dialog-container"
                    style={{
                        width: "auto",
                        backgroundColor: "white",
                        borderRadius: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <DayPicker
                        mode="single"
                        locale={ko}
                        selected={selected}
                        onSelect={onDaySelect}
                        disabled={disabledDays}
                    />
                </div>
            </div>
        )
    );
}

export default SocialLoginDialog;