import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/styles/global.css';

import './i18n';

import Landing from './components/Landing';
import PasswordReset from './components/auth/PasswordReset';
import PasswordResetConfirmation from './components/auth/PasswordResetConfirmation';
import Studio from './components/studio/Studio';
import LoginForm from './components/auth/LoginForm'
import Profile from './components/auth/Profile'
import EditProfile from './components/auth/EditProfile'
import About from './components/About'
import Terms from './components/Terms'
import TermsKr from './components/Terms_kr'
import Privacy from './components/Privacy'
import PrivacyKr from './components/Privacy_kr'
import Cookie from './components/Cookie'
import Contact from './components/Contact'
import Pricing from './components/Pricing';
import Kakao from './components/auth/KakaoLogin';
import RegisterationForm from './components/auth/RegisterationForm';
import ErrorPage from './components/Error';
import ErrorBoundary from './components/utils/ErrorBoundary';
import Verify from './components/auth/Verify';
import NaverLoginCallback from './components/auth/NaverLogin';
import Showcase from './pages/showcase/Showcase';
import EditImage from './components/studio/EditImage';

import {
  LandingRedesign,
  StoreHome,
  DesignerHome,
  StudioGenerateImageSearch
} from './pages';
import { GoogleOAuthProvider } from '@react-oauth/google';

import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import StudioBms from './components/studio/StudioBms';

<link rel="stylesheet"
  href="https://fonts.googleapis.com/css?family=Inter"></link>

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingRedesign />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/landing_redesign",
    element: <LandingRedesign />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/terms_kr",
    element: <TermsKr />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/store",
    element: <StoreHome />,
  },
  {
    path: "/designer",
    element: <DesignerHome />,
  },
  {
    path: "/generate/detail/:image_id",
    element: <StudioGenerateImageSearch />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/privacy_kr",
    element: <PrivacyKr />,
  },
  {
    path: "/cookie",
    element: <Cookie />,
  },
  {
    path: "/getstarted",
    element: <LandingRedesign />,
  },
  {
    path: "/home",
    element: <Landing />,
  },
  {
    path: "/verify",
    element: <Verify />,
  },
  {
    path: "/studio/:project_id",
    element: <Studio />,
  },
  {
    path: "/studio/:image_id/edit",
    element: <EditImage />,
  },
  {
    path: "/pcarpenter",
    element: <StudioBms />,
  },
  {
    path: "/studio",
    element: <Studio />,
  },
  {
    path: "/showcase",
    element: <Showcase />,
  },
  {
    path: "/login",
    element: <LoginForm />,
  },
  {
    path: "/register",
    element: <RegisterationForm />,
  },
  {
    path: "/oauth/kakao",
    element: <Kakao />,
  },
  {
    path: "/oauth/naver",
    element: <NaverLoginCallback />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/profile/editor",
    element: <EditProfile />,
  },
  {
    path: "/password/reset",
    element: <PasswordReset />,
  },
  {
    path: "/password/reset/:reset_token",
    element: <PasswordResetConfirmation />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

const userLocale = navigator.language || navigator.userLanguage;
if (userLocale) {
  console.log(userLocale);
  if ((userLocale === 'ko-KR' || userLocale === 'ko') && !localStorage.getItem('langSetup')) {
    localStorage.setItem('i18nextLng', 'kr');
    localStorage.setItem('langSetup', 'kr');
    window.location.reload();
  }
}

root.render(
  <GoogleOAuthProvider
    clientId={process.env.REACT_APP_GOOGLE_API_KEY}
  >
    <React.StrictMode>
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
