import React, { useRef } from 'react';
import { XCircle } from 'react-feather';
import { useTranslation, Trans } from 'react-i18next';

function ImageUploader({ onImageUpload, loadedImg, removeImg }) {
    const { t } = useTranslation();
    const fileInputRef = useRef(null);

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const onDrop = (e) => {
        e.preventDefault();
        onImageUpload(e.dataTransfer.files);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className='uploader-input'>
            <h4 className="" style={{ fontWeight: "bold", fontSize: "16px" }}>{t('uploadImageStudio')}</h4>
            {/* <p className="text-md lg:text-xl mb-5">
                Upload your desire space image to redesign using Hancut Studio in a glance.
            </p> */}
            {loadedImg ?
                <div
                    className="uploader-input-image not-selectable br"
                >
                    <div className="uploader-input-image wrap">
                        <img src={loadedImg} alt="Upload Image" className="thumbnail-image not-selectable br"
                            onDragOver={onDragOver}
                            onDrop={onDrop}
                            onClick={handleClick} />
                        <XCircle color="white" className='upload-image-remove' onClick={() => removeImg()} />
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={e => onImageUpload(e.target.files)}
                        accept="image/jpeg, image/jpg, image/png"
                        style={{ display: 'none' }}
                    />
                </div> :
                <div
                    className="uploader-input-box br"
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    onClick={handleClick}
                >
                    <div><p className="upload-image-txt"><span className='upload-image-txt-btn'><Trans>Upload Image</Trans></span><Trans> or Drag and Drop</Trans></p><p className='upload-image-description'><Trans>Each file under 8MB / png, jpeg, jpg formats</Trans></p></div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={e => onImageUpload(e.target.files)}
                        accept="image/jpeg, image/png"
                        style={{ display: 'none' }}
                    />
                </div>}
        </div >
    );
}

export default ImageUploader;
