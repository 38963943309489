import React, { useState, useEffect } from "react";
import { BrowseDesignerItemPortfolio, DesignerProfileDescHM } from "../../designer";
import './BrowseDesignerItem.css';

const BrowseDesignerItem = ({ item }) => {
    const { portfolio } = item;

    return (
        <div className="browse-designer-item-wrapper">
            <div className="browse-designer-item">
                <DesignerProfileDescHM item={item} />
                <div className="browse-designer-item-portfolio-wrapper">
                    {
                        portfolio.map((item, index) => {
                            return (
                                <BrowseDesignerItemPortfolio key={index} img={item} />
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default BrowseDesignerItem;