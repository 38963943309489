import React, { useState, useEffect } from "react";
import "./CategoryTab.css";

const CategoryTab = ({ categories }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    }

    return (
        <div className="category-tab not-selectable">
            <div className="category-tab-bar" />
            <div className="category-tab-header">
                {categories.map((category, index) => (
                    <div className={`category-tab-item ${activeTab === index ? "active" : ""}`} onClick={() => handleTabClick(index)} key={index}>
                        {category.name}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CategoryTab;