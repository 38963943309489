import React, { useTransition } from 'react';
import { useState, useEffect } from 'react';
import { Copy, Trash } from 'react-feather';
import NoMyPromptHistory from './NoMyPromptHistory';
import {
    Box,
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { Trans, useTranslation } from 'react-i18next';

function MyPromptsCardView({ item, onItemSelected, onItemDeleted }) {
    const { t } = useTranslation();

    return (
        <div className="prompt-history-list-item card-view not-selectable" id={'myprompts_' + item._id}
            style={{
                marginBottom: "0"
            }}>
            <div className='content'>
                <div className='delete_my_prompts_wrapper'>
                    <Trash color='#000' className='delete_my_prompts' size={16} onClick={() => {
                        onItemDeleted(item._id, false);
                    }} />
                    <a className='prompt_twitter'
                        href={"https://twitter.com/intent/tweet?text=" + item.prompt}
                        data-size="large">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                            <path fill="#1D9BF0" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg></a>

                </div>
                {/* <p className="" style={{ margin: "0px", fontSize: "0.95em" }}>{item.title}</p> */}
                {/* <div style={{ overflow: "auto" }}>
                </div> */}
                <div >
                    <p className="loading-prompt" style={{ width: "100%", margin: "0px" }}>
                        "{item.prompt}"
                    </p>
                </div>
            </div>
            <div className='copy-button not-selectable' onClick={() => { onItemSelected(item.prompt) }}>
                <Copy color='#000' className='copy-button-icon' size={16} />
                <p style={{ fontWeight: "bold", margin: "0px", color: "#000" }}>{t('copyPrompt')}

                </p>
            </div>

        </div>
    );
}

const MyPromptComponent = ({ items, onItemSelected, onItemDeleted }) => {
    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize]);
    const isTablet = () => {
        return screenSize.width > 768;
    }
    const isDesktop = () => {
        return screenSize.width > 1280;
    }

    return (
        <div style={{
            position: "relative",
            minHeight: "250px"
        }}>
            {(items?.length > 0) ?
                <div className="prompt-history-list-container">
                    <Box>
                        <Masonry columns={isDesktop() ? 4 : isTablet() ? 3 : 1} spacing={2}>
                            {items.map((item, index) => (
                                <Box key={index}>
                                    <MyPromptsCardView key={item._id} item={item} onItemSelected={onItemSelected} onItemDeleted={onItemDeleted} />
                                </Box>
                            ))}
                        </Masonry>
                    </Box>

                </div>
                : <NoMyPromptHistory />}
        </div>
    );
}

export default MyPromptComponent;
