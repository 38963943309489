// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse-designers-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 150px 0 0 0;
}

.browse-designers {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1200px;
}

.browse-designers .browse-designers-title h2{
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 30px 0;
    text-align: left;
}

.browse-designers-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/features/designer/components/BrowseDesigners.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".browse-designers-wrapper {\n    width: 100%;\n    height: auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 150px 0 0 0;\n}\n\n.browse-designers {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    max-width: 1200px;\n}\n\n.browse-designers .browse-designers-title h2{\n    font-size: 30px;\n    font-weight: 700;\n    margin: 0 0 30px 0;\n    text-align: left;\n}\n\n.browse-designers-list {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
