import React from 'react';
import { ArrowUpRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

function PromptGuide({ item, onItemSelected, prefix, preview }) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="prompt-guide-list-item card-view" id={prefix + String(item._id)}>
            <div className='content'>
                {prefix === "protips_" && !preview &&
                    <div style={{
                        display: "inline-block",
                        width: "auto",
                        marginTop: "0px",
                        marginBottom: "13px",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#f7f7f7",
                        borderRadius: "13px",
                        padding: "4px 7px",
                        filter: preview ? "blur(5px)" : "",
                        opacity: preview ? "0.5" : "1"
                    }}>
                        <p style={{
                            width: "auto",
                            fontSize: "0.75em",
                            fontWeight: "bold",
                            margin: "0px",
                            color: "#000",
                            padding: "2px 5px",
                            filter: preview ? "blur(5px)" : "",
                            opacity: preview ? "0.5" : "1"
                        }}><b style={{
                            marginRight: "3px"
                        }}>{item.emoji}</b> {item.topic}
                        </p>
                    </div>}
                <p className="" style={{ margin: "0px", fontSize: "0.95em" }}>{item.title}</p>
                <div style={{ overflow: "auto" }}>
                </div>
                <div style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <p className="loading-prompt" style={{
                        width: "100%",
                        marginBottom: "0px",
                        filter: preview ? "blur(5px)" : "",
                        opacity: preview ? "0.5" : "1",
                        overflow: "hidden",
                        display: "-webkit-box",
                    }}>
                        "{preview ? "This is pro feature for Hancut Studio subscribers." : item.description}"
                    </p>
                    {
                        preview &&
                        <p
                            className='subscribe-plan'
                            style={{
                                width: "min-content",
                                whiteSpace: "nowrap",
                                fontSize: "0.9em",
                                width: "auto",
                                fontWeight: "600",
                                position: "absolute",
                                background: "transparent",
                                color: "black"
                            }}
                            onClick={() => {
                                navigate(`/pricing`)
                            }}>Subscribe to access Pro Features</p>
                    }
                </div>
            </div>
            <div
                className='try-button not-selectable'
                style={{
                    cursor: preview ? "default" : "pointer"
                }}
                onClick={() => {
                    if (!preview) onItemSelected(item, prefix);
                }}>
                <p style={{
                    fontWeight: "bold",
                    margin: "0px",
                    filter: preview ? "blur(5px)" : "",
                }}><Trans>Try Prompt</Trans>

                </p>
                <ArrowUpRight
                    color='#FB5210'
                    className='try-button-icon'
                    size={16}
                    style={{
                        filter: preview ? "blur(5px)" : "",
                    }} />
            </div>
        </div>
    );
}

const PromptGuideComponent = ({ items, onItemSelected, preview = false, prefix = "prompts" }) => {
    return (
        <div className="prompt-guide-list-container prompt-component-responsive">
            {preview &&
                <PromptGuide
                    key={"protips_"}
                    item={{
                        _id: "protips_",
                        emoji: '🎬',
                        topic: 'Harry Porter',
                        title: 'MY04 to HOGWARTS for one way frabel Platform 9 3/4',
                        description: 'Wizarding World, Gothic Architecture, Bookshelves and Libraries, Candlelight and Dim Lighting, Magical Artwork and Posters, Four House Colors, Magical Creatures and Artifacts Display, Quidditch-inspired Decorations, Magical Doorway or Hidden Passageway, House Scarf or Robe-inspired Textiles'
                    }}
                    prefix="protips_"
                    onItemSelected={onItemSelected} />}
            {items.map(item => <PromptGuide key={item._id} item={item} onItemSelected={onItemSelected} preview={preview} prefix={prefix} />)}
        </div>
    );
}

export default PromptGuideComponent;
