// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-row {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.category-row-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.category-row-title {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-left: 10px;
    margin-bottom: 20px;
}

.category-row-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/features/store/components/CategoryRow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".category-row {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.category-row-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 100%;\n}\n\n.category-row-title {\n    width: 100%;\n    font-size: 16px;\n    font-weight: 500;\n    color: #000;\n    margin-left: 10px;\n    margin-bottom: 20px;\n}\n\n.category-row-items {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
