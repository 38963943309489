// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-landing {
  display: flex;
  padding: 1rem 1.5rem 1rem 2.5rem;
  border-radius: 99rem;
  background-color: #000;
  transition: all 0.5s ease;
  justify-content: center;
  align-items: center;
  gap: 1em;
  border: none;

  font-size: 16px;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  font-family: Inter;

  margin: 0;
}

.button-landing .icon {
  height: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.button-landing .icon span {
  background: #fff;
  border-radius: 100%;
  padding: 0rem;

  width: 0rem;
  height: 0rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  transform: rotate(-180deg);

  transition: all 0.5s ease;
}

.button-landing:hover .icon span {
  transform: rotate(0deg);

  border-radius: 100%;
  padding: 0.6rem;
  width: 2.1rem;
  height: 2.1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/GetStartedButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,oBAAoB;EACpB,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,YAAY;;EAEZ,eAAe;EACf,gBAAgB;EAChB,uBAAkB;EAAlB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;;EAElB,SAAS;AACX;;AAEA;EACE,YAAY;;EAEZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;;EAEb,WAAW;EACX,YAAY;;EAEZ,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;;EAEvB,0BAA0B;;EAE1B,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;;EAEvB,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,cAAc;AAChB","sourcesContent":[".button-landing {\n  display: flex;\n  padding: 1rem 1.5rem 1rem 2.5rem;\n  border-radius: 99rem;\n  background-color: #000;\n  transition: all 0.5s ease;\n  justify-content: center;\n  align-items: center;\n  gap: 1em;\n  border: none;\n\n  font-size: 16px;\n  font-weight: 600;\n  width: fit-content;\n  cursor: pointer;\n  font-family: Inter;\n\n  margin: 0;\n}\n\n.button-landing .icon {\n  height: 2rem;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.button-landing .icon span {\n  background: #fff;\n  border-radius: 100%;\n  padding: 0rem;\n\n  width: 0rem;\n  height: 0rem;\n\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n\n  transform: rotate(-180deg);\n\n  transition: all 0.5s ease;\n}\n\n.button-landing:hover .icon span {\n  transform: rotate(0deg);\n\n  border-radius: 100%;\n  padding: 0.6rem;\n  width: 2.1rem;\n  height: 2.1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
