import React, { useState } from "react";
import { Trans } from "react-i18next";
import { ArrowRight } from "react-feather";
import GoogleLoginButton from "../auth/GoogleLoginButton";
import NaverLoginIcon from '../../assets/images/naver-icon.svg';
import KakaoImg from '../../assets/images/kakao_img.png';

function SocialLoginDialog({
    title,
    description,
    show,
    onClose,
    isCancellable = true,
    enableBackgroundClick = true,
    onOk,
    onCancel,
    okBtn = "Ok",
    kakaoLogin
}) {

    const handleBackgroundClick = () => {
        if (enableBackgroundClick) {
            onClose();
        }
    };

    const handleOkClick = () => {
        onOk();
    };

    const handleCancelClick = () => {
        onCancel();
    };

    return (
        show && (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                    overflow: 'auto'
                }}
                onClick={handleBackgroundClick}
            >
                <div
                    className="dialog-container"
                    style={{
                        width: 500,
                        backgroundColor: "white",
                        borderRadius: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <h4 style={{ fontWeight: "bold" }}><Trans>{title}</Trans></h4>
                    <p className="dialog-description" style={{
                        whiteSpace: 'pre-line',
                        wordBreak: 'keep-all'
                    }}><Trans>{description}</Trans></p>
                    <div className='login-form'>
                        <div class="social-login-buttons">
                            <button className='kakao' onClick={() => {
                                localStorage.setItem('login_remark', 'pcarpenter');
                                kakaoLogin();
                            }}>
                                <img src={KakaoImg} style={{
                                    width: "18px",
                                    height: "auto",
                                    marginRight: "12px"
                                }} alt="" ></img><Trans>Login with Kakao</Trans>
                            </button>
                        </div>
                        {/* https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=YmmXMDX0SD7VMUSjiZJJ&redirect_uri=http://localhost:3003/oauth/naver&state=12345 */}
                        <div class="social-login-buttons" >
                            <button className='naver' onClick={() => {
                                window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&redirect_uri=${'https://hancut.my04.net/oauth/naver'}&state=12345`;
                                localStorage.setItem('login_remark', 'pcarpenter');
                            }} style={{
                                color: "#ffffff"
                            }}>
                                <img src={NaverLoginIcon} alt="" style={{
                                    width: "18px",
                                    height: "auto",
                                    marginRight: "12px"
                                }}></img><Trans>Login with Naver</Trans>
                            </button>
                        </div>
                        <GoogleLoginButton
                            onSuccessResponse={async (credentialResponse) => {
                                console.log(JSON.stringify(credentialResponse));
                                if (credentialResponse.access_token) {
                                    // handleGoogleLogin(credentialResponse);

                                    const response = await fetch(process.env.REACT_APP_API + '/v1/auth/google_login', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify(credentialResponse)
                                    })
                                        .then(response => {
                                            if (!response.ok) {
                                                return response.json().then(errorInfo => {

                                                });
                                            }
                                            else return response.json();
                                        })
                                        .then(data => {
                                            if (data != undefined) {
                                                console.log(JSON.stringify(data.token));
                                                localStorage.setItem('login_remark', 'pcarpenter');
                                                localStorage.setItem('session', data.token);
                                                localStorage.setItem('user', JSON.stringify(data.user));
                                                onClose();
                                            }
                                        })
                                        .catch(error => {
                                            console.log(error.message);
                                        });
                                }
                            }} onFailureResponse={(error) => {
                            }} />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        {
                            isCancellable &&
                            <button style={{ marginRight: 20 }} onClick={handleCancelClick} className="dialog-button cancel-dialog"><Trans>Cancel</Trans></button>
                        }
                    </div>
                </div>
            </div>
        )
    );
}

export default SocialLoginDialog;