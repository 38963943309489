import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import useToken from "../../../components/auth/useToken";

const useGoogleLogin = () => {
    const { token, setToken } = useToken();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleGoogleLogin = async (credentials) => {
        setLoading(true);
        try {
            fetch(process.env.REACT_APP_API + '/google_login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(credentials)
            }).then(async response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        setLoading(false);
                        setError(error.message || 'Failed to login using google!');
                    });
                }
                else return response.json();
            }).then(data => {
                setLoading(false);
                if (data?.status) {
                    setError(null);
                    setToken(data.token);
                    navigate("/studio", { replace: true });
                }
                else {
                    setError(error.message || 'Failed to login using google!');
                }
            }).catch(error => {
                setLoading(false);
                setError(error.message || 'Failed to login using google!');
            });
        }
        catch (error) {
            setLoading(false);
            setError(error.message || 'Failed to login using google!');
        }
    };

    return { handleGoogleLogin, loading, error };
};

export default useGoogleLogin;