import React, { useState } from "react";
import noDataImg from '../../assets/images/empty-folder.svg';
import { useTranslation, Trans } from 'react-i18next';

function NoGenerationHistory() {
    return (
        <div className="no-history-wrapper">
            <img src={noDataImg} alt="No Data" className="img" />
            <h4 className="" style={{
                fontWeight: "bold",
                fontSize: "16px"
            }}><Trans>No Generation History</Trans></h4>
            <p className="text-md lg:text-xl mb-5" style={{
                fontSize: "13px",
            }}>
                <Trans>Start generating your dream idea now.</Trans>
            </p>
        </div>
    );
}

export default NoGenerationHistory;