import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: {
                    // dreamBedroom: "Click the space type and style you want.",
                    dreamBedroom: "Please write space style you want or click the keyword suggestions below.",
                    dreamBedroomAdditional: "Please write additional space type and style you want.",
                    mobilePrompt: "Write a prompt to generate your dream space.",
                    createHint: "Modern Minimalist Living Room",
                    createDescHint: "To create minimalist living room with clean, sleek, and airy style",
                    standardTooltip: "Generate using standard settings that is optimized for most cases.",
                    creativeTooltip: "Generate for creative results using advanced settings.",

                    uploadImageStudio: "Upload Image",
                    copyPrompt: "Copy Prompt",
                    copySelectedPrompt: "Copy Selected Prompt",
                    freeGenerateTooltip: "5 gennerations per day, 50 generations per month for free users",

                    project_created: "Project is created successfully!",

                    "Generate the best one indoor scene with Hancut Studio.": "Create a space style with a hancut studio.",
                    "Create project for each of your spatial styling project, or each of your space.": "Please enter the name of the project you want to create.",
                    "Add your favorite generated images to My Hancuts to access them quickly.": "If you keep your favorite image in one cut, you can quickly check it.",
                    "Add your favorite prompts to My Prompts to access them quickly.": "You can quickly check your favorite prompts by keeping them in one cut of your own.",
                    "My04 dreams of a world where more creators can create indoor spaces that contain their creativity more easily and quickly. And My04 hopes that this creative act itself will be a joyful and fun play.": "MY04, dreams dream of a world that can make space style easier and quickly.",
                    "My04, Inc. (“My04“) is a creator platform that provides contents generation services to connect indoor spaces with human creativity.": "My04, Inc. (“My04“) is a creator platform that provides content generation services that connects creativity.",
                    "Hancut Studio is MY04's first content creation service. Hancut Studio enables creators to generate and create indoor space images in an easier and more innovative way. Our goal is to do our best to support creators to produce the best one scene with Hancut Studio.": "Hancut Studio is MY04's first spatial styling image production service, and we want to do our best to help creators produce the best Hancut.",
                    "Try Al-powered prompt packages": "Today's special space style",
                    "Want to try with your own images and personalized prompt?": "We change your space according to the space style you want.",
                    "Please login to upload your own image and personalized prompt.": "Once you sign in, you can create a space style as you wish.",
                    "Subscription plans for every creators": "Service Plan and price",
                    "All essentials for you to create the best spatial styling contents.": "You can create the best space styling images.",
                    "My dream bedroom…": "Click the space type and style you want.",

                    // Studio Page
                    "미니멀리즘": "Minimalism",
                    "미드센추리 모던": "Mid-century modern",
                    "보헤미안": "Bohemian",
                    "스칸디나비안": "Scandinavian",
                    "인더스트리얼": "Industrial",
                    "엔틱": "Traditional",
                    "아르데코": "Art Deco",
                    "팜하우스": "Farmhouse",
                    "오리엔탈": "Oriental Style",
                    "지중해 스타일": "Mediterranean Style",
                    "Quickly select from the following images to try.": "테스트가 필요하세요? 아래 샘플 사진을 터치!해 보세요.",

                    "침실": "Bedroom",
                    "거실": "Living Room",
                    "주방": "Kitchen",
                    "서재": "Study Room",
                    "다이닝룸": "Dining Room",
                    "욕실": "Bathroom",
                    "사무실": "Office",
                    "카페": "Cafe",
                    "레스토랑": "Restaurant",

                    "Minimalism": "Minimalism",
                    "Mid-century modern": "Mid-century modern",
                    "Bohemian": "Bohemian",
                    "Scandinavian": "Scandinavian",
                    "Industrial": "Industrial",
                    "Traditional": "Traditional",
                    "Art Deco": "Art Deco",
                    "Farmhouse": "Farmhouse",
                    "Oriental Style": "Oriental Style",
                    "Mediterranean Style": "Mediterranean Style",
                    "Bedroom": "Bedroom",
                    "Living Room": "Living Room",
                    "Kitchen": "Kitchen",
                    "Study Room": "Study Room",
                    "Dining Room": "Dining Room",
                    "Bathroom": "Bathroom",
                    "Office": "Office",
                    "Cafe": "Cafe",
                    "Restaurant": "Restaurant",
                    welcome_description: "Craft captivating spatial styles with Hancut Studio, an innovative platform designed to streamline your creative process. \n\n Simply upload a space photo and select your preferred room type, style, and generative style. With just a few clicks, our powerful AI will generate four unique and inspiring space styles, saving you valuable time and effort. ",

                    // Landing Redesign
                    start_1_year_free: "Start 1 Year Free Trial",
                    start_free: "Free AI Interior Designer",
                    try_now: "Try Hancut Studio Now",
                    register_now: "Register Now",
                }
            },
            kr: {
                translations: {
                    // Header
                    "Pro Membership since": "프로 멤버십 시작일",
                    "My": "나의",
                    "Projects": "프로젝트",
                    "Hancuts": "한컷",
                    "Prompts": "공간 스타일 내용",
                    "My Projects": "나의 프로젝트",
                    "My Hancuts": "나의 한컷",
                    "My Prompts": "나의 공간 스타일 내용",
                    // "Store": "스토어",
                    // "My Prompts": "나의 프롬프트",
                    "Pricing": "",
                    "Settings": "설정",
                    "Our Story": "미디엄 스토리",
                    "Log Out": "로그아웃",

                    // Dialog
                    "Create": "만들기",
                    "Save": "저장",
                    "Cancel": "취소",
                    "Delete": "삭제",
                    "Delete Project": "프로젝트 삭제",
                    "Are you sure want to delete your project? This action cannot be undone.": "이 프로젝트를 삭제하시겠습니까? 삭제된 프로젝트는 복구할 수 없습니다.",
                    "OK": "확인",
                    "Confirm Delete": "삭제 확인",

                    // Profile
                    // "My Projects": "나의 프로젝트",
                    "Create New Project": "새 프로젝트 만들기",
                    "Create project for each of your spatial styling project, or each of your space.": "각 공간에 대한 스타일링 프로젝트명를 만들어 주세요.",
                    "Name": "이름",
                    createHint: "예시) 우리 집 거실",
                    "Description": "설명",
                    createDescHint: "깔끔하고 시크한 미니멀리즘 거실",
                    // "My Hancuts": "나의 한컷",
                    "Add your favorite generated images to My Hancuts to access them quickly.": "마음에 드는 이미지를 나의 한컷에 보관하면 빠르게 확인할 수 있습니다.",
                    // "My Prompts": "나의",
                    myPromptsKorean: "프롬프트",
                    "Add your favorite prompts to My Prompts to access them quickly.": "마음에 드는 프롬프트를 나의 한컷에 보관하면 빠르게 확인할 수 있습니다.",
                    "generations": "만들기",
                    "generation": "만들기",

                    // Footer
                    "Home": "홈",
                    "About": "소개",
                    "Contact": "고객지원",
                    "Get Started": "시작하기",
                    "Pricing": "가격",
                    "Our Story": "미디엄",
                    "Terms": "이용약관",
                    "Privacy": "개인정보처리방침",

                    // Login Page
                    "LOGIN": "로그인",
                    "Welcome Back!": "기다리고 있었어요!",
                    "Generate the best one indoor scene with Hancut Studio.": "한컷 스튜디오로 공간 스타일을 만들어보세요.",
                    "Username or email": "아이디 또는 이메일",
                    "Username or email cannot be empty.": "아이디 또는 이메일은 비워 둘 수 없습니다.",
                    "Password": "비밀번호",
                    "Username cannot be empty.": "사용자 이름은 비워 둘 수 없습니다.",
                    "Password cannot be empty.": "암호는 비워 둘 수 없습니다.",
                    "Forget Password?": "암호를 잊으셨나요?",
                    "Reset Password": "암호 재설정",
                    "Enter your email address": "이메일 주소 입력",
                    "Send Password Reset Email": "암호 재설정 이메일 보내기",
                    "Login": "로그인",
                    "Login with Google": "구글로 로그인",
                    "Continue with Google": "구글로 로그인",
                    "Don't have an account?": "처음 오셨나요?",
                    "Sign up for free": "회원가입",
                    "Login with Kakao": "카카오톡 로그인",
                    "Login with Naver": "네이버 로그인",

                    // Signup Page
                    "SIGNUP": "가입하기",
                    "Generate the best one indoor scene with Hancut Studio.": "한컷 스튜디오로 공간 스타일을 만들어보세요.",
                    "First Name": "이름",
                    "Name cannot be empty.": "이름은 비워둘 수 없습니다.",
                    "Last Name": "성",
                    "Name cannot be empty.": "이름은 비워둘 수 없습니다.",
                    "Username": "사용자 이름",
                    "Username cannot be empty.": "사용자 이름은 비워둘 수 없습니다.",
                    "Email": "이메일",
                    "Email cannot be empty.": "이메일은 비워둘 수 없습니다.",
                    "Invalid email address": "사용할 수 없는 이메일 주소입니다.",
                    "Password": "비밀번호",
                    "Password cannot be empty.": "비밀번호는 비워둘 수 없습니다.",
                    "Password must be at least 5 characters.": "비밀번호는 5자 이상이어야 합니다.",
                    "Confirm Password": "비밀번호 확인",
                    "Confirm password does not match.": "확인 비밀번호가 일치하지 않습니다.",
                    "Sign up": "로그인",
                    "Login with Google": "구글로 로그인",
                    "Already have an account?": "이미 아이디가 있나요?",
                    "Login here": "로그인",
                    "Invalid username": "사용자 이름을 사용할 수 없습니다",
                    "User name or email already exists.": "사용자 이름 또는 이메일이 이미 있습니다.",
                    "Showcase": "쇼케이스",
                    // Pricing
                    "PRICING": "가격",
                    "Subscription plans for every creators": "서비스 플랜 및 가격",
                    "Monthly": "매월 결제",
                    "Basic": "베이직",
                    "Experience boundless creativity with just a touch of constraint.": "가볍게 즐겨보세요.",
                    "Free": "무료",
                    "Education": "교육",
                    "Editing Feature (Coming Soon)": "편집 기능(진행 중)",
                    "Claim 1 Year Free": "1년 무료로 이용해 보세요",
                    "per month, billed monthly": "월 단위로 청구",
                    "Get Started": "시작하기",
                    "5 generations per day": "하루 무료 횟수 5회 제공",
                    "Total 50 generations per month": "월 무료 횟수 50회 제공",
                    "Include watermark when download": "다운로드 시 워터마크 포함",
                    "30 prompt saves": "프롬프트 30개 저장",
                    "Pro tips for limitless creativity": "전문가 팁 제공",
                    "Pro": "프로",
                    "All essentials for you to create the best spatial styling contents.": "최고의 공간 스타일링 이미지를 만들 수 있습니다.",
                    "$10": "10 달러",
                    "per month, billed monthly": "월 단위로 청구",
                    "Subscribe": "이용하기",
                    "Already Subscribed": "이용중",
                    "Unlimited daily generations": "하루 무제한 횟수 제공",
                    "Unlimited monthly generations": "월 무제한 횟수 제공",
                    "Remove watermark": "다운로드 시 워터마크 제거",
                    "90 prompt saves": "프롬프트 90개 저장",
                    "Pro tips for limitless creativity": "전문가 팁 제공",
                    "Annually": "매년 결제",
                    "Basic": "베이직",
                    "Experience boundless creativity with just a touch of constraint.": "가볍게 즐겨보세요.",
                    "Free": "무료",
                    "per year, billed annually": "년 단위로 청구",
                    "Get Started": "시작하기",
                    "Apply within beta launch period": "베타 런칭 기간 내 적용", //출시
                    "Free (Partnership)": "무료 (제휴)",
                    // "5 generations per day": "하루 생성 횟수 5회",
                    // "Total 50 generations per month": "월 생성 횟수 50회",
                    "Include watermark when download": "다운로드 시 워터마크 포함",
                    "30 prompt saves": "프롬프트 30개 저장",
                    "Pro tips for limitless creativity": "전문가 팁 제공",
                    "Pro": "프로",
                    "All essentials for you to create the best spatial styling contents.": "최고의 공간 스타일링 이미지를 만들 수 있습니다.",
                    "$108": "108 달러",
                    "10% discount": "10% 할인",
                    "per year, billed annually": "년 단위로 청구",
                    "Subscribe": "이용하기",
                    "Already Subscribed": "이용중",
                    // "Unlimited daily generations": "하루 생성 횟수 무제한",
                    // "Unlimited monthly generations": "월 생성 횟수 무제한",
                    "Remove watermark": "다운로드 시 워터마크 제거",
                    "90 prompt saves": "프롬프트 90개 저장",
                    "Pro tips for limitless creativity": "전문가 팁 제공",

                    // Workspace Page
                    uploadImageStudio: "스텝 1.바꾸고 싶은 공간사진을 업로드",
                    " or Drag and Drop": " 또는 끌어다 붙여넣기",
                    "Each file under 8MB / png, jpeg, jpg formats": "8MB 미만 / png, jpeg, jpg 형식",
                    "Enter Prompt": "스텝 2. 내용을 작성하거나 타입을 선택", // 원하는 내용을 작성학거나 방타입과 스타일타입을 선택    방타입과 스타일타입을 선택합니다. 직접 원하는 내용을 자세하게 작성하시면 더욱 좋은 결과가 나옵니다. 기본과 고급,둘다 이용해 보시고 마음에 드는 이미지를 골라보세요. 
                    "Prompt": "원하는 내용 (Prompt)",
                    dreamBedroom: "내용을 직접 작성하거나 아래에 방타입과 스타일타입을 선택하시면 됩니다.",
                    dreamBedroomAdditional: "방타입과 스타일타입 선택후 원하는 내용을 자유롭게 추가로 작성해보세요.",
                    "Select Generate Options": "공간스타일 옵션 선택",
                    "Type": "방타입",
                    "bed room": "침실",
                    "living room": "거실",
                    "kitchen": "주방",
                    "study": "서재",
                    "dining room": "다이닝룸",
                    "kids room": "어린이방",
                    "Bathroom": "욕실",
                    "cafe": "카페",
                    "restaurant": "레스토랑",
                    "office": "사무실",
                    "Store": "상업매장",
                    "Style": "스타일",
                    // "Minimalism": "미니멀리즘",
                    // "Mid-century modern": "미드센추리 모던",
                    // "Bohemian": "보헤미안",
                    // "Scandinavian": "스칸디나비안",
                    // "Industrial": "인더스트리얼",
                    // "Traditional": "전통적인",
                    // "Art Deco": "아르데코",
                    // "Farmhouse": "팜하우스",
                    // "Oriental Style": "동양적인",
                    // "Modern": "모던",
                    "Condition": "컨디션",
                    "Empty": "창의적인",
                    "Regular": "최적화된",
                    "Generate": "공간 스타일링 만들기",
                    "No Generation History": "생성 기록이 없습니다",
                    "Start generating your dream idea now.": "여러분이 꿈꾸는 공간으로 만들어보세요.",
                    "🔥 Pro Tips": "🔥 Pro 공간 스타일 내용",
                    "Create using best tips inspired by world's most popular and trending creative ideas.": "세계적으로 인기 있고 트렌디한 창작 작품에서 영감을 받아 나의 공간을 연출해 보는 베스트 Pro 팁입니다.",
                    "💡 Basic Tips": "💡 Basic 공간 스타일 내용",
                    "Experience various spatial styles using Basic Tips.": "Basic 팁스로 여러 공간 스타일을 경험해 보세요.",
                    "My saved prompts appear here.": "여기에 저장된 프롬프트가 나타납니다.",
                    mobilePrompt: "원하는대로 공간 스타일 내용을 적성해보세요.",
                    "No Generation History": "이미지 만들기 없음",
                    "Start generating your dream idea now": "지금부터 꿈의 아이디어를 만들어 보세요",
                    "Try Prompt": "입럭",
                    "Use Prompt": "입럭",

                    "Welcome to Hancut Studio by MY04": "안녕하세요?",
                    welcome_description
                        : "안녕하세요?\n\n마이공사 한컷스튜디오에 오신걸 환영합니다.\n\n한컷스튜디오는 나만의 공간을 원하는 스타일로 만들어주는AI생성 기반 공간 스타일링 서비스입니다.\n\n<strong>Step 1.</strong> 공간사진을 업로드 합니다.  \n<strong>Step 2.</strong> 방타입과 스타일타입을 선택합니다.\n<strong>Step 3.</strong> 단계를 선택합니다. \n마지막으로 공간스타일링 만들기를 선택합니다. 공간스타일링 만들기를 누르시면 4장의 이미지가 제공됩니다.\n\n무한한 잠재력이 가득한 한컷 스튜디오에서 나만의 공간을 창의적으로 연출해 보세요!\nMY04 Team",
                    "Create Your First Project": "첫 프로젝트 만들기",
                    "Continue To Create": "계속 이동",
                    project_created: "프로젝트를 만들었습니다!",

                    "Minimalism": "미니멀리즘",
                    "Mid-century modern": "미드센추리 모던",
                    "Bohemian": "보헤미안",
                    "Scandinavian": "스칸디나비안",
                    "Industrial": "인더스트리얼",
                    "Traditional": "엔틱",
                    "Art Deco": "아르데코",
                    "Farmhouse": "팜하우스",
                    "Oriental Style": "오리엔탈",
                    "Mediterranean Style": "지중해 스타일",

                    "Bedroom": "침실",
                    "Living Room": "거실",
                    "Kitchen": "주방",
                    "Study Room": "서재",
                    "Dining Room": "다이닝룸",
                    "Bathroom": "욕실",
                    "Office": "사무실",
                    "Cafe": "카페",
                    "Restaurant": "레스토랑",

                    "미니멀리즘": "미니멀리즘",
                    "미드센추리 모던": "미드센추리 모던",
                    "보헤미안": "보헤미안",
                    "스칸디나비안": "스칸디나비안",
                    "인더스트리얼": "인더스트리얼",
                    "엔틱": "엔틱",
                    "아르데코": "아르데코",
                    "팜하우스": "팜하우스",
                    "오리엔탈": "오리엔탈",
                    "지중해 스타일": "지중해 스타일",

                    "침실": "침실",
                    "거실": "거실",
                    "주방": "주방",
                    "서재": "서재",
                    "다이닝룸": "다이닝룸",
                    "욕실": "욕실",
                    "사무실": "사무실",
                    "카페": "카페",
                    "레스토랑": "레스토랑",

                    copyPrompt: "내용 복사",
                    copySelectedPrompt: "선택한 프롬프트 복사",

                    "Are you sure want to delete this generation? This action cannot be undone.": "이 생성을 삭제하시겠습니까? 삭제된 생성은 복구할 수 없습니다.",
                    "Please wait 10 seconds to 1 minute during generating the room images.": "생성하는 동안 10초에서 1분 정도 기다려 주세요.",

                    // Generation Section
                    standardTooltip: "공간스타일을 편하게 바꾸어 볼 수 있습니다.",
                    creativeTooltip: "더욱 창의적으로 바꾸어 볼수있습니다.",
                    "Standard": "기본",
                    "Creative": "고급",
                    "Home Styling": "홈 스타일링",
                    "Generative Style": "스텝 3. 단계 선택",
                    "Daily Limit": "하루 생성 횟수",
                    "Monthly Limit": "월 생성 횟수",
                    freeGenerateTooltip: "하루에 5번, 한달 동안 50번 무료제공",

                    // Settings Page
                    "Edit Profile": "프로필 편집",
                    "Edit your Hancut user profile": "한컷 프로필 편집하기",
                    "Edit Profile": "프로필 편집",
                    "Password": "비밀번호",
                    "Subscriptions": "월 이용액",
                    "Profile Picture": "프로필 사진",
                    "Change Photo": "사진 변경",
                    "Remove": "지우기",
                    "Recommended: Square JPG, PNG, or GIF, at least 1,000 pixels per side.": "권장 : 정사각형 JPG, PNG 또는 GIF, 최소 1,000픽셀 이상",
                    "First Name": "이름",
                    "Last Name": "성",
                    "Username": "사용자 이름",
                    "Email": "이메일",
                    "Save Changes": "변경사항 저장",
                    "Password": "비밀번호",
                    "Old Password": "이전 비밀번호",
                    "New Password": "새로운 비밀번호",
                    "Confrirm New Password": "새로운 비밀번호 확인",
                    "Update Password": "비밀번호 변경하기",
                    "Subscriptions": "월 이용액",
                    "Subscription": "월 이용액",
                    "Pro Membership since": "프로 멤버십 시작일",
                    "Cancel Subscription": "이용 취소하기",
                    "Are you sure want to cancel your subscription?": "이용을 취소하시겠습니까?",
                    "Yes": "예",
                    "No": "아니오",
                    "You have no active subscription.": "이용중인 구독이 없습니다.",
                    "Subscribe Pro Membership Now": "프로 멤버십 이용하기",

                    "404": "404",
                    "Looks like this page can't be found, may be it is eaten by one of our AI model?": "이 페이지를 찾을 수 없어요. 아무래도 인공지능이 배고파서 먹은 건 아닐까요?",

                    // About Page
                    "MY04": "MY04",
                    "My Tiny Space, MY04": "나의 공간 MY04",
                    "My04 dreams of a world where more creators can create indoor spaces that contain their creativity more easily and quickly. And My04 hopes that this creative act itself will be a joyful and fun play.": "MY04는 공간 스타일링을 더욱 쉽고 빠르게 만들 수 있는 세상을 꿈꿉니다.",
                    "My04, Inc. (“My04”) is a creator platform that provides contents generation services to connect indoor spaces with human creativity.": "My04, Inc.(\"My04\")는 창의성을 연결하는 콘텐츠 생성 서비스를 제공하는 크리에이터 플랫폼입니다.",
                    "Hancut Studio": "한컷 스튜디오",
                    "Hancut Studio is MY04's first content creation service. Hancut Studio enables creators to generate and create indoor space images in an easier and more innovative way. Our goal is to do our best to support creators to produce the best one scene with Hancut Studio.": "한컷 스튜디오는 MY04의 첫 번째 공간 스타일링 이미지 제작 서비스이며, 크리에이터들에게 최고의 한컷을 제작할 수 있도록 최선을 다해 지원하고자 합니다.",
                    "TEAM": "팀",
                    "Awesome teammates at MY04": "마이공사의 멋진 팀원들",
                    "CEO": "CEO",
                    "Aglaia": "아글라이아",
                    "We will shine every moment in your life.": "마이공사를 모르는 당신, 괜찮으시겠어요?🙏",
                    "CFO": "CFO",
                    "Mark": "마크",
                    "You can succeed if you develop your dream": "꿈을 키우면 성공할 수 있습니다",
                    "Business Support Manager": "비즈니스 지원 매니저",
                    "Felix": "펠릭스",
                    "Everyday Wisdom, Business Innovation": "일상의 지혜로 비즈니스 혁신을 추구",
                    "Research & Development": "연구 및 개발",
                    "Khami": "카미",
                    "I dream of a sweet candy-like space.": "달콤한 사탕 같은 공간을 꿈꿉니다.",
                    "Andy": "앤디",
                    "Life is a binary, you either do it or die hard.": "Life is a binary, you either do it or die hard.",
                    "Prompt E & D": "프롬프트 E & D",
                    "April": "에이프릴",
                    "My imagination becomes reality.": "나의 상상은 현실이 됩니다.",
                    "May": "메이",
                    "prompt, make it work, accio, aparecium": "프롬프트, 작동되게 만들어라, 아치오, 아파레시움!",

                    // Contact
                    "Wanna talk about the adventures behind our journey?": "한컷 스튜디오서비스를 이용하실때 궁금한점이나 해결하고자 하는 문제는 아래 메일로 문의주시기 바랍니다.",
                    "Drop us a line, we promise to catch it!": "빠르게 답변드리겠습니다.",
                    "support@my04.net": "support@my04.net",
                    "BILLING": "요금관련문의",
                    "billing@my04.net": "billing@my04.net",
                    "OFFICE": "사무실주소",
                    "356, Seocho-daero, Seocho-gu,": "서울 서초구 서초대로 356",
                    "Seoul": "",

                    // Landing Page
                    "Try Al-powered prompt packages": "Today's",
                    landingTitle: "공간 스타일",

                    "AI-powered Interior Styling Platform": "AI기반 공간 스타일링 플랫폼",

                    "Generate the best indoor scene with Hancut Studio with Al-powered prompt packages": "한컷 스튜디오에서 원하는대로 공간 스타일을 마음껏 연출해보세요.",

                    "Generate the best indoor scene with Hancut Studio": "클릭해서 바꿔보세요.",
                    "Try Now": "Click",
                    "Upload Image": "공간사진을 업로드",
                    "Try Your Own Image": "공간 사진을 업로드 하세요.",
                    "Please wait while generating...": "이미지를 만들고 있는 중입니다...",
                    "We are working our best to provide with faster generation speed.": "10초애서 1분사이의 시간이 소요됩니다.",
                    "Thank you so much for your kind patient.": "기다려 주실꺼죠? ♡♡♡ ^^",
                    "Want to try with your own images and personalized prompt?": "원하는 공간 스타일대로 당신의 공간을 바꿔드립니다.",

                    "Login Required": "로그인 필요",
                    "Please login to upload your own image and personalized prompt.": "로그인 하시면 원하는 대로 공간 스타일을 만들어 볼 수 있습니다.",
                    "Cancel": "취소",
                    "Login": "로그인",
                    "Continue with one tap sign-up.": "원탭 가입으로 계속 진행!",
                    "SAVE UP TO $120": "최대 $120 할인",
                    "1 year pro membership for free!": "지금 가입하고 1년 프로 멤버십 서비스를 무료로 이용해 보세요.",
                    "Sign up": "가입하기",
                    "Continue with Google": "구글로 로그인",
                    "Generate Again": "다시 만들기",
                    "Try Your Own Image": "나의 공간을 바꿔보세요",
                    "Want to try with your own images and personalized prompt?": "나만의 공간을 원하는 대로 바꾸어보시겠습니까?",
                    "Emily in Paris": "파리의 에밀리",
                    "A Journey of Self-Discovery and True Love": "자기 발견과 진정한 사랑의 여정",
                    "Perfect party, Perfect Dream!": "완벽한 파티, 완벽한 꿈!",
                    "Dreaming of a Perfect Party with Friends, in My Own Imaginary Bedroom": "나만의 상상의 침실에서 친구들과 완벽한 파티를 꿈꾸며",

                    // Landing Redesign
                    "Revolutionize Your Home with AI Interior Design": "나의 공간을 한컷스튜디오와 함께 디자인해보세요!",
                    "Transform your living space effortlessly with our cutting-edge AI Interior Design tool. Simply upload a photo of your room, and watch as our intelligent system interprets your style preferences, crafting a personalized interior design tailored just for you. Gone are the days of envying others' homes; now, you can realize your dream space with ease and efficiency. Experience the future of home styling with our AI Space Styling Design Tool – where your imagination meets reality.": "사진을 찍은 후 업로드하면 AI가 이미지를 자동으로 분석하고, 원하는 스타일에 맞는 인테리어를 신속하게 제작해줍니다. 남의 집을 보며 상상만 했던 내 공간을 AI 공간 스타일링 디자인 도구를 통해 멋진 변화를 만들어보세요!",
                    "Unleash Your Inner Designer": "당신은 이미 디자이너입니다",
                    "Tired of the same old look? Dreaming of a fresh, stylish interior but worried about the time and expense? Hancut Studio is here to change all that. Hancut Studio empowers you to reimagine and transform your living space, combining cost-effectiveness with creative freedom. Experience the future of interior design: visualize your ideas in real-time, tailored to the unique layout of your home. Save time, save money, and step into the role of a space designer with confidence.": "한컷스튜디오에서는, 오래된 집의 인테리어를 취향에 맞게 변화시키고 직관적인 공간디자인을 미리 경험하여 자유롭게 인테리어 아이디어를 시각화하여 적용할수 있습니다. 사전정의된 인테리어 스타일 키워드와 풍부한 프롬프트를 만들어 당신이 머무는 공간을 자신있게 바꾸고 표현하시기 바랍니다.당신은 이미 공간디자이너입니다.",
                    "1 Minute is Enough to Design Your Room": "1분이면 충분합니다",
                    "See How It Works": "슬라이더를 좌우로 움직여보세요.",
                    "Transform your room in the blink of an eye with our revolutionary design tool. Our tool invites you to experiment with styles, colors, and layouts effortlessly, giving you the freedom to explore and refine your vision without any commitment. Skip the months-long planning process and see your dream room just in your coffee break. With Hancut Studio, every minute is an opportunity to bring your interior design dreams to life.": "한컷스튜디오에서는 공간디자인에 특화된 생성형 gpt기술을 기반으로 여러분을 위한 혁신적인 인테리어 디자인도구를 개발하였습니다.저희 기술은 인테리어 스타일, 색상, 레이아웃을 여러 가지 스타일로 선보여 인테리어를 구상하고 계획하는 과정을 쉽고 빠르게 구체화할 수 있습니다.한컷스튜디오에서 당신이 머무르는 모든 공간을 여러분의 미래로 만들어보세요. 1분이면 충분합니다.",
                    "Everyone Deserves a Room They Love": "한컷 스튜디오 AI 적용 사례",
                    "* Only apply during beta lunching period.": "* 베타 런칭 기간내에 적용됩니다.",
                    "Imagine a space that whispers your name, a haven where every corner sings your story. A perfect room that reflects your spirit, fuels your creativity, and wraps you in comfort. Embrace the joy of designing a space that's uniquely yours with Hancut Studio. Our belief is simple: everyone deserves to live in a room they adore, a place that reflects their personal style and comfort. Are you a minimalist at heart? Or perhaps the cozy warmth of Scandinavian design speaks to you. With our platform, you're not just limited to these; explore various design themes, each offering a unique aesthetic and mood.": "창의력을 자극하고, 편안함으로 감싸주는 나만의 완벽한 공간을  Hancut Studio 에서 만들어 보세요. 모든 사람은 자신이 좋아하는 방, 자신의 스타일과 편안함을 반영하는 공간에서 살 자격이 있습니다. 여러분은 미니멀리스트입니까? 아니면 스칸디나비아 디자인의 아늑하고 따뜻한 공간을 원하시나요? 한컷스튜디오에서 무한한 상상력으로 각각의 독특한 미학과 분위기를 제공하는 다양한 공간디자인 테마를 경험해 보시기 바랍니다.",

                    start_1_year_free: "1 년 무료로 사용하기",
                    start_free: "무료로 시작하기",
                    try_now: "바로 사용해보기",
                    register_now: "지금 가입하기",
                    "Why Hancut Studio?": "왜 한컷스튜디오인가?",
                    "AI Interior Design": "인테리어 디자인",
                    "Instantly transform any room with customized design options tailored to your preferences.": "맞춤형디자인으로 여러분의 공간을 바꿔보세요.",
                    "E-Commerce": "이커머스",
                    "After designing your room, effortlessly purchase the items directly through our platform.": "공간에 잘 어울리는 가구와 소품을 구매하실 수 있습니다.",
                    "Community": "커뮤니티",
                    "Join our vibrant community of design enthusiasts and interior design professionals.": "국내외 인테리어 디자인 전문가를 한컷스튜디오에서  만나보실 수 있습니다.",
                    "The perfect room is just a click away. Start creating yours today!": "한컷스튜디오로 가입하셔서 나만의 완벽한 공간을 만들어보세요.",
                    "Sign up for AI Space Styling Design Tool today and unlock a world of creative possibilities for your home!": "무료로 가입하여 이용해 보시기 바랍니다.",

                    // Password Reset
                    "Reset Password": "비밀번호 재설정",
                    "Enter your email address": "이메일 주소 입력",
                    "Send Password Reset Email": "암호 재설정 이메일 보내기",
                    "We have sent the password reset link to your email. Please check your email for reset password link.": "비밀번호 재설정 링크를 이메일로 보냈습니다. 이메일을 확인해 주세요.",
                    "Your password reset link has expired. Please request a new one.": "비밀번호 재설정 링크가 만료되었습니다. 새로운 링크를 요청하세요.",
                    "Send a new password reset link": "새로운 비밀번호 재설정 링크 보내기",
                }
            }
        },
        fallbackLng: "en",
        debug: false,

        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
