import React, { useState } from "react";
import { useTranslation, Trans } from 'react-i18next';

function QuickSelectImage({ img1, img2, img3, img4, handleQuickImageBase64 }) {
    return (
        <div style={{ width: "100%" }}>
            {/* <p className="text-md lg:text-xl mb-5" style={{ textAlign: "center" }}>
                or
            </p> */}
            <p className="text-md lg:text-xl mb-5" style={{ textAlign: "center", wordBreak: "keep-all" }}>
                <Trans>Quickly select from the following images to try.</Trans>
            </p>
            <div className='select-existing-upload-container' style={{ width: "100%" }}>
                <div className="select-existing-upload-image not-selectable" style={{ marginRight: "0%" }} onClick={() => handleQuickImageBase64(img1)} ><img src={img1} alt="Thumbnail" className="thumbnail-image br" /></div>
                <div className="select-existing-upload-image not-selectable" style={{ marginLeft: "0%" }} onClick={() => handleQuickImageBase64(img2)}> <img src={img2} alt="Thumbnail" className="thumbnail-image br" /></div>
            </div>

            <div className='select-existing-upload-container' style={{ marginTop: "30px" }}>
                <div className="select-existing-upload-image not-selectable" style={{ marginRight: "0%" }} onClick={() => handleQuickImageBase64(img3)} ><img src={img3} alt="Thumbnail" className="thumbnail-image br" /></div>
                <div className="select-existing-upload-image not-selectable" style={{ marginLeft: "0%" }} onClick={() => handleQuickImageBase64(img4)}> <img src={img4} alt="Thumbnail" className="thumbnail-image br" /></div>
            </div>
        </div>
    );
}

export default QuickSelectImage;