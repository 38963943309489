import React, { useEffect, useState } from 'react';
import '../assets/styles/Header.css';
import '../App.css';
import { useNavigate, useLocation } from 'react-router-dom';
import logoLabel from '../assets/images/hancut_logo_small.png';
import logoIcon from '../assets/images/hancut_logo_only.png';
import profileImg1 from '../assets/images/profiles/ToyFaces_Colored_BG_8.jpg'
import profileImg2 from '../assets/images/profiles/ToyFaces_Colored_BG_29.jpg'
import profileImg3 from '../assets/images/profiles/ToyFaces_Colored_BG_32.jpg'
import profileImg4 from '../assets/images/profiles/ToyFaces_Colored_BG_37.jpg'
import profileImg5 from '../assets/images/profiles/ToyFaces_Colored_BG_56.jpg'
import profileImg6 from '../assets/images/profiles/ToyFaces_Colored_BG_59.jpg'
import { ChevronDown, Shield } from 'react-feather';
import { Award } from 'react-feather';
import Avatar from 'react-avatar';
import useToken from './auth/useToken';
import { useTranslation, Trans } from 'react-i18next';
import { TweenMax } from 'gsap';
import { useWindowResize } from '../hooks/useWindowsResize';

function Header({ headerRef, toggleDropdown, project }) {

    const { isTablet, isDesktop } = useWindowResize();
    const { t } = useTranslation();
    const default_user_profile_images = [
        profileImg1,
        profileImg2,
        profileImg3,
        profileImg4,
        profileImg5,
        profileImg6];

    const [userType, setUserType] = useState(-1);
    const [userName, setUserName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userImg, setUserProfileImage] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState({
        status: "none",
    });

    const { token, setToken } = useToken();

    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = (navigate) => {
        localStorage.removeItem('session');
        navigate("/login", { replace: true });
    };

    const loadUser = () => {
        if (token) {
            fetch(process.env.REACT_APP_API + '/v1/profile', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(errorInfo => {
                            // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                            throw new Error(errorInfo.message);
                        });
                    }
                    else return response.json();
                })
                .then(data => {
                    // if (data !== undefined) {
                    //     // console.log(JSON.stringify(data));
                    //     if (data.status) {
                    //         const user = data.user;
                    //         setUserName(user.fname + " " + user.lname);
                    //         setUserEmail(user.email);
                    //         setUserProfileImage(user.profile_img);
                    //         setUserType(user.role);
                    //         if (data.active_subscription) {
                    //             setIsSubscribed(true);
                    //             setActiveSubscription(data.active_subscription);
                    //         }
                    //     }
                    // }
                    const user = data.user;
                    setUserName(user.fname + " " + user.lname);
                    setUserEmail(user.email);
                    setUserProfileImage(user.profile_img);
                    setUserType(user.role);
                    if (data.active_subscription) {
                        setIsSubscribed(true);
                        setActiveSubscription(data.active_subscription);
                    }
                })
                .catch(error => {
                    if (error.toString() === "Error: Invalid or missing token") {
                        console.log("Header: checked " + error);
                        localStorage.removeItem('session');
                        window.location.href = "/"; // Change the window path here
                    }
                    else {
                        console.log("Header: " + error);
                    }
                });
        }
    }
    useEffect(() => {
        loadUser();
    }, [token]);

    return (
        <div className="header" ref={headerRef}>
            <div style={{
                display: "flex",
                alignItems: "center"
            }}>
                <img src={logoLabel} alt="Logo" className="header-logo not-selectable" style={{ cursor: "pointer" }} onClick={() => {
                    if (!location.pathname.includes("/studio")) {
                        if (token)
                            navigate("/studio")
                        else navigate("/profile")
                    }
                    else navigate("/")
                }} />

                <img src={logoIcon} alt="Logo" className="header-logo-mobile not-selectable" style={{ cursor: "pointer" }} onClick={() => {
                    if (!location.pathname.includes("/studio/")) {
                        if (token)
                            navigate("/studio")
                        else navigate("/")
                    }
                }} />

                {

                    <div style={{
                        display: "flex"
                    }}>
                        <div style={{
                            color: "black",
                            fontWeight: "700",
                            fontSize: "14px",
                            marginLeft: "33px",
                            cursor: "pointer"
                        }} onClick={() => {
                            window.open("https://v2.hancut.my04.net/showcase", "_self");
                        }}>
                            <Trans>Showcase</Trans>
                        </div>
                        {
                            (isTablet || isDesktop) && (
                                <div style={{
                                    color: "black",
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    marginLeft: "33px",
                                    cursor: "pointer"
                                }} onClick={() => {
                                    navigate("/store");
                                }}>
                                    <Trans>Store</Trans>
                                </div>
                            )
                        }
                        {
                            (isTablet || isDesktop) && (
                                <div style={{
                                    color: "black",
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    marginLeft: "33px",
                                    cursor: "pointer"
                                }} onClick={() => {
                                    navigate("/designer");
                                }}>
                                    <Trans>04연구소</Trans>
                                </div>
                            )
                        }
                    </div>

                }
            </div>
            {/* <div className='mobile' style={{
                display: 'none',
                justifyContent: 'left',
                alignItems: 'center',
                width: '51px',
            }}>
                <img src={logoIcon} alt="Logo" className="header-logo not-selectable" style={{ cursor: "pointer" }} onClick={() => {
                    if (!location.pathname.includes("/studio/")) {
                        if (token)
                            navigate("/studio")
                        else navigate("/home")
                    }
                }} />
            </div> */}

            {project ? <p className='project_title'>{project}</p> : <div></div>}
            <div className="user-section">
                {!token && <p className="user-section-menu user-section-menu-mystudio not-selectable"
                    onClick={() => {
                        if (location.pathname.includes("/pcarpenter")) localStorage.setItem('login_remark', 'pcarpenter');
                        else localStorage.setItem('login_remark', 'default');
                        navigate("/login");
                    }}><Trans>Login</Trans></p>}
                {/* {!token && <p className="user-section-menu user-section-menu-mystudio not-selectable"
                    onClick={() => {
                        if (location.pathname.includes("/pcarpenter")) localStorage.setItem('login_remark', 'pcarpenter');
                        else localStorage.setItem('login_remark', 'default');
                        navigate("/register");
                    }}><Trans>Sign up</Trans></p>} */}
                {
                    token &&
                    <div>
                        <div className="profile-section not-selectable" onClick={toggleDropdown}>
                            {userImg === null
                                ? <Avatar name={userName} size="30px" round={true} color={'black'} textSizeRatio={2.2} maxInitials={1} />
                                : <img
                                    style={{
                                        border: isSubscribed ? "2px solid #111827" : "none",
                                    }}
                                    src={!isNaN(userImg) ? default_user_profile_images[userImg] : userImg.includes('http://') || userImg.includes('https://') ? `${userImg}` : `data:image/png;base64,${userImg}`} alt="Profile" className="profile-picture not-selectable" />}
                            <ChevronDown className="dropdown-icon not-selectable" color='black' size={15} />
                        </div>
                        {/* <button className="login-btn" onClick={() => handleLogout(nevigate)}>Logout</button> */}
                    </div>
                }
            </div>
        </div>
    );
}

export default Header;