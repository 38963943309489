import React, { useState } from "react";

function SubscriptionDialog({
    title,
    description,
    show,
    onClose,
    isCancellable = true,
    enableBackgroundClick = true,
    onOk,
    onCancel,
    okBtn = "Go to Subscription"
}) {

    const handleBackgroundClick = () => {
        if (enableBackgroundClick) {
            onClose();
        }
    };

    const handleOkClick = () => {
        onOk();
    };

    const handleCancelClick = () => {
        onCancel();
    };

    return (
        show && (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                }}
                onClick={handleBackgroundClick}
            >
                <div
                    className="dialog-container"
                    style={{
                        width: 500,
                        backgroundColor: "white",
                        borderRadius: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <h4 style={{ fontWeight: "bold" }}>{title}</h4>
                    <p className="dialog-description">{description}</p>
                    <p className="dialog-description" style={{
                        marginTop: "16px",
                        color: "#000"
                    }}>With Hancut Pro Membership, you can generate without any limitiations. Get access to Pro Membership now!</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        {isCancellable &&
                            <button style={{ marginRight: 20, flex: "1" }} onClick={handleCancelClick} className="dialog-button cancel-dialog">Cancel</button>
                        }
                        {isCancellable ? <button onClick={handleOkClick} className="dialog-button"
                            style={{
                                flex: "1.714"
                            }}>{okBtn}</button> : <button onClick={handleOkClick} className="dialog-button-single">{okBtn}</button>}

                    </div>
                </div>
            </div>
        )
    );
}

export default SubscriptionDialog;