import React, { useState, useEffect } from 'react';
import { Square, Columns, X, Download } from 'react-feather';
import { getLocalImageUrl } from '../../utils/Helper';

const ImageViewer = ({ generated_history, currentIndex, setCurrentIndex, onBackgroundClick, isSubscribed }) => {

    const [viewOption, setViewOption] = useState(0);
    const [currentDate, setCurrentDate] = useState(Date.now());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(Date.now());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const downloadBase64Image = (encodedImage) => {
        const link = document.createElement('a');
        link.href = encodedImage;
        link.download = 'hancutstudio_' + Math.floor((Math.random() * 999) + 100) + "_" + currentDate + '.jpeg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight' && currentIndex < (generated_history.length * 4) - 1) {
                setCurrentIndex((prevIndex) => prevIndex + 1);
            } else if (event.key === 'ArrowLeft' && currentIndex > 0) {
                setCurrentIndex((prevIndex) => prevIndex - 1);
            } else if (event.key === 'Escape') {
                onBackgroundClick();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex, generated_history.length]);

    return (
        <div className='image-viewer-container no-selectable'>

            {
                viewOption === 1 ?
                    <div className='image-viewer-original' onClick={() => onBackgroundClick()}>
                        {generated_history.length > 0 && (
                            <img
                                style={{
                                    marginLeft: "5%"
                                }}
                                className='no-selectable br'
                                src={(generated_history[currentIndex].original_image_url.includes("jpeg") ? `${process.env.REACT_APP_API_URL}` : `data:image/png;base64,`) + generated_history[currentIndex].original_image_url} alt={`Image ${currentIndex + 1}`}
                            />
                        )}
                        {generated_history.length > 0 && (
                            <img
                                style={{
                                    marginRight: "5%"
                                }}
                                className='no-selectable br' src={getLocalImageUrl(generated_history[currentIndex], isSubscribed)} alt={`Image ${currentIndex}`} />
                        )}
                    </div> :

                    <div className='image-viewer not-selectable' onClick={() => onBackgroundClick()}>
                        {generated_history.length > 0 && (
                            viewOption !== 1 && <img className='no-selectable br'
                                src={
                                    // (generated_history[Math.floor(currentIndex / 4)].images[currentIndex % 4].thumbnail.includes("jpeg") ? `${process.env.REACT_APP_API_URL}` : `data:image/png;base64,`) + generated_history[Math.floor(currentIndex / 4)].images[(currentIndex % 4)].image_url
                                    getLocalImageUrl(generated_history[currentIndex], isSubscribed)
                                }
                                alt={`Image ${currentIndex}`
                                } />
                        )}
                    </div>
            }

            <div className='preview-images-background no-selectable'></div>

            <div className='x-btn-container not-selectable'>
                <X className='x-btn' color='white' onClick={() => onBackgroundClick()} />
                <Download className='x-btn' color='white' onClick={() => downloadBase64Image(
                    getLocalImageUrl(generated_history[currentIndex], isSubscribed)
                )} />
            </div>

            <div className='preview-images-show-generated-option not-selectable'>
                <div className='image-viewer-show-original not-selectable' onClick={() => { setViewOption(1) }}>
                    <Columns color={viewOption === 1 ? "#fff" : "#A0A0A0"} className='not-selectable' />
                </div>
                <div className='image-viewer-show-generated not-selectable image-viewer-show-original-selected' onClick={() => { setViewOption(0) }}>
                    <Square color={viewOption === 0 ? "#fff" : "#A0A0A0"} className='not-selectable' /></div>
            </div>
        </div>
    );
}

export default ImageViewer;