/**
 * TODO: Replace with API call
*/

const products = [
    {
        id: 1,
        name: "Elegant Dreamer's Bed",
        desc: "A luxurious bed designed for ultimate comfort and elegance, perfect for a restful night's sleep.",
        image: "https://images.unsplash.com/photo-1620987278429-ab178d6eb547?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        price: 100,
        priceUnit: "KRW",
        category: "Bed",
        discount: 0,
    },
    {
        id: 2,
        name: "Vintage Charm Sofa",
        desc: "A retro-inspired sofa that brings a touch of vintage charm and comfort to any living room.",
        image: "https://www.ikea.com/global/en/images/PH_190955_0636d499ca_ed05dff729.jpg?f=xxxs",
        price: 100,
        priceUnit: "KRW",
        category: "Living Room",
        discount: 0,
    },
    {
        id: 3,
        name: "Minimalist Oak Table",
        desc: "Crafted from fine oak, this minimalist table is a perfect blend of functionality and modern design.",
        image: "https://images.unsplash.com/photo-1611486212355-d276af4581c0?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        price: 100,
        priceUnit: "KRW",
        category: "Dining Room",
        discount: 0,
    },
    {
        id: 4,
        name: "Scandinavian Comfort Chair",
        desc: "A stylish and comfortable chair that embodies the simplicity and functionality of Scandinavian design.",
        image: "https://images.unsplash.com/photo-1474508297924-60ae8de135eb?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        price: 100,
        priceUnit: "KRW",
        category: "Office",
        discount: 0,
    },
    {
        id: 5,
        name: "Modern Art Deco Lamp",
        desc: "Illuminate your space with this modern art deco lamp, blending contemporary design with classic elegance.",
        image: "https://www.ikea.com/global/en/images/IKEA_PS_V_LL_e647dc8bf5.jpg?f=sg",
        price: 100,
        priceUnit: "KRW",
        category: "Lighting",
        discount: 0,
    },
    {
        id: 6,
        name: "Urban Loft Bookshelf",
        desc: "A chic and functional bookshelf that brings an urban loft vibe to your personal library or living space.",
        image: "https://images.unsplash.com/photo-1567016520496-0cb37d8467a7?q=80&w=1760&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        price: 100,
        priceUnit: "KRW",
        category: "Study Room",
        discount: 0,
    },
    {
        id: 7,
        name: "Rustic Countryside Dresser",
        desc: "A rustic dresser that evokes the serene and simplistic beauty of the countryside, perfect for bedroom storage.",
        image: "https://images.unsplash.com/photo-1584589167171-541ce45f1eea?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        price: 100,
        priceUnit: "KRW",
        category: "Bed",
        discount: 0,
    },
    {
        id: 8,
        name: "Elegant Dreamer's Bed",
        desc: "A luxurious bed designed for ultimate comfort and elegance, perfect for a restful night's sleep.",
        image: "https://images.unsplash.com/photo-1532372576444-dda954194ad0?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        price: 100,
        priceUnit: "KRW",
        category: "Bed",
        discount: 0,
    }
]

const getProductsByCategories = () => {
    return products;
}

export default getProductsByCategories;