
{/* 🎵 🎬*/ }

export const protips_items = [
    {
        _id: 0,
        title: 'I\'m a Barbie girl, in the Barbie world!',
        description: 'Living room, coastal, hot pink, princess, barbie, sofa, whtie table, ribbon, pink capet,cushion glitter, Feminine and Glamorous Elements, Vintage and Retro Touches, Glamorous Furniture, Floral Prints and Patterns, Mirrored Surfaces, Dressing Room Area, Barbie Doll Collection Display, Soft Fabrics and Textures, Playful Accents and Accessories',
        emoji: '🎬',
        topic: 'Barbie'
    },
    {
        _id: 1,
        title: 'Vibing with the BLACKPINK\'s tunes',
        description: 'Living room, sofa, whtie table, Milky Way, pink mood light, black sofa, pink rose, black rose, hip-hop, Black, pink, carpet ,Provence, Sleek and Edgy, Monochrome Color Palette, Statement Wall Art, Dynamic Lighting Effects, Industrial Elements, Bold Patterns and Textures, Music-inspired Decorations, Stage-like Display Area, Fashion-inspired Details, Playful Accents and Accessories',
        emoji: '🎵',
        topic: 'BLACKPINK'
    },
    {
        _id: 2,
        title: 'BTS comes to my space like butter!',
        description: 'Living room, Orange, Retro Vibes, Vibrant Colors, Disco Elements, Music-inspired Decorations, Sleek and Modern Furniture, Dance Floor Area, Stage-like Display Area, Lyrics and Quotes Wall Art, K-Pop Merchandise Display, Dynamic Lighting Effects',
        emoji: '🎵',
        topic: 'BTS'
    },
    {
        _id: 3,
        title: 'Bella ciao, bella ciao, bella ciao, ciao, ciao !',
        description: 'Living room, steel, Dark red, loft, Industrial, Blood, fear, gun, Dark, Black sofa, table, Brick, Money, sewer, Safe',
        emoji: '📺',
        topic: 'Paper house'
    },
    {
        _id: 4,
        title: 'Imagine Squid Game 2',
        description: 'Please design an industrial style space that emphasizes the thriller atmosphere, use mint and hot pink as the point color, create a scary atmosphere and include elements of blood everywhere, a scary atmosphere should be depicted, including a giant girl doll shooting a laser from her eyes, wallpaper with circles, triangles, squares, thriller atmosphere, industrial style, mint, hot pink',
        emoji: '📺',
        topic: 'Squid game'
    },
    {
        _id: 5,
        title: 'My room where everything is planned',
        description: 'Living room, Minimalism, Wood Table, Blue Sofa, Staircase, dark',
        emoji: '🎬',
        topic: 'Parasite'
    },
    {
        _id: 6,
        title: 'This place I came out after chasing a white rabbit',
        description: 'living room, green, red, neoclassical, palace, sofa, table, forest, Cheshire Cat Smile, complex, antique mirror, checkerboard carpet, White rabbit, key, classical, Bohemian, Tea Party Set, Mushroom Decorations, Playing Card Motifs, Clocks and Timepieces, Clocks and Timepieces, Whimsical Artwork, red',
        emoji: '🎬',
        topic: 'Alice in wonderland'
    },
    {
        _id: 7,
        title: 'The Avengers who meet in my space',
        description: 'Superhero Colors, Comic Book Art, Superhero Logos and Symbols, Movie Posters and Merchandise Display, Action-packed Wall Murals, Avengers-inspired Furniture and Decorations, Cosplay Corner or Display Area, Geeky Gadgets and Tech Integration, Movie Theater Setup or Entertainment Area, Lighting Effects and Mood Lighting Control',
        emoji: '🎬',
        topic: 'Avengers'
    },
    {
        _id: 8,
        title: 'MY04 to HOGWARTS for one way travel Platform 9 3/4',
        description: 'Wizarding World, Gothic Architecture, Bookshelves and Libraries, Candlelight and Dim Lighting, Magical Artwork and Posters, Four House Colors, Magical Creatures and Artifacts Display, Quidditch-inspired Decorations, Magical Doorway or Hidden Passageway, House Scarf or Robe-inspired Textiles',
        emoji: '🎬',
        topic: 'Harry potter'
    },
    {
        _id: 9,
        title: 'Imagine the real world',
        description: 'Nature-inspired Color Palette, Organic Materials, Botanical Elements, Bioluminescent Lighting, Water Features and Aquariums, Ethnic Textiles and Patterns, Floating Furniture or Suspended Elements, Avatar-inspired Artwork and Posters, Zen Meditation Area or Peaceful Nook, Audiovisual Integration and Sound Effects',
        emoji: '🎬',
        topic: 'Avatar'
    },
    {
        _id: 10,
        title: 'A Journey of Self-Discovery and True Love',
        description: 'Emily Paris Vintage French Interior Inspired by the movie This space focuses on expressing the Paris and Montmartre hills of France, highlighting the colorful panoramic views of the vast Montmartre hills, with walls decorated with various beautiful artwork or graphic art highlighting the artistic atmosphere of Paris, complete the interior with special Paris design elements and colorful lights, along with French-style furniture, cute props, and colorful palette, a unique space recreating the colorful and lively atmosphere felt by Emily Paris; a Wayne\'s coat; and a view of Paris outside the window',
        emoji: '📺',
        topic: 'Emily in Paris'
    },
    {
        _id: 11,
        title: 'Perfect party, perfect dream',
        description: 'Virtual Idol Imagine a colorful space in the world, this realistic and virtual interior captures the landscape of the high-tech future, and is full of cute details full of full moon and hearts, fill the space with cheongborah and navy purple, and create the space beautifully with a gradation effect, virtual idol, high-tech interior, jeongborah and navy purple, pantone color, gradation effect, full moon-shaped neon signs, heart motif, virtual reality, fancy space, digital art',
        emoji: '🎵',
        topic: 'LESSERAFIM'
    }
];

export const basic_tips = [
    {
        _id: 0,
        type: "message",
        title: 'Simple but sensual classic mood minimalism',
        smallTitle: 'Prompt Example',
        description: 'minimalism, white, black, steel, Simplicity, Space, Functionality, Neutral Colors, Clean Lines, Natural Light, Orderliness, Minimal Decoration, Modern Elements, Texture and Materials'
    },
    {
        _id: 1,
        type: "message",
        title: 'a place with urban sense',
        smallTitle: 'Prompt Example',
        description: 'Urban Modern, Black,Poserine Tile, White Silk Wallpaper'
    },
    {
        _id: 2,
        type: "message",
        title: 'I\'ll live like an elegant princess in a mansion',
        smallTitle: 'Prompt Example',
        description: 'Provence, Romantic,Pastel Colors,Natural Materials,Ornate Fabrics,frill,Flowers and Vases,Vintage Furniture,Artwork and Murals'
    },
    {
        _id: 3,
        type: "message",
        title: 'a tranquil space on Miami Beach',
        smallTitle: 'Prompt Example',
        description: 'Costal, overall white feel, point color blue, polishing'
    },
    {
        _id: 4,
        type: "message",
        title: 'a simple and attractive vintage',
        smallTitle: 'Prompt Example',
        description: 'vintage interior, brown, leather,Antique Furniture,Retro Patterns,Art Deco Elements,Handmade Items,Vintage Accessories'
    },
    {
        _id: 5,
        type: "message",
        title: 'affectionate and beautiful',
        smallTitle: 'Prompt Example',
        description: 'Modern Farm House,Shiplap,Shiplap Curtains,Pottery and Ceramic,Pendant Lighting,Wall Decor,Modern Furniture'
    },
    {
        _id: 6,
        type: "message",
        title: 'The space that I like I like to choose',
        smallTitle: 'Prompt Example',
        description: 'New Nordic,Scandinavian Minimalism,Functional Design Elements,Handmade or Artisan Accessories,Indoor Plants for Freshness,Soft Lighting for Coziness'
    },
    {
        _id: 7,
        type: "message",
        title: 'timeless elegance',
        smallTitle: 'Prompt Example',
        description: 'Art deco,Bold Geometric Patterns,Rich Color Palette,Luxurious Materials,Sunburst or Fan Motifs,Glamorous Lighting,Mirrors and Chrome Accents,Artwork and Sculptures,High Contras'
    },
    {
        _id: 8,
        type: "message",
        title: 'traditional beauty',
        smallTitle: 'Prompt Example',
        description: 'Traditional,Classic Furniture Styles,Rich Color Palette,Detailed Woodwork and Moulding,Luxurious Fabrics,Antique Pieces or Artworks,Ornate Details,Symmetry in Layout'
    },
    {
        _id: 9,
        type: "message",
        title: 'My own sea in my space',
        smallTitle: 'Prompt Example',
        description: 'Beach, Surf Style,white,Bright and Airy Colors,Natural Materials,Beach-Inspired Decorations,Casual and Comfortable Furniture,Nautical Elements,Lightweight Fabrics'
    }
];