// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --yellow: #FFC529;
  --yellow-light: #FFD94D;
  --yellow-dark: #FFB800;
  --secondary-gray: #9e9ea7;
  --dark-navy: #3d3d4e;
}

@font-face {
  font-family: InterFont;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: PoppinsFont;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/global.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,uBAAuB;EACvB,sBAAsB;EACtB,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;EACtB,4CAAoC;AACtC;;AAEA;EACE,wBAAwB;EACxB,4CAAsC;AACxC","sourcesContent":[":root {\n  --yellow: #FFC529;\n  --yellow-light: #FFD94D;\n  --yellow-dark: #FFB800;\n  --secondary-gray: #9e9ea7;\n  --dark-navy: #3d3d4e;\n}\n\n@font-face {\n  font-family: InterFont;\n  src: url(../fonts/Inter-Regular.ttf);\n}\n\n@font-face {\n  font-family: PoppinsFont;\n  src: url(../fonts/Poppins-Regular.ttf);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
