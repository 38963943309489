import React, { useState, useEffect } from "react";
import "./ImageSearchViewer.css";

const ImageSearchViewer = ({ image }) => {
    return (
        <div className="image-search-viewer">
            <div className="image-search-viewer-image">
                <img src={image} alt={image} />
            </div>
        </div>
    );
}

export default ImageSearchViewer;