import { useState } from "react";
import { getImageSearch } from "../../studio"

const useImageSearch = () => {
    const [error, setError] = useState(null);
    const [similarProducts, setSimilarProducts] = useState([]);

    const handleSearchImage = async (credentials) => {
        try {
            const response = await getImageSearch();
            setSimilarProducts(response);
            setError(null);
        }
        catch (error) {
            setError(error.message || "Failed to load similar products!");
        }
    }

    return { similarProducts, handleSearchImage, error };
}

export default useImageSearch;