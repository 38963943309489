import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../assets/styles/Studio.css";
import DefaultDialog from "../dialog/DefaultDialog";
import "font-awesome/css/font-awesome.min.css";
import Header from "../Header";
import Footer from "../Footer";
import { Toaster } from "react-hot-toast";
import { Trans } from "react-i18next";

export default function PasswordReset() {
  const [email, setEmail] = useState();

  const [isDialogShow, setIsDialogShow] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogAction, setDialogAction] = useState("");
  const [isBackgroundClickable, setIsBackgroundClickable] = useState(true);

  const [isInputStarted, setIsInputStarted] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordResetSend, setPasswordResetSend] = useState(false);

  const navigate = useNavigate();

  async function sendResetPassword(credentials, navigate) {
    return fetch(process.env.REACT_APP_API + "/v1/auth/forget_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorInfo) => {
            setDialogTitle("Error");
            setDialogAction("");
            setDialogMessage(errorInfo.message);
            setIsDialogShow(true);
            setIsBackgroundClickable(true);
          });
        } else return response.json();
      })
      .then((data) => {
        if (data != undefined) {
          if (data.status) {
            // setDialogTitle('Success');
            // setDialogMessage('Please check your email for reset password link.');
            // setDialogAction('login');
            // setIsDialogShow(true);
            // setIsBackgroundClickable(false);
            setPasswordResetSend(true);
          } else {
            setDialogTitle("Password reset");
            setDialogAction("");
            setDialogMessage(data.message);
            setIsDialogShow(true);
            setIsBackgroundClickable(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setDialogTitle("Error");
        setDialogAction("");
        setDialogMessage("Unexpected error occurs.");
        setIsDialogShow(true);
        setIsBackgroundClickable(true);
      });
  }

  const handleSubmit = async () => {
    // e.preventDefault();

    let error = false;

    if (!email) {
      setEmailError("Username cannot be empty.");
      error = true;
    }

    if (isInputStarted && !emailError) {
      await sendResetPassword(
        {
          email: email,
        },
        navigate
      );
    }
  };

  return (
    <div className="AuthApp">
      <Header toggleDropdown={() => {}} />
      <div className="content">
        {passwordResetSend ? (
          <>
            <div className="wrap-content">
              <p
                className="verify-text"
                style={{
                  fontWeight: "bold",
                }}
              >
                <Trans>
                  We have sent the password reset link to your email. Please
                  check your email for reset password link.
                </Trans>
              </p>
            </div>
          </>
        ) : (
          <div className="wrap-content">
            <section>
              <h2 className="auth-title">
                <Trans>Reset Password</Trans>
              </h2>
            </section>
            <div className="auth-page">
              <div
                className=""
                style={{
                  width: "100%",
                  maxWidth: "1000px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="login-form">
                  <label>
                    <p
                      style={{
                        marginTop: "2px",
                        marginBottom: "10px",
                        color: "black",
                      }}
                    >
                      <Trans>Enter your email address</Trans>
                    </p>
                    <input
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={() => {
                        setIsInputStarted(true);
                        if (!email) setEmailError("Email cannot be empty.");
                        else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            email
                          )
                        )
                          setEmailError("Invalid email address");
                        else setEmailError("");
                      }}
                    />
                    {emailError && (
                      <div
                        className="input-feedback"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <Trans>{emailError}</Trans>
                      </div>
                    )}
                  </label>
                  <div>
                    <button
                      className="loginBtn"
                      style={{
                        marginTop: "28px",
                      }}
                      type="submit"
                      onClick={() => handleSubmit()}
                    >
                      <Trans>Send Password Reset Email</Trans>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
      {isDialogShow && (
        <DefaultDialog
          title={dialogTitle}
          description={dialogMessage}
          show={isDialogShow}
          isCancellable={false}
          onClose={() => {
            setIsDialogShow(false);
          }}
          onOk={() => {
            setIsDialogShow(false);

            // if (dialogAction === 'login')
            //     navigate("/login", { replace: true });
          }}
          okBtn="Ok"
          onCancel={() => {}}
          enableBackgroundClick={isBackgroundClickable}
        />
      )}
      <Toaster />
    </div>
  );
}
