import React, { useEffect } from "react";
import './StudioGenerateImageSearch.css';
import { ImageSearchViewer, SimilarProducts, useImageSearch } from "../../features/studio";
import image_search_preview from '../../assets/images/studio/image_search_preview.png'
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const StudioGenerateImageSearch = () => {
    const { similarProducts, handleSearchImage, error } = useImageSearch();

    useEffect(() => {
        handleSearchImage("https://i.imgur.com/9m9D4qQ.jpg");
    }, []);

    return (
        <div className="studio-generate-product-search-wrapper">
            <Header />
            <div className="studio-generate-product-search">
                <ImageSearchViewer image={image_search_preview} />
                <SimilarProducts products={similarProducts} />
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default StudioGenerateImageSearch;