import React, { useState, useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { getLocalImageUrl } from '../utils/Helper'

function CreateProjectDialog({
    item,
    show,
    onClose,
    isCancellable = true,
    enableBackgroundClick = true,
    onOk,
    onCancel,
    setPreTitle = "",
    setPreDescription = "",
    okBtn = "Submit"
}) {
    const [descriptionInput, setDescriptionValue] = useState(setPreDescription);

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize]);
    const isTablet = () => {
        return screenSize.width > 768;
    }
    const isDesktop = () => {
        return screenSize.width > 1280;
    }

    const handleBackgroundClick = () => {
        if (enableBackgroundClick) {
            onClose();
        }
    };

    const handleOkClick = () => {
        onOk();
        toast('Thank you for your valuable feedback.',
            {
                icon: '❤️',
                style: {
                    borderRadius: '10px',
                    background: '#fff',
                    color: '#000',
                    borderColor: "#000",
                    borderWidth: "2px",
                },
            }
        );
    };

    const handleCancelClick = () => {
        onCancel();
    };

    return (
        show && (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                }}
                onClick={handleBackgroundClick}
            >
                <div
                    className="dialog-container"
                    style={{
                        width: screenSize.width < 500 ? screenSize.width : 500,
                        backgroundColor: "white",
                        borderRadius: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <h4 style={{ fontWeight: "bold" }}>Thanks for your feedback.</h4>
                    <p className="dialog-description">Please let us know what went wrong with the generation. We value your feedback and will look into it to provide better services.</p>
                    <img className="br not-selectable" style={{
                        maxWidth: isTablet() ? 500 : 100,
                        marginTop: "16px",
                        objectFit: "cover",
                        aspectRatio: "1/1",
                    }} src={item.img} alt={"Feedback Image"} />
                    <p className="dialog-description label" style={{
                        marginTop: "20px"
                    }}>Feedback</p>
                    <textarea
                        className="dialog-input textarea"
                        type="text"
                        maxLength={200}
                        placeholder="Please briefly explain why you think the generation is bad"
                        value={descriptionInput}
                        onChange={(e) => setDescriptionValue(e.target.value)}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        {isCancellable ? <button style={{ marginRight: 20 }} onClick={handleCancelClick} className="dialog-button cancel-dialog">Cancel</button> : <div></div>}
                        {(descriptionInput !== "")
                            ? <button onClick={handleOkClick} className="dialog-button">{okBtn}</button>
                            : <button disabled onClick={handleOkClick} className="dialog-button">{okBtn}</button>}
                    </div>
                </div>
            </div>
        )
    );
}

export default CreateProjectDialog;