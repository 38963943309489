/**
 * TODO: Replace with API call
*/

const trendingCategories = [
    {
        id: 1,
        name: 'Trending',
    },
    {
        id: 2,
        name: 'New',
    },
    {
        id: 3,
        name: 'Best Seller',
    },
    {
        id: 4,
        name: 'Sale',
    }
];

const getTrendingCategories = () => {
    return trendingCategories;
}

export default getTrendingCategories;