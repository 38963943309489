import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import '../assets/styles/Studio.css';
import logoImg from '../assets/images/hancut_logo_only.png';
import useToken from './auth/useToken';
import Header from './Header';
import Footer from './Footer';
import '../assets/styles/Header.css'
import 'font-awesome/css/font-awesome.min.css';
import toast, { Toaster } from 'react-hot-toast';
import DropdownMenu from './DropdownMenu';
import SwipeableGallery from './utils/SwipeableGallery';
import GoogleLoginButton from './auth/GoogleLoginButton';
import { isDesktop, isTablet } from './utils/Helper';
import DefaultDialog from './dialog/DefaultDialog';
import { ArrowLeft } from 'react-feather';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import LogoAnimation from '../assets/lottie/hancut_studio_loading_icon.json'
import loadingSpinner from '../assets/lottie/loading_spin.json'
import ReactGA from "react-ga4";
import { Trans, useTranslation } from 'react-i18next';
import KakaoLogin from 'react-kakao-login';
import KakaoImg from '../assets/images/kakao_img.png';
import LandingImage from '../assets/images/landing_image3.png';
import '../assets/styles/Landing.css';
import GoogleLoginLogoButton from './auth/GoogleLoginLogoButton';

export default function Home() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [shareId, setShareId] = useState();
    const [shareUserName, setShareUserName] = useState();
    const [showShareImage, setShowShareImage] = useState(false);
    const [shareImageUrl, setShareImageUrl] = useState();

    const kakaoToken = process.env.REACT_APP_KAKAO_ID;
    const { t, i18n } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const [isErrorMessageShow, setIsErrorMessageShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isDialogShow, setIsDialogShow] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogOkBtn, setDialogOkBtn] = useState("Ok");
    const navigate = useNavigate();

    const { token, setToken } = useToken();

    // Image Generation
    const [isGenerateInitiated, setIsGenerateInitiated] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [previewImage, setPreviewImage] = useState(undefined);
    const [initialImage, setInitialImage] = useState(undefined);
    const [isGenerating, setIsGenerating] = useState(false);

    const [generatedImage1, setGeneratedImage1] = useState(undefined);
    const [generatedImage2, setGeneratedImage2] = useState(undefined);
    const [generatedImage3, setGeneratedImage3] = useState(undefined);

    const data = [
        {
            img: 'https://hancut.my04.net/api/assets/landing_emily.jpeg',
            title: 'Emily in Paris',
            desc: 'A Journey of Self-Discovery and True Love',
            prompt: 'bedRoom, Emily Paris Vintage French Interior Inspired by the movie This space focuses on expressing the Paris and Montmartre hills of France, highlighting the colorful panoramic views of the vast Montmartre hills, with walls decorated with various beautiful artwork or graphic art highlighting the artistic atmosphere of Paris, complete the interior with special Paris design elements and colorful lights, along with French-style furniture, cute props, and colorful palette, a unique space recreating the colorful and lively atmosphere felt by Emily Paris; a Wayne\'s coat; and a view of Paris outside the window'
        },
        {
            img: 'https://hancut.my04.net/api/assets/landing_LESSERAFIM.png',
            title: 'Perfect party, Perfect Dream!',
            desc: 'Dreaming of a Perfect Party with Friends, in My Own Imaginary Bedroom',
            prompt: 'Imagine the colorful spaces of the virtual idol world. Realistic and virtual, this interior captures the landscape of the high-tech future and is filled with cute details full of full moons and hearts, and fills the space with a cheongborah and navy purple, creating a beautiful space with a gradation effect. Virtual idol, high-tech interior, cheongborah and navy purple, pantone color, gradation effect, full moon-shaped neon sign, heart motif, virtual reality, colorful space, digital art'
        }
    ];

    const loginWithKakao = () => {
        window.Kakao.Auth.authorize({
            redirectUri: 'https://hancut.my04.net/oauth/kakao',
        });
    };

    const displayToken = () => {
        const token = getCookie('authorize-access-token');
        if (token) {
            window.Kakao.Auth.setAccessToken(token);
            window.Kakao.Auth.getStatusInfo()
                .then((res) => {
                    if (res.status === 'connected') {
                        // setToken('login success, token: ' + Kakao.Auth.getAccessToken());
                    }
                })
                .catch((err) => {
                    window.Kakao.Auth.setAccessToken(null);
                });
        }
    };

    const getCookie = (name) => {
        const parts = document.cookie.split(name + '=');
        if (parts.length === 2) return parts[1].split(';')[0];
    };

    useEffect(() => {
        // Load the Kakao SDK
        const script = document.createElement('script');
        script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.5.0/kakao.min.js';
        script.integrity = 'sha384-kYPsUbBPlktXsY6/oNHSUDZoTX6+YI51f63jCPEIPFP09ttByAdxd2mEjKuhdqn4';
        script.crossOrigin = 'anonymous';
        document.body.appendChild(script);

        script.onload = () => {
            // Initialize Kakao SDK
            window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
            displayToken();
        };

        const share = searchParams.get("share");
        if (share) {
            setShareId(share);
        }
    }, []);

    // TO CLEAN UP
    async function googleLogin(credentials, setToken, navigate) {

        return fetch(process.env.REACT_APP_API + '/google_login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data != undefined) {
                    if (data.status) {
                        setToken(data.token);
                        navigate("/studio", { replace: true });
                    }
                    else {
                        setErrorMessage(data.message);
                        setIsErrorMessageShow(true);
                    }
                }
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    const handleGoogleLogin = async (access_token) => {
        await googleLogin({
            access_token
        }, setToken, navigate);
    }

    async function kakaoLogin(credentials, setToken, navigate) {

        return fetch(process.env.REACT_APP_API + '/kakao_login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data != undefined) {
                    if (data.status) {
                        setToken(data.token);
                        navigate("/studio", { replace: true });
                    }
                    else {
                        setErrorMessage(data.message);
                        setIsErrorMessageShow(true);
                    }
                }
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage("Unexpected error occurs.");
                setIsErrorMessageShow(true);
            });
    }

    async function getShareInfo(credentials, navigate) {
        console.log(credentials.id);
        return fetch(process.env.REACT_APP_API + '/v1/studio/share/' + credentials.id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        // setErrorMessage(errorInfo.message);
                        // setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data !== undefined) {
                    setShareImageUrl(data.share_image.image_url);
                    setShareUserName(data.user);
                    setShowShareImage(true);
                }
            })
            .catch(error => {
                console.log(error.message);
            });
    }

    useEffect(() => {
        if (shareId) {
            getShareInfo({ id: shareId });
        }
    }, [shareId]);

    // Image Generation Logics
    const handleGenerateImage = (index) => {
        setIsGenerating(true);

        ReactGA.initialize("G-GNSL6M6VMP");
        ReactGA.event({
            category: "User Engagement",
            action: "Landing Generate Clicked",
            label: "Selected Option [" + index + "]",
            // value: 99, // optional, must be a number
        });
        ReactGA.send({ hitType: "pageview", page: "/landing-generate", title: "Landing Generate Clicked" });

        const payload = {
            "enable_hr": true,
            "room": index,
            "prompt": data[index].prompt,
        };

        console.log('Generating');
        fetch("/img2img_try", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        console.log(errorInfo);
                        // alert(`Error: ${response.status} ${response.statusText} - ${errorInfo.message}`);
                        setErrorMessage(errorInfo.message);
                        setIsErrorMessageShow(true);
                    });
                }
                else return response.json();
            })
            .then((data) => {
                ReactGA.initialize("G-GNSL6M6VMP");
                ReactGA.event({
                    category: "User Engagement",
                    action: "Landing Generate Finished",
                    label: "Selected Option [" + index + "]",
                    // value: 99, // optional, must be a number
                });
                ReactGA.send({ hitType: "pageview", page: "/landing-generate", title: "Landing Generate Finished" });

                setIsGenerating(false);
                setPreviewImage("https://hancut.my04.net/api/uploads/" + data.data.images[0]);
                setGeneratedImage1("https://hancut.my04.net/api/uploads/" + data.data.images[0]);
                setGeneratedImage2("https://hancut.my04.net/api/uploads/" + data.data.images[1]);
                setGeneratedImage3("https://hancut.my04.net/api/uploads/" + data.data.images[2]);
            })
            .catch(error => {
                console.log("Error fetching data: ", error);
                setIsGenerating(false);
                setTimeout(function () {
                    setErrorMessage("Unexpected error occurs when generating.");
                    setIsErrorMessageShow(true);
                }, 500);
            });
    };

    return (
        <div className="DefaultApp LandingPage">
            <Header toggleDropdown={toggleDropdown} />
            <div className="content not-selectable" style={{
                marginTop: "10px",
                justifyContent: "flex-start"
            }}>
                {
                    showShareImage && shareImageUrl &&
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: "33px",
                        marginBottom: "66px"
                    }}>
                        <p style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "16px",
                            paddingLeft: "50px",
                            paddingRight: "50px",
                            wordBreak: "keep-all"
                        }}><b style={{
                            fontWeight: "700"
                        }}>{shareUserName}</b> 님이  한컷 스튜디오에서 제작한 전후 이미지를 공유해왔습니다.</p>
                        <img src={`https://hancut.my04.net/share/uploads/shared/${shareImageUrl}`}
                            style={{
                                width: "100%",
                                maxWidth: "1000px"
                            }} />
                        {/* <p style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "16px",
                            paddingLeft: "50px",
                            paddingRight: "50px"
                        }}>Try your image now</p> */}
                        <div className='landing-social-logins-wraper' style={{
                            alignItems: "center",
                            marginTop: "50px"
                        }}>
                            <button className='button-landing' onClick={() => {
                                navigate("/login");
                                // const isLoginOnce = JSON.parse(localStorage.getItem('isLoginOnce'));
                                // if (isLoginOnce !== null && isLoginOnce.status === true) {
                                //     navigate("/login");
                                //     return;
                                // }
                                // else {
                                //     ReactGA.initialize("G-EVSWTBYQ7M");
                                //     ReactGA.event({
                                //         category: "User Engagement",
                                //         action: "No Login Try Now",
                                //     });
                                //     navigate("/studio");
                                //     return;
                                // }
                            }}>
                                무료 체험 바로 하기
                                <div className="icon">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                            <path
                                                d="M1.05403 31.6175C0.271626 32.3972 0.271626 33.6614 1.05403 34.441C1.83644 35.2207 3.10497 35.2207 3.88737 34.441L1.05403 31.6175ZM35.5599 2.05152C35.5599 0.948871 34.6629 0.0549994 33.5564 0.0549994L15.5251 0.0549994C14.4187 0.0549994 13.5217 0.948871 13.5217 2.05152C13.5217 3.15416 14.4187 4.04804 15.5251 4.04804H31.5529V20.0202C31.5529 21.1228 32.4499 22.0167 33.5564 22.0167C34.6629 22.0167 35.5599 21.1228 35.5599 20.0202L35.5599 2.05152ZM3.88737 34.441L34.9731 3.46327L32.1397 0.639766L1.05403 31.6175L3.88737 34.441Z"
                                                fill="#000" />
                                        </svg>
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                }
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100vw",
                    backgroundColor: "#f7f7f7",
                }}>
                    <div
                        className='landing_wrapper'
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            maxWidth: "1200px",
                            marginTop: "22px",
                            marginBottom: "22px"
                        }}>
                        <div
                            className='landing_left'
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "left"
                            }}>
                            <p
                                className='landing_title'
                                style={{
                                    width: "100%",
                                    fontSize: "32px",
                                    textAlign: "left",
                                    color: "#000",
                                    fontWeight: "900",
                                    fontFamily: "Inter",
                                    marginTop: "0px",
                                    marginBottom: "22px"
                                }}>
                                <Trans>AI-powered Interior Styling Platform</Trans>
                            </p>
                            <p
                                className='landing_description'
                                style={{
                                    width: "100%",
                                    fontSize: "18px",
                                    textAlign: "left",
                                    color: "#000",
                                    fontWeight: "200",
                                    fontFamily: "Inter",
                                    marginTop: "0px"
                                }}>
                                <Trans>Generate the best indoor scene with Hancut Studio with Al-powered prompt packages</Trans>
                            </p>

                            <div className='landing-social-logins-wraper'>
                                <button className='button-landing' onClick={() => {
                                    const isLoginOnce = JSON.parse(localStorage.getItem('isLoginOnce'));
                                    if (isLoginOnce !== null && isLoginOnce.status === true) {
                                        navigate("/login");
                                        return;
                                    }
                                    else {
                                        ReactGA.initialize("G-EVSWTBYQ7M");
                                        ReactGA.event({
                                            category: "User Engagement",
                                            action: "No Login Get Started",
                                        });
                                        navigate("/studio");
                                        return;
                                    }
                                }}>
                                    Get Started

                                    <div className="icon">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                                <path
                                                    d="M1.05403 31.6175C0.271626 32.3972 0.271626 33.6614 1.05403 34.441C1.83644 35.2207 3.10497 35.2207 3.88737 34.441L1.05403 31.6175ZM35.5599 2.05152C35.5599 0.948871 34.6629 0.0549994 33.5564 0.0549994L15.5251 0.0549994C14.4187 0.0549994 13.5217 0.948871 13.5217 2.05152C13.5217 3.15416 14.4187 4.04804 15.5251 4.04804H31.5529V20.0202C31.5529 21.1228 32.4499 22.0167 33.5564 22.0167C34.6629 22.0167 35.5599 21.1228 35.5599 20.0202L35.5599 2.05152ZM3.88737 34.441L34.9731 3.46327L32.1397 0.639766L1.05403 31.6175L3.88737 34.441Z"
                                                    fill="#000" />
                                            </svg>
                                        </span>
                                    </div>
                                </button>
                            </div>

                            <div className='landing-social'>

                            </div>

                            {
                                !token &&
                                <div className='landing-social-logins-wraper'>
                                    <p style={{
                                        color: "#000",
                                        fontSize: "14px",
                                        marginTop: "44px",
                                        marginBottom: "22px",
                                        fontWeight: "normal",
                                        textAlign: "left",
                                    }}>
                                        <Trans>Continue with one tap sign-up.</Trans>
                                    </p>
                                    <div className='landing-social-logins-form login-form'
                                        style={{
                                            width: "200px",
                                            flexDirection: "row",
                                            display: "flex",
                                            gap: "22px",
                                        }}>

                                        <button href="javascript:;"
                                            className='card-view-shadow2'
                                            style={{
                                                borderRadius: "30px",
                                                width: "63px",
                                                height: "63px",
                                                backgroundColor: "#FEE500",
                                            }}
                                            onClick={loginWithKakao}>
                                            <img src={KakaoImg} style={{
                                                width: "18px",
                                                height: "auto",
                                                marginTop: "6px",
                                            }}></img>
                                        </button>
                                        <GoogleLoginLogoButton
                                            isLanding={true}
                                            isDesktop={isDesktop()}
                                            onSuccessResponse={(credentialResponse) => {
                                                console.log(JSON.stringify(credentialResponse));
                                                if (credentialResponse.access_token) {
                                                    handleGoogleLogin(credentialResponse.access_token);
                                                }
                                            }} onFailureResponse={(error) => {
                                                setErrorMessage(error);
                                                setIsErrorMessageShow(true);
                                            }} />
                                    </div>
                                </div>
                            }
                        </div>
                        <div
                            className='landing_right'
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "10px",
                            }}>
                            <img src={LandingImage}
                                className='landing_image'
                                style={{
                                    width: "100%",
                                    flex: "1",
                                }} />
                        </div>
                    </div>
                </div>
                {/* {
                    !isGenerateInitiated &&
                    <div className="text-center">
                        {
                            isDesktop() && <img src={logoImg} className='logo' />
                        }
                        <h1 className="logo-label"><Trans>Try Al-powered prompt packages</Trans> <b style={{
                            fontSize: "1em",
                            marginLeft: "3px`"
                        }}>{t('landingTitle')}</b></h1>
                        <p className="logo-description" style={{
                            color: "#000",
                            marginTop: "30px",
                            marginBottom: "0px",
                            fontSize: "14px",
                            padding: "0 20px"
                        }}>
                            <Trans>Generate the best indoor scene with Hancut Studio</Trans>
                        </p>
                    </div>
                } */}
                {/* {
                    !isGenerateInitiated &&
                    <div style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }} className='scroll-container'>
                        <SwipeableGallery data={data} onItemSelected={
                            (index) => {
                                if (index == 2) {
                                    if (token) {
                                        navigate("/studio");
                                    }
                                    else {
                                        setDialogTitle("Login Required");
                                        setDialogMessage("Please login to upload your own image and personalized prompt.");
                                        setDialogOkBtn("Login");
                                        setIsDialogShow(true);
                                    }
                                    return;
                                }
                                setPreviewImage(data[index].img);
                                setInitialImage(data[index].img);
                                setSelectedIndex(index);
                                setIsGenerateInitiated(true);
                                setGeneratedImage1(undefined);
                                setGeneratedImage2(undefined);
                                setGeneratedImage3(undefined);
                                handleGenerateImage(index);
                            }
                        } />
                    </div>
                } */}

                {/* {
                    (isGenerateInitiated) &&
                    <div key={selectedIndex} className="image-container"
                        style={{
                            width: "100%",
                            height: "auto",
                            maxWidth: "500px",
                            padding: "20px",
                            marginBottom: "20px",
                        }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "left",
                            gap: "10px",
                            marginBottom: "20px",
                            cursor: isGenerating ? "not-allowed" : "pointer",
                        }}
                            onClick={() => {
                                if (!isGenerating) setIsGenerateInitiated(false);
                            }}>
                            <ArrowLeft color='black' size={16} />
                            <p style={{
                                color: "#000",
                                fontSize: "14px",
                                margin: "0px",
                                padding: "0px",
                            }}>Try other images</p>
                        </div>
                        <div className="scroll-image not-selectable"
                            style={{
                                width: "100%",
                                height: "auto",
                                maxWidth: "500px",
                            }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                columnGap: "20px"

                            }}>
                                <img className='preview-img' src={previewImage} alt={`Item ${selectedIndex}`}
                                    style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        filter: isGenerating ? "brightness(50%)" : "none",
                                    }} />
                                {
                                    isGenerating &&
                                    <div style={{
                                        position: "absolute",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}>
                                        <div style={{
                                            position: "absolute",
                                            height: "300px",
                                            width: "300px",
                                        }}
                                        />
                                        <div style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "12px"
                                        }}
                                            className='try-lottie-loading-animation'>
                                            <Player
                                                autoplay
                                                loop
                                                src={LogoAnimation}
                                            >
                                            </Player>
                                        </div>
                                        <p style={{
                                            color: "#fff",
                                            textAlign: "center",
                                            padding: "10px",
                                            fontSize: "18px",
                                            width: "100%",
                                            marginTop: "60px",
                                            maxWidth: "250px"
                                        }}><Trans>Please wait while generating...</Trans><br /><br /><b style={{
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                        }}><Trans>We are working our best to provide with faster generation speed.</Trans><br /><Trans>Thank you so much for your kind patient.</Trans></b></p>
                                    </div>
                                }
                            </div>
                            <div style={{
                                width: "100%",
                                maxWidth: "500px",
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                marginTop: "20px",
                            }}>
                                <img
                                    style={{
                                    }}
                                    onClick={() => {
                                        setPreviewImage(initialImage);
                                    }}
                                    className='preview-thumbnail' src={initialImage} alt={`Item ${selectedIndex}`} />
                                {
                                    !generatedImage1 ?
                                        <div style={{
                                            backgroundColor: "#D8D8D8",
                                        }}
                                            className='preview-thumbnail' >
                                            {
                                                isGenerating && <Player
                                                    autoplay
                                                    loop
                                                    src={loadingSpinner}
                                                >
                                                </Player>
                                            }
                                        </div>
                                        :
                                        <img
                                            style={{
                                            }}
                                            onClick={() => {
                                                setPreviewImage(generatedImage1);
                                            }}
                                            className='preview-thumbnail' src={generatedImage1} alt={`Generated Image 1`} />
                                }
                                {
                                    !generatedImage2 ?
                                        <div style={{
                                            backgroundColor: "#D8D8D8",
                                        }}
                                            className='preview-thumbnail' >
                                            {
                                                isGenerating && <Player
                                                    autoplay
                                                    loop
                                                    src={loadingSpinner}
                                                >
                                                </Player>
                                            }
                                        </div>
                                        :
                                        <img
                                            style={{
                                            }}
                                            onClick={() => {
                                                setPreviewImage(generatedImage2);
                                            }}
                                            className='preview-thumbnail' src={generatedImage2} alt={`Generated Image 2`} />
                                }
                                {
                                    !generatedImage3 ?
                                        <div style={{
                                            backgroundColor: "#D8D8D8",
                                        }}
                                            className='preview-thumbnail' >
                                            {
                                                isGenerating && <Player
                                                    autoplay
                                                    loop
                                                    src={loadingSpinner}
                                                >
                                                </Player>
                                            }
                                        </div>
                                        :
                                        <img
                                            style={{
                                            }}
                                            onClick={() => {
                                                setPreviewImage(generatedImage3);
                                            }}
                                            className='preview-thumbnail' src={generatedImage3} alt={`Generated Image 3`} />
                                }
                            </div>
                            <div className='generate-button' style={{
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                display: "flex",
                                marginTop: "20px",
                                backgroundColor: isGenerating ? "#D8D8D8" : "#000",
                            }}
                                onClick={() => {
                                    if (isGenerating) return;
                                    handleGenerateImage(selectedIndex);
                                    setGeneratedImage1(undefined);
                                    setGeneratedImage2(undefined);
                                    setGeneratedImage3(undefined);
                                }}>
                                {
                                    isGenerating ?
                                        <div style={{
                                            width: "120px",
                                            height: "120px"
                                        }}>
                                            <Player
                                                autoplay
                                                loop
                                                src={loadingSpinner}
                                            >
                                            </Player>
                                        </div>
                                        :
                                        <p style={{
                                            margin: "auto",
                                            padding: "0px",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                        }}><Trans>Generate Again</Trans></p>
                                }
                            </div>
                        </div>
                    </div>
                } */}
            </div >
            <DropdownMenu
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen} />
            <Footer />

            {isErrorMessageShow && <DefaultDialog
                title={"Error"}
                description={errorMessage}
                show={isErrorMessageShow}
                isCancellable={false}
                onClose={() => {
                    setIsErrorMessageShow(false);
                }}
                onOk={() => {
                    setIsErrorMessageShow(false);
                }}
                okBtn='Ok'
                onCancel={() => { }}
                enableBackgroundClick={true} />}

            {isDialogShow && <DefaultDialog
                title={dialogTitle}
                description={dialogMessage}
                show={isDialogShow}
                isCancellable={true}
                onClose={() => {
                    setIsDialogShow(false);
                }}
                onOk={() => {
                    setIsDialogShow(false);
                    if (dialogTitle == "Login Required") {
                        navigate("/login");
                    }
                }}
                okBtn={dialogOkBtn}
                onCancel={() => { setIsDialogShow(false); }}
                enableBackgroundClick={true} />}
            <Toaster />
        </div >
    )
}