import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Header from '../../components/Header';
import Footer from "../../components/Footer";
import DropdownMenu from '../../components/DropdownMenu';
import useToken from "../../components/auth/useToken";
import './Showcase.css'

const Showcase = () => {
    const navigate = useNavigate();
    const { token, setToken } = useToken();

    const { t } = useTranslation();

    // TO FIX USING HOOK AND HEADER COMPONENT
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className="HomeApp">
            <Header toggleDropdown={toggleDropdown} />
            <div className=''>

            </div>

            <Footer />
            <DropdownMenu
                isDropdownOpen={isDropdownOpen} />
        </div>
    )
}

export default Showcase