import React, { useState, useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getLocalImageUrl } from '../utils/Helper'

function PcarpenterCTADialog({
    show,
    onClose,
    isCancellable = true,
    enableBackgroundClick = true,
    onOk,
    onCancel,
    setPreTitle = "",
    setPreDescription = "",
    okBtn = "Submit"
}) {
    const [descriptionInput, setDescriptionValue] = useState(setPreDescription);
    const navigate = useNavigate();
    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize]);
    const isTablet = () => {
        return screenSize.width > 768;
    }
    const isDesktop = () => {
        return screenSize.width > 1280;
    }

    const handleBackgroundClick = () => {
        if (enableBackgroundClick) {
            // onClose();
        }
    };

    const handleOkClick = () => {
        onOk();
        toast('Thank you for your valuable feedback.',
            {
                icon: '❤️',
                style: {
                    borderRadius: '10px',
                    background: '#fff',
                    color: '#000',
                    borderColor: "#000",
                    borderWidth: "2px",
                },
            }
        );
    };

    const handleCancelClick = () => {
        onCancel();
    };

    return (
        show && (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                }}
                onClick={handleBackgroundClick}
            >
                <div
                    className="dialog-container"
                    style={{
                        width: screenSize.width < 500 ? screenSize.width : 500,
                        backgroundColor: "white",
                        borderRadius: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <p className="dialog-description" style={{
                        color: "#000",
                        fontWeight: "700",
                        width: "100%",
                        paddingTop: 20,
                    }}>열린견적서 의뢰가 접수되었습니다.<br />
                        빠른 시간 내에 업체 선정 후, 고객님의 핸드폰으로 안내 문자 발송해 드리겠습니다.
                        <br /><br />
                        '박목수의 열린견적서' 카페지기 배상<br />
                        <a style={{ color: 'gray', fontSize: "13px" }} href="https://cafe.naver.com/pcarpenter">https://cafe.naver.com/pcarpenter</a></p>

                    <p className="dialog-description" style={{
                        color: "#000",
                        fontWeight: "700",
                        width: "100%",
                        paddingTop: 20,
                    }}>내가 원하는 인테리어 스타일도 찾아드려요.</p>
                    <img className="br not-selectable" style={{
                        maxWidth: "60%",
                        marginTop: "16px",
                        objectFit: "cover",
                        aspectRatio: "1/1",
                    }}
                        onClick={() => {
                            navigate("/studio");
                        }}
                        src={"https://hancut.my04.net/static/media/img2img_row1.ff6d2e75c9bdd8cf1d4d.png"} alt={"Studio Image"} />
                    <div style={{ width: "100%", display: 'flex', whiteSpace: "nowrap", justifyContent: 'flex-end', marginTop: 20 }}>
                        {isCancellable ? <button style={{ marginRight: 20 }} onClick={handleCancelClick} className="dialog-button cancel-dialog">완료</button> : <div></div>}
                        <button onClick={() => {
                            navigate("/studio");
                        }} className="dialog-button">{"AI로 1분 연출하기"}</button>
                    </div>
                </div>
            </div>
        )
    );
}

export default PcarpenterCTADialog;