import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { StoreBannerItem } from '../../store';
import './StoreBanner.css';

function StoreBanner() {

    /**
     * TODO: Move this to services and replace this with API call
    */
    var items = [

        {
            name: "Opening event 20% off",
            description: "Visit our store to get 20% off for all products",
            image: "https://images.unsplash.com/photo-1606744824163-985d376605aa?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            name: "Get inspired by our new collection",
            description: "Decorate your home with our new collection",
            image: "https://images.unsplash.com/photo-1618221195710-dd6b41faaea6?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            name: "New collection 2024",
            description: "Essentials for your home",
            image: "https://images.unsplash.com/photo-1615873968403-89e068629265?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        }
    ]

    return (
        <div className='store-banner-wrapper'>
            <Carousel>
                {
                    items.map((item, i) => <StoreBannerItem key={i} item={item} />)
                }
            </Carousel>
        </div>
    )
}

export default StoreBanner;