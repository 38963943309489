// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-products-grid-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.store-products-grid {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0px 10px;
    margin: 0 auto;
    max-width: 1200px;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/features/store/components/StoreProductsGrid.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,2BAA2B;IAC3B,WAAW;IACX,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".store-products-grid-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n}\n\n.store-products-grid {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    justify-content: flex-start;\n    width: 100%;\n    padding: 0px 10px;\n    margin: 0 auto;\n    max-width: 1200px;\n    flex-wrap: wrap;\n    row-gap: 20px;\n    column-gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
