import React, { useState, useEffect } from "react";
import "./CategoryRow.css";
import { CategoryRowItem } from "../../store";

const CategoryRow = ({ categories }) => {
    return (
        <div className="category-row">
            <div className="category-row-wrapper">
                <div className="category-row-title">
                    <p>Categories</p>
                </div>
                <div className="category-row-items">
                    {categories.map((category, index) => (
                        <CategoryRowItem category={category} index={index} key={index} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CategoryRow;