import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import '../../assets/styles/Studio.css';
import DefaultDialog from '../dialog/DefaultDialog';
import 'font-awesome/css/font-awesome.min.css';
import Header from '../Header';
import Footer from '../Footer';
import { Toaster } from 'react-hot-toast';
import { Trans } from 'react-i18next';

export default function PasswordResetConfirmation() {
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [isDialogShow, setIsDialogShow] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogAction, setDialogAction] = useState("");
    const [isBackgroundClickable, setIsBackgroundClickable] = useState(true);

    const [isInputStarted, setIsInputStarted] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const { reset_token } = useParams();
    const [isInvalidToken, setIsInvalidToken] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        async function checkIfResetTokenValid() {
            return fetch(process.env.REACT_APP_API + '/v1/auth/check_if_reset_token_valid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    reset_token: reset_token
                })
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(errorInfo => {
                            if (errorInfo.message === 'Invalid reset token') {
                                setIsInvalidToken(true);
                            }
                            else {
                                setDialogTitle('Error');
                                setDialogAction('');
                                setDialogMessage(errorInfo.message);
                                setIsDialogShow(true);
                                setIsBackgroundClickable(true);
                            }
                        });
                    }
                    else return response.json();
                })
                .then(data => {

                })
                .catch(error => {
                    console.log(error);
                });
        }
        checkIfResetTokenValid();
    }, []);

    async function sendResetPasswordRequest(credentials, navigate) {

        return fetch(process.env.REACT_APP_API + '/v1/auth/reset_password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => {
                        setDialogTitle('Error');
                        setDialogAction('');
                        setDialogMessage(errorInfo.message);
                        setIsDialogShow(true);
                        setIsBackgroundClickable(true);
                    });
                }
                else return response.json();
            })
            .then(data => {
                if (data != undefined) {
                    setDialogTitle('Success');
                    setDialogMessage('Your password is successfully updated. Please login with your new password.');
                    setDialogAction('login');
                    setIsDialogShow(true);
                    setIsBackgroundClickable(false);
                }
            })
            .catch(error => {
                console.log(error.message);
                setDialogTitle('Error');
                setDialogAction('');
                setDialogMessage("Unexpected error occurs.");
                setIsDialogShow(true);
                setIsBackgroundClickable(true);
            });
    }

    const handleSubmit = async () => {
        // e.preventDefault();

        let error = false;
        if (!password) {
            setPasswordError('Password cannot be empty.');
            error = true;
        }
        if (!confirmPassword) {
            setConfirmPasswordError('Confirm password cannot be empty.');
            error = true;
        }

        if (isInputStarted && !passwordError) {
            await sendResetPasswordRequest({
                reset_token: reset_token,
                password: password
            }, navigate);
        }
    }

    return (
        <div className="PricingApp">
            <Header toggleDropdown={() => { }} />
            <div className="content">
                <div className='wrap-content'>
                    {
                        isInvalidToken ?
                            <div className="pricing-page">
                                <div className='' style={{
                                    width: "100%",
                                    maxWidth: "1000px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column"
                                }}>
                                    <div className='login-form'>
                                        <p style={{
                                            marginTop: "2px",
                                            marginBottom: "10px",
                                            color: "black",
                                            textAlign: "center",
                                            fontWeight: "bold"
                                        }}><Trans>Your password reset link has expired. Please request a new one.</Trans></p>
                                        <button className="loginBtn" style={{
                                            marginTop: "28px"
                                        }} type="submit" onClick={() => {
                                            navigate("/password/reset/", { replace: true });
                                        }}><Trans>Send a new password reset link</Trans></button>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <section>
                                    <h2 className='pricing-title'>Reset Password</h2>
                                </section>
                                <div className="pricing-page">
                                    <div className='' style={{
                                        width: "100%",
                                        maxWidth: "1000px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "column"
                                    }}>
                                        <div className='login-form'>
                                            <label>
                                                <p style={{
                                                    marginTop: "2px",
                                                    marginBottom: "10px",
                                                    color: "black"
                                                }}>Password</p>
                                                <input type="password" name="new-password" autocomplete="new-password" onChange={e => setPassword(e.target.value)} onBlur={() => {
                                                    setIsInputStarted(true);
                                                    if (!password)
                                                        setPasswordError('Password cannot be empty.');
                                                    else if (password.length < 5)
                                                        setPasswordError('Password must be at least 5 characters.');
                                                    else
                                                        setPasswordError('');
                                                }} />
                                                {passwordError && (
                                                    <div className="input-feedback" style={{
                                                        textAlign: "left"
                                                    }}>{passwordError}</div>
                                                )}
                                            </label>

                                            <label>
                                                <p style={{
                                                    marginTop: "2px",
                                                    marginBottom: "10px",
                                                    color: "black"
                                                }}>Confrirm Password</p>
                                                <input type="password" name="confirm-new-password" autocomplete="new-password" onChange={e => setConfirmPassword(e.target.value)} onBlur={() => {
                                                    setIsInputStarted(true);
                                                    if (!confirmPassword || confirmPassword !== password)
                                                        setConfirmPasswordError('Confirm password does not match.');
                                                    else
                                                        setConfirmPasswordError('');
                                                }} />
                                                {confirmPasswordError && (
                                                    <div className="input-feedback" style={{
                                                        textAlign: "left"
                                                    }}>{confirmPasswordError}</div>
                                                )}
                                            </label>
                                            <div>
                                                <button className="loginBtn" style={{
                                                    marginTop: "28px"
                                                }} type="submit" onClick={() => handleSubmit()}>Reset Password</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
            <Footer />
            {isDialogShow &&
                <DefaultDialog
                    title={dialogTitle}
                    description={dialogMessage}
                    show={isDialogShow}
                    isCancellable={false}
                    onClose={() => {
                        setIsDialogShow(false);
                    }}
                    onOk={() => {
                        setIsDialogShow(false);

                        if (dialogAction === 'login')
                            navigate("/login", { replace: true });
                    }}
                    okBtn={dialogAction === 'login' ? 'Login' : "Ok"}
                    onCancel={() => { }}
                    enableBackgroundClick={isBackgroundClickable} />}
            <Toaster />
        </div>
    )
}