const browseDesignersList = [
    {
        id: 1,
        name: "Sofia Alvarez",
        location: "Buenos Aires, Argentina",
        image: "https://i.pinimg.com/236x/b3/cc/72/b3cc72388005b0cc8d106e5477ed22df.jpg",
        description: "I am an interior designer based in Buenos Aires, Argentina. I have been working in the fashion industry for 10 years. I have a passion for creating beautiful clothes that are both comfortable and stylish. My designs are inspired by the natural beauty of my country and the rich culture of Argentina. I am always looking for new ways to express myself through my work and I hope you enjoy my designs as much as I do!",
        portfolio: [
            "https://i.pinimg.com/564x/56/d5/c1/56d5c1117581f5c3f08b6ec8d2b85e52.jpg",
            "https://i.pinimg.com/736x/b3/d3/d4/b3d3d4312bc47061a28f0139edfe714f.jpg",
            "https://i.pinimg.com/736x/db/c7/6c/dbc76cec6593262dea3f580dc9eacb55.jpg",
            "https://i.pinimg.com/564x/ef/c5/fb/efc5fb1e899027f1f65449757c763467.jpg",
            "https://i.pinimg.com/564x/41/bf/a4/41bfa4c37365226c4f5b4a1805db91a9.jpg"
        ]
    },
    {
        id: 2,
        name: "Kenji Takahashi",
        location: "Tokyo, Japan",
        image: "https://i.pinimg.com/236x/2a/31/de/2a31de108bf780d3219add7b45687804.jpg",
        description: "I am an interior designer based in Tokyo, Japan. I have been designing clothes for over 10 years and have worked with many different brands. My designs are inspired by the Japanese culture and lifestyle. I love to create clothes that are comfortable, stylish, and functional. My goal is to make people feel good about themselves when they wear my clothes. I hope you enjoy my designs as much as I do!",
        portfolio: [
            "https://i.pinimg.com/736x/fb/19/3c/fb193cb8d3e6a67c5915623796dd65bb.jpg",
            "https://i.pinimg.com/564x/d9/70/2b/d9702b942d0c4441cd984b4661a7673d.jpg",
            "https://i.pinimg.com/736x/1f/9f/96/1f9f960aff1ce64bc870c3b080bb09b3.jpg",
            "https://i.pinimg.com/736x/d8/a8/dc/d8a8dc17a90662899af4c1045e43ffd1.jpg",
            "https://i.pinimg.com/736x/4d/ef/ac/4defacbddf61f8c3f3241b7e3d64bc8c.jpg"
        ]
    },
    {
        id: 3,
        name: "Amelia Dubois",
        location: "Paris, France",
        image: "https://i.pinimg.com/236x/12/75/40/127540404fd8f8b0e423a8e599245701.jpg",
        description: "I am an interior designer based in Paris, France. I have been designing clothes for over 10 years and have worked with many different brands. My designs are inspired by the French culture and lifestyle. I love to create clothes that are comfortable, stylish, and functional. My goal is to make people feel good about themselves when they wear my clothes. I hope you enjoy my designs as much as I do!",
        portfolio: [
            "https://i.pinimg.com/736x/62/7e/2f/627e2f777205fa29beb9fc430438f372.jpg",
            "https://i.pinimg.com/736x/60/ca/15/60ca157cf69395e66108922e551d73ac.jpg",
            "https://i.pinimg.com/236x/fe/fc/82/fefc822555c6d73a6c8cd4d0cd5826c2.jpg",
            "https://i.pinimg.com/236x/e4/8d/a0/e48da0b25b0a680ed5a23f86c61e8e21.jpg",
            "https://i.pinimg.com/236x/69/4d/74/694d7418f629bfd3b0ab191a5d03df9f.jpg"
        ]
    }
]

const getFeaturedDesigners = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(browseDesignersList);
        }, 1000);
    });
}

export default getFeaturedDesigners;