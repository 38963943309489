const similarImages = [
    {
        id: 1,
        name: "IKEA",
        image: "https://i.pinimg.com/236x/11/24/35/1124350720a8a9592040aa573335b7c5.jpg",
        price: 100,
        priceUnit: "KRW",
    },
    {
        id: 2,
        name: "West Elm",
        image: "https://i.pinimg.com/236x/b6/74/53/b67453f8edd30a481d098955fc74de4c.jpg",
        price: 100,
        priceUnit: "KRW",
    },
    {
        id: 3,
        name: "Pottery Barn",
        image: "https://i.pinimg.com/236x/e4/c7/13/e4c7130d67930a110df2d3da63df2e50.jpg",
        price: 100,
        priceUnit: "KRW",
    },
    {
        id: 4,
        name: "Ashley Furniture",
        image: "https://i.pinimg.com/564x/fb/41/71/fb4171c0c6da5ce6b0a26d32bd32da05.jpg",
        price: 100,
        priceUnit: "KRW",
    },
    {
        id: 5,
        name: "Restoration Hardware",
        image: "https://i.pinimg.com/236x/79/01/42/790142b78b65f1a0c386b7114ad58bbf.jpg",
        price: 100,
        priceUnit: "KRW",
    },
    {
        id: 6,
        name: "Wayfair",
        image: "https://i.pinimg.com/236x/ee/52/e0/ee52e0a76e6af5023e8930c028aac771.jpg",
        price: 100,
        priceUnit: "KRW",
    },
    {
        id: 7,
        name: "CB2",
        image: "https://i.pinimg.com/236x/19/a3/da/19a3da5dff8d76831bc5c3a9d227bb2e.jpg",
        price: 100,
        priceUnit: "KRW",
    },
    {
        id: 8,
        name: "Raymour & Flanigan",
        image: "https://i.pinimg.com/236x/5f/05/20/5f05202e3d92c45e782cdc0f4e283937.jpg",
        price: 100,
        priceUnit: "KRW",
    },
];

const getImageSearch = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(similarImages);
        }, 1000);
    });
}

export default getImageSearch;